import * as R from 'ramda';

import { CaseSubjectData, CaseSubjectFormData } from '../../../types/cases/case-subject';

export const transformCaseSubjectToFormData = (caseSubjectData: CaseSubjectData): Partial<CaseSubjectFormData> => {
  return {
    name: caseSubjectData.name,
    name_fr: caseSubjectData.name_fr
  };
};

export const transformCaseSubjectFormDataToCaseSubjectSubmission = (formData: Partial<CaseSubjectFormData>): CaseSubjectData => {
  return {
    name: R.trim(formData.name || ''),
    name_fr: R.trim(formData.name_fr || '')
  };
};
