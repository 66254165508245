import * as R from 'ramda';
import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import { PAGINATION_DEFAULT } from '../../../constants';
import { RepresentativeFilterFormData } from '../../../modules/representative-manager/components/RepresentativeFilterComponent';
import { PaginationMeta } from '../../util/fetch';
import { RootAction } from '../actions';
import { ManagerDeleteState } from '../types';
import { RepresentativeEntity } from '../../../types/cases/representative';

import {
  applyRepresentativeFilters,
  clearRepresentativeFilters,
  fetchDependentRepresentativeData,
  loadRepresentativeForDelete,
  loadRepresentativeForEdit,
  representativePaginatedFetchActions,
  startNewRepresentativeOperation
} from './actions';
import { RepresentativeForm } from './types';

export type RepresentativeManagerEntitiesState = {
  entities: RepresentativeEntity[],
  filter: Partial<RepresentativeFilterFormData>,
  paginated: {
    entities: RepresentativeEntity[],
    pagination: PaginationMeta,
  }
};

export type RepresentativeManagerDependentDataState = {
  loading: boolean,
};

export const representativesManagerEntitiesInitialState: RepresentativeManagerEntitiesState = {
  entities: [],
  filter: {},
  paginated: {
    entities: [],
    pagination: PAGINATION_DEFAULT
  }
};

export const representativesManagerEntitiesReducer = (state: RepresentativeManagerEntitiesState = representativesManagerEntitiesInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(applyRepresentativeFilters): {
      return {
        ...state,
        filter: action.payload
      };
    }

    case getType(clearRepresentativeFilters): {
      return {
        ...state,
        filter: {}
      };
    }

    case getType(representativePaginatedFetchActions.success): {
      return {
        ...state,
        paginated: {
          entities: action.payload.data,
          pagination: action.payload.pagination
        }
      };
    }

    case getType(representativePaginatedFetchActions.request): {
      return {
        ...state
      };
    }

    case getType(representativePaginatedFetchActions.failure): {
      return {
        ...state,
        paginated: {
          entities: [],
          pagination: PAGINATION_DEFAULT
        }
      };
    }

    default: {
      return state;
    }
  }
};

export type RepresentativeManagerNewState = {
  operationGuid: {
    [K in RepresentativeForm]?: string
  }
};

export const representativeManagerNewInitialState = {
  operationGuid: {}
};

const setOperationGuid = (key: RepresentativeForm, value: string, state: RepresentativeManagerNewState) => R.assocPath(['operationGuid', key], value, state);

export const representativeManagerNewReducer = (state: RepresentativeManagerNewState = representativeManagerNewInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(startNewRepresentativeOperation): {
      return setOperationGuid(action.payload, uuidv4(), state);
    }

    default: {
      return state;
    }
  }
};

export type RepresentativeManagerEditState = {
  id: string | null,
  data: RepresentativeEntity | null,
  operationGuid: string | null,
};

const representativeManagerEditInitialState = {
  id: null,
  data: null,
  operationGuid: null
};

export const representativeManagerEditReducer = (state: RepresentativeManagerEditState = representativeManagerEditInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(loadRepresentativeForEdit.request): {
      return {
        ...state,
        id: action.payload,
        operationGuid: null
      };
    }
    case getType(loadRepresentativeForEdit.success): {
      return {
        ...state,
        data: action.payload,
        operationGuid: uuidv4()
      };
    }
    case getType(loadRepresentativeForEdit.failure): {
      return {
        ...state,
        data: null
      };
    }
    default: {
      return state;
    }
  }
};


const representativeManagerDeleteInitialState: ManagerDeleteState<RepresentativeEntity> = {
  loading: false
};

export const representativeManagerDeleteReducer = (state: ManagerDeleteState<RepresentativeEntity> = representativeManagerDeleteInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(loadRepresentativeForDelete.request): {
      return {
        loading: true
      };
    }
    case getType(loadRepresentativeForDelete.success): {
      return {
        ...state,
        loading: false,
        operationGuid: uuidv4(),
        ...action.payload
      };
    }
    case getType(loadRepresentativeForDelete.failure): {
      return {
        ...state,
        loading: false,
        info: {
          canDelete: false,
          usage: 0
        }
      };
    }
    default: {
      return state;
    }
  }
};

export const representativeManagerDependentDataInitialState = {
  loading: false
};

export const representativeManagerDependentDataReducer = (state: RepresentativeManagerDependentDataState = representativeManagerDependentDataInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(fetchDependentRepresentativeData.request): {
      return {
        ...state,
        loading: true
      };
    }
    case getType(fetchDependentRepresentativeData.success): {
      return {
        ...state,
        loading: false
      };
    }
    case getType(fetchDependentRepresentativeData.failure): {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export const representativesManagerInitialState = {
  entities: representativesManagerEntitiesInitialState,
  dependentData: representativeManagerDependentDataInitialState,
  edit: representativeManagerEditInitialState,
  new: representativeManagerNewInitialState,
  delete: representativeManagerDeleteInitialState
};

export type RepresentativesManagerState = {
  entities: RepresentativeManagerEntitiesState,
  dependentData: RepresentativeManagerDependentDataState,
  edit: RepresentativeManagerEditState,
  new: RepresentativeManagerNewState,
  delete: ManagerDeleteState<RepresentativeEntity>
};

export const representativesManagerReducer = combineReducers<RepresentativesManagerState>({
  entities: representativesManagerEntitiesReducer,
  dependentData: representativeManagerDependentDataReducer,
  edit: representativeManagerEditReducer,
  new: representativeManagerNewReducer,
  delete: representativeManagerDeleteReducer
});
