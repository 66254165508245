import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SubmissionError } from 'redux-form';
import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';

import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { IconButtonComponent } from '../../../components/ui/IconButton';
import { PaperWrapper } from '../../../components/ui/PaperContainer';
import {
  fetchDependentCaseManagerData,
  startNewCaseManagerOperation,
  submitCaseCreateForm,
  submitImportFromUrlForm,
  toggleIsImportUrlOpen
} from '../../../redux/modules/case-manager/actions';
import {
  selectCaseManagerImportOpen,
  selectImportDetails
} from '../../../redux/modules/case-manager/selectors';
import { CaseDependantDataRequestType } from '../../../redux/modules/case-manager/types';
import { CaseFormComponent } from '../components/CaseFormComponent';
import { CaseImportInfoPanelComponent } from '../components/CaseImportInfoPanel';
import { ImportFromUrlFormSection } from '../components/ImportFromUrlFormSection';
import {
  createCaseFormPropsSelector,
  createCaseFormDispatchers,
  importFormPropsSelectors,
  importFromUrlFormDispatchers
} from '../selectors';
import { createGenericFormComponent } from '../../../components/form/createGenericFormComponent';
import { ImportIcon } from '../../../components/ui/mui-icons';
import { debugErr } from '../../../lib/debug';


const FORM_ID = 'case-new';
const NewCaseForm = createGenericFormComponent(FORM_ID, CaseFormComponent);

const caseFormPropsSelector = createCaseFormPropsSelector(FORM_ID);

export const NewCase: React.FC = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const formProps = useSelector(caseFormPropsSelector);
  const isImportOpen = useSelector(selectCaseManagerImportOpen);
  const importFormProps = useSelector(importFormPropsSelectors);
  const importData = useSelector(selectImportDetails);

  const formDispatchers = useMemo(() => createCaseFormDispatchers(dispatch, submitCaseCreateForm, FORM_ID), []);
  const toggleIsImportOpen = useCallback(() => dispatch(toggleIsImportUrlOpen()), []);
  const importFormDispatchers = useMemo(() => importFromUrlFormDispatchers(dispatch, submitImportFromUrlForm, FORM_ID), []);

  useEffect(() => {
    dispatch(startNewCaseManagerOperation());
    dispatch(fetchDependentCaseManagerData.request(CaseDependantDataRequestType.EDIT));
  }, []);

  useEffect(() => {
    const importUrl = searchParams.get('importUrl');
    if (importUrl) {
      importFormDispatchers.importFromUrlSubmitHandler({ importUrl })
        .catch((err) => {
          if (err instanceof SubmissionError) {
            debugErr('importUrl failed to import', err);
          } else {
            throw err;
          }
        })
        .then(() => {
          // remove importUrl from search
          searchParams.delete('importUrl');
          setSearchParams(searchParams);
        });
    }
  }, []);

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12}>
        <Grid container={true}>
          <Grid item={true} xs={6} container={true}>
            <Grid item={true}>
              <Typography component="h2" variant="h5">
                <BreadcrumbLink to={'/case-manager'}>Cases</BreadcrumbLink> / Create Case
              </Typography>
            </Grid>
          </Grid>
          <Grid item={true} xs={6} container={true} justifyContent="flex-end">
            <IconButtonComponent
              icon={ImportIcon}
              onClick={toggleIsImportOpen}
            >
              Import from URL
            </IconButtonComponent>
          </Grid>
        </Grid>
      </Grid>

      <Grid container={true}>
        <Grid item={true} xs={12}>
          {
            isImportOpen &&
            (
              <PaperWrapper>
                <ImportFromUrlFormSection props={importFormProps}
                                          dispatchers={importFormDispatchers} />
              </PaperWrapper>
            )
          }
          {
            importData &&
            (
              <PaperWrapper>
                <CaseImportInfoPanelComponent importData={importData} />
              </PaperWrapper>
            )
          }
          <PaperWrapper>
            <NewCaseForm {...formProps} {...formDispatchers} />
          </PaperWrapper>
        </Grid>
      </Grid>
    </Grid>
  );
}
