import { createManager } from '../lib/entity-manager';
import { FieldTypes } from '../lib/entity-manager/forms/shared';
import { CuratorApi } from '../services/CuratorApi';
import { JudgeEntity, JudgeFormData } from '../types/cases/judge';
import { transformJudgeFormDataToJudgeSubmission } from '../redux/modules/judges/utils';
import { personEntityFormatter } from '../redux/modules/case-manager/utils';

// TODO: Define a filtering type
const getEntities = (api: CuratorApi, _filter: Partial<JudgeEntity>, page: number, perPage: number) => {
  return api.searchJudges({
    filter: {},
    pagination: {
      page,
      perPage
    }
  });
};

const createEntity = (api: CuratorApi, guid: string, formData: JudgeFormData) => {
  const data = transformJudgeFormDataToJudgeSubmission(formData);
  return api.createJudge({ guid, data });
}

const checkDeleteEligibility = (api: CuratorApi, id: string) => {
  return api.getCaseQueryEntityDeleteEligibility('judge', id);
}

const updateEntity = (api: CuratorApi, guid:string, id: string, formData: JudgeFormData) => {
  const data = transformJudgeFormDataToJudgeSubmission(formData);
  return api.updateJudge({ guid, id, data });
}

const transformCountryEntityToFormData = (entity: JudgeEntity): JudgeFormData => ({
  lastName: entity.lastName,
  givenNames: entity.givenNames,
  country: {
    label: entity.country.name,
    value: entity.country.id
  }
});

const getEntity = (api: CuratorApi, id: string) => {
  return api.getJudge(id, { relations: ['country']})
    .then((result) => result.data)
};

const deleteEntity = (api: CuratorApi, guid: string, id: string) => {
  return api.deleteJudge({ guid, id });
};

const generateDefaultCountryFilterData = () => ({
  lastName: '',
  givenNames: '',
  country: undefined
});

const generateDefaultCountryFormData = () => ({
  lastName: '',
  givenNames: '',
  country: undefined
});

export const judgeManager = createManager<CuratorApi, JudgeFormData, JudgeEntity>({
  key: 'judge',
  basePath: 'judge-manager',
  displayName: {
    singular: 'Judge',
    plural: 'Judges'
  },
  generateDefaultFilterData: generateDefaultCountryFilterData,
  generateDefaultData: generateDefaultCountryFormData,
  data: {
    fetch: getEntity,
    create: createEntity,
    update: updateEntity,
    all: getEntities,
    delete: deleteEntity,
    deleteEligibility: checkDeleteEligibility,
    entityDisplayName: personEntityFormatter,
    entityDataToFormDataTransform: transformCountryEntityToFormData
  },
  listColumns: [
    {
      id: 'name',
      label: 'Name',
      getDisplayValue: (entity) => personEntityFormatter(entity)
    }
  ],
  fields: {
    givenNames: {
      label: 'Given name(s)',
      type: FieldTypes.Text
    },
    lastName: {
      label: 'Last name',
      type: FieldTypes.Text
    },
    country: {
      label: 'Country',
      isMulti: false,
      type: FieldTypes.Country
    }
  },
  filters: {}
});
