import { ErrorType, ICuratorError } from './types';

export class EntityDeleteError extends Error implements ICuratorError {
  public readonly status: number = 500;
  public readonly type: ErrorType;
  constructor(message: string, type = ErrorType.Delete) {
    super(message);
    this.type = type;
    Object.setPrototypeOf(this, EntityDeleteError.prototype);
  }
}
