import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { initialize, SubmissionError } from 'redux-form';


import { Services } from '../../../services';
import { RootAction } from '../actions';
import { RootState } from '../root';
import { PAGINATION_DEFAULT } from '../../../constants';
import { EntityFetchError } from '../../../lib/errors/EntityFetchError';
import { EntityUpdateError } from '../../../lib/errors/EntityUpdateError';
import { documentAuthorFetchActions } from '../document-authors/actions';
import { documentTypeFetchActions } from '../document-types/actions';
import { statesFetchActions } from '../states/actions';
import { countriesFetchActions } from '../countries/actions';
import {
  DOCUMENT_FILTERS_FORM_ID,
  DOCUMENT_MANAGER_ROUTE,
  EDIT_DOCUMENT_FORM_ID
} from '../../../modules/document-manager/types';
import { EntityCreateError } from '../../../lib/errors/EntityCreateError';
import { DeleteError } from '../../../lib/errors/DeleteError';
import { pushRoute } from '../router/actions';
import { debugErr } from '../../../lib/debug';
import {
  DocumentCreateSubmission,
  DocumentDeleteSubmission,
  DocumentUpdateSubmission
} from '../../../types/documents/document';

import {
  applyDocumentFilters,
  clearDocumentFilters,
  fetchDependentDocumentManagerData,
  fetchDocuments,
  loadDocumentForDelete,
  loadDocumentForEdit,
  refreshDocumentManagerList,
  submitDocumentCreateForm,
  submitDocumentCreateFormFailed,
  submitDocumentEditForm,
  submitDocumentEditFormCompleted,
  submitDocumentEditFormFailed,
  updateDocumentManagerList,
  deleteDocument,
  changeDocumentManagerListPage,
  changeDocumentManagerListPageSize
} from './actions';
import {
  selectDocumentCreateNew,
  selectDocumentDelete,
  selectDocumentEdit,
  selectDocumentFilterFormData,
  selectDocumentFilterQuery,
  selectDocumentsPagination
} from './selectors';
import { DocumentDependantDataRequestType } from './types';
import {
  transformDocumentEntityToDocumentForm,
  transformDocumentFormDataToDocumentSubmission,
  transformRawDocumentEntityToDocumentEntity
} from './utils';

type DocumentManagerEpic = Epic<RootAction, RootAction, RootState, Services>;

const fetchDocumentsEpic: DocumentManagerEpic = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(fetchDocuments.request)),
    switchMap((action) =>
      from(curatorApi.searchDocuments(action.payload)).pipe(
        map(fetchDocuments.success),
        catchError((err) => of(fetchDocuments.failure(err)))
      )
    )
  );

const refreshDocumentListEpic: DocumentManagerEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(refreshDocumentManagerList)),
    withLatestFrom(store$),
    switchMap(([_, state]) => {
      const currentPagination = selectDocumentsPagination(state);
      const currentFilters = selectDocumentFilterQuery(state);
      const currentFilterFormData = selectDocumentFilterFormData(state);
      return [
        fetchDocuments.request({
          filter: currentFilters,
          pagination: currentPagination
        }),
        initialize(DOCUMENT_FILTERS_FORM_ID, currentFilterFormData)
      ];
    })
  );

const updateListEpic: DocumentManagerEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(updateDocumentManagerList)),
    map(({ payload }) => fetchDocuments.request({
      filter: payload.filter,
      pagination: payload.pagination
    }))
  );

const changePageEpic: DocumentManagerEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeDocumentManagerListPage)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = selectDocumentsPagination(state);
      const currentFilters = selectDocumentFilterQuery(state);
      return updateDocumentManagerList({
        filter: currentFilters,
        pagination: {
          ...currentPagination,
          page: action.payload
        }
      });
    })
  );

const changePageSizeEpic: DocumentManagerEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeDocumentManagerListPageSize)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = selectDocumentsPagination(state);
      const currentFilters = selectDocumentFilterQuery(state);
      return updateDocumentManagerList({
        filter: currentFilters,
        pagination: {
          ...currentPagination,
          page: 1,
          perPage: action.payload
        }
      });
    })
  );

const applyFiltersEpic: DocumentManagerEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(applyDocumentFilters)),
    withLatestFrom(store$),
    map(([_, state]) => {
      const currentFilters = selectDocumentFilterQuery(state);
      return updateDocumentManagerList({
        filter: currentFilters,
        pagination: PAGINATION_DEFAULT
      });
    })
  );

const clearFiltersEpic: DocumentManagerEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(clearDocumentFilters)),
    switchMap(() => [
      initialize(DOCUMENT_FILTERS_FORM_ID, {}),
      fetchDependentDocumentManagerData.request(DocumentDependantDataRequestType.FILTER),
      updateDocumentManagerList({ filter: {}, pagination: PAGINATION_DEFAULT })
    ])
  );

const createDocumentEpic: DocumentManagerEpic = (action$, store$, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(submitDocumentCreateForm)),
    withLatestFrom(store$),
    switchMap(([action, state]) => {
      const { reject, resolve } = action.meta;
      const { operationGuid } = selectDocumentCreateNew(state);
      if (!operationGuid) {
        reject(new SubmissionError({ _error: 'Failed to create document, operation GUID missing' }));
        return [submitDocumentCreateFormFailed(new EntityCreateError('operation guid missing'))];
      }
      const submission: DocumentCreateSubmission = {
        guid: operationGuid,
        ...transformDocumentFormDataToDocumentSubmission(action.payload)
      };
      return from(curatorApi.createDocument(submission)).pipe(
        switchMap((response) => {
          if (response.status >= 300) {
            reject(new SubmissionError({ _error: `Failed to create document\n\n${JSON.stringify(response.data, null, 2)}` }));
            return [submitDocumentCreateFormFailed(new EntityCreateError('server error'))];
          }
          resolve();
          return [
            pushRoute(DOCUMENT_MANAGER_ROUTE),
            submitDocumentEditFormCompleted()
          ];
        }),
        catchError((err) => {
          reject(new SubmissionError({ _error: `Failed to create document. (${err.message})` }));
          return [submitDocumentCreateFormFailed(new EntityCreateError('unknown error'))];
        })
      );
    })
  );

const fetchDocumentForEditEpic: DocumentManagerEpic = (action$, _, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(loadDocumentForEdit.request)),
      switchMap((action) => {
        return from(curatorApi.getFullDocument(action.payload))
          .pipe(
            switchMap((response) => {
              const documentEntity = transformRawDocumentEntityToDocumentEntity(response.data);
              const formData = transformDocumentEntityToDocumentForm(documentEntity);
              return of(
                fetchDependentDocumentManagerData.request(DocumentDependantDataRequestType.EDIT),
                loadDocumentForEdit.success(documentEntity),
                initialize(EDIT_DOCUMENT_FORM_ID, formData)
              );
            }),
            catchError((err: Error) => {
              debugErr(err);
              return from([
                loadDocumentForEdit.failure(new EntityFetchError(err.message)),
                pushRoute(DOCUMENT_MANAGER_ROUTE)
              ]);
            })
          );
      })
    );

const updateDocumentEpic: DocumentManagerEpic = (action$, store$, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(submitDocumentEditForm)),
    withLatestFrom(store$),
    switchMap(([{ meta, payload }, state]) => {
      const { id, operationGuid } = selectDocumentEdit(state);
      if (!id) {
        meta.reject(new SubmissionError({ _error: 'Failed to update document, id is missing' }));
        return [submitDocumentEditFormFailed(new EntityUpdateError('document id missing'))];
      }
      if (!operationGuid) {
        meta.reject(new SubmissionError({ _error: 'Failed to update document, operation GUID is missing' }));
        return [submitDocumentEditFormFailed(new EntityUpdateError('operation guid missing'))];
      }
      const command: DocumentUpdateSubmission = {
        id,
        guid: operationGuid,
        ...transformDocumentFormDataToDocumentSubmission(payload)
      };

      return from(curatorApi.updateDocument(command)).pipe(
        mergeMap((response) => {
          if (response.status >= 300) {
            meta.reject(new SubmissionError({
              _error: `Failed to update document\n\n${JSON.stringify(response.data, null, 2)}`
            }));
            return [submitDocumentEditFormFailed(new EntityUpdateError('server error'))];
          }
          meta.resolve();
          return [
            submitDocumentEditFormCompleted(),
            pushRoute(DOCUMENT_MANAGER_ROUTE)
          ];
        }),
        catchError((err: Error) => {
          meta.reject(new SubmissionError({ _error: `Failed to update document. (${err.message})` }));
          return of(submitDocumentEditFormFailed(new EntityUpdateError('unknown error')));
        })
      );
    })
  );

const fetchDocumentForDeleteEpic: DocumentManagerEpic = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(loadDocumentForDelete.request)),
    switchMap(({ payload }) =>
      from(curatorApi.getDocument(payload)).pipe(
        map((response) =>
          loadDocumentForDelete.success({
            info: {
              canDelete: true,
              usage: 0
            },
            data: transformRawDocumentEntityToDocumentEntity(response.data)
          })
        ),
        catchError((err) => of(
          loadDocumentForDelete.failure(err),
          pushRoute(DOCUMENT_MANAGER_ROUTE)
        ))
      )
    )
  );

const deleteDocumentEpic: DocumentManagerEpic = (action$, store$, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(deleteDocument.request)),
    withLatestFrom(store$),
    switchMap(([action, state]) => {
      const { operationGuid } = selectDocumentDelete(state);
      if (!operationGuid) {
        return [deleteDocument.failure(new DeleteError('operation guid missing'))];
      }
      const submission: DocumentDeleteSubmission = {
        guid: operationGuid,
        id: action.payload
      };
      return from(curatorApi.deleteDocument(submission)).pipe(
        switchMap(() => [
          deleteDocument.success(),
          pushRoute(DOCUMENT_MANAGER_ROUTE)
        ]),
        catchError((err) => of(deleteDocument.failure(err)))
      );
    })
  )

const dependantDataActions: Record<DocumentDependantDataRequestType, RootAction[]> = {
  [DocumentDependantDataRequestType.EDIT]: [
    documentAuthorFetchActions.request({}),
    documentTypeFetchActions.request(),
    statesFetchActions.request()
  ],
  [DocumentDependantDataRequestType.FILTER]: [
    documentAuthorFetchActions.request({}),
    documentTypeFetchActions.request(),
    countriesFetchActions.request()
  ]
};

const fetchDocumentManagerDependantDataEpic: DocumentManagerEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(fetchDependentDocumentManagerData.request)),
    switchMap(({ payload }) => dependantDataActions[payload])
  );

export const documentManagerEpics = [
  fetchDocumentsEpic,
  refreshDocumentListEpic,
  updateListEpic,
  applyFiltersEpic,
  clearFiltersEpic,
  fetchDocumentForEditEpic,
  updateDocumentEpic,
  fetchDocumentManagerDependantDataEpic,
  createDocumentEpic,
  fetchDocumentForDeleteEpic,
  deleteDocumentEpic,
  changePageEpic,
  changePageSizeEpic
];
