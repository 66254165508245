export function getApiBaseUrl(window: Window): string {
  // grab from build variable if available
  if (process.env.REACT_APP_API_URL) {
    return process.env.REACT_APP_API_URL;
  }

  // construct based on window location
  const { hostname, protocol, port } = window.location;
  const portString = (port === '80' && protocol === 'http:') || (port === '443' && protocol === 'https:') ?
    '' :
    `:${port}`;

  return `${protocol}//api.${hostname}${portString}`;
}

export function getApiUrl(window: Window): string {
  return `${getApiBaseUrl(window)}/api`;
}

export function getCuratorLoginUrl(window: Window): string {
  return `${getApiBaseUrl(window)}/login?redirectTo=${encodeURIComponent(window.location.href)}`;
}

export function getCuratorLogoutUrl(window: Window): string {
  return `${getApiBaseUrl(window)}/logout`;
}
