import { createManager } from '../lib/entity-manager';
import { FieldTypes } from '../lib/entity-manager/forms/shared';
import { CuratorApi } from '../services/CuratorApi';
import { IndustryGroupEntity, IndustryGroupFormData } from '../types/cases/industry-group';
import { namedEntityFormatter } from '../redux/modules/case-manager/utils';

const getEntity = (api: CuratorApi, id: string) => api.getIndustryGroup(id).then((result) => result.data);

const getEntities = (api: CuratorApi, filter: Partial<IndustryGroupEntity>, page: number, perPage: number) => {
  return api.searchIndustryGroups({
    filter,
    pagination: {
      page,
      perPage
    }
  });
};


const deleteEntity = (api: CuratorApi, guid: string, id: string) => api.deleteIndustryGroup({ guid, id });

const createEntity = (api: CuratorApi, guid: string, data: IndustryGroupFormData) =>
  api.createIndustryGroup({
    guid,
    data
  })

const updateEntity = (api: CuratorApi, guid: string, id: string, data: IndustryGroupFormData) => api.updateIndustryGroup({
  guid,
  id,
  data
})

const transformIndustryGroupEntityToFormData = (entity: IndustryGroupEntity): IndustryGroupFormData => ({
  name: entity.name || '',
  name_fr: entity.name_fr || ''
});

const generateDefaultFilterData = () => ({
  name: '',
  name_fr: ''
});

const generateDefaultData = () => ({
  name: '',
  name_fr: ''
});

const checkDeleteEligibility = (api: CuratorApi, id: string) => api.getCaseQueryEntityDeleteEligibility('industry-group', id);

export const industryGroupManager = createManager<CuratorApi, IndustryGroupFormData, IndustryGroupEntity>({
  key: 'industry-group',
  basePath: 'industry-group-manager',
  displayName: {
    singular: 'Industry Group',
    plural: 'Industry Groups'
  },
  generateDefaultFilterData,
  generateDefaultData,
  listColumns: [
    {
      id: 'name',
      label: 'Name',
      getDisplayValue: (entity) => entity.name
    },
    {
      id: 'name_fr',
      label: 'Name (FR)',
      getDisplayValue: (entity) => entity.name_fr || ''
    }
  ],
  data: {
    fetch: getEntity,
    create: createEntity,
    update: updateEntity,
    all: getEntities,
    delete: deleteEntity,
    entityDataToFormDataTransform: transformIndustryGroupEntityToFormData,
    entityDisplayName: namedEntityFormatter,
    deleteEligibility: checkDeleteEligibility
  },
  fields: {
    name: {
      label: 'Name',
      type: FieldTypes.Text
    },
    name_fr: {
      label: 'Name (FR)',
      type: FieldTypes.Text
    }
  },
  filters: {}
});
