import { FormAction } from 'redux-form';
import { ActionType } from 'typesafe-actions';

import * as authActions from './auth/actions';
import * as caseBodyActions from './case-bodies/actions';
import * as caseManagerActions from './case-manager/actions';
import * as caseSubjectActions from './case-subjects/actions';
import * as countryActions from './countries/actions';
import * as courtActions from './courts/actions';
import * as firmActions from './firms/actions';
import * as judgeActions from './judges/actions';
import * as legalDomainActions from './legal-domains/actions';
import * as representativeActions from './representatives/actions';
import * as stateActions from './states/actions';
import * as documentManagerActions from './document-manager/actions';
import * as routerActions from './router/actions';

export type RootAction = ActionType<typeof caseBodyActions>
  | ActionType<typeof caseManagerActions>
  | ActionType<typeof caseSubjectActions>
  | ActionType<typeof countryActions>
  | ActionType<typeof courtActions>
  | ActionType<typeof documentManagerActions>
  | ActionType<typeof firmActions>
  | ActionType<typeof judgeActions>
  | ActionType<typeof legalDomainActions>
  | ActionType<typeof representativeActions>
  | ActionType<typeof stateActions>
  | ActionType<typeof authActions>
  | FormAction
  | ActionType<typeof routerActions>;

export const failActions = [
  caseBodyActions.caseBodyFetchActions.failure,
  caseSubjectActions.caseSubjectsFetchActions.failure,
  countryActions.countriesPaginatedFetchActions.failure,
  countryActions.countriesFetchActions.failure,
  courtActions.courtsFetchActions.failure,
  firmActions.firmsPaginatedFetchActions.failure,
  judgeActions.judgesPaginatedFetchActions.failure,
  legalDomainActions.legalDomainsFetchActions.failure,
  representativeActions.representativePaginatedFetchActions.failure,
  stateActions.statesPaginatedFetchActions.failure,
  stateActions.statesFetchActions.failure,
  caseManagerActions.casesFetchActions.failure,
  caseManagerActions.submitCaseCreateFormFailed,
  caseManagerActions.submitImportFromUrlFormFailed,
  documentManagerActions.fetchDocuments.failure
];
