import { ComponentType } from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { DecoratedComponentClass, DecoratedFormProps } from 'redux-form/lib/reduxForm';
import { Dispatch } from 'redux';

import { FormDispatchers } from '../../types/forms';

export function createGenericFormComponent<TFormData, TFormProps extends FormDispatchers<TFormData>>(
  formId: string,
  FormComponent: ComponentType<TFormProps & InjectedFormProps<TFormData, TFormProps>>
): DecoratedComponentClass<TFormData, DecoratedFormProps<TFormData, TFormProps>> {
  return reduxForm<TFormData, TFormProps>({
    form: formId,
    onSubmit: (values: TFormData, _dispatch: Dispatch, props: TFormProps) => props.submitHandler(values)
  })(FormComponent);
}
