import React from 'react';
import { useParams } from 'react-router';

import { DeleteEntity } from '../../../containers/DeleteEntity';
import { DOCUMENT_MANAGER_ROUTE } from '../types';
import { deleteDocument, loadDocumentForDelete } from '../../../redux/modules/document-manager/actions';
import { selectDocumentDelete } from '../../../redux/modules/document-manager/selectors';
import { DocumentEntity } from '../../../types/documents/document';

export const DeleteDocumentContainer: React.FC = () => {
  const { id } = useParams<'id'>();
  return (
    <DeleteEntity<DocumentEntity>
      entityId={id}
      entityName="document"
      cancelRoute={DOCUMENT_MANAGER_ROUTE}
      formatEntityLabel={(entity) => entity.title || entity.title_fr || ''}
      loadActionCreator={loadDocumentForDelete.request}
      deleteActionCreator={deleteDocument.request}
      deleteStateSelector={selectDocumentDelete}
    />
  );
}
