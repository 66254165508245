import { createSelector } from 'reselect';

import { SelectOption } from '../../../types/util';
import { RootState } from '../root';
import { LegalDomainEntity } from '../../../types/cases/legal-domain';

import { LegalDomainsState } from './reducer';

export const legalDomainsSelector = (state: RootState): LegalDomainsState => state.legalDomains;

export const legalDomainListSelector = createSelector(
  legalDomainsSelector,
  (domainMap) => Object.values(domainMap)
);

export function formatLegalDomainLabel(legalDomain: LegalDomainEntity): string {
  return legalDomain.name;
}

function formatLegalDomainsToOptions(legalDomains: LegalDomainEntity[]): SelectOption[] {
  return legalDomains.map((legalDomain) => ({
    value: legalDomain.id.toString(),
    label: formatLegalDomainLabel(legalDomain)
  }));
}

export const legalDomainOptionsSelector = createSelector(
  legalDomainListSelector,
  formatLegalDomainsToOptions
);
