import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import { PAGINATION_DEFAULT } from '../../../constants';
import { DeleteEligibilityResponse } from '../../../types/command-types';
import { PaginationMeta } from '../../util/fetch';
import { RootAction } from '../actions';
import { ManagerDeleteState } from '../types';
import { CountryData, CountryEntity } from '../../../types/cases/country';

import {
  countriesFetchActions,
  countriesPaginatedFetchActions,
  loadCountryForDelete,
  loadCountryForEdit,
  startNewCountryOperation
} from './actions';

export type CountryManagerEntitiesState = {
  entities: CountryEntity[],
  paginated: {
    entities: CountryEntity[],
    pagination: PaginationMeta,
  }
};

export const countriesManagerEntitiesInitialState: CountryManagerEntitiesState = {
  entities: [],
  paginated: {
    entities: [],
    pagination: PAGINATION_DEFAULT
  }
};

export const countriesManagerEntitiesReducer = (state: CountryManagerEntitiesState = countriesManagerEntitiesInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(countriesFetchActions.request): {
      return {
        ...state,
        entities: []
      };
    }

    case getType(countriesFetchActions.success): {
      return {
        ...state,
        entities: action.payload.data
      };
    }

    case getType(countriesFetchActions.failure): {
      return {
        ...state,
        entities: []
      };
    }

    case getType(countriesPaginatedFetchActions.success): {
      return {
        ...state,
        paginated: {
          entities: action.payload.data,
          pagination: action.payload.pagination
        }
      };
    }

    case getType(countriesPaginatedFetchActions.request): {
      return {
        ...state
      };
    }

    case getType(countriesPaginatedFetchActions.failure): {
      return {
        ...state,
        entities: [],
        pagination: PAGINATION_DEFAULT
      };
    }

    default: {
      return state;
    }
  }
};

export type CountryManagerNewState = {
  operationGuid: string | null,
};

export const countryManagerNewInitialState = {
  operationGuid: null
};

export const countryManagerNewReducer = (state: CountryManagerNewState = countryManagerNewInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(startNewCountryOperation): {
      return {
        ...state,
        operationGuid: uuidv4()
      };
    }

    default: {
      return state;
    }
  }
};

export type CountryManagerEditState = {
  id: string | null,
  data: CountryData | null,
  operationGuid: string | null,
};

const countryManagerEditInitialState = {
  id: null,
  data: null,
  operationGuid: null
};

export const countryManagerEditReducer = (country: CountryManagerEditState = countryManagerEditInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(loadCountryForEdit.request): {
      return {
        ...country,
        id: action.payload,
        operationGuid: null
      };
    }
    case getType(loadCountryForEdit.success): {
      return {
        ...country,
        data: action.payload,
        operationGuid: uuidv4()
      };
    }
    case getType(loadCountryForEdit.failure): {
      return {
        ...country,
        data: null
      };
    }
    default: {
      return country;
    }
  }
};

export type CountryManagerDeleteState = {
  id: string | null,
  operationGuid: string | null,
  loading: boolean,
  data: CountryEntity | null,
  info: DeleteEligibilityResponse
};

const countryManagerDeleteInitialState: ManagerDeleteState<CountryEntity> = {
  loading: false
};

export const countryManagerDeleteReducer = (state: ManagerDeleteState<CountryEntity> = countryManagerDeleteInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(loadCountryForDelete.request): {
      return {
        loading: true
      };
    }
    case getType(loadCountryForDelete.success): {
      return {
        ...state,
        loading: false,
        operationGuid: uuidv4(),
        ...action.payload
      };
    }
    case getType(loadCountryForDelete.failure): {
      return {
        ...state,
        loading: false,
        info: {
          canDelete: false,
          usage: 0
        }
      };
    }
    default: {
      return state;
    }
  }
};

export const countriesManagerInitialState = {
  entities: countriesManagerEntitiesInitialState,
  edit: countryManagerEditInitialState,
  new: countryManagerNewInitialState,
  delete: countryManagerDeleteInitialState
};

export type CountriesManagerState = {
  entities: CountryManagerEntitiesState,
  edit: CountryManagerEditState,
  new: CountryManagerNewState,
  delete: ManagerDeleteState<CountryEntity>
};

export const countriesManagerReducer = combineReducers<CountriesManagerState>({
  entities: countriesManagerEntitiesReducer,
  edit: countryManagerEditReducer,
  new: countryManagerNewReducer,
  delete: countryManagerDeleteReducer
});
