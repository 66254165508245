import * as React from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Route, Routes } from 'react-router';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { GlobalHeaderComponent } from './components/GlobalHeaderComponent';
import { AuthHandler } from './modules/AuthHandler';
import { CaseManagerModule } from './modules/case-manager';
import { DocumentManagerModule } from './modules/document-manager';
import { DOCUMENT_MANAGER_ROUTE } from './modules/document-manager/types';
import { LandingModule } from './modules/landing';
import { GENERATED_DATA_MANAGERS } from './config/managers';

const muiTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1a5991'
    }
  }
});

const queryClient = new QueryClient();

export const AppComponent = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={muiTheme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            <ToastContainer theme="dark" />
            <GlobalHeaderComponent />
            <Routes>
              <Route path="/" element={<LandingModule />} />
              <Route path="case-manager/*" element={<CaseManagerModule />} />
              <Route path={`${DOCUMENT_MANAGER_ROUTE}/*`} element={<DocumentManagerModule />} />
              {
                GENERATED_DATA_MANAGERS.map(({ router: { component: Component, routerPath } }) =>
                  (
                    <Route key={routerPath} path={routerPath} element={<Component />} />
                  )
                )
              }
              <Route path="auth" element={<AuthHandler />} />
            </Routes>
          </LocalizationProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
};
