import * as React from 'react';
import styled from 'styled-components';

import { CASE_MODULE_NAVIGATION_ITEMS, DOCUMENT_MODULE_NAVIGATION_ITEMS } from '../../../constants';

import { ModuleItemComponent } from './ModuleItemComponent';

const generateModuleList = (modules: any[]) =>
  modules.map((module: any) =>
    (
      <ItemWrapper key={module.name}>
        <ModuleItemComponent {...module} />
      </ItemWrapper>
    )
  );

export const CaseModuleListComponent: React.FC = () => (
  <>
    {generateModuleList(CASE_MODULE_NAVIGATION_ITEMS)}
  </>
);

export const DocumentModuleListComponent: React.FC = () => (
  <>
    {generateModuleList(DOCUMENT_MODULE_NAVIGATION_ITEMS)}
  </>
);

const ItemWrapper = styled.div`
  margin: 1rem;
  max-width: 340px;
`;
