import { createSelector } from 'reselect';

import { SelectOption } from '../../../types/util';
import { RootState } from '../root';
import { DocumentAuthorEntity } from '../../../types/documents/document-author';

export const documentAuthorsSelector = (state: RootState) => state.documentAuthors;

export const documentAuthorListSelector = createSelector(
  [documentAuthorsSelector],
  ({ entities }) => entities
);

export const documentAuthorNewSelector = createSelector(
  [documentAuthorsSelector],
  (documentAuthorState) => documentAuthorState.new
);

export const documentAuthorEditSelector = createSelector(
  [documentAuthorsSelector],
  ({ edit }) => edit
);

export const documentAuthorDeleteSelector = createSelector(
  [documentAuthorsSelector],
  (documentAuthorState) => documentAuthorState.delete
);

export function formatDocumentAuthorLabel(author: DocumentAuthorEntity): string {
  return author.name;
}

function formatDocumentAuthorsToOptions(documentAuthors: DocumentAuthorEntity[]): SelectOption[] {
  return documentAuthors.map((author) => ({
    value: author.id.toString(),
    label: formatDocumentAuthorLabel(author)
  }));
}

export const documentAuthorOptionsSelector = createSelector(
  [documentAuthorListSelector],
  formatDocumentAuthorsToOptions
);

export const documentAuthorsPaginationSelector = createSelector(
  [documentAuthorsSelector],
  ({ paginated }) => paginated.pagination
);

export const documentAuthorsPaginatedListSelector = createSelector(
  [documentAuthorsSelector],
  ({ paginated }) => paginated.entities
);

export const documentAuthorsForTableSelector = createSelector(
  [documentAuthorsPaginatedListSelector],
  (authors) => authors.map(({ id, name }) => ({ id, name }))
);
