import React from 'react';
import { Route, Routes } from 'react-router';

import { ManagerContextProvider } from '../contexts/manager-context';
import { ManagerConfig } from '../shared';
import { EntityApiContextProvider } from '../contexts/entity-api-context';
import { FormContextProvider } from '../contexts/form-context';
import { CuratorApiAbstract } from '../../../services/CuratorApiAbstract';

import { EntityManagerRoutes } from './routes';

export function createEntityManagerComponent<
  TCuratorApi extends CuratorApiAbstract = CuratorApiAbstract,
  TFormData = any,
  TEntityData = any
> (
  config: ManagerConfig<TCuratorApi, TFormData, TEntityData>
): React.ComponentType {
  const moduleContextValue = {
    dataKey: config.key,
    entityName: config.displayName,
    listColumns: config.listColumns
  }

  const apiContextValue = {
    ...config.data
  }

  const formContextValue = {
    filterConfigs: config.filters,
    defaultFilterValues: config.generateDefaultFilterData(),
    fieldConfigs: config.fields,
    defaultValues: config.generateDefaultData()
  }

  return () => {
    return (
      <ManagerContextProvider value={moduleContextValue}>
        <EntityApiContextProvider value={apiContextValue}>
          <FormContextProvider value={formContextValue}>
            <Routes>
              <Route path="/*" element={<EntityManagerRoutes />} />
            </Routes>
          </FormContextProvider>
        </EntityApiContextProvider>
      </ManagerContextProvider>
    );
  }
}
