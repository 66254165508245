import React from 'react';

import {
  CuratorEntitiesFetch,
  CuratorEntityCreate,
  CuratorEntityDelete,
  CuratorEntityDeleteEligibility,
  CuratorEntityFetch,
  CuratorEntityUpdate
} from '../data';
import { EntityDataToFormData, GenerateEntityDisplayString } from '../hooks/shared';
import { CuratorApiAbstract } from '../../../services/CuratorApiAbstract';
import { TestCuratorApi, ThingEntity, ThingFormData } from '../../../services/TestCuratorApi';

export type EntityApiContextValue<
  TCuratorApi extends CuratorApiAbstract = any,
  TFormData = any,
  TEntityData = any
> = {
  create: CuratorEntityCreate<TCuratorApi, TFormData, TEntityData>;
  fetch: CuratorEntityFetch<TCuratorApi, TEntityData>;
  update: CuratorEntityUpdate<TCuratorApi, TFormData, TEntityData>;
  all: CuratorEntitiesFetch<TCuratorApi, TEntityData>;
  delete: CuratorEntityDelete<TCuratorApi>;
  deleteEligibility: CuratorEntityDeleteEligibility<TCuratorApi>;
  entityDataToFormDataTransform: EntityDataToFormData<TFormData, TEntityData>;
  entityDisplayName: GenerateEntityDisplayString<TEntityData>
}

export const EntityApiContext = React.createContext<EntityApiContextValue | null>(null);

export const EntityApiContextProvider = EntityApiContext.Provider;

export function createExampleEntityApiContextValue(): EntityApiContextValue<TestCuratorApi, ThingFormData, ThingEntity> {
  return {
    create: (api, guid, data) => api.createThing({ data, guid }),
    all: (api, filter) => api.searchThings({ filter, pagination: { perPage: 25, page: 1 } }),
    delete: (api: TestCuratorApi, guid, id) => api.deleteThing({ guid, id }),
    deleteEligibility: (api: TestCuratorApi, id: string) => api.deleteEligibility('thing', id),
    entityDataToFormDataTransform: (entityData) => {
      return {
        title: entityData.title || ''
      }
    },
    entityDisplayName: (entityData): string => {
      return entityData.title;
    },
    fetch: (api, id) => api.getThing(id).then((result) => result.data),
    update: (api, guid, id, data) => api.updateThing({ guid, id, data })
  }
}
