import { CircularProgress } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

const DimmerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
`;

export const ProgressDimmer = () => (
  <DimmerContainer>
    <LoaderContainer>
      <CircularProgress />
    </LoaderContainer>
  </DimmerContainer>
);
