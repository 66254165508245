import { createAsyncAction, createAction } from 'typesafe-actions';

import { PageNumberType, PageSizeType, PaginatedQuery, WithPaginatedFetchMeta } from '../../util/fetch';
import { ICuratorError } from '../../../lib/errors/types';
import { DocumentSearchQuery } from '../../../services/CuratorApi';
import { DocumentFilterFormData } from '../../../modules/document-manager/components/DocumentFilterComponent';
import { FormSubmissionMeta } from '../../../types/forms';
import { LoadEntityForDeleteResponse } from '../../../types/command-types';
import { DocumentEntity, DocumentFormData, RawDocumentEntity } from '../../../types/documents/document';

import { DocumentDependantDataRequestType } from './types';

export const fetchDocuments = createAsyncAction(
  '@@CURATOR/FETCH_DOCUMENTS/REQUEST',
  '@@CURATOR/FETCH_DOCUMENTS/SUCCESS',
  '@@CURATOR/FETCH_DOCUMENTS/FAILURE'
)<PaginatedQuery<DocumentSearchQuery>, WithPaginatedFetchMeta<RawDocumentEntity>, ICuratorError>();

export const refreshDocumentManagerList = createAction('@@CURATOR/DOCUMENT_MANAGER/REFRESH_LIST')<void>();

export const changeDocumentManagerListPage = createAction('@@CURATOR/DOCUMENT_MANAGER/CHANGE_PAGE')<PageNumberType>();

export const changeDocumentManagerListPageSize = createAction('@@CURATOR/DOCUMENT_MANAGER/CHANGE_PAGE_SIZE')<PageSizeType>();

export const fetchDependentDocumentManagerData = createAsyncAction(
  '@@CURATOR/DOCUMENT_MANAGER/FETCH_DEPENDENT_DATA',
  '@@CURATOR/DOCUMENT_MANAGER/FETCH_DEPENDENT_DATA_SUCCESS',
  '@@CURATOR/DOCUMENT_MANAGER/FETCH_DEPENDENT_DATA_FAILURE'
)<DocumentDependantDataRequestType, void, void>();

export const startNewDocumentManagerOperation = createAction('@@CURATOR/DOCUMENT_MANAGER/START_NEW_OPERATION')<void>();

export const applyDocumentFilters = createAction('@@CURATOR/DOCUMENT_MANAGER/APPLY_FILTERS')<DocumentFilterFormData>();

export const clearDocumentFilters = createAction('@@CURATOR/DOCUMENT_MANAGER/RESET_FILTERS')<void>();

export const updateDocumentManagerList = createAction('@@CURATOR/DOCUMENT_MANAGER/UPDATE_LIST')<PaginatedQuery<DocumentSearchQuery>>();

export const loadDocumentForEdit = createAsyncAction(
  '@@CURATOR/DOCUMENT_MANAGER/LOAD_DOCUMENT_FOR_EDIT',
  '@@CURATOR/DOCUMENT_MANAGER/LOAD_DOCUMENT_FOR_EDIT_SUCCESS',
  '@@CURATOR/DOCUMENT_MANAGER/LOAD_DOCUMENT_FOR_EDIT_FAILURE'
)<string, DocumentEntity, ICuratorError>();

export const loadDocumentForDelete = createAsyncAction(
  '@@CURATOR/DOCUMENT_MANAGER/LOAD_DOCUMENT_FOR_DELETE',
  '@@CURATOR/DOCUMENT_MANAGER/LOAD_DOCUMENT_FOR_DELETE_SUCCESS',
  '@@CURATOR/DOCUMENT_MANAGER/LOAD_DOCUMENT_FOR_DELETE_FAILURE'
)<string, LoadEntityForDeleteResponse<DocumentEntity>, ICuratorError>();

export const submitDocumentCreateForm = createAction('@@CURATOR/DOCUMENT_MANAGER/SUBMIT_CREATE_DOCUMENT')<DocumentFormData, FormSubmissionMeta>();
export const submitDocumentCreateFormCompleted = createAction('@@CURATOR/DOCUMENT_MANAGER/SUBMIT_CREATE_DOCUMENT_COMPLETED')<void>();
export const submitDocumentCreateFormFailed = createAction('@@CURATOR/DOCUMENT_MANAGER/SUBMIT_CREATE_DOCUMENT_FAILED')<ICuratorError>();

export const submitDocumentEditForm = createAction('@@CURATOR/DOCUMENT_MANAGER/SUBMIT_EDIT_DOCUMENT')<DocumentFormData, FormSubmissionMeta>();
export const submitDocumentEditFormCompleted = createAction('@@CURATOR/DOCUMENT_MANAGER/SUBMIT_EDIT_DOCUMENT_COMPLETED')<void>();
export const submitDocumentEditFormFailed = createAction('@@CURATOR/DOCUMENT_MANAGER/SUBMIT_EDIT_DOCUMENT_FAILED')<ICuratorError>();

export const deleteDocument = createAsyncAction(
  '@@CURATOR/DOCUMENT/DELETE/REQUEST',
  '@@CURATOR/DOCUMENT/DELETE/SUCCESS',
  '@@CURATOR/DOCUMENT/DELETE/FAILURE'
)<DocumentEntity['id'], void, ICuratorError>()
