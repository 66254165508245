import { Box, Paper } from '@mui/material';
import * as React from 'react';

interface IPaperContainerProps {
  children: React.ReactNode | React.ReactNode[];
  elevation?: number;
}

export const PaperWrapper: React.FC<IPaperContainerProps> = (props) =>
  (
    <Box p={2}>
      <Paper elevation={props.elevation || 1}>
        {props.children}
      </Paper>
    </Box>
  );
