import { useMutation } from '@tanstack/react-query';

import { EntityDeleteError } from '../../errors/EntityDeleteError';
import { ErrorType } from '../../errors/types';

import { useOperationGuid } from './useOperationGuid';
import { useEntityApiContext } from './useEntityApiContext';
import { useCuratorApiContext } from './useCuratorApiContext';


export function useEntityDelete(id: string) {
  const guid = useOperationGuid();
  const api = useEntityApiContext();
  const { curatorApi } = useCuratorApiContext();

  const useMutationResult = useMutation(() => {
    return api.delete(curatorApi, guid, id).then((result) => {
      // TODO: Parse out constraint errors?
      if (result.status >= 300) {
        throw new EntityDeleteError('Entity create failed');
      }

      if (result.data.status === 'noop') {
        throw new EntityDeleteError('Entity delete failed with no op', ErrorType.Noop);
      }

      return result.data.result;
    })
  });

  return {
    ...useMutationResult,
    operationGuid: guid
  }
}
