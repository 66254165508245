import { Dispatch } from 'redux';
import { submit } from 'redux-form';

import { RootAction } from '../../redux/modules/actions';
import { countryOptionsSelector } from '../../redux/modules/countries/selectors';
import { JudgeForm } from '../../redux/modules/judges/types';
import { RootState } from '../../redux/modules/root';
import { JudgeManagerFormSubmissionMeta } from '../../types/forms';
import { JudgeFormData } from '../../types/cases/judge';

type JudgeFormSubmitAction = (values: JudgeFormData, meta: JudgeManagerFormSubmissionMeta) => RootAction;

export const createJudgeFormDispatchers = (
  dispatch: Dispatch<RootAction>,
  submitAction: JudgeFormSubmitAction,
  formId: JudgeForm,
  inline: boolean = false,
  parentForm?: string,
  fieldId?: string
) =>
  ({
    submitForm: () => dispatch(submit((formId))),
    submitHandler: (values: JudgeFormData) =>
      new Promise<void>((resolve, reject) => {
        dispatch(submitAction(values, { resolve, reject, inline, parentForm, fieldId }));
      })
  });

export const judgeFormOptionsSelector = (state: RootState) => ({
  countryOptions: countryOptionsSelector(state)
});

export type JudgeFormOptions = ReturnType<typeof judgeFormOptionsSelector>;

export type JudgeFormDispatchers = ReturnType<typeof createJudgeFormDispatchers>;

export type JudgeFormProps = JudgeFormOptions & JudgeFormDispatchers;
