import { useQuery } from '@tanstack/react-query';

import { Option } from '../shared';
import { useCuratorApiContext } from '../../entity-manager/hooks/useCuratorApiContext';

import { useEntitySelectContext } from './useEntitySelectContext';

const DEFAULT_NO_OPTIONS: Option[] = [];

export function useEntitySelectData() {
  const { get, key } = useEntitySelectContext();
  const { curatorApi } = useCuratorApiContext();

  const { data, status, fetchStatus } = useQuery(['entity-select', key], () => {
    return get(curatorApi);
  }, {
    initialData: DEFAULT_NO_OPTIONS
  });

  return {
    status,
    fetchStatus,
    data
  }
}
