import { useMutation } from '@tanstack/react-query';

import { EntityUpdateError } from '../../errors/EntityUpdateError';

import { useOperationGuid } from './useOperationGuid';
import { useEntityApiContext } from './useEntityApiContext';
import { useCuratorApiContext } from './useCuratorApiContext';

export function useEntityUpdate(id: string) {
  const guid = useOperationGuid();
  const api = useEntityApiContext();
  const { curatorApi } = useCuratorApiContext();

  const useMutationResult = useMutation((data) => {
    return api.update(curatorApi, guid, id, data).then((result) => {
      if (result.status >= 300) {
        throw new EntityUpdateError(result.data.message || 'Entity update failed for unknown reason');
      }

      if (result.data.status === 'noop') {
        throw new EntityUpdateError(result.data.message || 'Entity create failed with noop');
      }

      return result.data.result;
    })
  });

  return {
    ...useMutationResult,
    operationGuid: guid
  }
}
