import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { ManagerContext } from '../contexts/manager-context';

import { useCuratorApiContext } from './useCuratorApiContext';
import { useEntityApiContext } from './useEntityApiContext';

export function useEntityFetch(id: string) {
  const { dataKey } = useContext(ManagerContext);
  const { curatorApi } = useCuratorApiContext();
  const api = useEntityApiContext();

  return useQuery([dataKey, id], async () => {
    return api.fetch(curatorApi, id);
  }, {
    // Fresh data every time
    refetchOnWindowFocus: false,
    cacheTime: 0,
    staleTime: 0
  });
}
