import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reduxForm } from 'redux-form';

import {
  applyDocumentFilters,
  clearDocumentFilters,
  fetchDependentDocumentManagerData
} from '../../../redux/modules/document-manager/actions';
import {
  DocumentDependantDataRequestType
} from '../../../redux/modules/document-manager/types';
import {
  DocumentFilterComponent,
  DocumentFilterFormData,
  DocumentFilterFormProps
} from '../components/DocumentFilterComponent';
import { documentFormOptionsPropsSelector, filterDocumentFormDispatchers, isDocumentFormDisabledSelector } from '../selectors';
import { DOCUMENT_FILTERS_FORM_ID } from '../types';
import { BODY_AVAILABILITY_OPTIONS } from '../../../constants';

const FormComponent = reduxForm<DocumentFilterFormData, DocumentFilterFormProps>({
  form: DOCUMENT_FILTERS_FORM_ID
})(DocumentFilterComponent)

export const DocumentFilterContainer: React.FC = () => {
  const dispatch = useDispatch();

  const isFilterDisabled = useSelector(isDocumentFormDisabledSelector);
  const formProps = useSelector(documentFormOptionsPropsSelector);
  const formDispatchers = useMemo(() =>
    filterDocumentFormDispatchers(dispatch, clearDocumentFilters, applyDocumentFilters),
    [dispatch]
  );

  useEffect(() => {
    dispatch(fetchDependentDocumentManagerData.request(DocumentDependantDataRequestType.FILTER))
  }, [dispatch]);

  return (
    <FormComponent
      disabled={isFilterDisabled}
      bodyAvailability={BODY_AVAILABILITY_OPTIONS}
      {...formProps}
      {...formDispatchers}
    />
  )
}
