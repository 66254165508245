import * as R from 'ramda';
import { createSelector } from 'reselect';

import { FirmTableData } from '../../../modules/firm-manager/containers/FirmListContainer';
import { RootState } from '../root';
import { FirmEntity } from '../../../types/cases/firm';

import { FirmsManagerState } from './reducer';

export const selectFirmsManagerState = (state: RootState): FirmsManagerState => state.firms;

export const selectFirmsDependantData = createSelector(
  [selectFirmsManagerState],
  (firmsManager: FirmsManagerState) => firmsManager.dependentData
);

export const selectIsLoadingFirmsDependentData = createSelector(
  [selectFirmsDependantData],
  (dependantData) => dependantData.loading
);

export const selectFirmsManagerEntitiesState = createSelector(
  [selectFirmsManagerState],
  (firmsManager: FirmsManagerState) => firmsManager.entities);

export const selectFirmsManagerPaginatedEntitiesState = createSelector(
  [selectFirmsManagerState],
  (firmsManager) => firmsManager.entities.paginated
);

export const selectFirmsManagerPaginatedEntities = createSelector(
  [selectFirmsManagerPaginatedEntitiesState],
  (firmsPaginatedState) => firmsPaginatedState.entities
);

export function formatFirmLabel(firm: FirmEntity): string {
  return firm.name;
}

export const selectFirmsForTable = createSelector(
  [selectFirmsManagerPaginatedEntities],
  (firms: FirmEntity[]): FirmTableData[] => firms.map(R.applySpec<FirmTableData>({
    id: R.prop('id'),
    name: R.prop('name'),
    country: R.pathOr('', ['country', 'name'])
  }))
);

export const selectFirmsPagination = createSelector(
  [selectFirmsManagerPaginatedEntitiesState],
  (firmsPaginationState) => firmsPaginationState.pagination
);

export const selectFirmFilterFormData = createSelector(
  [selectFirmsManagerEntitiesState],
  (firmsManager) => firmsManager.filter
);

export const selectFirmFilterQuery = createSelector(
  [selectFirmFilterFormData],
  (formData) => ({
    name: formData.name ? formData.name : undefined,
    country: formData.country ? formData.country.value : undefined
  })
);

export const selectFirmsManagerDeleteState = createSelector(
  [selectFirmsManagerState],
  (firmsManager) => firmsManager.delete
);

export const selectDeleteFirmData = createSelector(
  [selectFirmsManagerDeleteState],
  (deleteState) => deleteState.data
);

export const selectIsLoadingFirmForDelete = createSelector(
  [selectFirmsManagerDeleteState],
  (deleteState) => deleteState.loading
);

export const selectFirmDeleteEligibility = createSelector(
  [selectFirmsManagerDeleteState],
  (deleteState) => deleteState.info
);
