import { createSelector, createStructuredSelector } from 'reselect';

import { selectCaseSubjectEntities } from '../case-subjects/selectors';
import { selectCourtsManagerEntities } from '../courts/selectors';
import { RootState } from '../root';
import { selectStatesManagerEntities } from '../states/selectors';
import { legalDomainListSelector } from '../legal-domains/selectors';
import { CourtEntity } from '../../../types/cases/court';
import { CaseSubjectEntity } from '../../../types/cases/case-subject';
import { StateEntity } from '../../../types/cases/state';
import { LegalDomainEntity } from '../../../types/cases/legal-domain';

import { transformCaseFilterFormDataToQuery } from './utils';
import { CaseManagerQuickAddForm } from './reducer';

export const selectCaseManagerState = (state: RootState) => state.caseManager;

export const selectCaseManagerEntitiesState = createSelector(
  [selectCaseManagerState],
  (caseManager) => caseManager.entities
);
export const selectCaseManagerDependentDataState = createSelector(
  [selectCaseManagerState],
  (caseManager) => caseManager.dependentData
);

export const selectCaseManagerDeleteState = createSelector(
  [selectCaseManagerState],
  (caseManager) => caseManager.delete
);

export const selectCaseManagerImportState = createSelector(
  [selectCaseManagerState],
  (caseManagerState) => caseManagerState.import
);

export const selectCases = createSelector(
  [selectCaseManagerEntitiesState],
  (caseManager) => caseManager.entities
);

export const selectCasesPagination = createSelector(
  [selectCaseManagerEntitiesState],
  (caseManager) => caseManager.pagination
);

export const selectCaseFilterFormData = createSelector(
  [selectCaseManagerEntitiesState],
  (caseManager) => caseManager.filter
);

export const selectIsLoadingCaseForDelete = createSelector(
  [selectCaseManagerDeleteState],
  (deleteState) => deleteState.loading
);

export const selectDeleteCaseData = createSelector(
  [selectCaseManagerDeleteState],
  (deleteState) => deleteState.data
);

export const selectCaseFilterQuery = createSelector(
  [selectCaseFilterFormData],
  transformCaseFilterFormDataToQuery
);

export const selectIsLoadingCaseManagerDependentData = createSelector(
  [selectCaseManagerDependentDataState],
  (dependentDataState) => dependentDataState.loading
);

export const selectCaseManagerUiState = createSelector(
  [selectCaseManagerState],
  (caseManagerState) => caseManagerState.ui
);

export const selectCaseManagerImportOpen = createSelector(
  [selectCaseManagerUiState],
  (uiState) => uiState.importOpen
);

export const selectImportDetails = createSelector(
  [selectCaseManagerImportState],
  (importState) => importState.data
);

export const selectQuickAddState = createSelector(
  [selectCaseManagerUiState],
  (uiState) => uiState.quickAdd
);

export const createQuickAddSelector = (form: CaseManagerQuickAddForm) => createSelector(
  [selectQuickAddState],
  (quickAddState) => quickAddState[form]
);

type DataSelectors = {
  caseSubjects: CaseSubjectEntity[],
  courts: CourtEntity[],
  states: StateEntity[],
  legalDomains: LegalDomainEntity[]
};

export const selectEntitiesForImportMatching = createStructuredSelector<RootState, DataSelectors>({
  caseSubjects: selectCaseSubjectEntities,
  courts: selectCourtsManagerEntities,
  states: selectStatesManagerEntities,
  legalDomains: legalDomainListSelector
});
