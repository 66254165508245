import 'whatwg-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Routes } from 'react-router';
import { createBrowserHistory } from 'history';

import { AppComponent } from './App';
import { getAppConfig } from './config';
import './dropdown.scss';
import './index.css';
import { debugLog } from './lib/debug';
import { createCuratorStore, initializeCuratorStore } from './redux/modules/root';
import { createServices } from './services';
import { BrowserRouter } from './components/router/BrowserRouter';
import { CuratorApiContextProvider } from './lib/entity-manager/contexts/curator-context';

const appConfig = getAppConfig();
debugLog('appConfig', appConfig);

const browserHistory = createBrowserHistory();
const services = createServices(appConfig, browserHistory);
const store = createCuratorStore(services);

initializeCuratorStore(store);

// Using the same api service the epics get and let the app set the token, etc., on this instance
const curatorApiContextValue = {
  curatorApi: services.curatorApi
}

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter browserHistory={browserHistory}>
      <CuratorApiContextProvider value={curatorApiContextValue}>
        <Routes>
          <Route path="/*" element={<AppComponent />} />
        </Routes>
      </CuratorApiContextProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
