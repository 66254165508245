import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { CommonFieldProps } from '../../shared';
import { EntitySelectorProps } from '../../../../entity-selector';

interface FormFieldProps extends CommonFieldProps {
  control: Control<any>;
  isMulti: boolean;
  formComponent: React.ComponentType<EntitySelectorProps>;
}

export const HookFormControlledEntitySelectorField: React.FC<FormFieldProps> = (
  {
    label,
    name,
    control,
    isMulti = false,
    formComponent: FormComponent
  }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <FormComponent
            isMulti={isMulti}
            label={label}
            {...field}
          />
        );
      }}
    />
  )
}
