import { createAsyncAction, createAction } from 'typesafe-actions';

import { ICuratorError } from '../../../lib/errors/types';
import { CaseFilterFormData } from '../../../modules/case-manager/components/CaseFilterComponent';
import { SearchableEntityType } from '../../../modules/case-manager/selectors';
import { CaseSearchQuery } from '../../../services/CuratorApi';
import { FormSubmissionMeta, ImportFormSubmissionMeta } from '../../../types/forms';
import { SelectOption } from '../../../types/util';
import { PageNumberType, PageSizeType, PaginatedQuery, WithPaginatedFetchMeta } from '../../util/fetch';
import {
  CaseData,
  CaseEntity,
  CaseFormData,
  ImportedCase,
  ImportFromUrlDataForm,
  RawCaseEntity
} from '../../../types/cases/case';

import { CaseManagerQuickAddForm } from './reducer';
import { CaseDependantDataRequestType } from './types';

export const casesFetchActions = createAsyncAction(
  '@@CURATOR/CASES/REQUEST',
  '@@CURATOR/CASES/SUCCESS',
  '@@CURATOR/CASES/FAILURE'
)<PaginatedQuery<CaseSearchQuery>, WithPaginatedFetchMeta<RawCaseEntity>, ICuratorError>();

export const refreshCaseManagerList = createAction('@@CURATOR/CASE_MANAGER/REFRESH_LIST')<void>();

export const updateCaseManagerList = createAction('@@CURATOR/CASE_MANAGER/UPDATE_LIST')<PaginatedQuery<CaseSearchQuery>>();

export const changeCaseManagerListPage = createAction('@@CURATOR/CASE_MANAGER/CHANGE_PAGE')<PageNumberType>();

export const changeCaseManagerListPageSize = createAction('@@CURATOR/CASE_MANAGER/CHANGE_PAGE_SIZE')<PageSizeType>();

export const fetchDependentCaseManagerData = createAsyncAction(
  '@@CURATOR/CASE_MANAGER/FETCH_DEPENDENT_DATA',
  '@@CURATOR/CASE_MANAGER/FETCH_DEPENDENT_DATA_SUCCESS',
  '@@CURATOR/CASE_MANAGER/FETCH_DEPENDENT_DATA_FAILURE'
)<CaseDependantDataRequestType, void, void>();

export const startNewCaseManagerOperation = createAction('@@CURATOR/CASE_MANAGER/START_NEW_OPERATION')<void>();

export const submitCaseCreateForm = createAction('@@CURATOR/CASE_MANAGER/SUBMIT_NEW_CASE')<Partial<CaseFormData>, FormSubmissionMeta>();

export const submitCaseCreateFormCompleted = createAction('@@CURATOR/CASE_MANAGER/SUBMIT_NEW_CASE_COMPLETED')<void>();

export const submitCaseCreateFormFailed = createAction('@@CURATOR/CASE_MANAGER/SUBMIT_NEW_CASE_FAILED')<ICuratorError>();

export const deleteCase = createAsyncAction(
  '@@CURATOR/CASE_MANAGER/DELETE_CASE',
  '@@CURATOR/CASE_MANAGER/DELETE_CASE_SUCCESS',
  '@@CURATOR/CASE_MANAGER/DELETE_CASE_FAILURE')<CaseEntity['id'], void, ICuratorError>();

export const submitCaseEditForm = createAction('@@CURATOR/CASE_MANAGER/SUBMIT_EDIT_CASE')<Partial<CaseFormData>, FormSubmissionMeta>();

export const submitCaseEditFormCompleted = createAction('@@CURATOR/CASE_MANAGER/SUBMIT_EDIT_CASE_COMPLETED')<void>();

export const submitCaseEditFormFailed = createAction('@@CURATOR/CASE_MANAGER/SUBMIT_EDIT_CASE_FAILURE')<ICuratorError>();

export const loadCaseForEdit = createAsyncAction(
  '@@CURATOR/CASE_MANAGER/LOAD_CASE_FOR_EDIT',
  '@@CURATOR/CASE_MANAGER/LOAD_CASE_FOR_EDIT_SUCCESS',
  '@@CURATOR/CASE_MANAGER/LOAD_CASE_FOR_EDIT_FAILURE'
)<string, CaseEntity, ICuratorError>();

export const loadCaseForDelete = createAsyncAction(
  '@@CURATOR/CASE_MANAGER/LOAD_CASE_FOR_DELETE',
  '@@CURATOR/CASE_MANAGER/LOAD_CASE_FOR_DELETE_SUCCESS',
  '@@CURATOR/CASE_MANAGER/LOAD_CASE_FOR_DELETE_FAILURE'
)<string, CaseEntity, ICuratorError>();

export type SetCasePayload = {
  formId: string,
  data: CaseData
};

export const setFormDataFromCase = createAction('@@CURATOR/CASE_MANAGER/SET_FORM_DATA_FROM_CASE')<SetCasePayload>();

export const submitImportFromUrlForm = createAction('@@CURATOR/CASE_MANAGER/SUBMIT_IMPORT_FROM_URL')<ImportFromUrlDataForm, ImportFormSubmissionMeta>();

export const submitImportFromUrlFormSuccess = createAction('@@CURATOR/CASE_MANAGER/SUBMIT_IMPORT_FROM_URL_SUCCESS')<ImportedCase>();

export const submitImportFromUrlFormFailed = createAction('@@CURATOR/CASE_MANAGER/SUBMIT_IMPORT_FROM_URL_FAILURE')<ICuratorError>();

export const toggleIsImportUrlOpen = createAction('@@CURATOR/CASE_MANAGER/TOGGLE_IS_IMPORT_URL_OPEN')<void>();

export const applyCaseFilters = createAction('@@CURATOR/CASE_MANAGER/APPLY_FILTERS')<CaseFilterFormData>();

export const clearCaseFilters = createAction('@@CURATOR/CASE_MANAGER/RESET_FILTERS')<void>();

export type ToggleQuickAddPayload = {
  form: CaseManagerQuickAddForm,
  field?: string
};

export const toggleQuickAddFormVisibility = createAction('@@CURATOR/CASE_MANAGER/TOGGLE_QUICK_ADD_FORM_VISIBILITY')<ToggleQuickAddPayload>();

export type EntitySearchPayload = {
  formId: string,
  fieldId: string,
  entityType: SearchableEntityType,
  query: string
};

export type EntitySearchMeta = {
  resolve: (options: SelectOption[]) => void,
  reject: () => void,
};

export const entityFieldSearch = createAction('@@CURATOR/CASE_MANAGER/ENTITY_FIELD_SEARCH')<EntitySearchPayload, EntitySearchMeta>();

export const entityFieldSearchSuccess = createAction('@@CURATOR/CASE_MANAGER/ENTITY_FIELD_SEARCH_SUCCESS')<void>();

export const entityFieldSearchFailed = createAction('@@CURATOR/CASE_MANAGER/ENTITY_FIELD_SEARCH_FAILED')<Error>();
