import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { useEntityFormDataFetch } from '../hooks/useEntityFormDataFetch';
import { EntityFormContainer } from '../forms';
import { FormContextProvider } from '../contexts/form-context';
import { useFormContext } from '../hooks/useFormContext';
import { useManagerContext } from '../hooks/useManagerContext';
import { useEntityUpdate } from '../hooks/useEntityUpdate';
import { EntityUpdateError } from '../../errors/EntityUpdateError';
import { EntityName } from '../shared';

interface EditComponentProps {
  id: string;
  loading?: React.ComponentType;
  error?: React.ComponentType;
  form?: React.ComponentType<EntityManagerEditFormProps>;
}


export function EntityManagerEditRoutingComponent() {
  const { id } = useParams<'id'>();

  if (!id) {
    // TODO: Toast and navigate?
    throw new Error('missing id parameter');
  }

  return (
    <EntityManagerEditContainer id={id} />
  )
}

function DefaultEntityManagerEditError() {
  return (
    <p>Failed to retrieve entity data</p>
  )
}

function DefaultEntityManagerEditLoading() {
  return (
    <p>Retrieving entity data</p>
  )
}

interface EntityManagerEditFormProps {
  data: unknown;
  id: string;
  entityName: EntityName;
}

function DefaultEntityManagerEditForm(props: EntityManagerEditFormProps) {
  const {
    id,
    data,
    entityName
  } = props;
  const navigate = useNavigate();
  const [operationState, setOperationState] = React.useState<string | null>(null);
  const { mutate, operationGuid } = useEntityUpdate(id);
  const currentFormContext = useFormContext();

  const onSubmit = React.useCallback((data) => {
    setOperationState(null);
    mutate(data, {
      onSuccess: () => {
        toast.success(`${entityName.singular} saved`);
        navigate('..')
      },
      onError: (error) => {
        toast.error(`Saving ${entityName.singular} failed.`);
        if (error instanceof EntityUpdateError) {
          setOperationState(error.message);
        } else {
          setOperationState('Unknown reason');
        }
      }
    });
  }, [mutate, navigate]);

  const formContextWithEntityData = {
    ...currentFormContext,
    defaultValues: data
  };

  return (
    <>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12}>
          <Grid container={true}>
            <Grid item={true} xs={6}>
              <Typography component="h2" variant="h5">
                <BreadcrumbLink to={'..'}>{entityName.plural}</BreadcrumbLink> /
                Edit <small>({id})</small></Typography>
            </Grid>
          </Grid>
        </Grid>
        {operationState && (
          <Grid item={true} xs={12}>
            <Card>
              <CardContent>
                <Typography variant="caption">Operation Failed ({operationGuid})</Typography>
                <Typography variant="body2">
                  {operationState}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item={true} xs={12}>
          <Grid container={true}>
            <FormContextProvider value={formContextWithEntityData}>
              <EntityFormContainer onSubmit={onSubmit} type="edit" />
            </FormContextProvider>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export function EntityManagerEditContainer(props: EditComponentProps) {
  const {
    id,
    loading: Loading = DefaultEntityManagerEditLoading,
    error: Error = DefaultEntityManagerEditError,
    form: Form = DefaultEntityManagerEditForm
  } = props;

  const { entityName } = useManagerContext();
  const { data, status } = useEntityFormDataFetch(id);

  if (status === 'loading') {
    return (
      <Loading />
    );
  }

  if (status === 'error') {
    return (
      <Error />
    );
  }

  if (status === 'success' && data) {
    return (
      <Form
        id={id}
        entityName={entityName}
        data={data}
      />
    );
  }

  // TODO: Show a generic "Oops, looks like something went wrong here :("
  return null;
}
