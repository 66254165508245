import { Button, Grid, Typography } from '@mui/material';
import { ActionType, PayloadActionCreator } from 'typesafe-actions';
import { Selector } from 'reselect';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ManagerDeleteState } from '../redux/modules/types';
import { RootState } from '../redux/modules/root';
import { RootAction } from '../redux/modules/actions';
import { pushRoute } from '../redux/modules/router/actions';

type Props<TEntity extends { id: string }> = {
  entityId: string | undefined,
  entityName: string,
  cancelRoute: string,
  formatEntityLabel: (entity: TEntity) => string,
  loadActionCreator: PayloadActionCreator<ActionType<RootAction>, TEntity['id']>,
  deleteActionCreator: PayloadActionCreator<ActionType<RootAction>, TEntity['id']>,
  deleteStateSelector: Selector<RootState, ManagerDeleteState<TEntity>>
}

export function DeleteEntity<TEntity extends { id: string }>(
  {
    entityId,
    entityName,
    deleteStateSelector,
    formatEntityLabel,
    loadActionCreator,
    cancelRoute,
    deleteActionCreator
  }: Props<TEntity>
): ReturnType<React.FC<Props<TEntity>>> {
  const dispatch = useDispatch();

  useEffect(() => {
    entityId && dispatch(loadActionCreator(entityId));
  }, [dispatch, entityId, loadActionCreator]);

  const cancelDelete = useCallback(() => dispatch(pushRoute(cancelRoute)), [dispatch, cancelRoute]);
  const confirmDelete = useCallback(() => entityId && dispatch(deleteActionCreator(entityId)), [dispatch, entityId, deleteActionCreator]);

  const { loading, data, info } = useSelector(deleteStateSelector);

  if (loading) {
    return null;
  }

  return (
    <Grid container={true} spacing={3} direction="column">
      <Grid item={true}>
        <Typography component="h2" variant="h5">
          {data ? formatEntityLabel(data) : ''} - {entityId}
        </Typography>
        {
          info?.canDelete ? (
            <Typography component="h2" variant="subtitle1">
              Are you sure you want to delete this {entityName}?
            </Typography>
          ) : (
            <Typography component="h2" variant="subtitle1">
              You cannot delete this {entityName}. It is currently in use by {info?.usage} entities.
            </Typography>
          )
        }
      </Grid>
      <Grid item={true}>
        <Grid container={true} spacing={3}>
          <Grid item={true}>
            <Button color="primary" variant="contained" onClick={cancelDelete}>
              Cancel
            </Button>
          </Grid>
          <Grid item={true}>
            <Button disabled={!info?.canDelete} onClick={confirmDelete}>Confirm</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
