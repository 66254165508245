import { useContext } from 'react';

import { EntityApiContext } from '../contexts/entity-api-context';

export function useEntityApiContext() {
  const api = useContext(EntityApiContext);
  if (!api) {
    throw new Error('EntityApiContext value must be set');
  }
  return api;
}
