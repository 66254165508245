import React from 'react';

import { EntityAsyncSelectContext } from '../contexts/entity-select';

export function useEntityAsyncSelectContext() {
  const entitySelectContext = React.useContext(EntityAsyncSelectContext);

  if (!entitySelectContext) {
    throw new Error('EntityAsyncSelectContext value must be set')
  }

  return entitySelectContext;
}
