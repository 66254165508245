import { ActionType, createReducer } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import { DocumentFilterFormData } from '../../../modules/document-manager/components/DocumentFilterComponent';
import { PaginationMeta } from '../../util/fetch';
import { PAGINATION_DEFAULT } from '../../../constants';
import { ManagerDeleteState, ManagerEditState, ManagerNewState } from '../types';
import { DocumentEntity } from '../../../types/documents/document';

import * as documentActions from './actions';
import {
  applyDocumentFilters,
  clearDocumentFilters,
  fetchDocuments, loadDocumentForDelete,
  loadDocumentForEdit,
  startNewDocumentManagerOperation
} from './actions';

type DocumentManagerRootAction = ActionType<typeof documentActions>;

export type DocumentManagerState = {
  entities: DocumentEntity[],
  filter: Partial<DocumentFilterFormData>,
  pagination: PaginationMeta,
  loading: boolean,
  dependentDataLoading: boolean,
  edit: ManagerEditState<DocumentEntity>,
  new: ManagerNewState,
  delete: ManagerDeleteState<DocumentEntity>
};

export const documentManagerEntitiesInitialState: DocumentManagerState = {
  entities: [],
  filter: {},
  pagination: PAGINATION_DEFAULT,
  loading: false,
  dependentDataLoading: false,
  edit: {},
  new: {},
  delete: {
    loading: false
  }
};

export const documentManagerReducer = createReducer<DocumentManagerState, DocumentManagerRootAction>(documentManagerEntitiesInitialState)
  .handleAction(applyDocumentFilters, (state, { payload }) => ({
    ...state,
    filter: payload
  }))
  .handleAction(clearDocumentFilters, (state) => ({
    ...state,
    filter: {}
  }))
  .handleAction(fetchDocuments.success, (state, { payload }) => ({
    ...state,
    entities: payload.data,
    pagination: payload.pagination
  }))
  .handleAction(startNewDocumentManagerOperation, (state) => ({
    ...state,
    new: {
      operationGuid: uuidv4()
    }
  }))
  .handleAction(loadDocumentForEdit.request, (state, { payload }) => ({
    ...state,
    edit: {
      ...state.edit,
      id: payload,
      operationGuid: undefined
    }
  }))
  .handleAction(loadDocumentForEdit.success, (state, { payload }) => ({
    ...state,
    edit: {
      ...state.edit,
      data: payload,
      operationGuid: uuidv4()
    }
  }))
  .handleAction(loadDocumentForDelete.request, (state) => ({
    ...state,
    delete: {
      loading: true
    }
  }))
  .handleAction(loadDocumentForDelete.success, (state, { payload }) => ({
    ...state,
    delete: {
      ...state.delete,
      loading: false,
      operationGuid: uuidv4(),
      ...payload
    }
  }))
  .handleAction(loadDocumentForDelete.failure, (state) => ({
    ...state,
    delete: {
      ...state.delete,
      loading: false,
      info: {
        canDelete: false,
        usage: 0
      }
    }
  }));
