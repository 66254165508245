import { CuratorApiAbstract } from '../../services/CuratorApiAbstract';

import { createEntityManagerComponent } from './views/module';
import { createManagerLayout } from './views/layout';
import { ManagerConfig } from './shared';

export function createManager<
  TCuratorApi extends CuratorApiAbstract,
  TFormData,
  TEntityData
>(
  config: ManagerConfig<TCuratorApi, TFormData, TEntityData>
) {
  const { basePath } = config;

  const routerPath = `/${basePath}/*`;

  const managerComponent = createEntityManagerComponent<TCuratorApi, TFormData, TEntityData>(config);
  const component = createManagerLayout({ managerComponent });

  return {
    router: { routerPath, component }
  }
}
