import { Button, SvgIconProps } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface IIconButtonProps {
  icon?: React.ComponentType<SvgIconProps>;
  children?: any;
  onClick?: () => void;
  to?: string;
  type?: 'submit' | 'reset' | 'button';
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

export const IconButtonComponent = (props: IIconButtonProps) => {
  const IconComponent = props.icon;
  const { type = 'button', color = 'primary', variant = 'text' } = props;
  const iconProps = IconComponent !== undefined ? {
    startIcon: <IconComponent />
  } : {};

  return (
    props.to ? (
        <Button
          component={Link}
          to={props.to}
          color={color}
          variant={variant}
          {...iconProps}
        >
          {props.children}
        </Button>
      ) :
      (
        <Button
          onClick={props.onClick}
          type={type}
          color={color}
          variant={variant}
          {...iconProps}
        >
          {props.children}
        </Button>
      )
  );
};
