import { Box, IconButton, SvgIconProps } from '@mui/material';
import * as React from 'react';

interface IIconProps {
  icon: React.ComponentType<SvgIconProps>;
  onClick?: () => void;
}

export const IconComponent = (props: IIconProps) => {
  const Icon = props.icon;
  return (
    <Box display="inline-block">
      <IconButton onClick={props.onClick} {...{ to: '/' } as any}>
        <Icon color="primary" />
      </IconButton>
    </Box>
  );
};
