import { caseTypeManager } from '../managers/case-type';
import { countryManager } from '../managers/country';
import { industryGroupManager } from '../managers/industry-group';
import { subjectAreaManager } from '../managers/subject-area';
import { judgeManager } from '../managers/judge';
import { stateManager } from '../managers/state';
import { courtManager } from '../managers/court';
import { representativeManager } from '../managers/representative';
import { firmManager } from '../managers/firm';
import { caseSubjectManager } from '../managers/case-subject';
import { documentTypeManager } from '../managers/document-type';
import { documentAuthorManager } from '../managers/document-author';
import { legalDomainManager } from '../managers/legal-domain';

export const GENERATED_DATA_MANAGERS = [
  caseTypeManager,
  countryManager,
  stateManager,
  courtManager,
  industryGroupManager,
  subjectAreaManager,
  judgeManager,
  representativeManager,
  firmManager,
  caseSubjectManager,
  documentTypeManager,
  documentAuthorManager,
  legalDomainManager
];
