import { createSelector } from 'reselect';

import { SelectOption } from '../../../types/util';
import { RootState } from '../root';
import { CountryEntity } from '../../../types/cases/country';

import { CountriesManagerState } from './reducer';

export const selectCountriesManagerState = (state: RootState): CountriesManagerState => state.countries;

export const selectCountriesManagerEntitiesState = createSelector(
  [selectCountriesManagerState],
  (countriesManager: CountriesManagerState) => countriesManager.entities);

export const selectCountriesManagerEntities = createSelector(
  [selectCountriesManagerEntitiesState],
  (countriesManagerEntityCountry) => countriesManagerEntityCountry.entities
);

export const selectCountriesManagerPaginatedEntitiesState = createSelector(
  [selectCountriesManagerState],
  (countriesManager) => countriesManager.entities.paginated
);

export const selectCountriesManagerPaginatedEntities = createSelector(
  [selectCountriesManagerPaginatedEntitiesState],
  (countriesPaginatedState) => countriesPaginatedState.entities
);

export function formatCountryLabel(country: CountryEntity): string {
  return country.name;
}

function formatCountriesToOptions(countries: CountryEntity[]): SelectOption[] {
  return countries.map((country) => ({
    value: country.id.toString(),
    label: formatCountryLabel(country)
  }));
}

export const countryOptionsSelector = createSelector(
  [selectCountriesManagerEntities],
  formatCountriesToOptions
);

export const selectCountriesPagination = createSelector(
  [selectCountriesManagerPaginatedEntitiesState],
  (countriesPaginatedState) => countriesPaginatedState.pagination
);

export const selectCountriesManagerDeleteState = createSelector(
  [selectCountriesManagerState],
  (judgesManager) => judgesManager.delete
);

export const selectDeleteCountryData = createSelector(
  [selectCountriesManagerDeleteState],
  (deleteState) => deleteState.data
);

export const selectIsLoadingCountryForDelete = createSelector(
  [selectCountriesManagerDeleteState],
  (deleteState) => deleteState.loading
);

export const selectCountryDeleteEligibility = createSelector(
  [selectCountriesManagerDeleteState],
  (deleteState) => deleteState.info
);
