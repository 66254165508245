import { BrowserHistory } from 'history';

import { AppConfig } from '../config';

import { CuratorApi } from './CuratorApi';

export type Services = {
  curatorApi: CuratorApi,
  browserHistory: BrowserHistory
};

export function createServices(appConfig: AppConfig, browserHistory: BrowserHistory): Services {
  return {
    curatorApi: new CuratorApi(appConfig.apiUrl),
    browserHistory
  };
}
