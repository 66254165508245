import { createAsyncAction, createAction } from 'typesafe-actions';

import { ICuratorError } from '../../../lib/errors/types';
import { RepresentativeFilterFormData } from '../../../modules/representative-manager/components/RepresentativeFilterComponent';
import { RepresentativeSearchQuery } from '../../../services/CuratorApi';
import { LoadEntityForDeleteResponse } from '../../../types/command-types';
import { FormSubmissionMeta, RepresentativeFormSubmissionMeta } from '../../../types/forms';
import {
  PageNumberType,
  PageSizeType,
  PaginatedQuery,
  WithPaginatedFetchMeta
} from '../../util/fetch';
import { RepresentativeEntity, RepresentativeFormData } from '../../../types/cases/representative';

import { RepresentativeForm } from './types';

export const representativePaginatedFetchActions = createAsyncAction(
  '@@CURATOR/REPRESENTATIVES/PAGINATED/REQUEST',
  '@@CURATOR/REPRESENTATIVES/PAGINATED/SUCCESS',
  '@@CURATOR/REPRESENTATIVES/PAGINATED/FAILURE'
)<PaginatedQuery<RepresentativeSearchQuery>, WithPaginatedFetchMeta<RepresentativeEntity>, ICuratorError>();

export const refreshRepresentativesList = createAction('@@CURATOR/REPRESENTATIVES_MANAGER/REFRESH_LIST')<void>();

export const updateRepresentativesList = createAction('@@CURATOR/REPRESENTATIVES_MANAGER/UPDATE_LIST')<PaginatedQuery<RepresentativeSearchQuery>>();

export const changeRepresentativesListPage = createAction('@@CURATOR/REPRESENTATIVES_MANAGER/CHANGE_PAGE')<PageNumberType>();

export const changeRepresentativesListPageSize = createAction('@@CURATOR/REPRESENTATIVES_MANAGER/CHANGE_PAGE_SIZE')<PageSizeType>();

export const fetchDependentRepresentativeData = createAsyncAction(
  '@@CURATOR/REPRESENTATIVES_MANAGER/FETCH_DEPENDENT_DATA',
  '@@CURATOR/REPRESENTATIVES_MANAGER/FETCH_DEPENDENT_DATA_SUCCESS',
  '@@CURATOR/REPRESENTATIVES_MANAGER/FETCH_DEPENDENT_DATA_FAILURE')<void, void, void>();

export const startNewRepresentativeOperation = createAction('@@CURATOR/REPRESENTATIVES_MANAGER/START_NEW_REPRESENTATIVE_OPERATION')<RepresentativeForm>();

export const submitRepresentativeCreateForm = createAction('@@CURATOR/REPRESENTATIVES_MANAGER/SUBMIT_NEW_REPRESENTATIVE')<Partial<RepresentativeFormData>, RepresentativeFormSubmissionMeta>();

export const submitRepresentativeCreateFormCompleted = createAction('@@CURATOR/REPRESENTATIVES_MANAGER/SUBMIT_NEW_REPRESENTATIVE_COMPLETED')<void>();

export const submitRepresentativeCreateFormFailed = createAction('@@CURATOR/REPRESENTATIVES_MANAGER/SUBMIT_NEW_REPRESENTATIVE_FAILED')<ICuratorError>();

export const submitRepresentativeEditForm = createAction('@@CURATOR/REPRESENTATIVES_MANAGER/SUBMIT_EDIT_REPRESENTATIVE')<Partial<RepresentativeFormData>, FormSubmissionMeta>();

export const submitRepresentativeEditFormCompleted = createAction('@@CURATOR/REPRESENTATIVES_MANAGER/SUBMIT_EDIT_REPRESENTATIVE_COMPLETED')<void>();

export const submitRepresentativeEditFormFailed = createAction('@@CURATOR/REPRESENTATIVES_MANAGER/SUBMIT_EDIT_REPRESENTATIVE_FAILURE')<ICuratorError>();

export const loadRepresentativeForEdit = createAsyncAction(
  '@@CURATOR/REPRESENTATIVES_MANAGER/LOAD_REPRESENTATIVE_FOR_EDIT',
  '@@CURATOR/REPRESENTATIVES_MANAGER/LOAD_REPRESENTATIVE_FOR_EDIT_SUCCESS',
  '@@CURATOR/REPRESENTATIVES_MANAGER/LOAD_REPRESENTATIVE_FOR_EDIT_FAILURE'
)<string, RepresentativeEntity, ICuratorError>();

export const applyRepresentativeFilters = createAction('@@CURATOR/REPRESENTATIVES_MANAGER/APPLY_FILTERS')<RepresentativeFilterFormData>();

export const clearRepresentativeFilters = createAction('@@CURATOR/REPRESENTATIVES_MANAGER/RESET_FILTERS')<void>();

export const loadRepresentativeForDelete = createAsyncAction(
  '@@CURATOR/REPRESENTATIVES_MANAGER/LOAD_REPRESENTATIVE_FOR_DELETE',
  '@@CURATOR/REPRESENTATIVES_MANAGER/LOAD_REPRESENTATIVE_FOR_DELETE_SUCCESS',
  '@@CURATOR/REPRESENTATIVES_MANAGER/LOAD_REPRESENTATIVE_FOR_DELETE_FAILURE'
)<string, LoadEntityForDeleteResponse<RepresentativeEntity>, ICuratorError>();

export const deleteRepresentative = createAsyncAction(
  '@@CURATOR/CASE_MANAGER/DELETE_REPRESENTATIVE',
  '@@CURATOR/CASE_MANAGER/DELETE_REPRESENTATIVE_SUCCESS',
  '@@CURATOR/CASE_MANAGER/DELETE_REPRESENTATIVE_FAILURE')<RepresentativeEntity['id'], void, ICuratorError>();

export type SetRepresentativePayload = {
  formId: string,
  data: RepresentativeEntity
};

export const setFormDataFromRepresentative = createAction('@@CURATOR/REPRESENTATIVES_MANAGER/SET_FORM_DATA_FROM_REPRESENTATIVE')<SetRepresentativePayload>();
