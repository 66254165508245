import { createAsyncAction, createAction } from 'typesafe-actions';

import { ICuratorError } from '../../../lib/errors/types';
import { PageNumberType, PageSizeType, PaginatedQuery, WithFetchMeta, WithPaginatedFetchMeta } from '../../util/fetch';
import { FormSubmissionMeta } from '../../../types/forms';
import { LoadEntityForDeleteResponse } from '../../../types/command-types';
import { DocumentAuthorEntity, DocumentAuthorFormData } from '../../../types/documents/document-author';

export const documentAuthorFetchActions = createAsyncAction(
  '@@CURATOR/DOCUMENT_AUTHOR/REQUEST',
  '@@CURATOR/DOCUMENT_AUTHOR/SUCCESS',
  '@@CURATOR/DOCUMENT_AUTHOR/FAILURE'
)<Partial<DocumentAuthorEntity>, WithFetchMeta<DocumentAuthorEntity[]>, ICuratorError>();

export const documentAuthorPaginatedFetchActions = createAsyncAction(
  '@@CURATOR/DOCUMENT_AUTHOR/PAGINATED/REQUEST',
  '@@CURATOR/DOCUMENT_AUTHOR/PAGINATED/SUCCESS',
  '@@CURATOR/DOCUMENT_AUTHOR/PAGINATED/FAILURE'
)<PaginatedQuery<DocumentAuthorEntity>, WithPaginatedFetchMeta<DocumentAuthorEntity>, ICuratorError>();

export const refreshDocumentAuthorsList = createAction('@@CURATOR/DOCUMENT_AUTHOR/REFRESH_LIST')<void>();

export const changeDocumentAuthorsListPage = createAction('@@CURATOR/DOCUMENT_AUTHOR/CHANGE_PAGE')<PageNumberType>();
export const changeDocumentAuthorsListPageSize = createAction('@@CURATOR/DOCUMENT_AUTHOR/CHANGE_PAGE_SIZE')<PageSizeType>();

export const loadDocumentAuthorForEdit = createAsyncAction(
  '@@CURATOR/DOCUMENT_AUTHOR/LOAD_FOR_EDIT/REQUEST',
  '@@CURATOR/DOCUMENT_AUTHOR/LOAD_FOR_EDIT/SUCCESS',
  '@@CURATOR/DOCUMENT_AUTHOR/LOAD_FOR_EDIT/FAILURE'
)<DocumentAuthorEntity['id'], DocumentAuthorEntity, ICuratorError>();

export const initializeCreateNewDocumentAuthor = createAction('@@CURATOR/DOCUMENT_AUTHOR/INIT_CREATE_NEW')<void>();
export const submitDocumentAuthorCreateForm = createAction('@@CURATOR/DOCUMENT_AUTHOR/SUBMIT_CREATE_FORM')<DocumentAuthorFormData, FormSubmissionMeta>();
export const submitDocumentAuthorCreateFormCompleted = createAction('@@CURATOR/DOCUMENT_AUTHOR/SUBMIT_CREATE_FORM/COMPLETED')<void>();
export const submitDocumentAuthorCreateFormFailed = createAction('@@CURATOR/DOCUMENT_AUTHOR/SUBMIT_CREATE_FORM/FAILED')<ICuratorError>();

export const submitDocumentAuthorEditForm = createAction('@@CURATOR/DOCUMENT_AUTHOR/SUBMIT_EDIT_FORM')<DocumentAuthorFormData, FormSubmissionMeta>();
export const submitDocumentAuthorEditFormCompleted = createAction('@@CURATOR/DOCUMENT_AUTHOR/SUBMIT_EDIT_FORM/COMPLETED')<void>();
export const submitDocumentAuthorEditFormFailed = createAction('@@CURATOR/DOCUMENT_AUTHOR/SUBMIT_EDIT_FORM/FAILED')<ICuratorError>();

export const loadDocumentAuthorForDelete = createAsyncAction(
  '@@CURATOR/DOCUMENT_AUTHOR/LOAD_FOR_DELETE/REQUEST',
  '@@CURATOR/DOCUMENT_AUTHOR/LOAD_FOR_DELETE/SUCCESS',
  '@@CURATOR/DOCUMENT_AUTHOR/LOAD_FOR_DELETE/FAILURE'
)<DocumentAuthorEntity['id'], LoadEntityForDeleteResponse<DocumentAuthorEntity>, ICuratorError>();

export const deleteDocumentAuthor = createAsyncAction(
  '@@CURATOR/DOCUMENT_AUTHOR/DELETE/REQUEST',
  '@@CURATOR/DOCUMENT_AUTHOR/DELETE/SUCCESS',
  '@@CURATOR/DOCUMENT_AUTHOR/DELETE/FAILURE'
)<DocumentAuthorEntity['id'], void, ICuratorError>();
