import { createSelector } from 'reselect';

import { CaseSubjectTableData } from '../../../modules/case-subject-manager/containers/CaseSubjectListContainer';
import { SelectOption } from '../../../types/util';
import { RootState } from '../root';
import { CaseSubjectEntity } from '../../../types/cases/case-subject';

import { CaseSubjectsManagerState } from './reducer';

export const selectCaseSubjectsManagerState = (state: RootState): CaseSubjectsManagerState => state.caseSubjects;

export const selectCaseSubjectEntitiesState = createSelector(
  [selectCaseSubjectsManagerState],
  (caseSubjectsManager: CaseSubjectsManagerState) => caseSubjectsManager.entities);

export const selectCaseSubjectEntities = createSelector(
  [selectCaseSubjectEntitiesState],
  (caseSubjectsEntitiesState) => caseSubjectsEntitiesState.entities
);

export const selectCaseSubjectsManagerPaginatedEntitiesState = createSelector(
  [selectCaseSubjectsManagerState],
  (caseSubjectsManager) => caseSubjectsManager.entities.paginated);

export const selectCaseSubjectsManagerPaginatedEntities = createSelector(
  [selectCaseSubjectsManagerPaginatedEntitiesState],
  (caseSubjectsManagerEntityState) => caseSubjectsManagerEntityState.entities
);

export function formatCaseSubjectLabel(caseSubject: CaseSubjectEntity): string {
  return caseSubject.name;
}

function formatCaseSubjectsToOptions(caseSubjects: CaseSubjectEntity[]): SelectOption[] {
  return caseSubjects.map((caseSubject) => ({
    value: caseSubject.id.toString(),
    label: formatCaseSubjectLabel(caseSubject)
  }));
}

export const caseSubjectOptionsSelector = createSelector(
  [selectCaseSubjectEntities],
  formatCaseSubjectsToOptions
);

export const selectCaseSubjectsForTable = createSelector(
  [selectCaseSubjectsManagerPaginatedEntities],
  (caseSubjects: CaseSubjectEntity[]): CaseSubjectTableData[] => caseSubjects.map((c) => ({
    id: c.id,
    name: c.name
  }))
);

export const selectCaseSubjectsPagination = createSelector(
  [selectCaseSubjectsManagerPaginatedEntitiesState],
  (paginatedEntitiesState) => paginatedEntitiesState.pagination
);

export const selectCaseSubjectsManagerDeleteState = createSelector(
  [selectCaseSubjectsManagerState],
  (caseSubjectsManager) => caseSubjectsManager.delete
);

export const selectDeleteCaseSubjectData = createSelector(
  [selectCaseSubjectsManagerDeleteState],
  (deleteState) => deleteState.data
);

export const selectIsLoadingCaseSubjectForDelete = createSelector(
  [selectCaseSubjectsManagerDeleteState],
  (deleteState) => deleteState.loading
);

export const selectCaseSubjectDeleteEligibility = createSelector(
  [selectCaseSubjectsManagerDeleteState],
  (deleteState) => deleteState.info
);
