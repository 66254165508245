import * as R from 'ramda';

import { ClassWithId } from '../../types/util';

export function arrayToIdMap<T extends ClassWithId>(arr: T[]): Record<string, T> {
  return R.zipObj(
    R.pipe(
      (data: T[]) => R.pluck('id', data),
      (ids) => ids.map(R.toString)
    )(arr),
    arr
  );
}
