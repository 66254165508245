import * as R from 'ramda';
import { createSelector } from 'reselect';

import { RepresentativeTableData } from '../../../modules/representative-manager/containers/RepresentativeListContainer';
import { RootState } from '../root';
import { RepresentativeEntity } from '../../../types/cases/representative';

import { RepresentativesManagerState } from './reducer';

export const selectRepresentativesManagerState = (state: RootState): RepresentativesManagerState => state.representatives;

export const selectRepresentativesDependantData = createSelector(
  [selectRepresentativesManagerState],
  (representativesManager: RepresentativesManagerState) => representativesManager.dependentData
);

export const selectIsLoadingRepresentativesDependentData = createSelector(
  [selectRepresentativesDependantData],
  (dependantData) => dependantData.loading
);

export const selectRepresentativesManagerEntitiesState = createSelector(
  [selectRepresentativesManagerState],
  (representativesManager: RepresentativesManagerState) => representativesManager.entities);

export const selectRepresentativesManagerPaginatedEntitiesState = createSelector(
  [selectRepresentativesManagerState],
  (representativesManager) => representativesManager.entities.paginated
);

export const selectRepresentativesPaginatedEntities = createSelector(
  [selectRepresentativesManagerPaginatedEntitiesState],
  (representativesPaginatedState) => representativesPaginatedState.entities
);

export function formatRepresentativeLabel(representative: RepresentativeEntity): string {
  return `${representative.givenNames} ${representative.lastName}`;
}

export const selectRepresentativesForTable = createSelector(
  [selectRepresentativesPaginatedEntities],
  (representatives: RepresentativeEntity[]): RepresentativeTableData[] => representatives.map(
    R.applySpec<RepresentativeTableData>({
      id: R.prop('id'),
      givenNames: R.prop('givenNames'),
      lastName: R.prop('lastName'),
      country: R.pathOr('', ['country', 'name'])
    })
  )
);

export const selectRepresentativesPagination = createSelector(
  [selectRepresentativesManagerPaginatedEntitiesState],
  (representativesPaginationState) => representativesPaginationState.pagination
);

export const selectRepresentativeFilterFormData = createSelector(
  [selectRepresentativesManagerEntitiesState],
  (representativesManager) => representativesManager.filter
);

export const selectRepresentativeFilterQuery = createSelector(
  [selectRepresentativeFilterFormData],
  (formData) => ({
    fullName: formData.fullName ? formData.fullName : undefined,
    country: formData.country ? formData.country.value : undefined
  })
);

export const selectRepresentativesManagerDeleteState = createSelector(
  [selectRepresentativesManagerState],
  (representativesManager) => representativesManager.delete
);

export const selectDeleteRepresentativeData = createSelector(
  [selectRepresentativesManagerDeleteState],
  (deleteState) => deleteState.data
);

export const selectIsLoadingRepresentativeForDelete = createSelector(
  [selectRepresentativesManagerDeleteState],
  (deleteState) => deleteState.loading
);

export const selectRepresentativeDeleteEligibility = createSelector(
  [selectRepresentativesManagerDeleteState],
  (deleteState) => deleteState.info
);
