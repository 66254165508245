import { getApiUrl } from './util/api-url';
import { getAuthRedirectURL } from './util/auth-redirect-url';

export type AppConfig = {
  apiUrl: string,
  authLocation: string
};

let memoizedAppConfig: AppConfig;
export function getAppConfig(): AppConfig {
  if (!memoizedAppConfig) {
    memoizedAppConfig = {
      apiUrl: getApiUrl(window),
      authLocation: getAuthRedirectURL(window)
    };
  }
  return memoizedAppConfig;
}
