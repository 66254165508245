import { createEntitySelector } from '../../../entity-selector';
import { namedEntityFormatter } from '../../../../redux/modules/case-manager/utils';
import { CuratorApi } from '../../../../services/CuratorApi';

function fetchOptions(api: CuratorApi) {
  return api.getAllCourts({}).then((result) =>
    result.data.map((entity) => ({
      label: namedEntityFormatter(entity),
      value: entity.id
    })));
}

export const CourtField = createEntitySelector({
  fetchOptions,
  key: 'court',
  pluralEntityName: 'courts'
});
