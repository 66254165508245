import { EntityTable } from '../../../components/EntityTable';

export type CaseTableData = {
  id: string,
  title: string,
  title_fr: string,
  citation: string,
  legalDomain: string,
  court: string,
  state: string,
  judgementDate: string
};

export class CaseEntityTableComponent extends EntityTable<CaseTableData> {

}
