import React from 'react';
import { Grid } from '@mui/material';

import { FormLayoutComponentProps } from './shared';

export const DefaultFormLayout: React.FC<FormLayoutComponentProps> = (props) => {
  const { fields } = props;
  const fieldElements = React.useMemo(() => {
    const wrappedElements: React.ReactElement[] = [];
    for (const [key, element] of Object.entries(fields)) {
      wrappedElements.push((
        <Grid key={key} item={true}>
          { element }
        </Grid>
      ));
    }
    return wrappedElements;
  }, [ fields]);

  return (
    <Grid container={true} direction="column" spacing={2}>
      { fieldElements }
    </Grid>
  )
}

