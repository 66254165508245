import { Dispatch } from 'redux';
import { submit } from 'redux-form';

import { RootAction } from '../../redux/modules/actions';
import { CaseManagerQuickAddForm } from '../../redux/modules/case-manager/reducer';
import { countryOptionsSelector } from '../../redux/modules/countries/selectors';
import { RepresentativeForm } from '../../redux/modules/representatives/types';
import { RootState } from '../../redux/modules/root';
import { RepresentativeFormSubmissionMeta } from '../../types/forms';
import { RepresentativeFormData } from '../../types/cases/representative';

type RepresentativeFormSubmitAction = (values: RepresentativeFormData, meta: RepresentativeFormSubmissionMeta) => RootAction;

export const createRepresentativeFormDispatchers = (
  dispatch: Dispatch<RootAction>,
  submitAction: RepresentativeFormSubmitAction,
  formId: RepresentativeForm,
  parentForm?: string,
  fieldId?: string,
  quickAddForm?: CaseManagerQuickAddForm
) =>
  ({
    submitForm: () => dispatch(submit((formId))),
    submitHandler: (values: RepresentativeFormData) =>
      new Promise<void>((resolve, reject) => {
        dispatch(submitAction(values, { resolve, reject, formId, parentForm, fieldId, quickAddForm }));
      })
  });

export const representativeFormOptionsSelector = (state: RootState) => ({
  countryOptions: countryOptionsSelector(state)
});

export type RepresentativeFormOptions = ReturnType<typeof representativeFormOptionsSelector>;

export type RepresentativeFormDispatchers = ReturnType<typeof createRepresentativeFormDispatchers>;

export type RepresentativeFormProps = RepresentativeFormOptions & RepresentativeFormDispatchers;
