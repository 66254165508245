import React from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import { FormControl } from '@mui/material';
import styled from 'styled-components';

import { CuratorApiAbstract } from '../../services/CuratorApiAbstract';

import { Option } from './shared';
import { EntityAsyncSelectContextProvider, EntitySelectContextProvider } from './contexts/entity-select';
import { useEntityAsyncSelectData } from './hooks/useEntityAsyncSelectData';
import { useEntitySelectData } from './hooks/useEntitySelectData';

// TODO: DRY this up some more

type CommonEntitySelectorOptions = {
  key: string;
  pluralEntityName: string;
}

type EntityAsyncSelectorOptions<TCuratorApi extends CuratorApiAbstract = CuratorApiAbstract> = CommonEntitySelectorOptions & {
  search: (api: TCuratorApi, filter: string) => Promise<Option[]>
}

type EntitySelectorOptions<TCuratorApi extends CuratorApiAbstract = CuratorApiAbstract> = CommonEntitySelectorOptions & {
  fetchOptions: (api: TCuratorApi) => Promise<Option[]>
}

// TODO: Multi vs Single onChange -- should be able to use a generic to narrow the required callback
export type EntitySelectorProps = {
  isMulti: boolean;
  label: string;
  onChange: (selectedOption: Option | readonly Option[] | null) => void;
  onBlur: (event: FocusEvent) => void;
  value: Option;
}

type InternalEntitySelectorProps = {
  placeholder: string;
}

const InputLabel = styled.label`
  color: black;
`;

const EntitySelector = React.forwardRef<any, EntitySelectorProps & InternalEntitySelectorProps>((props, ref) => {
  const { isMulti, onBlur, onChange, value, placeholder, label } = props;
  const { data, fetchStatus } = useEntitySelectData();

  const handleChange = React.useCallback((value: SingleValue<Option> | MultiValue<Option> | null) => {
    onChange(value);
  }, [isMulti, onChange]);

  const handleBlur = React.useCallback((event) => {
    onBlur(event);
  }, [onBlur]);

  return (
    <FormControl fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Select
        ref={ref}
        options={data}
        styles={selectStyles}
        isSearchable={true}
        isClearable={true}
        isMulti={isMulti}
        value={value}
        isLoading={fetchStatus === 'fetching'}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
      />
    </FormControl>
  );
});


// Makes sure regular and portal menus
//   float above MaterialUI labels
const selectStyles = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 100
  })
};

const AsyncEntitySelector = React.forwardRef<any, EntitySelectorProps & InternalEntitySelectorProps>((props, ref) => {
  const { isMulti, onBlur, onChange, label, value, placeholder } = props;
  const { data, setFilter, fetchStatus } = useEntityAsyncSelectData();

  const handleInputChange = React.useCallback((inputValue: string) => {
    setFilter(inputValue);
  }, [setFilter]);

  const handleChange = React.useCallback((value: SingleValue<Option> | MultiValue<Option> | null) => {
    onChange(value);
  }, [isMulti, onChange]);

  const handleBlur = React.useCallback((event) => {
    onBlur(event);
  }, [onBlur]);

  return (
    <FormControl fullWidth={true}>
      <InputLabel>{label}</InputLabel>
      <Select
        ref={ref}
        options={data}
        styles={selectStyles}
        isClearable={true}
        isMulti={isMulti}
        value={value}
        isLoading={fetchStatus === 'fetching'}
        onChange={handleChange}
        onBlur={handleBlur}
        onInputChange={handleInputChange}
        placeholder={placeholder}
      />
    </FormControl>
  );
});

export function createAsyncEntitySelector<TCuratorApi extends CuratorApiAbstract>(options: EntityAsyncSelectorOptions<TCuratorApi>) {
  const entitySelectContextValue = {
    key: options.key,
    search: options.search
  }

  const placeholder = `Search for ${options.pluralEntityName.toLowerCase()}`;

  return React.forwardRef((props: EntitySelectorProps, ref) => {
    return (
      <EntityAsyncSelectContextProvider value={entitySelectContextValue}>
        <AsyncEntitySelector
          ref={ref}
          placeholder={placeholder}
          {...props}
        />
      </EntityAsyncSelectContextProvider>
    )
  });
}

export function createEntitySelector<TCuratorApi extends CuratorApiAbstract>(options: EntitySelectorOptions<TCuratorApi>) {
  const entitySelectContextValue = {
    key: options.key,
    get: options.fetchOptions
  }

  const placeholder = `Search for ${options.pluralEntityName.toLowerCase()}`;

  return React.forwardRef((props: EntitySelectorProps, ref) => {
    return (
      <EntitySelectContextProvider value={entitySelectContextValue}>
        <EntitySelector
          ref={ref}
          placeholder={placeholder}
          {...props} />
      </EntitySelectContextProvider>
    )
  });
}
