import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Epic } from 'redux-observable';
import { forkJoin, from, of } from 'rxjs';
import { initialize, SubmissionError } from 'redux-form';

import { EntityCreateError } from '../../../lib/errors/EntityCreateError';
import { Services } from '../../../services';
import { RootState } from '../root';
import { RootAction } from '../actions';
import {
  DOCUMENT_AUTHOR_MANAGER_ROUTE,
  EDIT_DOCUMENT_AUTHOR_FORM_ID
} from '../../../modules/document-author-manager/types';
import { EntityFetchError } from '../../../lib/errors/EntityFetchError';
import { submitDocumentEditFormFailed } from '../document-manager/actions';
import { EntityUpdateError } from '../../../lib/errors/EntityUpdateError';
import { DeleteError } from '../../../lib/errors/DeleteError';
import { pushRoute } from '../router/actions';
import {
  DocumentAuthorCreateSubmission,
  DocumentAuthorDeleteSubmission,
  DocumentAuthorUpdateSubmission
} from '../../../types/documents/document-author';

import {
  documentAuthorDeleteSelector,
  documentAuthorEditSelector,
  documentAuthorNewSelector,
  documentAuthorsPaginationSelector
} from './selectors';
import {
  changeDocumentAuthorsListPage,
  changeDocumentAuthorsListPageSize,
  documentAuthorPaginatedFetchActions,
  refreshDocumentAuthorsList,
  submitDocumentAuthorCreateForm,
  submitDocumentAuthorCreateFormFailed,
  submitDocumentAuthorCreateFormCompleted,
  loadDocumentAuthorForEdit,
  submitDocumentAuthorEditForm,
  submitDocumentAuthorEditFormCompleted, submitDocumentAuthorEditFormFailed, loadDocumentAuthorForDelete, deleteDocumentAuthor
} from './actions';
import { transformDocumentAuthorFormDataToSubmission } from './utils';

type DocumentAuthorEpic = Epic<RootAction, RootAction, RootState, Services>;

const fetchPaginatedDocumentAuthorsEpic: DocumentAuthorEpic = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(documentAuthorPaginatedFetchActions.request)),
    mergeMap((action) =>
      from(curatorApi.getPaginatedDocumentAuthors(action.payload)).pipe(
        map(documentAuthorPaginatedFetchActions.success),
        catchError((err) => of(documentAuthorPaginatedFetchActions.failure(err)))
      )
    )
  );

const changePageEpic: DocumentAuthorEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeDocumentAuthorsListPage)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = documentAuthorsPaginationSelector(state);
      return documentAuthorPaginatedFetchActions.request({
        filter: {},
        pagination: {
          ...currentPagination,
          page: action.payload
        }
      });
    })
  );

const changePageSizeEpic: DocumentAuthorEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeDocumentAuthorsListPageSize)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = documentAuthorsPaginationSelector(state);
      return documentAuthorPaginatedFetchActions.request({
        filter: {},
        pagination: {
          ...currentPagination,
          page: 1,
          perPage: action.payload
        }
      });
    })
  );

const refreshDocumentAuthorsEpic: DocumentAuthorEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(refreshDocumentAuthorsList)),
    withLatestFrom(store$),
    map(([_, state]) => {
      const currentPagination = documentAuthorsPaginationSelector(state);
      const currentFilters = {};
      return documentAuthorPaginatedFetchActions.request({
        filter: currentFilters,
        pagination: currentPagination
      });
    })
  );

const createDocumentAuthorEpic: DocumentAuthorEpic = (action$, store$, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(submitDocumentAuthorCreateForm)),
    withLatestFrom(store$),
    switchMap(([action, state]) => {
      const { reject, resolve } = action.meta;
      const { operationGuid } = documentAuthorNewSelector(state);
      if (!operationGuid) {
        reject(new SubmissionError({ _error: 'Failed to create document author, operation guid missing' }));
        return [submitDocumentAuthorCreateFormFailed(new EntityCreateError('operation guid missing'))];
      }
      const submission: DocumentAuthorCreateSubmission = {
        guid: operationGuid,
        data: transformDocumentAuthorFormDataToSubmission(action.payload)
      };
      return from(curatorApi.createDocumentAuthor(submission)).pipe(
        switchMap((response) => {
          if (response.status >= 300) {
            reject(new SubmissionError({ _error: `Failed to create document author\n\n${JSON.stringify(response.data, null, 2)}` }));
            return [submitDocumentAuthorCreateFormFailed(new EntityCreateError('server error'))];
          }
          resolve();
          return [
            submitDocumentAuthorCreateFormCompleted(),
            pushRoute(DOCUMENT_AUTHOR_MANAGER_ROUTE)
          ];
        }),
        catchError((err) => {
          reject(new SubmissionError({ _error: `Failed to create document author. (${err.message})` }));
          return of(submitDocumentAuthorCreateFormFailed(new EntityCreateError('unknown error')));
        })
      )
    })
  );

const loadDocumentAuthorForUpdateEpic: DocumentAuthorEpic = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(loadDocumentAuthorForEdit.request)),
    switchMap((action) => from(curatorApi.getDocumentAuthor(action.payload)).pipe(
      switchMap((response) => [
        loadDocumentAuthorForEdit.success(response.data),
        initialize(EDIT_DOCUMENT_AUTHOR_FORM_ID, response.data)
      ]),
      catchError((err) => from([
        loadDocumentAuthorForEdit.failure(new EntityFetchError(err.message)),
        pushRoute(DOCUMENT_AUTHOR_MANAGER_ROUTE)
      ]))
    ))
  );

const updateDocumentAuthorEpic: DocumentAuthorEpic = (action$, store$, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(submitDocumentAuthorEditForm)),
    withLatestFrom(store$),
    switchMap(([action, state]) => {
      const { reject, resolve } = action.meta;
      const { id, operationGuid } = documentAuthorEditSelector(state);
      if (!id) {
        reject(new SubmissionError({ _error: 'Failed to update document author, id missing' }));
        return [submitDocumentEditFormFailed(new EntityUpdateError('document author id missing'))];
      }
      if (!operationGuid) {
        reject(new SubmissionError({ _error: 'Failed to update document author, guid missing' }));
        return [submitDocumentEditFormFailed(new EntityUpdateError('operation guid missing'))];
      }
      const submission: DocumentAuthorUpdateSubmission = {
        id,
        guid: operationGuid,
        data: transformDocumentAuthorFormDataToSubmission(action.payload)
      };

      return from(curatorApi.updateDocumentAuthor(submission)).pipe(
        switchMap((response) => {
          if (response.status >= 300) {
            reject(new SubmissionError({ _error: `Failed to update document author\n\n${JSON.stringify(response.data, null, 2)}` }));
            return [submitDocumentAuthorEditFormFailed(new EntityUpdateError('server error'))];
          }
          resolve();
          return [
            submitDocumentAuthorEditFormCompleted(),
            pushRoute(DOCUMENT_AUTHOR_MANAGER_ROUTE)
          ];
        }),
        catchError((err) => {
          reject(new SubmissionError({ _error: `Failed to update document author. (${err.message})`}));
          return [submitDocumentAuthorEditFormFailed(new EntityUpdateError('unknown error'))];
        })
      );
    })
  );

const fetchDocumentAuthorForDeleteEpic: DocumentAuthorEpic = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(loadDocumentAuthorForDelete.request)),
    switchMap(({ payload }) =>
      forkJoin([
        from(curatorApi.getDocumentAuthor(payload)),
        from(curatorApi.getDocumentQueryEntityDeleteEligibility('document-author', payload))
      ]).pipe(
        map(([entityResponse, deleteEligibilityResponse]) =>
          loadDocumentAuthorForDelete.success({
            data: entityResponse.data,
            info: deleteEligibilityResponse.data
          })
        ),
        catchError((err) => of(
          loadDocumentAuthorForDelete.failure(err),
          pushRoute(DOCUMENT_AUTHOR_MANAGER_ROUTE)
        ))
      )
    )
  );

const deleteDocumentAuthorEpic: DocumentAuthorEpic = (action$, store$, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(deleteDocumentAuthor.request)),
    withLatestFrom(store$),
    switchMap(([{ payload }, state]) => {
      const { operationGuid, info } = documentAuthorDeleteSelector(state);
      if (!operationGuid) {
        return [deleteDocumentAuthor.failure(new DeleteError('operation guid missing'))];
      }
      if (!info?.canDelete) {
        return [deleteDocumentAuthor.failure(new DeleteError('delete failed state check'))];
      }
      const submission: DocumentAuthorDeleteSubmission = {
        guid: operationGuid,
        id: payload
      };
      return from(curatorApi.deleteDocumentAuthor(submission)).pipe(
        switchMap(() => [
          deleteDocumentAuthor.success(),
          pushRoute(DOCUMENT_AUTHOR_MANAGER_ROUTE)
        ]),
        catchError((err) => of(
          deleteDocumentAuthor.failure(err)
        ))
      )
    })
  );

export const documentAuthorEpics = [
  fetchPaginatedDocumentAuthorsEpic,
  changePageEpic,
  changePageSizeEpic,
  refreshDocumentAuthorsEpic,
  createDocumentAuthorEpic,
  loadDocumentAuthorForUpdateEpic,
  updateDocumentAuthorEpic,
  fetchDocumentAuthorForDeleteEpic,
  deleteDocumentAuthorEpic
];
