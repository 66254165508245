import * as R from 'ramda';

import { JudgeFormOptions } from '../../../modules/judge-manager/selectors';
import { transformSelectOptionToValue, transformValueToSelectOption } from '../../util/forms';
import { JudgeData, JudgeEntity, JudgeFormData } from '../../../types/cases/judge';

export const transformJudgeToFormData = (judgeData: JudgeEntity, formOptions: JudgeFormOptions): Partial<JudgeFormData> => {
  return {
    givenNames: judgeData.givenNames,
    lastName: judgeData.lastName,
    country: transformValueToSelectOption(formOptions.countryOptions, judgeData.country.id)
  };
};

export const transformJudgeFormDataToJudgeSubmission = (formData: Partial<JudgeFormData>): JudgeData => {
  return {
    givenNames: R.trim(formData.givenNames || ''),
    lastName: R.trim(formData.lastName || ''),
    country: transformSelectOptionToValue(formData.country)
  };
};
