import React from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';


import { DocumentFormProps } from '../selectors';
import { Checkbox } from '../../../components/form/Checkbox';
import { ButtonRow, Column, DoubleColumn, FullRowColumn, HalfWidthColumn, Row, FlexContainer } from '../../../components/ui/FlexTable';
import { TranslatedTextField } from '../../../components/form/TranslatedTextField';
import { SelectField } from '../../../components/form/SelectField';
import { DatePickerField } from '../../../components/form/DatePickerField';
import { MultiValueTextField } from '../../../components/form/MultiValueTextField';
import { TextField } from '../../../components/form/TextField';
import { TranslatedRichTextArea } from '../../../components/form/TranslatedRichTextArea';
import { createFormValueSelector } from '../../../redux/util/forms';
import { DocumentFormData } from '../../../types/documents/document';
import { IndustryGroupField } from '../../../lib/entity-manager/forms/fields/industry-group';
import { ReduxFormEntitySelectorField } from '../../../lib/entity-manager/forms/fields/redux-form/entity-selector';
import { SubjectAreaField } from '../../../lib/entity-manager/forms/fields/subject-area';
import { DocumentAuthorField } from '../../../lib/entity-manager/forms/fields/authors';
import { StateField } from '../../../lib/entity-manager/forms/fields/state';
import { FlaggedAsListField } from '../../../components/FlagComponent/FlaggedAsListField';

type ReduxFormInjectedProps = InjectedFormProps<DocumentFormData, DocumentFormProps>;

export const DocumentFormComponent: React.FC<ReduxFormInjectedProps & DocumentFormProps> = (
  {
    isDisabled,
    documentTypeOptions,
    error,
    handleSubmit,
    form
  }
) => {

  const bodyUnavailableSelector = React.useMemo(() => createFormValueSelector(form, 'meta_bodyUnavailable'), [form]);
  const bodyUnavailable = useSelector(bodyUnavailableSelector);

  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <DoubleColumn>
          <TranslatedTextField name="title" label="Title" disabled={isDisabled} />
          <Field
            disabled={isDisabled}
            name="type"
            label="Document Type"
            component={SelectField}
            options={documentTypeOptions}
          />
          <Field
            disabled={isDisabled}
            name="authors"
            label="Document Authors"
            isMulti={true}
            formComponent={DocumentAuthorField}
            component={ReduxFormEntitySelectorField}
          />
          <Field
            disabled={isDisabled}
            name="state"
            label="State/Province"
            isMulti={false}
            formComponent={StateField}
            component={ReduxFormEntitySelectorField}
          />
          <Field
            disabled={isDisabled}
            name="meta_industryGroups"
            label="Industry Group(s)"
            isMulti={true}
            formComponent={IndustryGroupField}
            component={ReduxFormEntitySelectorField}
          />
          <Field
            disabled={isDisabled}
            name="meta_subjectAreas"
            label="Subject Area(s)"
            isMulti={true}
            formComponent={SubjectAreaField}
            component={ReduxFormEntitySelectorField}
          />
          <FlaggedAsListField
            name="meta_flaggedAs"
          />
        </DoubleColumn>
        <Column>
          <Field
            disabled={isDisabled}
            name="citations"
            label="Citation(s)"
            component={MultiValueTextField}
          />
          <Field
            disabled={isDisabled}
            name="indexNumbers"
            label="Index Number(s)"
            component={MultiValueTextField}
          />
          <Field
            disabled={isDisabled}
            name="dateReleased"
            label="Date Released"
            component={DatePickerField}
          />
          <Field
            disabled={isDisabled}
            name="datePublished"
            label="Date Published"
            component={DatePickerField}
          />
          <Field
            disabled={isDisabled}
            name="meta_codeSection"
            label="Code Section(s)"
            component={MultiValueTextField}
          />
          <Field
            disabled={isDisabled}
            label="Source Type"
            name="meta_sourceType"
            component={TextField}
            fullWidth={true}
          />
          <Field
            disabled={isDisabled}
            label="Source ID"
            name="meta_sourceId"
            component={TextField}
            fullWidth={true}
          />
        </Column>
      </Row>
      <Row>
        <HalfWidthColumn>
          <Field
            disabled={isDisabled}
            name="urlPrimary"
            label="Primary URL"
            component={TextField}
          />
        </HalfWidthColumn>
        <HalfWidthColumn>
          <Field
            disabled={isDisabled}
            name="urlSecondary"
            label="Secondary URL"
            component={TextField}
          />
        </HalfWidthColumn>
      </Row>
      <TranslatedRichTextArea height="20vh" name="meta_headlineBody" label="Headline" />
      <TranslatedRichTextArea height="20vh" name="meta_crossReferenceBody" label="Cross Reference" />
      <TranslatedRichTextArea name="descriptionBody" label="Description" height={350} />
      <Row>
        <HalfWidthColumn flexDirection="column">
          <FlexContainer>
            <Field
              disabled={isDisabled}
              name="meta_bodyUnavailable"
              label="Unavailable Document Body"
              fullWidth={false}
              component={Checkbox}
            />
          </FlexContainer>
        </HalfWidthColumn>
      </Row>
      <TranslatedRichTextArea disabled={bodyUnavailable} hidden={bodyUnavailable} name="documentBody" label="Document Body" />
      <ButtonRow>
        <Column>
          <Button disabled={isDisabled} type="submit" variant={'contained'} color="primary">Submit</Button>
        </Column>
      </ButtonRow>
      {
        error &&
        (
          <Row>
            <FullRowColumn>
              <pre>{error}</pre>
            </FullRowColumn>
          </Row>
        )
      }
    </form>
  );
};
