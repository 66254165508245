import { Epic } from 'redux-observable';
import { isActionOf } from 'typesafe-actions';
import { filter, ignoreElements, map } from 'rxjs/operators';

import { RootAction } from '../actions';
import { RootState } from '../root';
import { Services } from '../../../services';

import { pushRoute } from './actions';


export const pushRouteEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { browserHistory }) =>
  action$.pipe(
    filter(isActionOf(pushRoute)),
    map(({ payload }) => {
      browserHistory.push(payload);
    }),
    ignoreElements()
);

export const routerEpics = [
  pushRouteEpic
];
