import { createManager } from '../lib/entity-manager';
import { FieldTypes } from '../lib/entity-manager/forms/shared';
import { CuratorApi } from '../services/CuratorApi';
import { LegalDomainEntity, LegalDomainFormData } from '../types/cases/legal-domain';
import { namedEntityFormatter } from '../redux/modules/case-manager/utils';

const getEntity = (api: CuratorApi, id: string) => api.getLegalDomain(id).then((result) => result.data);

const getEntities = (api: CuratorApi, filter: Partial<LegalDomainEntity>, page: number, perPage: number) => {
  return api.searchLegalDomains({
    filter,
    pagination: {
      page,
      perPage
    }
  });
};


const deleteEntity = (api: CuratorApi, guid: string, id: string) => api.deleteLegalDomain({ guid, id });

const createEntity = (api: CuratorApi, guid: string, data: LegalDomainFormData) =>
  api.createLegalDomain({
    guid,
    data
  })

const updateEntity = (api: CuratorApi, guid: string, id: string, data: LegalDomainFormData) => api.updateLegalDomain({
  guid,
  id,
  data
})

const transformLegalDomainEntityToFormData = (entity: LegalDomainEntity): LegalDomainFormData => ({
  name: entity.name || '',
  name_fr: entity.name_fr || ''
});

const generateDefaultFilterData = () => ({
  name: '',
  name_fr: ''
});

const generateDefaultData = () => ({
  name: '',
  name_fr: ''
});

const checkDeleteEligibility = (api: CuratorApi, id: string) => api.getCaseQueryEntityDeleteEligibility('legal-domain', id);

export const legalDomainManager = createManager<CuratorApi, LegalDomainFormData, LegalDomainEntity>({
  key: 'legal-domain',
  basePath: 'legal-domain-manager',
  displayName: {
    singular: 'Legal Domain',
    plural: 'Legal Domains'
  },
  generateDefaultFilterData,
  generateDefaultData,
  listColumns: [
    {
      id: 'name',
      label: 'Name',
      getDisplayValue: (entity) => entity.name
    },
    {
      id: 'name_fr',
      label: 'Name (FR)',
      getDisplayValue: (entity) => entity.name_fr || ''
    }
  ],
  data: {
    fetch: getEntity,
    create: createEntity,
    update: updateEntity,
    all: getEntities,
    delete: deleteEntity,
    entityDataToFormDataTransform: transformLegalDomainEntityToFormData,
    entityDisplayName: namedEntityFormatter,
    deleteEligibility: checkDeleteEligibility
  },
  fields: {
    name: {
      label: 'Name',
      type: FieldTypes.Text
    },
    name_fr: {
      label: 'Name (FR)',
      type: FieldTypes.Text
    }
  },
  filters: {}
});
