import * as R from 'ramda';

import { createManager } from '../lib/entity-manager';
import { FieldTypes } from '../lib/entity-manager/forms/shared';
import { CuratorApi } from '../services/CuratorApi';
import { CourtData, CourtEntity, CourtFormData } from '../types/cases/court';
import { namedEntityFormatter } from '../redux/modules/case-manager/utils';
import { transformSelectOptionToValue } from '../redux/util/forms';

// TODO: Define a filtering type
const getEntities = (api: CuratorApi, _filter: Partial<CourtEntity>, page: number, perPage: number) => {
  return api.searchCourts({
    filter: {},
    pagination: {
      page,
      perPage
    }
  });
};

const transformCourtFormDataToCourtSubmission = (formData: Partial<CourtFormData>): CourtData => {
  return {
    name: R.trim(formData.name || ''),
    name_fr: R.trim(formData.name_fr || ''),
    state: transformSelectOptionToValue(formData.state)
  };
};

const transformCourtEntityToFormData = (entity: CourtEntity): CourtFormData => ({
  name: entity.name,
  name_fr: entity.name_fr,
  state: {
    label: entity.state.name,
    value: entity.state.id
  }
});

const createEntity = (api: CuratorApi, guid: string, formData: CourtFormData) => {
  const data = transformCourtFormDataToCourtSubmission(formData);
  return api.createCourt({ guid, data });
}

const checkDeleteEligibility = (api: CuratorApi, id: string) => {
  return api.getCaseQueryEntityDeleteEligibility('court', id);
}

const updateEntity = (api: CuratorApi, guid:string, id: string, formData: CourtFormData) => {
  const data = transformCourtFormDataToCourtSubmission(formData);
  return api.updateCourt({ guid, id, data });
}

const getEntity = (api: CuratorApi, id: string) => {
  return api.getCourt(id, { relations: ['state']})
    .then((result) => result.data)
};

const deleteEntity = (api: CuratorApi, guid: string, id: string) => {
  return api.deleteCourt({ guid, id });
};

const generateDefaultCourtFilterData = () => ({
  name: '',
  name_fr: '',
  state: undefined
});

const generateDefaultCourtFormData = () => ({
  name: '',
  name_fr: '',
  state: undefined
});

export const courtManager = createManager<CuratorApi, CourtFormData, CourtEntity>({
  key: 'court',
  basePath: 'court-manager',
  displayName: {
    singular: 'Court',
    plural: 'Courts'
  },
  generateDefaultFilterData: generateDefaultCourtFilterData,
  generateDefaultData: generateDefaultCourtFormData,
  data: {
    fetch: getEntity,
    create: createEntity,
    update: updateEntity,
    all: getEntities,
    delete: deleteEntity,
    deleteEligibility: checkDeleteEligibility,
    entityDisplayName: namedEntityFormatter,
    entityDataToFormDataTransform: transformCourtEntityToFormData
  },
  listColumns: [
    {
      id: 'name',
      label: 'Name',
      getDisplayValue: (entity) => namedEntityFormatter(entity)
    }
  ],
  fields: {
    name: {
      label: 'Name',
      type: FieldTypes.Text
    },
    name_fr: {
      label: 'Name (FR)',
      type: FieldTypes.Text
    },
    state: {
      label: 'State',
      isMulti: false,
      type: FieldTypes.State
    }
  },
  filters: {}
});
