import * as R from 'ramda';
import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import {  v4 as uuidv4 } from 'uuid';

import { PAGINATION_DEFAULT } from '../../../constants';
import { CaseFilterFormData } from '../../../modules/case-manager/components/CaseFilterComponent';
import { PaginationMeta } from '../../util/fetch';
import { RootAction } from '../actions';
import { CaseEntity, ImportedCase } from '../../../types/cases/case';

import {
  applyCaseFilters,
  casesFetchActions,
  clearCaseFilters,
  fetchDependentCaseManagerData,
  loadCaseForDelete,
  loadCaseForEdit,
  startNewCaseManagerOperation, submitImportFromUrlForm, submitImportFromUrlFormFailed,
  submitImportFromUrlFormSuccess,
  toggleIsImportUrlOpen,
  toggleQuickAddFormVisibility
} from './actions';

export type CaseManagerEntitiesState = {
  entities: CaseEntity[],
  filter: Partial<CaseFilterFormData>,
  pagination: PaginationMeta
};

export type CaseManagerDependentDataState = {
  loading: boolean
};

export const caseManagerEntitiesInitialState: CaseManagerEntitiesState = {
  entities: [],
  filter: {},
  pagination: PAGINATION_DEFAULT
};

export const caseManagerEntitiesReducer = (state: CaseManagerEntitiesState = caseManagerEntitiesInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(applyCaseFilters): {
      return {
        ...state,
        filter: action.payload
      };
    }

    case getType(clearCaseFilters): {
      return {
        ...state,
        filter: {}
      };
    }

    case getType(casesFetchActions.success): {
      return {
        ...state,
        entities: action.payload.data,
        pagination: action.payload.pagination
      };
    }

    case getType(casesFetchActions.failure): {
      return {
        ...state,
        entities: [],
        pagination: PAGINATION_DEFAULT
      };
    }

    default: {
      return state;
    }
  }
};

export type CaseManagerNewState = {
  operationGuid: string | null,
};

export const caseManagerNewInitialState = {
  operationGuid: null
};

export const caseManagerNewReducer = (state: CaseManagerNewState = caseManagerNewInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(startNewCaseManagerOperation): {
      return {
        ...state,
        operationGuid: uuidv4()
      };
    }

    default: {
      return state;
    }
  }
};

export type CaseManagerEditState = {
  id: string | null,
  operationGuid: string | null,
  data: CaseEntity | null,
};

const caseManagerEditInitialState = {
  id: null,
  operationGuid: null,
  data: null
};

export const caseManagerEditReducer = (state: CaseManagerEditState = caseManagerEditInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(loadCaseForEdit.request): {
      return {
        ...state,
        id: action.payload,
        operationGuid: null
      };
    }
    case getType(loadCaseForEdit.success): {
      return {
        ...state,
        data: action.payload,
        operationGuid: uuidv4()
      };
    }
    case getType(loadCaseForEdit.failure): {
      return {
        ...state,
        data: null
      };
    }
    default: {
      return state;
    }
  }
};

export type CaseManagerDeleteState = {
  id: string | null,
  operationGuid: string | null,
  loading: boolean,
  data: CaseEntity | null
};

const caseManagerDeleteInitialState = {
  id: null,
  operationGuid: null,
  loading: false,
  data: null
};

export const caseManagerDeleteReducer = (state: CaseManagerDeleteState = caseManagerDeleteInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(loadCaseForDelete.request): {
      return {
        ...state,
        operationGuid: uuidv4(),
        loading: true,
        data: null
      };
    }
    case getType(loadCaseForDelete.success): {
      return {
        ...state,
        loading: false,
        data: action.payload
      };
    }
    case getType(loadCaseForDelete.failure): {
      return {
        ...state,
        loading: false,
        data: null
      };
    }
    default: {
      return state;
    }
  }
};

export const caseManagerDependentDataInitialState = {
  loading: false
};

export const caseManagerDependentDataReducer = (state: CaseManagerDependentDataState = caseManagerDependentDataInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(fetchDependentCaseManagerData.request): {
      return {
        ...state,
        loading: true
      };
    }
    case getType(fetchDependentCaseManagerData.success): {
      return {
        ...state,
        loading: false
      };
    }
    case getType(fetchDependentCaseManagerData.failure): {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export type CaseManagerImportState = {
  data: ImportedCase | null,
  pending: boolean;
};

export const caseManagerImportInitialState: CaseManagerImportState = {
  data: null,
  pending: false
};

export const caseManagerImportReducer = (state: CaseManagerImportState = caseManagerImportInitialState, action: RootAction): CaseManagerImportState => {
  switch (action.type) {
    case getType(submitImportFromUrlForm): {
      return {
        ...state,
        data: null,
        pending: true
      };
    }

    case getType(submitImportFromUrlFormSuccess): {
      return {
        ...state,
        pending: false,
        data: action.payload
      };
    }

    case getType(submitImportFromUrlFormFailed): {
      return {
        ...state,
        pending: false
      };
    }

    case getType(loadCaseForEdit.request):
    case getType(startNewCaseManagerOperation): {
      return {
        ...state,
        data: null
      };
    }
    default: {
      return state;
    }
  }
};

export enum CaseManagerQuickAddForm {
  JUDGE = 'judge',
  REPRESENTATIVE = 'representative',
  PLAINTIFF_FIRM = 'plaintiff-firm',
  DEFENDANT_FIRM = 'defendant-firm',
  DEFENDANT_REPRESENTATIVE = 'defendant-representative',
  PLAINTIFF_REPRESENTATIVE = 'plaintiff-representative'
}

export type CaseManagerUIState = {
  importOpen: boolean,
  quickAdd: {
    [K in CaseManagerQuickAddForm]?: boolean
  }
};

export const caseManagerUIInitialState: CaseManagerUIState = {
  importOpen: false,
  quickAdd: {}
};

const setQuickAdd = (key: CaseManagerQuickAddForm, value: boolean, state: CaseManagerUIState) => R.assocPath(['quickAdd', key], value, state);

export const caseManagerUIReducer = (state: CaseManagerUIState = caseManagerUIInitialState, action: RootAction): CaseManagerUIState => {
  switch (action.type) {
    case getType(startNewCaseManagerOperation): {
      return {
        ...state,
        importOpen: false,
        quickAdd: {}
      };
    }
    case getType(submitImportFromUrlFormSuccess):
    case getType(loadCaseForEdit.request): {
      return {
        ...state,
        importOpen: false,
        quickAdd: {}
      };
    }
    case getType(toggleIsImportUrlOpen): {
      return {
        ...state,
        importOpen: !state.importOpen
      };
    }

    case getType(toggleQuickAddFormVisibility): {
      const currentValue = R.propOr(false, action.payload.form, state.quickAdd);
      return setQuickAdd(action.payload.form, !currentValue, state);
    }

    default: {
      return state;
    }
  }
};

export const caseManagerInitialState = {
  entities: caseManagerEntitiesInitialState,
  dependentData: caseManagerDependentDataInitialState,
  edit: caseManagerEditInitialState,
  new: caseManagerNewInitialState,
  delete: caseManagerDeleteInitialState,
  ui: caseManagerUIInitialState,
  import: caseManagerImportInitialState
};

export type CaseManagerState = {
  entities: CaseManagerEntitiesState,
  dependentData: CaseManagerDependentDataState,
  edit: CaseManagerEditState,
  new: CaseManagerNewState,
  delete: CaseManagerDeleteState,
  ui: CaseManagerUIState,
  import: CaseManagerImportState
};

export const caseManagerReducer = combineReducers<CaseManagerState>({
  entities: caseManagerEntitiesReducer,
  dependentData: caseManagerDependentDataReducer,
  edit: caseManagerEditReducer,
  new: caseManagerNewReducer,
  delete: caseManagerDeleteReducer,
  ui: caseManagerUIReducer,
  import: caseManagerImportReducer
});
