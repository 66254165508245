import React from 'react';

import { EntitySelectContext } from '../contexts/entity-select';

export function useEntitySelectContext() {
  const contextValue = React.useContext(EntitySelectContext);

  if (!contextValue) {
    throw new Error('EntitySelectContext value must be set')
  }

  return contextValue;
}
