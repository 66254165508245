import { ErrorType, ICuratorError } from './types';

export class EntityFetchError extends Error implements ICuratorError {
  public readonly status: number = 500;
  public readonly type: ErrorType = ErrorType.Load;
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, EntityFetchError.prototype);
  }
}
