import { FormControl } from '@mui/material';
import * as React from 'react';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';

import { InputLabel } from './InputLabel';
import { IAsyncSelectFieldProps } from './types';

const LabelWrapper = styled.div`
  margin-bottom: 2px;
`;

export const AsyncSelectField: React.FC<IAsyncSelectFieldProps> = ({ input, options, isMulti, label, entitySearch, required, disabled = false, searchable = true, margin = 'normal',  fullWidth = true, placeholder = 'Search...'}) =>
  (
    <FormControl margin={margin} fullWidth={fullWidth}>
      <LabelWrapper>
        <InputLabel htmlFor={input.name}>{label} {required ? '*' : ''}</InputLabel>
      </LabelWrapper>
      <AsyncSelect
        placeholder={placeholder}
        loadOptions={entitySearch}
        options={options}
        backspaceRemovesValue={true}
        value={input.value}
        isDisabled={disabled}
        onChange={(value: any) => input.onChange(value)}
        isSearchable={searchable}
        isMulti={isMulti} />
    </FormControl>
  );
