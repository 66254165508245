import React from 'react';

import { EntityListColumnConfig, EntityName } from '../shared';

interface ManagerContextValue {
  dataKey: string;
  entityName: EntityName;
  listColumns: EntityListColumnConfig[]
}

function createExampleManagerContextValue(): ManagerContextValue {
  return {
    dataKey: 'dataKey',
    entityName: {
      singular: 'entityName',
      plural: 'entityNames'
    },
    listColumns: []
  }
}

export const ManagerContext = React.createContext<ManagerContextValue>(createExampleManagerContextValue());

export const ManagerContextProvider = ManagerContext.Provider;
