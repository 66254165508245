import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';
import { Epic } from 'redux-observable';
import { forkJoin, from, of } from 'rxjs';
import { initialize, SubmissionError } from 'redux-form';


import { DOCUMENT_TYPE_MANAGER_ROUTE, EDIT_DOCUMENT_TYPE_FORM_ID } from '../../../modules/document-type-manager/types';
import { EntityFetchError } from '../../../lib/errors/EntityFetchError';
import { Services } from '../../../services';
import { RootState } from '../root';
import { RootAction } from '../actions';
import { submitDocumentEditFormFailed } from '../document-manager/actions';
import { EntityUpdateError } from '../../../lib/errors/EntityUpdateError';
import { EntityCreateError } from '../../../lib/errors/EntityCreateError';
import { DeleteError } from '../../../lib/errors/DeleteError';
import { pushRoute } from '../router/actions';
import {
  DocumentTypeCreateSubmission,
  DocumentTypeDeleteSubmission,
  DocumentTypeUpdateSubmission
} from '../../../types/documents/document-type';

import {
  documentTypeDeleteSelector,
  documentTypeEditSelector,
  documentTypeNewSelector,
  documentTypesPaginationSelector
} from './selectors';
import { transformDocumentTypeFormDataToSubmission } from './utils';
import {
  changeDocumentTypesListPage,
  changeDocumentTypesListPageSize,
  deleteDocumentType,
  documentTypeFetchActions,
  documentTypePaginatedFetchActions,
  loadDocumentTypeForDelete,
  loadDocumentTypeForEdit,
  refreshDocumentTypesList,
  submitDocumentTypeCreateForm,
  submitDocumentTypeCreateFormCompleted,
  submitDocumentTypeCreateFormFailed,
  submitDocumentTypeEditForm,
  submitDocumentTypeEditFormCompleted,
  submitDocumentTypeEditFormFailed
} from './actions';

type DocumentTypeEpic = Epic<RootAction, RootAction, RootState, Services>;

const fetchDocumentTypesEpic: DocumentTypeEpic = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(documentTypeFetchActions.request)),
    switchMap(() =>
      from(curatorApi.getAllDocumentTypes()).pipe(
        map(documentTypeFetchActions.success),
        catchError((err) => of(documentTypeFetchActions.failure(err)))
      )
    )
  );

const fetchPaginatedDocumentTypesEpic: DocumentTypeEpic = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(documentTypePaginatedFetchActions.request)),
    mergeMap((action) =>
      from(curatorApi.getPaginatedDocumentTypes(action.payload)).pipe(
        map(documentTypePaginatedFetchActions.success),
        catchError((err) => of(documentTypePaginatedFetchActions.failure(err)))
      )
    )
  );

const changePageEpic: DocumentTypeEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeDocumentTypesListPage)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = documentTypesPaginationSelector(state);
      return documentTypePaginatedFetchActions.request({
        filter: {},
        pagination: {
          ...currentPagination,
          page: action.payload
        }
      });
    })
  );

const changePageSizeEpic: DocumentTypeEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeDocumentTypesListPageSize)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = documentTypesPaginationSelector(state);
      return documentTypePaginatedFetchActions.request({
        filter: {},
        pagination: {
          ...currentPagination,
          page: 1,
          perPage: action.payload
        }
      });
    })
  );

const refreshDocumentTypesEpic: DocumentTypeEpic = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(refreshDocumentTypesList)),
    withLatestFrom(store$),
    map(([_, state]) => {
      const currentPagination = documentTypesPaginationSelector(state);
      const currentFilters = {};
      return documentTypePaginatedFetchActions.request({
        filter: currentFilters,
        pagination: currentPagination
      });
    })
  );

const createDocumentTypeEpic: DocumentTypeEpic = (action$, store$, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(submitDocumentTypeCreateForm)),
    withLatestFrom(store$),
    switchMap(([action, state]) => {
      const { reject, resolve } = action.meta;
      const { operationGuid } = documentTypeNewSelector(state);
      if (!operationGuid) {
        reject(new SubmissionError({ _error: 'Failed to create document type, operation guid missing' }));
        return [submitDocumentTypeCreateFormFailed(new EntityCreateError('operation guid missing'))];
      }
      const submission: DocumentTypeCreateSubmission = {
        guid: operationGuid,
        data: transformDocumentTypeFormDataToSubmission(action.payload)
      };
      return from(curatorApi.createDocumentType(submission)).pipe(
        switchMap((response) => {
          if (response.status >= 300) {
            reject(new SubmissionError({ _error: `Failed to create document type\n\n${JSON.stringify(response.data, null, 2)}` }));
            return [submitDocumentTypeCreateFormFailed(new EntityCreateError('server error'))];
          }
          resolve();
          return [
            submitDocumentTypeCreateFormCompleted(),
            pushRoute(DOCUMENT_TYPE_MANAGER_ROUTE)
          ];
        }),
        catchError((err) => {
          reject(new SubmissionError({ _error: `Failed to create document type. (${err.message})` }));
          return of(submitDocumentTypeCreateFormFailed(new EntityCreateError('unknown error')));
        })
      )
    })
  );

const loadDocumentTypeForUpdateEpic: DocumentTypeEpic = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(loadDocumentTypeForEdit.request)),
    switchMap((action) => from(curatorApi.getDocumentType(action.payload)).pipe(
      switchMap((response) => [
        loadDocumentTypeForEdit.success(response.data),
        initialize(EDIT_DOCUMENT_TYPE_FORM_ID, response.data)
      ]),
      catchError((err) => from([
        loadDocumentTypeForEdit.failure(new EntityFetchError(err.message)),
        pushRoute(DOCUMENT_TYPE_MANAGER_ROUTE)
      ]))
    ))
  );

const updateDocumentTypeEpic: DocumentTypeEpic = (action$, store$, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(submitDocumentTypeEditForm)),
    withLatestFrom(store$),
    switchMap(([action, state]) => {
      const { reject, resolve } = action.meta;
      const { id, operationGuid } = documentTypeEditSelector(state);
      if (!id) {
        reject(new SubmissionError({ _error: 'Failed to update document type, id missing' }));
        return [submitDocumentEditFormFailed(new EntityUpdateError('document type id missing'))];
      }
      if (!operationGuid) {
        reject(new SubmissionError({ _error: 'Failed to update document type, guid missing' }));
        return [submitDocumentEditFormFailed(new EntityUpdateError('operation guid missing'))];
      }
      const submission: DocumentTypeUpdateSubmission = {
        id,
        guid: operationGuid,
        data: transformDocumentTypeFormDataToSubmission(action.payload)
      };

      return from(curatorApi.updateDocumentType(submission)).pipe(
        switchMap((response) => {
          if (response.status >= 300) {
            reject(new SubmissionError({ _error: `Failed to update document type\n\n${JSON.stringify(response.data, null, 2)}` }));
            return [submitDocumentTypeEditFormFailed(new EntityUpdateError('server error'))];
          }
          resolve();
          return [
            submitDocumentTypeEditFormCompleted(),
            pushRoute(DOCUMENT_TYPE_MANAGER_ROUTE)
          ];
        }),
        catchError((err) => {
          reject(new SubmissionError({ _error: `Failed to update document type. (${err.message})`}));
          return [submitDocumentTypeEditFormFailed(new EntityUpdateError('unknown error'))];
        })
      );
    })
  );

const fetchDocumentTypeForDeleteEpic: DocumentTypeEpic = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(loadDocumentTypeForDelete.request)),
    switchMap(({ payload }) =>
      forkJoin([
        from(curatorApi.getDocumentType(payload)),
        from(curatorApi.getDocumentQueryEntityDeleteEligibility('document-type', payload))
      ]).pipe(
        map(([entityResponse, deleteEligibilityResponse]) =>
          loadDocumentTypeForDelete.success({
            data: entityResponse.data,
            info: deleteEligibilityResponse.data
          })
        ),
        catchError((err) => of(
          loadDocumentTypeForDelete.failure(err),
          pushRoute(DOCUMENT_TYPE_MANAGER_ROUTE)
        ))
      )
    )
  );

const deleteDocumentTypeEpic: DocumentTypeEpic = (action$, store$, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(deleteDocumentType.request)),
    withLatestFrom(store$),
    switchMap(([{ payload }, state]) => {
      const { operationGuid, info } = documentTypeDeleteSelector(state);
      if (!operationGuid) {
        return [deleteDocumentType.failure(new DeleteError('operation guid missing'))];
      }
      if (!info?.canDelete) {
        return [deleteDocumentType.failure(new DeleteError('delete failed state check'))];
      }
      const submission: DocumentTypeDeleteSubmission = {
        guid: operationGuid,
        id: payload
      };
      return from(curatorApi.deleteDocumentType(submission)).pipe(
        switchMap(() => [
          deleteDocumentType.success(),
          pushRoute(DOCUMENT_TYPE_MANAGER_ROUTE)
        ]),
        catchError((err) => of(
          deleteDocumentType.failure(err)
        ))
      )
    })
  );

export const documentTypeEpics = [
  fetchDocumentTypesEpic,
  changePageEpic,
  changePageSizeEpic,
  refreshDocumentTypesEpic,
  fetchPaginatedDocumentTypesEpic,
  createDocumentTypeEpic,
  loadDocumentTypeForUpdateEpic,
  updateDocumentTypeEpic,
  fetchDocumentTypeForDeleteEpic,
  deleteDocumentTypeEpic
];
