import { createAsyncAction, createAction } from 'typesafe-actions';

import { ICuratorError } from '../../../lib/errors/types';
import { FirmFilterFormData } from '../../../modules/firm-manager/components/FirmFilterComponent';
import { FirmSearchQuery } from '../../../services/CuratorApi';
import { LoadEntityForDeleteResponse } from '../../../types/command-types';
import { FirmFormSubmissionMeta, FormSubmissionMeta } from '../../../types/forms';
import {
  PageNumberType,
  PageSizeType,
  PaginatedQuery,
  WithPaginatedFetchMeta
} from '../../util/fetch';
import { FirmEntity, FirmFormData } from '../../../types/cases/firm';

import { FirmForm } from './types';

export const firmsPaginatedFetchActions = createAsyncAction(
  '@@CURATOR/FIRMS/PAGINATED/REQUEST',
  '@@CURATOR/FIRMS/PAGINATED/SUCCESS',
  '@@CURATOR/FIRMS/PAGINATED/FAILURE'
)<PaginatedQuery<FirmSearchQuery>, WithPaginatedFetchMeta<FirmEntity>, ICuratorError>();

export const refreshFirmsList = createAction('@@CURATOR/FIRMS_MANAGER/REFRESH_LIST')<void>();

export const updateFirmsList = createAction('@@CURATOR/FIRMS_MANAGER/UPDATE_LIST')<PaginatedQuery<FirmSearchQuery>>();

export const changeFirmsListPage = createAction('@@CURATOR/FIRMS_MANAGER/CHANGE_PAGE')<PageNumberType>();

export const changeFirmsListPageSize = createAction('@@CURATOR/FIRMS_MANAGER/CHANGE_PAGE_SIZE')<PageSizeType>();

export const fetchDependentFirmData = createAsyncAction(
  '@@CURATOR/FIRMS_MANAGER/FETCH_DEPENDENT_DATA',
  '@@CURATOR/FIRMS_MANAGER/FETCH_DEPENDENT_DATA_SUCCESS',
  '@@CURATOR/FIRMS_MANAGER/FETCH_DEPENDENT_DATA_FAILURE')<void, void, void>();

export const startNewFirmOperation = createAction('@@CURATOR/FIRMS_MANAGER/START_NEW_FIRM_OPERATION')<FirmForm>();

export const submitFirmCreateForm = createAction('@@CURATOR/FIRMS_MANAGER/SUBMIT_NEW_FIRM')<Partial<FirmFormData>, FirmFormSubmissionMeta>();

export const submitFirmCreateFormCompleted = createAction('@@CURATOR/FIRMS_MANAGER/SUBMIT_NEW_FIRM_COMPLETED')<void>();

export const submitFirmCreateFormFailed = createAction('@@CURATOR/FIRMS_MANAGER/SUBMIT_NEW_FIRM_FAILED')<ICuratorError>();

export const submitFirmEditForm = createAction('@@CURATOR/FIRMS_MANAGER/SUBMIT_EDIT_FIRM')<Partial<FirmFormData>, FormSubmissionMeta>();

export const submitFirmEditFormCompleted = createAction('@@CURATOR/FIRMS_MANAGER/SUBMIT_EDIT_FIRM_COMPLETED')<void>();

export const submitFirmEditFormFailed = createAction('@@CURATOR/FIRMS_MANAGER/SUBMIT_EDIT_FIRM_FAILURE')<ICuratorError>();

export const loadFirmForEdit = createAsyncAction(
  '@@CURATOR/FIRMS_MANAGER/LOAD_FIRM_FOR_EDIT',
  '@@CURATOR/FIRMS_MANAGER/LOAD_FIRM_FOR_EDIT_SUCCESS',
  '@@CURATOR/FIRMS_MANAGER/LOAD_FIRM_FOR_EDIT_FAILURE'
)<string, FirmEntity, ICuratorError>();

export const applyFirmFilters = createAction('@@CURATOR/FIRMS_MANAGER/APPLY_FILTERS')<FirmFilterFormData>();

export const clearFirmFilters = createAction('@@CURATOR/FIRMS_MANAGER/RESET_FILTERS')<void>();

export const loadFirmForDelete = createAsyncAction(
  '@@CURATOR/FIRMS_MANAGER/LOAD_FIRM_FOR_DELETE',
  '@@CURATOR/FIRMS_MANAGER/LOAD_FIRM_FOR_DELETE_SUCCESS',
  '@@CURATOR/FIRMS_MANAGER/LOAD_FIRM_FOR_DELETE_FAILURE'
)<string, LoadEntityForDeleteResponse<FirmEntity>, ICuratorError>();

export const deleteFirm = createAsyncAction(
  '@@CURATOR/CASE_MANAGER/DELETE_FIRM',
  '@@CURATOR/CASE_MANAGER/DELETE_FIRM_SUCCESS',
  '@@CURATOR/CASE_MANAGER/DELETE_FIRM_FAILURE')<FirmEntity['id'], void, ICuratorError>();

export type SetFirmPayload = {
  formId: string,
  data: FirmEntity
};

export const setFormDataFromFirm = createAction('@@CURATOR/FIRMS_MANAGER/SET_FORM_DATA_FROM_FIRM')<SetFirmPayload>();
