export function getAuthRedirectURL(window: Window): string {
  // grab from build variable if available
  if (process.env.REACT_APP_AUTH_REDIRECT) {
    return process.env.REACT_APP_AUTH_REDIRECT;
  }

  // determine based on window location
  const { hostname } = window.location;

  if (hostname === 'curator.dev.bluej.co') {
    return 'https://internal.dev.bluej.co';
  }

  return 'https://internal.bluej.co';
}
