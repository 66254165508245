import { createSelector } from 'reselect';

import { SelectOption } from '../../../types/util';
import { RootState } from '../root';
import { DocumentTypeEntity } from '../../../types/documents/document-type';

export const documentTypesSelector = (state: RootState) => state.documentTypes;

export const documentTypeListSelector = createSelector(
  [documentTypesSelector],
  ({ entities }) => entities
);

export const documentTypeNewSelector = createSelector(
  [documentTypesSelector],
  (documentTypeState) => documentTypeState.new
);

export const documentTypeEditSelector = createSelector(
  [documentTypesSelector],
  ({ edit }) => edit
);

export const documentTypeDeleteSelector = createSelector(
  [documentTypesSelector],
  (documentTypeState) => documentTypeState.delete
);

export function formatDocumentTypeLabel(type: DocumentTypeEntity): string {
  return type.name;
}

function formatDocumentTypesToOptions(documentTypes: DocumentTypeEntity[]): SelectOption[] {
  return documentTypes.map((type) => ({
    value: type.id.toString(),
    label: formatDocumentTypeLabel(type)
  }));
}

export const documentTypeOptionsSelector = createSelector(
  [documentTypeListSelector],
  formatDocumentTypesToOptions
);

export const documentTypesPaginationSelector = createSelector(
  [documentTypesSelector],
  ({ paginated }) => paginated.pagination
);

export const documentTypesPaginatedListSelector = createSelector(
  [documentTypesSelector],
  ({ paginated }) => paginated.entities
);

export const documentTypesForTableSelector = createSelector(
  [documentTypesPaginatedListSelector],
  (docTypes) => docTypes.map(({ id, name }) => ({ id, name }))
);
