import { Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { useCallback, useEffect } from 'react';

import { AddIcon, RefreshIcon } from '../../../components/ui/mui-icons';
import { RootAction } from '../../../redux/modules/actions';
import { refreshDocumentManagerList, changeDocumentManagerListPage, changeDocumentManagerListPageSize } from '../../../redux/modules/document-manager/actions';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCard';
import { IconButtonComponent } from '../../../components/ui/IconButton';
import { DocumentEntityTableComponent, DocumentTableData } from '../components/DocumentEntityTableComponent';
import { EntityTableColumn } from '../../../components/EntityTable/types';
import { selectDocumentsForTable } from '../selectors';
import { selectDocumentsPagination } from '../../../redux/modules/document-manager/selectors';
import { DOCUMENT_MANAGER_ROUTE } from '../types';
import { pushRoute } from '../../../redux/modules/router/actions';

import { DocumentFilterContainer } from './DocumentFilterContainer';

const columns: EntityTableColumn<DocumentTableData>[] = [
  { name: 'title', title: 'Title' },
  { name: 'citations', title: 'Citation(s)' },
  { name: 'type', title: 'Document Type' },
  { name: 'datePublished', title: 'Date Published' }
];

export const DocumentListContainer: React.FunctionComponent = () => {
  const dispatch = useDispatch<Dispatch<RootAction>>();

  const refreshDocuments = useCallback(() => dispatch(refreshDocumentManagerList()), [dispatch]);

  const editDocument = useCallback((entityId: string) =>
    dispatch(pushRoute(`${DOCUMENT_MANAGER_ROUTE}/${entityId}`))
  , [dispatch]);

  const deleteDocument = useCallback((entityId: string) =>
    dispatch(pushRoute(`${DOCUMENT_MANAGER_ROUTE}/${entityId}/delete`))
  , [dispatch]);

  const goToPage = useCallback((pageIndex: number) => {
    dispatch(changeDocumentManagerListPage(pageIndex + 1));
  }, [dispatch]);

  const changePageSize = useCallback((pageSize: number) => {
    dispatch(changeDocumentManagerListPageSize(pageSize));
  }, [dispatch]);

  useEffect(() => {
    refreshDocuments();
  }, [refreshDocuments]);

  const documentTableData = useSelector(selectDocumentsForTable);
  const pagination = useSelector(selectDocumentsPagination);

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12}>
        <Grid container={true}>
          <Grid item={true} xs={6}>
            <Typography component="h2" variant="h5">Documents</Typography>
          </Grid>
          <Grid item={true} xs={6}>
            <Grid container={true} justifyContent="flex-end" spacing={3}>
              <Grid item={true}>
                <IconButtonComponent
                  onClick={refreshDocuments}
                  icon={RefreshIcon}>
                  Refresh
                </IconButtonComponent>
                <IconButtonComponent
                  to={`${DOCUMENT_MANAGER_ROUTE}/add`}
                  icon={AddIcon}>
                  Add Document
                </IconButtonComponent>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={12}>
        <MainContainerCardComponent>
          <Grid container={true}>
            <Grid item={true} xs={6}>
              <Typography component="h2" variant="h6">Filters</Typography>
            </Grid>
          </Grid>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <DocumentFilterContainer />
            </Grid>
          </Grid>
        </MainContainerCardComponent>
      </Grid>
      <Grid item={true} xs={12}>
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <DocumentEntityTableComponent
              columns={columns}
              data={documentTableData}
              onEntityEdit={editDocument}
              onEntityDelete={deleteDocument}
              onChangePage={goToPage}
              onChangePageSize={changePageSize}
              pagination={pagination}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
