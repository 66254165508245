import React from 'react';

import { CuratorApiContext } from '../contexts/curator-context';

export function useCuratorApiContext() {
  const curatorApiContext = React.useContext(CuratorApiContext);

  if (!curatorApiContext) {
    throw new Error('CuratorApiContext value must be set');
  }

  return {
    curatorApi: curatorApiContext.curatorApi
  }
}
