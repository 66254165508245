import * as R from 'ramda';

import { createManager } from '../lib/entity-manager';
import { FieldTypes } from '../lib/entity-manager/forms/shared';
import { CuratorApi } from '../services/CuratorApi';
import { RepresentativeData, RepresentativeEntity, RepresentativeFormData } from '../types/cases/representative';
import { personEntityFormatter } from '../redux/modules/case-manager/utils';
import { transformSelectOptionToValue } from '../redux/util/forms';

// TODO: Define a filtering type
const getEntities = (api: CuratorApi, _filter: Partial<RepresentativeEntity>, page: number, perPage: number) => {
  return api.searchRepresentatives({
    filter: {},
    pagination: {
      page,
      perPage
    }
  });
};

const transformRepresentativeFormDataToRepresentativeSubmission = (formData: Partial<RepresentativeFormData>): RepresentativeData => {
  return {
    givenNames: R.trim(formData.givenNames || ''),
    lastName: R.trim(formData.lastName || ''),
    country: transformSelectOptionToValue(formData.country)
  };
};

const transformRepresentativeEntityToFormData = (entity: RepresentativeEntity): RepresentativeFormData => ({
  givenNames: entity.givenNames,
  lastName: entity.lastName,
  country: {
    label: entity.country.name,
    value: entity.country.id
  }
});

const createEntity = (api: CuratorApi, guid: string, formData: RepresentativeFormData) => {
  const data = transformRepresentativeFormDataToRepresentativeSubmission(formData);
  return api.createRepresentative({ guid, data });
}

const checkDeleteEligibility = (api: CuratorApi, id: string) => {
  return api.getCaseQueryEntityDeleteEligibility('representative', id);
}

const updateEntity = (api: CuratorApi, guid:string, id: string, formData: RepresentativeFormData) => {
  const data = transformRepresentativeFormDataToRepresentativeSubmission(formData);
  return api.updateRepresentative({ guid, id, data });
}

const getEntity = (api: CuratorApi, id: string) => {
  return api.getRepresentative(id, { relations: ['country']})
    .then((result) => result.data)
};

const deleteEntity = (api: CuratorApi, guid: string, id: string) => {
  return api.deleteRepresentative({ guid, id });
};

const generateDefaultRepresentativeFilterData = () => ({
  givenNames: '',
  lastName: '',
  country: undefined
});

const generateDefaultRepresentativeFormData = () => ({
  givenNames: '',
  lastName: '',
  country: undefined
});

export const representativeManager = createManager<CuratorApi, RepresentativeFormData, RepresentativeEntity>({
  key: 'representative',
  basePath: 'representative-manager',
  displayName: {
    singular: 'Representative',
    plural: 'Representatives'
  },
  generateDefaultFilterData: generateDefaultRepresentativeFilterData,
  generateDefaultData: generateDefaultRepresentativeFormData,
  data: {
    fetch: getEntity,
    create: createEntity,
    update: updateEntity,
    all: getEntities,
    delete: deleteEntity,
    deleteEligibility: checkDeleteEligibility,
    entityDisplayName: personEntityFormatter,
    entityDataToFormDataTransform: transformRepresentativeEntityToFormData
  },
  listColumns: [
    {
      id: 'name',
      label: 'Name',
      getDisplayValue: (entity) => personEntityFormatter(entity)
    }
  ],
  fields: {
    givenNames: {
      label: 'Given name(s)',
      type: FieldTypes.Text
    },
    lastName: {
      label: 'Last name',
      type: FieldTypes.Text
    },
    country: {
      label: 'Country',
      isMulti: false,
      type: FieldTypes.Country
    }
  },
  filters: {}
});
