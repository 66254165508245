import * as React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';

import { ManagerContext } from '../contexts/manager-context';
import { useEntityDeleteEligibility } from '../hooks/useEntityDeleteEligibility';
import { ButtonComponent } from '../../../components/ui/Button';
import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { EntityName } from '../shared';
import { useEntityDelete } from '../hooks/useEntityDelete';
import { useEntityFetch } from '../hooks/useEntityFetch';
import { useEntityApiContext } from '../hooks/useEntityApiContext';

export function EntityManagerDeleteRoutingComponent() {
  const { id } = useParams<'id'>();

  if (!id) {
    throw new Error('missing id parameter');
  }

  return (
    <EntityManagerDeleteContainer id={id} />
  )
}

type DeleteContainerProps = {
  id: string;
  confirm?: React.ComponentType<EntityManagerDeleteConfirmProps>;
  error?: React.ComponentType;
  loading?: React.ComponentType;
}

function DefaultEntityManagerDeleteError() {
  return (
    <p>Failed to retrieve delete eligibility data</p>
  )
}

function DefaultEntityManagerDeleteLoading() {
  return (
    <p>Retrieving delete eligibility</p>
  )
}

type EntityManagerDeleteConfirmProps = {
  entityName: EntityName;
  onConfirm: () => void;
  id: string;
  title: string;
  error?: React.ComponentType;
  loading?: React.ComponentType;
}

function DefaultEntityManagerDeleteConfirm(props: EntityManagerDeleteConfirmProps) {
  const {
    id,
    entityName,
    title,
    onConfirm,
    error: Error = DefaultEntityManagerDeleteError,
    loading: Loading = DefaultEntityManagerDeleteLoading
  } = props;

  const lowerCasedEntityName = entityName.singular.toLowerCase();

  const { data, status } = useEntityDeleteEligibility(id);

  if (status === 'loading') {
    return (
      <Loading />
    )
  }

  if (status === 'error') {
    return (
      <Error />
    )
  }

  if (status === 'success' && data) {
    return (
      <Grid container={true} spacing={3} direction="column">
        <Grid item={true}>
          <Grid item={true}>
            <Grid container={true}>
              <Grid item={true} xs={6}>
                <Typography component="h2" variant="h5">
                  <BreadcrumbLink to={'..'}>{entityName.plural}</BreadcrumbLink> /
                  Delete <small>({id})</small></Typography>
              </Grid>
            </Grid>
          </Grid>
          {
            data.canDelete ? (
              <Typography component="h2" variant="subtitle1">
                Are you sure you want to delete the {lowerCasedEntityName} <em>{title}</em>?
              </Typography>
            ) : (
              <Typography component="h2" variant="subtitle1">
                You cannot delete the {lowerCasedEntityName} <em>{title}</em>. It is currently in use by <strong>{data.usage}</strong> entities.
              </Typography>
            )
          }
        </Grid>
        <Grid item={true}>
          <Stack direction="row" spacing={2}>
            <ButtonComponent
              disabled={!data.canDelete}
              onClick={onConfirm}
              color="error"
              variant="outlined">Confirm</ButtonComponent>
            <ButtonComponent to="..">Cancel</ButtonComponent>
          </Stack>
        </Grid>
      </Grid>
    );
  }

  return null;
}

export function EntityManagerDeleteContainer(props: DeleteContainerProps) {
  const {
    id,
    confirm: Confirm = DefaultEntityManagerDeleteConfirm,
    error: Error = DefaultEntityManagerDeleteError,
    loading: Loading = DefaultEntityManagerDeleteLoading
  } = props;

  const { entityName } = React.useContext(ManagerContext);
  const { data, status } = useEntityFetch(id);

  const { entityDisplayName } = useEntityApiContext();
  const { mutate } = useEntityDelete(id);
  const navigate = useNavigate();

  const handleConfirm = React.useCallback(() => {
    mutate(undefined, {
      onSuccess: () => {
        navigate('..');
        toast.success(`${entityName.singular} (${id}) deleted`);
      }
    });
  }, [mutate]);

  if (status === 'loading') {
    return (
      <>
        <Loading />
      </>
    )
  }

  if (status === 'error') {
    return (
      <Error />
    )
  }

  if (status === 'success' && data) {
    const title = entityDisplayName(data);
    return (
      <Confirm
        id={id}
        entityName={entityName}
        title={title}
        onConfirm={handleConfirm}
      />
    );
  }

  return null;
}

