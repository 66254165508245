import { Box } from '@mui/material';
import * as React from 'react';
import { Route, Routes } from 'react-router';

import { MainContainerCardComponent } from '../../../components/ui/MainContainerCard';

import { DocumentListContainer } from './DocumentListContainer';
import { EditDocument } from './EditDocument';
import { NewDocumentContainer } from './NewDocumentContainer';
import { DeleteDocumentContainer } from './DeleteDocumentContainer';

export const DocumentManagerContainer = () => {
  return (
    <>
      <Box p={2}>
        <MainContainerCardComponent>
          <Routes>
            <Route path="/" element={<DocumentListContainer />} />
            <Route path="/add" element={<NewDocumentContainer />} />
            <Route path="/:id/delete" element={<DeleteDocumentContainer />} />
            <Route path="/:id" element={<EditDocument />} />
          </Routes>
        </MainContainerCardComponent>
      </Box>
    </>
  );
};
