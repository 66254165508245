import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

import { setAuthData } from '../redux/modules/auth/actions';

export const AuthHandler: React.FC = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get('token');
    if (typeof token === 'string') {
      dispatch(setAuthData({ token }));
    }
    navigate('/');
  }, []);

  return null;
};
