export type PageNumberType = number;

export type PageSizeType = number;

export type WithFetchMeta<T> = {
  data: T,
  fetchedAt: Date,
  fetchedWith?: any
};

export type PaginationMeta = {
  page: PageNumberType,
  perPage: PageSizeType,
  total: number,
};

export type PaginationConfig = {
  page: PageNumberType,
  perPage: PageSizeType,
};

export type PaginatedQuery<TEntity> = {
  filter: Partial<TEntity>,
  pagination: PaginationConfig,
  relations?: string[]
};

export type WithPaginatedFetchMeta<T> = WithFetchMeta<T[]> & {
  pagination: PaginationMeta
};

export type StandardResponse<T = any> = {
  data: T,
  status: number
};

export function addFetchData<T>(data: T, fetchedWith?: any): WithFetchMeta<T> {
  return {
    data,
    fetchedAt: new Date(),
    fetchedWith
  };
}

export function addPaginatedFetchData<T>(data: T[], pagination: PaginationMeta, fetchedWith?: any): WithPaginatedFetchMeta<T> {
  return {
    data,
    fetchedAt: new Date(),
    fetchedWith,
    pagination
  };
}
