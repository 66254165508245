import * as R from 'ramda';

import { createManager } from '../lib/entity-manager';
import { FieldTypes } from '../lib/entity-manager/forms/shared';
import { CuratorApi } from '../services/CuratorApi';
import { StateData, StateEntity, StateFormData } from '../types/cases/state';
import { namedEntityFormatter } from '../redux/modules/case-manager/utils';
import { transformSelectOptionToValue } from '../redux/util/forms';

// TODO: Define a filtering type
const getEntities = (api: CuratorApi, _filter: Partial<StateEntity>, page: number, perPage: number) => {
  return api.searchStates({
    filter: {},
    pagination: {
      page,
      perPage
    }
  });
};

const transformStateFormDataToStateSubmission = (formData: Partial<StateFormData>): StateData => {
  return {
    name: R.trim(formData.name || ''),
    name_fr: R.trim(formData.name_fr || ''),
    country: transformSelectOptionToValue(formData.country)
  };
};

const transformStateEntityToFormData = (entity: StateEntity): StateFormData => ({
  name: entity.name,
  name_fr: entity.name_fr,
  country: {
    label: entity.country.name,
    value: entity.country.id
  }
});

const createEntity = (api: CuratorApi, guid: string, formData: StateFormData) => {
  const data = transformStateFormDataToStateSubmission(formData);
  return api.createState({ guid, data });
}

const checkDeleteEligibility = (api: CuratorApi, id: string) => {
  return api.getCaseQueryEntityDeleteEligibility('state', id);
}

const updateEntity = (api: CuratorApi, guid:string, id: string, formData: StateFormData) => {
  const data = transformStateFormDataToStateSubmission(formData);
  return api.updateState({ guid, id, data });
}

const getEntity = (api: CuratorApi, id: string) => {
  return api.getState(id, { relations: ['country']})
    .then((result) => result.data)
};

const deleteEntity = (api: CuratorApi, guid: string, id: string) => {
  return api.deleteState({ guid, id });
};

const generateDefaultStateFilterData = () => ({
  name: '',
  name_fr: '',
  country: undefined
});

const generateDefaultStateFormData = () => ({
  name: '',
  name_fr: '',
  country: undefined
});

export const stateManager = createManager<CuratorApi, StateFormData, StateEntity>({
  key: 'state',
  basePath: 'state-manager',
  displayName: {
    singular: 'State',
    plural: 'States'
  },
  generateDefaultFilterData: generateDefaultStateFilterData,
  generateDefaultData: generateDefaultStateFormData,
  data: {
    fetch: getEntity,
    create: createEntity,
    update: updateEntity,
    all: getEntities,
    delete: deleteEntity,
    deleteEligibility: checkDeleteEligibility,
    entityDisplayName: namedEntityFormatter,
    entityDataToFormDataTransform: transformStateEntityToFormData
  },
  listColumns: [
    {
      id: 'name',
      label: 'Name',
      getDisplayValue: (entity) => namedEntityFormatter(entity)
    }
  ],
  fields: {
    name: {
      label: 'Name',
      type: FieldTypes.Text
    },
    name_fr: {
      label: 'Name (FR)',
      type: FieldTypes.Text
    },
    country: {
      label: 'Country',
      isMulti: false,
      type: FieldTypes.Country
    }
  },
  filters: {}
});
