export type PersonEntity = {
  id: string,
  lastName: string,
  givenNames: string
};

export type NamedEntity = {
  id: string,
  name: string
};

export type EntityWithLabel = PersonEntity | NamedEntity;

export type EntityLabelFormatter<TEntity extends EntityWithLabel> = (entity: TEntity) => string;

export enum CaseDependantDataRequestType {
  FILTER,
  IMPORT,
  EDIT
}

export const CASE_FILTERS_FORM_ID = 'case-filters';
