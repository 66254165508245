import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { ManagerContext } from '../contexts/manager-context';
import { useEntityCreate } from '../hooks/useEntityCreate';
import { EntityFormContainer } from '../forms';
import { EntityCreateError } from '../../errors/EntityCreateError';

export function EntityManagerCreateContainer() {
  const { entityName } = React.useContext(ManagerContext);
  const [operationState, setOperationState] = React.useState<string | null>(null);
  const { mutate, operationGuid } = useEntityCreate();
  const navigate = useNavigate();

  const onSubmit = React.useCallback((data) => {
    setOperationState(null);
    mutate(data, {
      onSuccess: () => {
        toast.success(`${entityName.singular} saved`);
        navigate('..');
      },
      onError: (error) => {
        toast.error(`Creating ${entityName.singular} failed.`);
        if (error instanceof EntityCreateError) {
          setOperationState(error.message);
        } else {
          setOperationState('Unknown reason');
        }
      }
    });
  }, [mutate, navigate]);

  return (
    <>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12}>
          <Grid container={true}>
            <Grid item={true} xs={6}>
              <Typography component="h2" variant="h5">
                <BreadcrumbLink to={'..'}>{entityName.plural}</BreadcrumbLink> / Create</Typography>
            </Grid>
          </Grid>
        </Grid>
        { operationState && (
          <Grid item={true} xs={12}>
            <Card>
              <CardContent>
                <Typography variant="caption">Operation Failed ({operationGuid})</Typography>
                <Typography variant="body2">
                  {operationState}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item={true} xs={12}>
          <Grid container={true}>
            <EntityFormContainer onSubmit={onSubmit} type="create" />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
