import React from 'react';

import { ManagerContext } from '../contexts/manager-context';

export function useManagerContext() {
  const managerContext = React.useContext(ManagerContext);

  if (!managerContext) {
    throw new Error('ManagerContext value must be set')
  }

  return managerContext;
}
