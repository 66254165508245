import React from 'react';

import { FormContext } from '../contexts/form-context';

export function useFormContext() {
  const formContext = React.useContext(FormContext);

  if (!formContext) {
    throw new Error('FormContext value must be set')
  }

  return formContext;
}
