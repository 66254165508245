import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';

import { deleteCase, loadCaseForDelete } from '../../../redux/modules/case-manager/actions';
import { selectDeleteCaseData, selectIsLoadingCaseForDelete } from '../../../redux/modules/case-manager/selectors';
import { pushRoute } from '../../../redux/modules/router/actions';
import { ButtonComponent } from '../../../components/ui/Button';

export const CaseDeleteContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<'id'>();

  const isLoading = useSelector(selectIsLoadingCaseForDelete);
  const caseData = useSelector(selectDeleteCaseData)
  const cancel = useCallback(() => dispatch(pushRoute('/case-manager')), []);
  const confirm = useCallback(() => id && dispatch(deleteCase.request(id)), [id]);

  useEffect(() => {
    id && dispatch(loadCaseForDelete.request(id));
  }, [id]);

  if (isLoading || !id) {
    return null;
  }

  return (
    <Grid container={true} spacing={3} direction="column">
      <Grid item={true}>
        <Typography component="h2" variant="h5">
          {caseData ? caseData.title : ''} - {id}
        </Typography>
        <Typography component="h2" variant="subtitle1">Are you sure you want to delete this case?</Typography>
      </Grid>
      <Grid item={true}>
        <Grid container={true} spacing={3}>
          <Grid item={true}>
            <ButtonComponent color="primary" onClick={cancel}>Cancel</ButtonComponent>
          </Grid>
          <Grid item={true}>
            <ButtonComponent disabled={isLoading}
                    onClick={confirm}>Confirm</ButtonComponent>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

}

