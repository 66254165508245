import React from 'react';
import { WrappedFieldProps } from 'redux-form';

import { CommonFieldProps } from '../../shared';
import { EntitySelectorProps } from '../../../../entity-selector';

type FormFieldProps = {
  isMulti: boolean,
  formComponent: React.ComponentType<EntitySelectorProps>
} & CommonFieldProps & WrappedFieldProps;

export const ReduxFormEntitySelectorField: React.FC<FormFieldProps> = (
  {
    label,
    input,
    isMulti,
    formComponent: FormComponent
  }) => {
  // Using a ref to retain the value so that we can avoid recreating callbacks and rendering
  const currentValueRef = React.useRef();

  currentValueRef.current = input.value;

  const { onChange, onBlur, ...restInput } = input;

  const handleChange = React.useCallback((value) => {
    currentValueRef.current = value;
    onChange(value);
  }, []);

  // Redux Form wants the value sent on blur
  const handleBlur = React.useCallback(() => {
    onBlur(currentValueRef.current);
  }, []);

  return (
    <FormComponent
      isMulti={isMulti}
      label={label}
      onChange={handleChange}
      onBlur={handleBlur}
      {...restInput}
    />
  )
}
