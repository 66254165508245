import { FormControl } from '@mui/material';
import * as React from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import { InputLabel } from './InputLabel';
import { ISelectFieldProps } from './types';

const LabelWrapper = styled.div`
  margin-bottom: 2px;
`;

const selectStyles = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 2
  })
};

export class SelectField extends React.Component<ISelectFieldProps> {
  static defaultProps = {
    disabled: false,
    clearable: false,
    searchable: true,
    margin: 'normal',
    clearValueIfOptionRemoved: false
  };

  componentDidUpdate() {
    if (this.props.clearValueIfOptionRemoved) {
      const { input, options } = this.props;
      if (input.value && options instanceof Array && !options.some((option) => option.value === input.value.value)) {
        input.onChange(null);
      }
    }
  }

  render() {
    const {
      input,
      options,
      isMulti,
      label,
      required,
      disabled,
      clearable,
      searchable,
      margin,
      fullWidth = true
    } = this.props;

    return (
      <FormControl margin={margin} fullWidth={fullWidth}>
        <LabelWrapper>
          <InputLabel htmlFor={input.name}>{label} {required ? '*' : ''}</InputLabel>
        </LabelWrapper>
        <Select
          styles={selectStyles}
          isClearable={clearable}
          value={input.value}
          options={options}
          backspaceRemovesValue={false}
          isDisabled={disabled}
          onChange={(value: any) => input.onChange(value)}
          isSearchable={searchable}
          isMulti={isMulti} />
      </FormControl>
    );
  }
}
