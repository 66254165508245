import { Dispatch } from 'redux';
import { submit, formValueSelector } from 'redux-form';
import { createSelector } from 'reselect';

import { RootAction } from '../../redux/modules/actions';
import {
  entityFieldSearch,
  toggleQuickAddFormVisibility
} from '../../redux/modules/case-manager/actions';
import { CaseManagerQuickAddForm } from '../../redux/modules/case-manager/reducer';
import {
  selectCaseManagerImportState,
  selectCases, selectIsLoadingCaseManagerDependentData,
  selectQuickAddState
} from '../../redux/modules/case-manager/selectors';
import { caseSubjectOptionsSelector } from '../../redux/modules/case-subjects/selectors';
import { countryOptionsSelector } from '../../redux/modules/countries/selectors';
import { courtOptionsSelector } from '../../redux/modules/courts/selectors';
import { legalDomainOptionsSelector } from '../../redux/modules/legal-domains/selectors';
import { RootState } from '../../redux/modules/root';
import { FormSubmissionMeta, ImportFormSubmissionMeta } from '../../types/forms';
import { SelectOption } from '../../types/util';
import { CaseFormData, ImportFromUrlDataForm } from '../../types/cases/case';
import { US_FEDERAL_CIRCUIT_VALUES } from '../../types/cases/case-metadata';

import { CaseFilterFormData } from './components/CaseFilterComponent';
import { CaseTableData } from './components/CaseEntityTableComponent';

type CaseFormSubmitAction = (values: CaseFormData, meta: FormSubmissionMeta) => RootAction;
type FilterCasesFormSubmitAction = (values: CaseFilterFormData) => RootAction;

type ImportFromUrlFormSubmitAction = (values: ImportFromUrlDataForm, meta: ImportFormSubmissionMeta) => RootAction;

export enum SearchableEntityType {
  JUDGE,
  FIRM,
  REPRESENTATIVE
}

const createEntitySearchHandler = (dispatch: Dispatch<RootAction>, formId: string, entityType: SearchableEntityType, fieldId: string) => {
  return (query: string) => {
    return new Promise((resolve, reject) => {
      dispatch(entityFieldSearch({ formId, fieldId, entityType, query }, { resolve, reject }));
    });
  };
};

export const createCaseFormDispatchers = (dispatch: Dispatch<RootAction>, submitAction: CaseFormSubmitAction, formId: string) => ({
  createToggleQuickAdd: (form: CaseManagerQuickAddForm, field: string) => () => dispatch(toggleQuickAddFormVisibility({
    form,
    field
  })),
  judgesSearchHandler: createEntitySearchHandler(dispatch, formId, SearchableEntityType.JUDGE, 'judges'),
  plaintiffFirmsSearchHandler: createEntitySearchHandler(dispatch, formId, SearchableEntityType.FIRM, 'plaintiffFirms'),
  defendantFirmsSearchHandler: createEntitySearchHandler(dispatch, formId, SearchableEntityType.FIRM, 'defendantFirms'),
  plaintiffRepresentativesSearchHandler: createEntitySearchHandler(dispatch, formId, SearchableEntityType.REPRESENTATIVE, 'plaintiffRepresentatives'),
  defendantRepresentativesSearchHandler: createEntitySearchHandler(dispatch, formId, SearchableEntityType.REPRESENTATIVE, 'defendantRepresentatives'),
  submitForm: () => dispatch(submit((formId))),
  submitHandler: (values: CaseFormData) =>
    new Promise<void>((resolve, reject) => {
      dispatch(submitAction(values, { resolve, reject }));
    })
});

export const filterCaseFormDispatchers = (dispatch: Dispatch<RootAction>, resetAction: (any: void) => RootAction, submitAction: FilterCasesFormSubmitAction, formId: string) => ({
  resetFilters: () => dispatch(resetAction()),
  applyFilters: (values: CaseFilterFormData) => dispatch(submitAction(values)),
  judgesSearchHandler: createEntitySearchHandler(dispatch, formId, SearchableEntityType.JUDGE, 'judgeIds'),
  firmsSearchHandler: createEntitySearchHandler(dispatch, formId, SearchableEntityType.FIRM, 'firmIds'),
  representativesSearchHandler: createEntitySearchHandler(dispatch, formId, SearchableEntityType.REPRESENTATIVE, 'representativeIds')
});

export const importFromUrlFormDispatchers = (dispatch: Dispatch<RootAction>, submitAction: ImportFromUrlFormSubmitAction, formId: string) =>
  ({
    importFromUrlSubmitHandler: (values: ImportFromUrlDataForm) =>
      new Promise<void>((resolve, reject) => {
        dispatch(submitAction(values, { resolve, reject, formId }));
      })
  });

const US_FEDERAL_CIRCUIT_COURTS_SELECT_OPTIONS = US_FEDERAL_CIRCUIT_VALUES.map((value) => ({ value, label: value }));

export const createCaseFormPropsSelector = (form: string) => {
  const authoringJudgeOptionsSelector = createAuthoringJudgeOptions(form);
  return (state: RootState) => ({
    authoringJudgeOptions: authoringJudgeOptionsSelector(state),
    usFederalCircuitOptions: US_FEDERAL_CIRCUIT_COURTS_SELECT_OPTIONS,
    quickAddVisibility: selectQuickAddState(state),
    isDisabled: selectIsCaseFormDisabled(state)
  });
};

export const caseFilterPropsSelector = (state: RootState) => ({
  countryOptions: countryOptionsSelector(state),
  courtOptions: courtOptionsSelector(state),
  domainOptions: legalDomainOptionsSelector(state),
  caseSubjectOptions: caseSubjectOptionsSelector(state)
});

export const importFormPropsSelectors = (state: RootState) => ({
  isPending: selectCaseImportIsPending(state)
});

export const selectCasesForTable = createSelector(
  [selectCases],
  (cases) => cases.map((c): CaseTableData => ({
    id: c.id,
    title: c.title || '',
    title_fr: c.title_fr || '',
    citation: c.citation,
    legalDomain: c.legalDomains.map((legalDomain) => legalDomain.name).join(', '),
    court: c.court.name,
    state: c.state.name,
    judgementDate: c.judgementDate
  }))
);

const createAuthoringJudgeOptions = (form: string) => {
  const formValues = formValueSelector(form);
  const emptyArray: SelectOption[] = [];
  return (state: RootState) => formValues(state, 'judges') || emptyArray;
};

export const selectCaseImportIsPending = createSelector(
  [selectCaseManagerImportState],
  (importState) => importState.pending
);

export const selectIsCaseFormDisabled = createSelector(
  [
    selectCaseImportIsPending,
    selectIsLoadingCaseManagerDependentData
  ],
  (importPending, dataLoading) => importPending || dataLoading
);

export type CaseFormState = ReturnType<ReturnType<typeof createCaseFormPropsSelector>>;

export type CaseFormDispatchers = ReturnType<typeof createCaseFormDispatchers>;

export type CaseFormProps = CaseFormState & CaseFormDispatchers;

export type CaseFilterProps = ReturnType<typeof caseFilterPropsSelector> & ReturnType<typeof filterCaseFormDispatchers>;

export type ImportFromUrlFormDispatchers = ReturnType<typeof importFromUrlFormDispatchers>;

export type ImportFromUrlFormState = ReturnType<typeof importFormPropsSelectors>;

export type ImportFromUrlFormProps = ImportFromUrlFormState & ImportFromUrlFormDispatchers;
