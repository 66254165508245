import { Dispatch } from 'redux';
import { submit } from 'redux-form';

import { RootAction } from '../../redux/modules/actions';
import { countryOptionsSelector } from '../../redux/modules/countries/selectors';
import { RootState } from '../../redux/modules/root';
import { FormSubmissionMeta } from '../../types/forms';
import { StateFormData } from '../../types/cases/state';

type StateFormSubmitAction = (values: StateFormData, meta: FormSubmissionMeta) => RootAction;

export const createStateFormDispatchers = (dispatch: Dispatch<RootAction>, submitAction: StateFormSubmitAction, formId: string) =>
  ({
    submitForm: () => dispatch(submit((formId))),
    submitHandler: (values: StateFormData) =>
      new Promise<void>((resolve, reject) => {
        dispatch(submitAction(values, { resolve, reject }));
      })
  });

export const stateFormOptionsSelector = (state: RootState) => ({
  countryOptions: countryOptionsSelector(state)
});

export type StateFormOptions = ReturnType<typeof stateFormOptionsSelector>;

export type StateFormDispatchers = ReturnType<typeof createStateFormDispatchers>;

export type StateFormProps = StateFormOptions & StateFormDispatchers;
