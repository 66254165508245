import { ActionType, createReducer } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import { PaginationMeta } from '../../util/fetch';
import { PAGINATION_DEFAULT } from '../../../constants';
import { ManagerDeleteState, ManagerEditState, ManagerNewState } from '../types';
import { DocumentTypeData, DocumentTypeEntity } from '../../../types/documents/document-type';

import { initializeCreateNewDocumentType, loadDocumentTypeForDelete, loadDocumentTypeForEdit } from './actions';
import * as documentTypeActions from './actions';

export type DocumentTypeManagerState = {
  entities: DocumentTypeEntity[],
  paginated: {
    entities: DocumentTypeEntity[],
    pagination: PaginationMeta
  },
  edit: ManagerEditState<DocumentTypeData>,
  new: ManagerNewState,
  delete: ManagerDeleteState<DocumentTypeEntity>
};

const initialDocumentTypeManagerState: DocumentTypeManagerState = {
  entities: [],
  paginated: {
    entities: [],
    pagination: PAGINATION_DEFAULT
  },
  edit: {},
  new: {},
  delete: {}
};

export const documentTypeReducer = createReducer<DocumentTypeManagerState, ActionType<typeof documentTypeActions>>(initialDocumentTypeManagerState)
  .handleAction(documentTypeActions.documentTypeFetchActions.request, (state) => ({
    ...state,
    entities: []
  }))
  .handleAction(documentTypeActions.documentTypeFetchActions.success, (state, { payload }) => ({
    ...state,
    entities: payload.data
  }))
  .handleAction(documentTypeActions.documentTypePaginatedFetchActions.success, (state, { payload }) => ({
    ...state,
    paginated: {
      entities: payload.data,
      pagination: payload.pagination
    }
  }))
  .handleAction(initializeCreateNewDocumentType, (state) => ({
    ...state,
    new: {
      operationGuid: uuidv4()
    }
  }))
  .handleAction(loadDocumentTypeForEdit.request, (state, { payload }) => ({
    ...state,
    edit: {
      id: payload
    }
  }))
  .handleAction(loadDocumentTypeForEdit.success, (state, { payload }) => ({
    ...state,
    edit: {
      ...state.edit,
      data: payload,
      operationGuid: uuidv4()
    }
  }))
  .handleAction(loadDocumentTypeForEdit.failure, (state) => ({
    ...state,
    edit: {}
  }))
  .handleAction(loadDocumentTypeForDelete.request, (state) => ({
    ...state,
    delete: {
      loading: true
    }
  }))
  .handleAction(loadDocumentTypeForDelete.success, (state, { payload }) => ({
    ...state,
    delete: {
      ...state.delete,
      loading: false,
      operationGuid: uuidv4(),
      ...payload
    }
  }))
  .handleAction(loadDocumentTypeForDelete.failure, (state) => ({
    ...state,
    delete: {
      ...state.delete,
      loading: false,
      info: {
        canDelete: false,
        usage: 0
      }
    }
  }));
