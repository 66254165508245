import React from 'react';

type ListCacheState = {
  page: number;
  perPage: number;
  filters: any;
}

function createDefaultState(): ListCacheState {
  return {
    page: 1,
    perPage: 25,
    filters: {}
  };
}

type ListCacheContextValue = {
  state: ListCacheState;
  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
  setFilters: (filters: any) => void;
}


export function createListCacheContextValue(): ListCacheContextValue {
  const state = createDefaultState();

  function setPage(newPage: number) {
    state.page = newPage;
  }

  function setPerPage(newPerPage: number) {
    state.perPage = newPerPage;
  }

  function setFilters(newFilters: any) {
    state.filters = newFilters;
  }

  return {
    state,
    setPage,
    setPerPage,
    setFilters
  }
}

export const ListCacheContext = React.createContext<ListCacheContextValue | null>(null);

export const ListCacheContextProvider = ListCacheContext.Provider;
