import { Dispatch } from 'redux';
import { submit } from 'redux-form';

import { RootAction } from '../../redux/modules/actions';
import { CaseManagerQuickAddForm } from '../../redux/modules/case-manager/reducer';
import { countryOptionsSelector } from '../../redux/modules/countries/selectors';
import { FirmForm } from '../../redux/modules/firms/types';
import { RootState } from '../../redux/modules/root';
import { FirmFormSubmissionMeta } from '../../types/forms';
import { FirmFormData } from '../../types/cases/firm';

type FirmFormSubmitAction = (values: FirmFormData, meta: FirmFormSubmissionMeta) => RootAction;

export const createFirmFormDispatchers = (
  dispatch: Dispatch<RootAction>,
  submitAction: FirmFormSubmitAction,
  formId: FirmForm,
  parentForm?: string,
  fieldId?: string,
  quickAddForm?: CaseManagerQuickAddForm
) =>
  ({
    submitForm: () => dispatch(submit((formId))),
    submitHandler: (values: FirmFormData) =>
      new Promise<void>((resolve, reject) => {
        dispatch(submitAction(values, { resolve, reject, formId, parentForm, fieldId, quickAddForm }));
      })
  });

export const firmFormOptionsSelector = (state: RootState) => ({
  countryOptions: countryOptionsSelector(state)
});

export type FirmFormOptions = ReturnType<typeof firmFormOptionsSelector>;

export type FirmFormDispatchers = ReturnType<typeof createFirmFormDispatchers>;

export type FirmFormProps = FirmFormOptions & FirmFormDispatchers;
