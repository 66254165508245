import { Box } from '@mui/material';
import * as React from 'react';
import { Route, Routes } from 'react-router';

import { MainContainerCardComponent } from '../../../components/ui/MainContainerCard';

import { CaseListContainer } from './CaseListContainer';
import { CaseDeleteContainer } from './DeleteCaseContainer';
import { EditCase } from './EditCase';
import { NewCase } from './NewCase';

export const CaseManagerContainer = () => {
  return (
    <>
      <Box p={2}>
        <MainContainerCardComponent>
          <Routes>
            <Route path="/" element={<CaseListContainer />} />
            <Route path="/add" element={<NewCase />} />
            <Route path="/:id/delete" element={<CaseDeleteContainer />} />
            <Route path="/:id" element={<EditCase />} />
          </Routes>
        </MainContainerCardComponent>
      </Box>
    </>
  );
};
