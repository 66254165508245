import * as R from 'ramda';
import { createReducer, StateType } from 'typesafe-actions';

import { arrayToIdMap } from '../../util/array-to-id-map';
import { RootAction } from '../actions';
import { CaseBodyEntity } from '../../../types/cases/case-body';

import { caseBodyFetchActions } from './actions';

type CaseBodyMap = Record<string, CaseBodyEntity>;

export const caseBodiesInitialState: CaseBodyMap = {};

export const caseBodiesReducer = createReducer<CaseBodyMap, RootAction>(caseBodiesInitialState)
  .handleAction(caseBodyFetchActions.success, (state, { payload }) => {
    const caseBodyMap = arrayToIdMap(payload.data);
    return R.mergeRight(state, caseBodyMap);
  })

export type CaseBodiesState = StateType<typeof caseBodiesReducer>;
