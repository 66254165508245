import React from 'react';
import { Grid, Typography, Stack } from '@mui/material';

import { AddIcon, RefreshIcon } from '../../../components/ui/mui-icons';
import { IconButtonComponent } from '../../../components/ui/IconButton';
import { ManagerContext } from '../contexts/manager-context';
import { useEntityRefresh } from '../hooks/useEntityRefresh';

import { ListEntitiesContainer } from './list';

export function EntityManagerLandingContainer() {
  const { entityName } = React.useContext(ManagerContext);
  const refreshEntities = useEntityRefresh();

  return (
    <>
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12}>
          <Grid container={true}>
            <Grid item={true} xs={6}>
              <Typography component="h2" variant="h5">{entityName.plural}</Typography>
            </Grid>
            <Grid item={true} xs={6}>
              <Grid container={true} justifyContent="flex-end" spacing={3}>
                <Grid item={true}>
                  <Stack direction="row" spacing={2}>
                    <IconButtonComponent onClick={refreshEntities} icon={RefreshIcon}>Refresh</IconButtonComponent>
                    <IconButtonComponent to={'./add'} icon={AddIcon}>Add</IconButtonComponent>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          <Grid container={true}>
            <ListEntitiesContainer />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
