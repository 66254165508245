import React from 'react';
import { TextField as MuiTextField } from '@mui/material';

import { CommonFieldProps } from '../shared';

export const TextField = React.forwardRef<HTMLInputElement, CommonFieldProps>(({ name, label, ...rest }, ref) => {
  return (
    <MuiTextField
      ref={ref}
      name={name}
      label={label}
      fullWidth={true}
      {...rest}
    />
  );
});

