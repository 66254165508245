
import * as R from 'ramda';
import { ActionCreator } from 'redux';
import { getFormValues, initialize, SubmissionError } from 'redux-form';
import { Epic } from 'redux-observable';
import { forkJoin, from, merge, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { PAGINATION_DEFAULT } from '../../../constants';
import { CaseImportFromUrlError } from '../../../lib/errors/CaseImportFromUrlError';
import { DeleteError } from '../../../lib/errors/DeleteError';
import { EntityFetchError } from '../../../lib/errors/EntityFetchError';
import { EntityCreateError } from '../../../lib/errors/EntityCreateError';
import { EntityUpdateError } from '../../../lib/errors/EntityUpdateError';
import { isEmpty } from '../../../lib/utils';
import { SearchableEntityType } from '../../../modules/case-manager/selectors';
import { Services } from '../../../services';
import { CuratorApi } from '../../../services/CuratorApi';
import { SelectOption } from '../../../types/util';
import { RootAction } from '../actions';
import { caseSubjectsFetchActions } from '../case-subjects/actions';
import { countriesFetchActions } from '../countries/actions';
import { courtsFetchActions } from '../courts/actions';
import { formatFirmLabel } from '../firms/selectors';
import { formatJudgeLabel } from '../judges/selectors';
import { legalDomainsFetchActions } from '../legal-domains/actions';
import { formatRepresentativeLabel } from '../representatives/selectors';
import { RootState } from '../root';
import { statesFetchActions } from '../states/actions';
import { pushRoute } from '../router/actions';
import { debugErr } from '../../../lib/debug';
import { RepresentativeEntity } from '../../../types/cases/representative';
import { JudgeEntity } from '../../../types/cases/judge';
import { FirmEntity } from '../../../types/cases/firm';

import {
  applyCaseFilters,
  casesFetchActions,
  changeCaseManagerListPage,
  changeCaseManagerListPageSize,
  clearCaseFilters,
  deleteCase,
  entityFieldSearch,
  entityFieldSearchFailed,
  entityFieldSearchSuccess,
  fetchDependentCaseManagerData,
  loadCaseForDelete,
  loadCaseForEdit,
  refreshCaseManagerList,
  submitCaseCreateForm,
  submitCaseCreateFormCompleted,
  submitCaseCreateFormFailed,
  submitCaseEditForm,
  submitCaseEditFormCompleted,
  submitCaseEditFormFailed,
  submitImportFromUrlForm,
  submitImportFromUrlFormFailed,
  submitImportFromUrlFormSuccess,
  updateCaseManagerList
} from './actions';
import { selectCaseFilterFormData, selectCaseFilterQuery, selectCasesPagination } from './selectors';
import { CASE_FILTERS_FORM_ID, CaseDependantDataRequestType } from './types';
import {
  entitiesToSelectOptions,
  transformCaseEntityToCaseForm,
  transformCaseFormDataToCaseSubmission,
  transformImportedLabelsToKnownEntityIds,
  transformRawCaseEntityToCaseEntity
} from './utils';

const fetchCasesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(casesFetchActions.request)),
    switchMap((action) =>
      from(curatorApi.searchCases(action.payload)).pipe(
        map(casesFetchActions.success),
        catchError((err) => of(casesFetchActions.failure(err)))
      )
    )
  );

const refreshCaseListEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(refreshCaseManagerList)),
    withLatestFrom(store$),
    switchMap(([_, state]) => {
      const currentPagination = selectCasesPagination(state);
      const currentFilters = selectCaseFilterQuery(state);
      const currentFilterFormData = selectCaseFilterFormData(state);
      return [
        casesFetchActions.request({
          filter: currentFilters,
          pagination: currentPagination
        }),
        initialize(CASE_FILTERS_FORM_ID, currentFilterFormData)
      ];
    }));

const updateList: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(updateCaseManagerList)),
    map((action) => casesFetchActions.request({ filter: action.payload.filter, pagination: action.payload.pagination }))
  );

const changePageEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeCaseManagerListPage)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = selectCasesPagination(state);
      const currentFilters = selectCaseFilterQuery(state);
      return updateCaseManagerList({
        filter: currentFilters,
        pagination: {
          ...currentPagination,
          page: action.payload
        }
      });
    })
  );

const changePageSizeEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeCaseManagerListPageSize)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = selectCasesPagination(state);
      const currentFilters = selectCaseFilterQuery(state);
      return updateCaseManagerList({
        filter: currentFilters,
        pagination: {
          ...currentPagination,
          page: 1,
          perPage: action.payload
        }
      });
    })
  );

const applyFiltersEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(applyCaseFilters)),
    withLatestFrom(store$),
    map(([_, state]) => {
      const currentFilters = selectCaseFilterQuery(state);
      return updateCaseManagerList({
        filter: currentFilters,
        pagination: PAGINATION_DEFAULT
      });
    })
  );

const clearFiltersEpic: Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(clearCaseFilters)),
    switchMap(() => {
      return [
        initialize(CASE_FILTERS_FORM_ID, {}),
        fetchDependentCaseManagerData.request(CaseDependantDataRequestType.FILTER),
        updateCaseManagerList({ filter: {}, pagination: PAGINATION_DEFAULT })
      ];
    })
  );

const dependantDataActions: Record<CaseDependantDataRequestType, RootAction[]> = {
  [CaseDependantDataRequestType.EDIT]: [
    caseSubjectsFetchActions.request(),
    courtsFetchActions.request(),
    legalDomainsFetchActions.request({}),
    statesFetchActions.request(),
    countriesFetchActions.request()
  ],
  [CaseDependantDataRequestType.FILTER]: [
    countriesFetchActions.request(),
    legalDomainsFetchActions.request({}),
    courtsFetchActions.request(),
    statesFetchActions.request(),
    caseSubjectsFetchActions.request()
  ],
  [CaseDependantDataRequestType.IMPORT]: [
    caseSubjectsFetchActions.request(),
    courtsFetchActions.request(),
    statesFetchActions.request()
  ]
};

const dependantDataSuccessActions = {
  [CaseDependantDataRequestType.EDIT]: [
    caseSubjectsFetchActions.success,
    courtsFetchActions.success,
    legalDomainsFetchActions.success,
    statesFetchActions.success,
    countriesFetchActions.success
  ],
  [CaseDependantDataRequestType.FILTER]: [
    countriesFetchActions.success,
    courtsFetchActions.success,
    legalDomainsFetchActions.success,
    statesFetchActions.success
  ],
  [CaseDependantDataRequestType.IMPORT]: [
    caseSubjectsFetchActions.success,
    courtsFetchActions.success,
    statesFetchActions.success
  ]
};

export const fetchCaseManagerDependentDataEpic: Epic<RootAction, RootAction> = (action$) =>
  action$
    .pipe(
      filter(isActionOf(fetchDependentCaseManagerData.request)),
      switchMap((action) => from(dependantDataActions[action.payload]))
    );

export const fetchCaseManagerDependentDataEpicSuccessWatcher: Epic<RootAction, RootAction> = (action$) =>
  action$
    .pipe(
      filter(isActionOf(fetchDependentCaseManagerData.request)),
      switchMap((action) => {
          // TODO: Ideally, we should handle any of the request actions failing and dispatch fetchDependentCaseManagerData.failure.
          //   Wasn't able to get `race` to work, maybe there's some `takeUntil` magic
          const actions: Array<ActionCreator<RootAction>> = dependantDataSuccessActions[action.payload] || [];
          const successActions = actions.map((val) => action$.pipe(filter(isActionOf(val)), take(1)));
          return forkJoin(successActions);
        }
      ),
      map(() => fetchDependentCaseManagerData.success())
    );

export const createCaseEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(submitCaseCreateForm)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const { reject, resolve } = action.meta;
        const guid = state.caseManager.new.operationGuid;
        if (!guid) {
          reject(new SubmissionError({ _error: 'Failed to create case, operation GUID missing' }));
          return [submitCaseCreateFormFailed(new EntityCreateError('operation guid missing'))];
        }
        const submission = {
          guid,
          ...transformCaseFormDataToCaseSubmission(action.payload)
        };
        return from(curatorApi.createCase(submission)).pipe(
          switchMap((response) => {
            if (response.status >= 300) {
              // TODO: Parse errors into friendlier format and field-specific where possible
              reject(new SubmissionError({ _error: `Failed to create case\n\n${JSON.stringify(response.data, null, 2)}` }));
              return [submitCaseCreateFormFailed(new EntityCreateError('server error'))];
            }
            resolve();
            return [
              pushRoute('/case-manager'),
              submitCaseCreateFormCompleted()
            ];
          }),
          catchError((err: Error) => {
            reject(new SubmissionError({ _error: `Failed to create case. (${err.message})` }));
            return of(submitCaseCreateFormFailed(new EntityCreateError('unknown error')));
          })
        );
      })
    );

export const updateCaseEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(submitCaseEditForm)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const { reject, resolve } = action.meta;
        const id = state.caseManager.edit.id;
        const guid = state.caseManager.edit.operationGuid;
        if (!id) {
          reject(new SubmissionError({ _error: 'Failed to update case, case id missing' }));
          return [submitCaseEditFormFailed(new EntityUpdateError('case id missing'))];
        }
        if (!guid) {
          reject(new SubmissionError({ _error: 'Failed to update case, operation GUID missing' }));
          return [submitCaseEditFormFailed(new EntityUpdateError('operation guid missing'))];
        }
        const submission = {
          id,
          guid,
          ...transformCaseFormDataToCaseSubmission(action.payload)
        };
        return from(curatorApi.updateCase(submission)).pipe(
          mergeMap((response) => {
            if (response.status >= 300) {
              // TODO: Parse errors into friendlier format and field-specific where possible
              reject(new SubmissionError({ _error: `Failed to update case\n\n${JSON.stringify(response.data, null, 2)}` }));
              return [submitCaseEditFormFailed(new EntityUpdateError('server error'))];
            }
            resolve();
            return [
              submitCaseEditFormCompleted(),
              pushRoute('/case-manager')
            ];
          }),
          catchError((err: Error) => {
            reject(new SubmissionError({ _error: `Failed to update case. (${err.message})` }));
            return of(submitCaseEditFormFailed(new EntityUpdateError('unknown error')));
          })
        );
      })
    );

export const fetchCaseForDeleteEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(loadCaseForDelete.request)),
      switchMap((action) => {
        return from(curatorApi.getCase(action.payload, { relations: ['judges'] })).pipe(
          switchMap((caseResponse) =>
            of(loadCaseForDelete.success(transformRawCaseEntityToCaseEntity(caseResponse.data)))
          ),
          catchError((err) =>
            of(
              loadCaseForDelete.failure(err),
              pushRoute('/case-manager')
            ))
        );
      })
    );

export const deleteCaseEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(deleteCase.request)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const guid = state.caseManager.delete.operationGuid;
        if (!guid) {
          return of(deleteCase.failure(new DeleteError('operation guid missing')));
        }
        const submission = {
          guid,
          id: action.payload
        };
        return from(curatorApi.deleteCase(submission)).pipe(
          switchMap(() => of(deleteCase.success(), pushRoute('/case-manager'))),
          catchError((err) => of(deleteCase.failure(err)))
        );
      })
    );

export const fetchCaseForEditEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(loadCaseForEdit.request)),
      switchMap((action) => {
        return from(curatorApi.getFullCase(action.payload)).pipe(
          switchMap((response) => {
            const caseEntity = transformRawCaseEntityToCaseEntity(response.data);
            const formData = transformCaseEntityToCaseForm(caseEntity);
            return of(
              fetchDependentCaseManagerData.request(CaseDependantDataRequestType.EDIT),
              loadCaseForEdit.success(caseEntity),
              initialize('case-edit', formData)
            );
          }),
          catchError((err: Error) => {
            debugErr(err);
            return from([
              loadCaseForEdit.failure(new EntityFetchError(err.message)),
              pushRoute('/case-manager')
            ]);
          })
        );
      })
    );

export const importFromUrlEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(submitImportFromUrlForm)),
      switchMap((action) => {
          const { reject, resolve, formId } = action.meta;
          return merge(
            of(fetchDependentCaseManagerData.request(CaseDependantDataRequestType.IMPORT)),
            action$.pipe(
              filter(isActionOf(fetchDependentCaseManagerData.success)),
              take(1),
              withLatestFrom(store$),
              switchMap(([_action, state]) => {
                return from(curatorApi.importFromUrl({ url: action.payload.importUrl })).pipe(
                  switchMap((response) => {
                    if (response.status >= 300) {
                      reject(new SubmissionError({ _error: `Failed to import from url: ${JSON.stringify(response.data, null, 2)}` }));
                      return [submitImportFromUrlFormFailed(new EntityCreateError('server error'))];
                    }
                    const data = transformImportedLabelsToKnownEntityIds(state, {
                      ...response.data,
                      sourceUrl: response.data.sourceUrl || action.payload.importUrl
                    });
                    resolve();
                    const currentFormValues = getFormValues(formId)(state);
                    const mergedFormValues = R.mergeDeepWith((current, incoming) => isEmpty(incoming) ? current : incoming, currentFormValues, data);
                    return of(
                      initialize(formId, mergedFormValues),
                      submitImportFromUrlFormSuccess(response.data)
                    );
                  }),
                  catchError((err: Error) => {
                    reject(new SubmissionError({ _error: `Failed to process import data: ${err.message}` }));
                    return of(submitImportFromUrlFormFailed(new CaseImportFromUrlError(err.message)));
                  })
                );
              })
            )
          );
        }
      )
    );

function searchFirms(curatorApi: CuratorApi, query: string) {
  return from(curatorApi.searchFirms({ pagination: { perPage: 10, page: 1 }, filter: { name: query } })).pipe(
    map((response) => entitiesToSelectOptions<FirmEntity>(formatFirmLabel, response.data))
  );
}

function searchRepresentatives(curatorApi: CuratorApi, query: string) {
  return from(curatorApi.searchRepresentatives({
    pagination: { perPage: 10, page: 1 },
    filter: { fullName: query }
  })).pipe(
    map((response) => entitiesToSelectOptions<RepresentativeEntity>(formatRepresentativeLabel, response.data))
  );
}

function searchJudges(curatorApi: CuratorApi, query: string) {
  return from(curatorApi.searchJudges({ pagination: { perPage: 10, page: 1 }, filter: { fullName: query } })).pipe(
    map((response) => entitiesToSelectOptions<JudgeEntity>(formatJudgeLabel, response.data))
  );
}

type SearchFunctions = {
  [K in SearchableEntityType]: (curatorApi: CuratorApi, query: string) => Observable<SelectOption[]>
};

const SEARCH_FUNCTIONS: SearchFunctions = {
  [SearchableEntityType.REPRESENTATIVE]: searchRepresentatives,
  [SearchableEntityType.FIRM]: searchFirms,
  [SearchableEntityType.JUDGE]: searchJudges
};

export const entityFieldSearchEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(entityFieldSearch)),
      switchMap((action) => {
        const { resolve, reject } = action.meta;
        const entityFetcher = SEARCH_FUNCTIONS[action.payload.entityType];
        if (!entityFetcher) {
          return of(entityFieldSearchFailed(new Error('unknown entity type passed for search')));
        }
        return entityFetcher(curatorApi, action.payload.query).pipe(
          switchMap((entities) => {
            resolve(entities);
            return of(entityFieldSearchSuccess());
          }),
          catchError((err: Error) => {
            const error = new EntityFetchError(`Failed to load entities for search: ${err.message}`);
            reject();
            return of(entityFieldSearchFailed(error));
          })
        );
      })
    );

export const caseManagerEpics = [
  fetchCaseManagerDependentDataEpicSuccessWatcher,
  fetchCaseManagerDependentDataEpic,
  fetchCasesEpic,
  refreshCaseListEpic,
  updateList,
  fetchCaseForDeleteEpic,
  deleteCaseEpic,
  changePageEpic,
  changePageSizeEpic,
  createCaseEpic,
  updateCaseEpic,
  fetchCaseForEditEpic,
  importFromUrlEpic,
  applyFiltersEpic,
  clearFiltersEpic,
  entityFieldSearchEpic
];
