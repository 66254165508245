import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Grid, Typography } from '@mui/material';

import { IconButtonComponent } from '../../../components/ui/IconButton';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCard';
import { RootAction } from '../../../redux/modules/actions';
import {
  changeCaseManagerListPage,
  changeCaseManagerListPageSize,
  refreshCaseManagerList
} from '../../../redux/modules/case-manager/actions';
import { selectCasesPagination } from '../../../redux/modules/case-manager/selectors';
import { RootState } from '../../../redux/modules/root';
import { CaseEntityTableComponent, CaseTableData } from '../components/CaseEntityTableComponent';
import { selectCasesForTable } from '../selectors';
import { EntityTableColumn } from '../../../components/EntityTable/types';
import { AddIcon, RefreshIcon } from '../../../components/ui/mui-icons';
import { pushRoute } from '../../../redux/modules/router/actions';

import { CaseFilterContainerComponent } from './CaseFilterContainer';

const ENTITY_PREPEND_ROUTE = '/case-manager';

const columns: EntityTableColumn<CaseTableData>[] = [
  { name: 'title', title: 'Title (En)' },
  { name: 'title_fr', title: 'Title (Fr)' },
  { name: 'citation', title: 'Citation' },
  { name: 'legalDomain', title: 'Domain' },
  { name: 'court', title: 'Court' },
  { name: 'state', title: 'State/Province' },
  { name: 'judgementDate', title: 'Judgment Date' }
];

const mapDispatchToProps = (dispatch: Dispatch<RootAction>) => ({
  editCase: (entityId: string) => dispatch(pushRoute(`${ENTITY_PREPEND_ROUTE}/${entityId}`)),
  deleteCase: (entityId: string) => dispatch(pushRoute(`${ENTITY_PREPEND_ROUTE}/${entityId}/delete`)),
  refreshCases: () => dispatch(refreshCaseManagerList()),
  changePage: (page: number) => dispatch(changeCaseManagerListPage(page + 1)),
  changePageSize: (size: number) => dispatch(changeCaseManagerListPageSize(size))
});

const mapStateToProps = (state: RootState) => ({
  data: selectCasesForTable(state),
  pagination: selectCasesPagination(state)
});

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export type CaseManagerContainerProps = StateProps & DispatchProps;

class CaseManagerListContainerComponent extends React.Component<CaseManagerContainerProps> {
  componentDidMount() {
    this.props.refreshCases();
  }

  render() {
    const tableProps = {
      columns,
      data: this.props.data,
      onEntityEdit: this.props.editCase,
      onEntityDelete: this.props.deleteCase,
      onChangePage: this.props.changePage,
      onChangePageSize: this.props.changePageSize,
      pagination: this.props.pagination
    };

    return (
      <Grid container={true} spacing={3}>
        <Grid item={true} xs={12}>
          <Grid container={true}>
            <Grid item={true} xs={6}>
              <Typography component="h2" variant="h5">Cases</Typography>
            </Grid>
            <Grid item={true} xs={6}>
              <Grid container={true} justifyContent="flex-end" spacing={3}>
                <Grid item={true}>
                  <IconButtonComponent
                    onClick={this.props.refreshCases}
                    icon={RefreshIcon}>
                    Refresh
                  </IconButtonComponent>
                  <IconButtonComponent
                    to={'/case-manager/add'}
                    icon={AddIcon}>
                    Add Case
                  </IconButtonComponent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12}>
          <MainContainerCardComponent>
            <Grid container={true}>
              <Grid item={true} xs={6}>
                <Typography component="h2" variant="h6">Filters</Typography>
              </Grid>
            </Grid>
            <Grid container={true}>
              <Grid item={true} xs={12}>
                <CaseFilterContainerComponent />
              </Grid>
            </Grid>
          </MainContainerCardComponent>
        </Grid>
        <Grid item={true} xs={12}>
          <Grid container={true}>
            <Grid item={true} xs={12}>
              <CaseEntityTableComponent {...tableProps} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export const CaseListContainer = connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(
  CaseManagerListContainerComponent
);
