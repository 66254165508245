import { stringify } from 'qs';

import { EntityFetchError } from '../lib/errors/EntityFetchError';
import { UNAUTHORIZED_STATUS_CODE, UnauthorizedError } from '../lib/errors/UnauthorizedError';
import {
  addFetchData,
  addPaginatedFetchData,
  PaginatedQuery,
  PaginationConfig,
  StandardResponse,
  WithFetchMeta,
  WithPaginatedFetchMeta
} from '../redux/util/fetch';
import { DeleteEligibilityResponse } from '../types/command-types';

export type Methods = 'POST' | 'GET';

export type CommandResult<TEntity> = {
  result: TEntity,
  status: string,
  message?: string
};

type StandardQueryOptions = { relations?: string[] };

export abstract class CuratorApiAbstract {

  protected curatorToken: string = '';
  protected readonly apiUrl: string = '';

  constructor(apiUrl: string = '') {
    this.apiUrl = apiUrl;
  }

  static generateQueryString(pagination: PaginationConfig, relations: string[] = []): string {
    const page = Math.max(1, pagination.page);
    const perPage = Math.max(5, pagination.perPage);
    return stringify({ page, per_page: perPage, relations }, { addQueryPrefix: true });
  }

  public setToken(token: string): void {
    this.curatorToken = token;
  }


  protected baseHeaders(): Record<string, string> {
    return {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.curatorToken}`
    };
  }

  protected apiFetch(url: string, requestParams: RequestInit): Promise<Response> {
    return fetch(`${this.apiUrl}${url}`, {
      ...requestParams,
      headers: {
        ...this.baseHeaders(),
        ...(requestParams.headers || {})
      },
      credentials: 'include'
    }).then((response) => {
      if (!response.ok) {
        if (response.status === UNAUTHORIZED_STATUS_CODE) {
          throw new UnauthorizedError(response.statusText);
        }
      }
      return response;
    });
  }

  protected createGetEntityDeleteEligibility(queryRoute: string) {
    return async (entityType: string, entityId: string): Promise<WithFetchMeta<DeleteEligibilityResponse>> => {
      const payload = { entityType, entityId };
      const url = `/${queryRoute}/rest/${entityType}/${entityId}/delete-eligibility`;
      const response = await this.apiFetch(url, {});
      const responseBody: DeleteEligibilityResponse = await response.json();
      if (response.status >= 400) {
        throw new EntityFetchError(response.statusText);
      }
      return addFetchData<DeleteEligibilityResponse>(responseBody, payload);
    }
  }

  protected createFetchStandardEntity<TEntity>(baseRoute: string, entityName: string) {
    return async (entityId: string, payload?: StandardQueryOptions): Promise<WithFetchMeta<TEntity>> => {
      const queryString = stringify(payload, { addQueryPrefix: true });
      const url = `/${baseRoute}/${entityName}/${entityId}${queryString}`;
      const response = await this.apiFetch(url, {});
      const responseBody: TEntity = await response.json();
      if (response.status >= 400) {
        throw new EntityFetchError(response.statusText);
      }
      return addFetchData<TEntity>(responseBody, payload);
    };
  }

  protected createFetchStandardEntities<TEntity>(apiSubRoute: string, command: string, method: Methods = 'POST') {
    return async (payload: Partial<TEntity> & StandardQueryOptions): Promise<WithFetchMeta<TEntity[]>> => {
      const requestParams: RequestInit = {
        method
      };

      let url = `/${apiSubRoute}/${command}`;

      if (method === 'POST') {
        requestParams.body = JSON.stringify(payload);
      } else if (method === 'GET') {
        url = `${url}${stringify(payload, { addQueryPrefix: true })}`;
      }

      const response = await this.apiFetch(url, requestParams);
      const responseBody: TEntity[] = await response.json();

      return addFetchData<TEntity[]>(responseBody, payload);
    };
  }

  protected createFetchCaseQuery<TEntity>(command: string, method: Methods = 'POST') {
    return this.createFetchStandardEntities<TEntity>('case-query', command, method);
  }

  protected createPaginatedFetchStandardEntity<TEntity, TQuery>(apiSubRoute: string, paginatedCommand: string) {
    return async (payload: PaginatedQuery<TQuery>): Promise<WithPaginatedFetchMeta<TEntity>> => {
      const query = CuratorApiAbstract.generateQueryString(payload.pagination, payload.relations);
      const body = JSON.stringify({
        ...payload.filter
      });

      const response = await this.apiFetch(`/${apiSubRoute}/${paginatedCommand}${query}`, {
        method: 'POST',
        body
      });

      if (response.status >= 400) {
        throw new EntityFetchError(response.statusText);
      }

      const responsePagination = {
        page: Number(response.headers.get('X-Page')),
        perPage: Number(response.headers.get('X-Per-Page')),
        total: Number(response.headers.get('X-Total-Count'))
      };
      return addPaginatedFetchData<TEntity>((await response.json()), responsePagination, payload);
    };
  }

  protected createPaginatedFetchCaseQuery<TEntity, TQuery = TEntity>(paginatedCommand: string) {
    return this.createPaginatedFetchStandardEntity<TEntity, TQuery>('case-query', paginatedCommand);
  }

  protected createPaginatedFetchDocumentQuery<TEntity, TQuery = TEntity>(paginatedCommand: string) {
    return this.createPaginatedFetchStandardEntity<TEntity, TQuery>('document-query', paginatedCommand);
  }

  protected createStandardEntityCommandRequest<TResult, TQuery>(apiSubRoute: string, command: string) {
    return async (payload: TQuery): Promise<StandardResponse<TResult>> => {
      const response = await this.apiFetch(`/${apiSubRoute}/${command}`, {
        method: 'POST',
        body: JSON.stringify(payload)
      });

      const body: TResult = await response.json();

      return {
        status: response.status,
        data: body
      };
    };
  }

  protected createCaseManagerCommandRequest<TEntity, TQuery>(command: string) {
    return this.createStandardEntityCommandRequest<CommandResult<TEntity>, TQuery>('case-manager', command);
  }

  protected createDocumentManagerCommandRequest<TEntity, TQuery>(command: string) {
    return this.createStandardEntityCommandRequest<CommandResult<TEntity>, TQuery>('document-manager', command);
  }

  protected createCaseImportCommandRequest<TEntity, TQuery>(command: string) {
    return this.createStandardEntityCommandRequest<TEntity, TQuery>('case-import', command);
  }
}
