import { ErrorType, ICuratorError } from './types';

export const UNAUTHORIZED_STATUS_CODE = 401;
export class UnauthorizedError extends Error implements ICuratorError {
  public readonly status: number = UNAUTHORIZED_STATUS_CODE;
  public readonly type: ErrorType = ErrorType.Unauthorized;
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, UnauthorizedError.prototype);
  }
}
