import { ErrorType, ICuratorError } from './types';

export class DeleteError extends Error implements ICuratorError {
  public readonly status: number = 500;
  public readonly type: ErrorType = ErrorType.Save;
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, DeleteError.prototype);
  }
}
