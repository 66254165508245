import { AppBar, Box, Button, IconButton, Toolbar, Typography } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { logout } from '../redux/modules/auth/actions';

import { HomeIcon } from './ui/mui-icons';

export const GlobalHeaderComponent: React.FC = () => {
  const dispatch = useDispatch();

  const logoutHandler = useCallback(() => {
    dispatch(logout(true));
  }, [dispatch])

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Box mr={2} ml={-2}>
            <IconButton
              color="inherit"
              aria-label="Go Home"
              component={Link}
              to="/">
              <HomeIcon />
            </IconButton>
          </Box>
          <Box flexGrow={1}>
            <Typography component="h1" variant="h6" color="inherit">
              Curator
            </Typography>
          </Box>
          <Button color="inherit" onClick={logoutHandler}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
