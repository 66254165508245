import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { Services } from '../../../services';
import { RootAction } from '../actions';
import { RootState } from '../root';

import { legalDomainsFetchActions } from './actions';

export const fetchLegalDomainsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(legalDomainsFetchActions.request)),
    mergeMap((action) =>
      from(curatorApi.getAllLegalDomains(action.payload)).pipe(
        map(legalDomainsFetchActions.success),
        catchError((err) => of(legalDomainsFetchActions.failure(err)))
      )
    )
  );
