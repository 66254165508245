import { composeWithDevToolsLogOnlyInProduction } from '@redux-devtools/extension';
import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { StateType } from 'typesafe-actions';

import { AUTH_LOCAL_STORAGE_KEY } from '../../constants';
import { Services } from '../../services';

import { RootAction } from './actions';
import { setAuthData } from './auth/actions';
import { authEpics } from './auth/epics';
import { fetchCaseBodyEpic } from './case-bodies/epics';
import { caseBodiesInitialState, caseBodiesReducer } from './case-bodies/reducer';
import { caseManagerEpics } from './case-manager/epics';
import { caseManagerInitialState, caseManagerReducer } from './case-manager/reducer';
import { caseSubjectEpics } from './case-subjects/epics';
import { caseSubjectsManagerInitialState, caseSubjectsManagerReducer } from './case-subjects/reducer';
import { countryEpics } from './countries/epics';
import { countriesManagerInitialState, countriesManagerReducer } from './countries/reducer';
import { courtEpics } from './courts/epics';
import { courtsManagerInitialState, courtsManagerReducer } from './courts/reducer';
import { firmEpics } from './firms/epics';
import { firmsManagerInitialState, firmsManagerReducer } from './firms/reducer';
import { judgeEpics } from './judges/epics';
import { judgesManagerInitialState, judgesManagerReducer } from './judges/reducer';
import { fetchLegalDomainsEpic } from './legal-domains/epics';
import { legalDomainsInitialState, legalDomainsReducer } from './legal-domains/reducer';
import { representativeEpics } from './representatives/epics';
import { representativesManagerInitialState, representativesManagerReducer } from './representatives/reducer';
import { stateEpics } from './states/epics';
import { statesManagerInitialState, statesManagerReducer } from './states/reducer';
import { documentManagerEpics } from './document-manager/epics';
import { documentManagerReducer } from './document-manager/reducer';
import { documentAuthorReducer } from './document-authors/reducer';
import { documentTypeReducer } from './document-types/reducer';
import { documentAuthorEpics } from './document-authors/epics';
import { documentTypeEpics } from './document-types/epics';
import { routerEpics } from './router/epics';

export const rootInitialState: Partial<RootState> = {
  caseBodies: caseBodiesInitialState,
  caseManager: caseManagerInitialState,
  caseSubjects: caseSubjectsManagerInitialState,
  countries: countriesManagerInitialState,
  courts: courtsManagerInitialState,
  firms: firmsManagerInitialState,
  judges: judgesManagerInitialState,
  legalDomains: legalDomainsInitialState,
  representatives: representativesManagerInitialState,
  states: statesManagerInitialState
};

export const rootReducer = combineReducers({
  form: formReducer,
  caseBodies: caseBodiesReducer,
  caseManager: caseManagerReducer,
  caseSubjects: caseSubjectsManagerReducer,
  countries: countriesManagerReducer,
  courts: courtsManagerReducer,
  documentAuthors: documentAuthorReducer,
  documentManager: documentManagerReducer,
  documentTypes: documentTypeReducer,
  firms: firmsManagerReducer,
  judges: judgesManagerReducer,
  legalDomains: legalDomainsReducer,
  representatives: representativesManagerReducer,
  states: statesManagerReducer
});

export type RootState = StateType<typeof rootReducer>;

export function initializeCuratorStore(store: Store) {
  const authToken = window.localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
  if (authToken) {
    store.dispatch(setAuthData({ token: authToken }));
  }
}

export function createCuratorStore(services: Services) {
  const rootEpic = combineEpics<RootAction, RootAction, RootState, Services>(
    fetchCaseBodyEpic,
    fetchLegalDomainsEpic,
    ...authEpics,
    ...courtEpics,
    ...caseManagerEpics,
    ...documentAuthorEpics,
    ...documentManagerEpics,
    ...documentTypeEpics,
    ...firmEpics,
    ...representativeEpics,
    ...judgeEpics,
    ...stateEpics,
    ...countryEpics,
    ...caseSubjectEpics,
    ...routerEpics
  );

  const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, Services>({
    dependencies: services
  });

  const store = createStore(
    rootReducer,
    rootInitialState,
    composeWithDevToolsLogOnlyInProduction(
      applyMiddleware(
        epicMiddleware
      )
    )
  );

  epicMiddleware.run(rootEpic);

  return store;
}
