
import { initialize, SubmissionError } from 'redux-form';
import { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import { forkJoin, from, merge, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { PAGINATION_DEFAULT } from '../../../constants';
import { DeleteError } from '../../../lib/errors/DeleteError';
import { EntityFetchError } from '../../../lib/errors/EntityFetchError';
import { EntityCreateError } from '../../../lib/errors/EntityCreateError';
import { EntityUpdateError } from '../../../lib/errors/EntityUpdateError';
import { courtFormOptionsSelector } from '../../../modules/court-manager/selectors';
import { Services } from '../../../services';
import { RootAction } from '../actions';
import { RootState } from '../root';
import { statesFetchActions } from '../states/actions';
import { pushRoute } from '../router/actions';

import {
  changeCourtsListPage,
  changeCourtsListPageSize,
  courtsFetchActions,
  courtsPaginatedFetchActions,
  deleteCourt,
  fetchDependentCourtData,
  loadCourtForDelete,
  loadCourtForEdit,
  refreshCourtsList,
  SetCourtPayload,
  setFormDataFromCourt,
  submitCourtCreateForm,
  submitCourtCreateFormCompleted,
  submitCourtCreateFormFailed,
  submitCourtEditForm,
  submitCourtEditFormCompleted,
  submitCourtEditFormFailed,
  updateCourtsList
} from './actions';
import { RELATIONS_DEFAULT } from './constants';
import { selectCourtsPagination } from './selectors';
import { transformCourtFormDataToCourtSubmission, transformCourtToFormData } from './utils';

const requestActions = [
  statesFetchActions.request()
];

const successActionCreators = [
  statesFetchActions.success
];

export const fetchPaginatedCourtsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(courtsPaginatedFetchActions.request)),
    switchMap((action) =>
      from(curatorApi.searchCourts(action.payload)).pipe(
        map(courtsPaginatedFetchActions.success),
        catchError((err) => of(courtsPaginatedFetchActions.failure(err)))
      )
    )
  );

export const fetchCourtsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(courtsFetchActions.request)),
    switchMap(() =>
      from(curatorApi.getAllCourts({ relations: ['state', 'state.country'] })).pipe(
        map(courtsFetchActions.success),
        catchError((err) => of(courtsFetchActions.failure(err)))
      )
    )
  );

export const fetchCourtManagerDependentDataEpic: Epic<RootAction, RootAction> = (action$) =>
  action$
    .pipe(
      filter(isActionOf(fetchDependentCourtData.request)),
      switchMap(() => requestActions)
    );

export const fetchCourtManagerDependentDataEpicSuccessWatcher: Epic<RootAction, RootAction> = (action$) =>
  action$
    .pipe(
      filter(isActionOf(fetchDependentCourtData.request)),
      switchMap(() =>
        // TODO: Ideally, we should handle any of the request actions failing and dispatch fetchDependentCaseManagerData.failure.
        //   Wasn't able to get `race` to work, maybe there's some `takeUntil` magic
        forkJoin(
          successActionCreators.map((action) =>
            action$.pipe(
              filter(isActionOf(action)),
              take(1)
            )
          )
        )
      ),
      map(() => fetchDependentCourtData.success())
    );

const refreshEpic: Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(refreshCourtsList)),
    map(() => courtsPaginatedFetchActions.request({
      filter: {},
      pagination: PAGINATION_DEFAULT,
      relations: RELATIONS_DEFAULT
    })));

const updateEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(updateCourtsList)),
    map((action) => {
      const queryFilter = {};
      return courtsPaginatedFetchActions.request({
        filter: queryFilter,
        pagination: action.payload,
        relations: RELATIONS_DEFAULT
      });
    })
  );

const changePageEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeCourtsListPage)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = selectCourtsPagination(state);
      return updateCourtsList({
        ...currentPagination,
        page: action.payload
      });
    })
  );

const changePageSizeEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeCourtsListPageSize)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = selectCourtsPagination(state);
      return updateCourtsList({
        ...currentPagination,
        page: 1,
        perPage: action.payload
      });
    })
  );

export const createCourtEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(submitCourtCreateForm)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const { reject, resolve } = action.meta;
        const guid = state.courts.new.operationGuid;
        if (!guid) {
          reject(new SubmissionError({ _error: 'Failed to create judge, operation GUID missing' }));
          return [submitCourtCreateFormFailed(new EntityCreateError('operation guid missing'))];
        }
        const submission = {
          guid,
          data: transformCourtFormDataToCourtSubmission(action.payload)
        };
        return from(curatorApi.createCourt(submission)).pipe(
          switchMap((response) => {
            if (response.status >= 300) {
              // TODO: Parse errors into friendlier format and field-specific where possible
              reject(new SubmissionError({ _error: `Failed to create court\n\n${JSON.stringify(response.data, null, 2)}` }));
              return [submitCourtCreateFormFailed(new EntityCreateError('server error'))];
            }
            resolve();
            return [
              submitCourtCreateFormCompleted(),
              pushRoute('/court-manager')
            ];
          }),
          catchError((err: Error) => {
            reject(new SubmissionError({ _error: `Failed to create court. (${err.message})` }));
            return of(submitCourtCreateFormFailed(new EntityCreateError('unknown error')));
          })
        );
      })
    );

export const updateCourtEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(submitCourtEditForm)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const { reject, resolve } = action.meta;
        const id = state.courts.edit.id;
        const guid = state.courts.edit.operationGuid;
        if (!id) {
          reject(new SubmissionError({ _error: 'Failed to update court, id missing' }));
          return [submitCourtEditFormFailed(new EntityUpdateError('court id missing'))];
        }
        if (!guid) {
          reject(new SubmissionError({ _error: 'Failed to update court, operation GUID missing' }));
          return [submitCourtEditFormFailed(new EntityUpdateError('court id missing'))];
        }
        const submission = {
          id,
          guid,
          data: transformCourtFormDataToCourtSubmission(action.payload)
        };
        return from(curatorApi.updateCourt(submission)).pipe(
          switchMap((response) => {
            if (response.status >= 300) {
              // TODO: Parse errors into friendlier format and field-specific where possible
              reject(new SubmissionError({ _error: `Failed to update court\n\n${JSON.stringify(response.data, null, 2)}` }));
              return [submitCourtEditFormFailed(new EntityUpdateError('server error'))];
            }
            resolve();
            return [
              submitCourtEditFormCompleted(),
              pushRoute('/court-manager')
            ];
          }),
          catchError((err: Error) => {
            reject(new SubmissionError({ _error: `Failed to update court. (${err.message})` }));
            return of(submitCourtEditFormFailed(new EntityUpdateError('unknown error')));
          })
        );
      })
    );

export const fetchCourtForEditEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(loadCourtForEdit.request)),
      switchMap((action) => from(curatorApi.getCourt(action.payload)).pipe(
        mergeMap((response) =>
          of(
            loadCourtForEdit.success(response.data),
            setFormDataFromCourt({ formId: 'court-edit', data: response.data })
          )),
        catchError((err: Error) => {
          return from([
            loadCourtForEdit.failure(new EntityFetchError(err.message)),
            pushRoute('/court-manager')
          ]);
        })
      )));

export const fetchCourtForDeleteEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(loadCourtForDelete.request)),
      switchMap((action) =>
        forkJoin([
          from(curatorApi.getCourt(action.payload, { relations: ['state'] })),
          from(curatorApi.getCaseQueryEntityDeleteEligibility('court', action.payload))
        ]).pipe(
          switchMap(([fetchEntityResponse, deleteEligibilityResponse]) =>
            of(loadCourtForDelete.success({
              data: fetchEntityResponse.data,
              info: deleteEligibilityResponse.data
            }))),
          catchError((err) =>
            of(
              loadCourtForDelete.failure(err),
              pushRoute('/court-manager')
            ))
        ))
    );

export const deleteCourtEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(deleteCourt.request)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const guid = state.courts.delete.operationGuid;
        if (!guid) {
          return of(deleteCourt.failure(new DeleteError('operation guid missing')));
        }
        if (!state.courts.delete.info?.canDelete) {
          return of(deleteCourt.failure(new DeleteError('delete failed state check')));
        }
        const submission = {
          guid,
          id: action.payload
        };
        return from(curatorApi.deleteCourt(submission)).pipe(
          switchMap(() => of(deleteCourt.success(), pushRoute('/court-manager'))),
          catchError((err) => of(deleteCourt.failure(err)))
        );
      })
    );

export const waitForSuccessAndInitializeForm = (action$: ActionsObservable<RootAction>, store$: StateObservable<RootState>, payload: SetCourtPayload) => {
  return action$.pipe(
    filter(isActionOf(fetchDependentCourtData.success)),
    take(1),
    withLatestFrom(store$),
    map(([_, state]) => {
      return initialize(payload.formId, transformCourtToFormData(payload.data, courtFormOptionsSelector(state)));
    })
  );
};

export const setFormDataFromCourtEpic: Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$
    .pipe(
      filter(isActionOf(setFormDataFromCourt)),
      map((action) => action.payload),
      switchMap((payload) =>
        merge(
          // Dispatch action to refresh dependent data
          of(fetchDependentCourtData.request()),
          // Listen for the dependent data success and then initialize the form values
          waitForSuccessAndInitializeForm(action$, store$, payload)
        )
      )
    );

export const courtEpics = [
  fetchCourtsEpic,
  fetchPaginatedCourtsEpic,
  refreshEpic,
  updateEpic,
  changePageEpic,
  changePageSizeEpic,
  fetchCourtManagerDependentDataEpic,
  fetchCourtManagerDependentDataEpicSuccessWatcher,
  createCourtEpic,
  updateCourtEpic,
  fetchCourtForEditEpic,
  setFormDataFromCourtEpic,
  fetchCourtForDeleteEpic,
  deleteCourtEpic
];
