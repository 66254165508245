import * as R from 'ramda';
import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import { PAGINATION_DEFAULT } from '../../../constants';
import { FirmFilterFormData } from '../../../modules/firm-manager/components/FirmFilterComponent';
import { PaginationMeta } from '../../util/fetch';
import { RootAction } from '../actions';
import { ManagerDeleteState } from '../types';
import { FirmEntity } from '../../../types/cases/firm';

import {
  applyFirmFilters,
  clearFirmFilters,
  fetchDependentFirmData,
  firmsPaginatedFetchActions,
  loadFirmForDelete,
  loadFirmForEdit,
  startNewFirmOperation
} from './actions';
import { FirmForm } from './types';

export type FirmManagerEntitiesState = {
  filter: Partial<FirmFilterFormData>,
  paginated: {
    entities: FirmEntity[],
    pagination: PaginationMeta,
  }
};

export type FirmManagerDependentDataState = {
  loading: boolean,
};

export const firmsManagerEntitiesInitialState: FirmManagerEntitiesState = {
  filter: {},
  paginated: {
    entities: [],
    pagination: PAGINATION_DEFAULT
  }
};

export const firmsManagerEntitiesReducer = (state: FirmManagerEntitiesState = firmsManagerEntitiesInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(applyFirmFilters): {
      return {
        ...state,
        filter: action.payload
      };
    }

    case getType(clearFirmFilters): {
      return {
        ...state,
        filter: {}
      };
    }

    case getType(firmsPaginatedFetchActions.success): {
      return {
        ...state,
        paginated: {
          entities: action.payload.data,
          pagination: action.payload.pagination
        }
      };
    }

    case getType(firmsPaginatedFetchActions.request): {
      return {
        ...state
      };
    }

    case getType(firmsPaginatedFetchActions.failure): {
      return {
        ...state,
        paginated: {
          entities: [],
          pagination: PAGINATION_DEFAULT
        }
      };
    }

    default: {
      return state;
    }
  }
};

export type FirmManagerNewState = {
  operationGuid: {
    [K in FirmForm]?: string
  }
};

export const firmManagerNewInitialState = {
  operationGuid: {}
};

const setOperationGuid = (key: FirmForm, value: string, state: FirmManagerNewState) => R.assocPath(['operationGuid', key], value, state);

export const firmManagerNewReducer = (state: FirmManagerNewState = firmManagerNewInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(startNewFirmOperation): {
      return setOperationGuid(action.payload, uuidv4(), state);
    }

    default: {
      return state;
    }
  }
};

export type FirmManagerEditState = {
  id: string | null,
  data: FirmEntity | null,
  operationGuid: string | null,
};

const firmManagerEditInitialState = {
  id: null,
  data: null,
  operationGuid: null
};

export const firmManagerEditReducer = (state: FirmManagerEditState = firmManagerEditInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(loadFirmForEdit.request): {
      return {
        ...state,
        id: action.payload,
        operationGuid: null
      };
    }
    case getType(loadFirmForEdit.success): {
      return {
        ...state,
        data: action.payload,
        operationGuid: uuidv4()
      };
    }
    case getType(loadFirmForEdit.failure): {
      return {
        ...state,
        data: null
      };
    }
    default: {
      return state;
    }
  }
};

const firmManagerDeleteInitialState: ManagerDeleteState<FirmEntity> = {
  loading: false
};

export const firmManagerDeleteReducer = (state: ManagerDeleteState<FirmEntity> = firmManagerDeleteInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(loadFirmForDelete.request): {
      return {
        loading: true
      };
    }
    case getType(loadFirmForDelete.success): {
      return {
        ...state,
        loading: false,
        operationGuid: uuidv4(),
        ...action.payload
      };
    }
    case getType(loadFirmForDelete.failure): {
      return {
        ...state,
        loading: false,
        info: {
          canDelete: false,
          usage: 0
        }
      };
    }
    default: {
      return state;
    }
  }
};

export const firmManagerDependentDataInitialState = {
  loading: false
};

export const firmManagerDependentDataReducer = (state: FirmManagerDependentDataState = firmManagerDependentDataInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(fetchDependentFirmData.request): {
      return {
        ...state,
        loading: true
      };
    }
    case getType(fetchDependentFirmData.success): {
      return {
        ...state,
        loading: false
      };
    }
    case getType(fetchDependentFirmData.failure): {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export const firmsManagerInitialState = {
  entities: firmsManagerEntitiesInitialState,
  dependentData: firmManagerDependentDataInitialState,
  edit: firmManagerEditInitialState,
  new: firmManagerNewInitialState,
  delete: firmManagerDeleteInitialState
};

export type FirmsManagerState = {
  entities: FirmManagerEntitiesState,
  dependentData: FirmManagerDependentDataState,
  edit: FirmManagerEditState,
  new: FirmManagerNewState,
  delete: ManagerDeleteState<FirmEntity>
};

export const firmsManagerReducer = combineReducers<FirmsManagerState>({
  entities: firmsManagerEntitiesReducer,
  dependentData: firmManagerDependentDataReducer,
  edit: firmManagerEditReducer,
  new: firmManagerNewReducer,
  delete: firmManagerDeleteReducer
});
