import { useForm } from 'react-hook-form';
import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';

import { useFormContext } from '../hooks/useFormContext';
import { FormGenerator } from '../forms/form-generator';
import { ButtonComponent } from '../../../components/ui/Button';
import { useListCacheContext } from '../hooks/useListCacheContext';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCard';

interface FiltersContainerProps {
  onSubmit: (values: unknown) => void;
}

export function EntityListFiltersContainer(props: FiltersContainerProps) {
  const { onSubmit } = props;
  const {
    filterConfigs,
    defaultFilterValues
  } = useFormContext();

  const listCache = useListCacheContext();

  const cachedFilterValues = listCache && listCache.state.filters ? listCache.state.filters : {};

  // Merge defaults with any stored filter data
  const defaultValues = {
    ...defaultFilterValues,
    ...cachedFilterValues
  };

  const { control, handleSubmit, reset } = useForm({ defaultValues });

  const onValidCallback = (values: unknown) => onSubmit(values);

  const wrappedHandleSubmit = handleSubmit(onValidCallback);

  const handleResetClicked = React.useCallback(() => {
    onSubmit(defaultFilterValues);
    reset(defaultFilterValues);
  }, [listCache]);

  // Don't bother rendering if there aren't any fields defined
  if (Object.keys(filterConfigs).length === 0) {
    return null;
  }

  return (
    <Grid item={true}>
      <MainContainerCardComponent>
        <Grid container={true}>
          <Grid item={true} xs={6}>
            <Typography component="h2" variant="h6">Filters</Typography>
          </Grid>
        </Grid>
        <Grid container={true}>
          <Grid item={true} xs={12}>
            <Box component="form" onSubmit={wrappedHandleSubmit} sx={{ flexGrow: 1 }}>
              <Grid container={true} spacing={3} direction="column">
                <Grid item={true}>
                  <FormGenerator
                    fields={filterConfigs}
                    control={control}
                  />
                </Grid>
                <Grid item={true}>
                  <Stack direction="row" spacing={2}>
                    <ButtonComponent variant="outlined" type="submit">Search</ButtonComponent>
                    <ButtonComponent type="button" onClick={handleResetClicked}>Reset</ButtonComponent>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </MainContainerCardComponent>
    </Grid>
  );
}
