import { createAsyncEntitySelector } from '../../../entity-selector';
import { personEntityFormatter } from '../../../../redux/modules/case-manager/utils';
import { CuratorApi } from '../../../../services/CuratorApi';

function search(api: CuratorApi, filter: string) {
  return api.searchJudges(
    {
      filter: {
        fullName: filter
      },
      pagination: {
        page: 1,
        perPage: 20
      }
    }
  ).then((result) =>
    result.data.map((judge) => ({
      label: personEntityFormatter(judge),
      value: judge.id
    })));
}

export const JudgeField = createAsyncEntitySelector({
  search,
  pluralEntityName: 'judges',
  key: 'judge'
});
