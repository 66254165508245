import { initialize, SubmissionError } from 'redux-form';
import { Epic } from 'redux-observable';
import { forkJoin, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { PAGINATION_DEFAULT } from '../../../constants';
import { DeleteError } from '../../../lib/errors/DeleteError';
import { EntityFetchError } from '../../../lib/errors/EntityFetchError';
import { EntityCreateError } from '../../../lib/errors/EntityCreateError';
import { EntityUpdateError } from '../../../lib/errors/EntityUpdateError';
import { Services } from '../../../services';
import { RootAction } from '../actions';
import { RootState } from '../root';
import { pushRoute } from '../router/actions';

import {
  changeCountriesListPage,
  changeCountriesListPageSize,
  countriesFetchActions,
  countriesPaginatedFetchActions,
  deleteCountry,
  loadCountryForDelete,
  loadCountryForEdit,
  refreshCountriesList,
  setFormDataFromCountry,
  submitCountryCreateForm,
  submitCountryCreateFormCompleted,
  submitCountryCreateFormFailed,
  submitCountryEditForm,
  submitCountryEditFormCompleted,
  submitCountryEditFormFailed,
  updateCountriesList
} from './actions';
import { selectCountriesPagination } from './selectors';
import { transformCountryFormDataToCountrySubmission, transformCountryToFormData } from './utils';

export const fetchPaginatedCountriesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(countriesPaginatedFetchActions.request)),
    mergeMap((action) =>
      from(curatorApi.searchCountries(action.payload)).pipe(
        map(countriesPaginatedFetchActions.success),
        catchError((err) => of(countriesPaginatedFetchActions.failure(err)))
      )
    )
  );

export const fetchCountriesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(countriesFetchActions.request)),
    mergeMap(() =>
      from(curatorApi.getAllCountries({})).pipe(
        map(countriesFetchActions.success),
        catchError((err) => of(countriesFetchActions.failure(err)))
      )
    )
  );

const refreshEpic: Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(refreshCountriesList)),
    map(() => countriesPaginatedFetchActions.request({ filter: {}, pagination: PAGINATION_DEFAULT })));

const updateEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(updateCountriesList)),
    map((action) => {
      const queryFilter = {};
      return countriesPaginatedFetchActions.request({ filter: queryFilter, pagination: action.payload });
    })
  );

const changePageEpic: Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeCountriesListPage)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = selectCountriesPagination(state);
      return updateCountriesList({
        ...currentPagination,
        page: action.payload
      });
    })
  );

const changePageSizeEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeCountriesListPageSize)),
    withLatestFrom(store$),
    map(([action, country]) => {
      const currentPagination = selectCountriesPagination(country);
      return updateCountriesList({
        ...currentPagination,
        page: 1,
        perPage: action.payload
      });
    })
  );

export const createCountryEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(submitCountryCreateForm)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const { reject, resolve } = action.meta;
        const guid = state.countries.new.operationGuid;
        if (!guid) {
          reject(new SubmissionError({ _error: 'Failed to create country, operation GUID missing' }));
          return [submitCountryCreateFormFailed(new EntityCreateError('operation GUID missing'))];
        }
        const submission = {
          guid,
          data: transformCountryFormDataToCountrySubmission(action.payload)
        };
        return from(curatorApi.createCountry(submission)).pipe(
          switchMap((response) => {
            if (response.status >= 300) {
              // TODO: Parse errors into friendlier format and field-specific where possible
              reject(new SubmissionError({ _error: `Failed to create country\n\n${JSON.stringify(response.data, null, 2)}` }));
              return [submitCountryCreateFormFailed(new EntityCreateError('server error'))];
            }
            resolve();
            return [
              submitCountryCreateFormCompleted(),
              pushRoute('/country-manager')
            ];
          }),
          catchError((err: Error) => {
            reject(new SubmissionError({ _error: `Failed to create country. (${err.message})` }));
            return of(submitCountryCreateFormFailed(new EntityCreateError('unknown error')));
          })
        );
      })
    );

export const updateCountryEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(submitCountryEditForm)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const { reject, resolve } = action.meta;
        const id = state.countries.edit.id;
        const guid = state.countries.edit.operationGuid;
        if (!id) {
          reject(new SubmissionError({ _error: 'Failed to update country, id missing' }));
          return [submitCountryEditFormFailed(new EntityUpdateError('country id missing'))];
        }
        if (!guid) {
          reject(new SubmissionError({ _error: 'Failed to update country, operation GUID missing' }));
          return [submitCountryEditFormFailed(new EntityUpdateError('operation guid missing'))];
        }
        const submission = {
          id,
          guid,
          data: transformCountryFormDataToCountrySubmission(action.payload)
        };
        return from(curatorApi.updateCountry(submission)).pipe(
          switchMap((response) => {
            if (response.status >= 300) {
              // TODO: Parse errors into friendlier format and field-specific where possible
              reject(new SubmissionError({ _error: `Failed to update country\n\n${JSON.stringify(response.data, null, 2)}` }));
              return [submitCountryEditFormFailed(new EntityUpdateError('server error'))];

            }
            resolve();
            return [
              submitCountryEditFormCompleted(),
              pushRoute('/country-manager')
            ];
          }),
          catchError((err: Error) => {
            reject(new SubmissionError({ _error: `Failed to update country. (${err.message})` }));
            return of(submitCountryEditFormFailed(new EntityUpdateError('unknown error')));
          })
        );
      })
    );

export const fetchCountryForEditEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(loadCountryForEdit.request)),
      switchMap((action) => from(curatorApi.getCountry(action.payload)).pipe(
        switchMap((response) => {
          return of(
            loadCountryForEdit.success(response.data),
            setFormDataFromCountry({ formId: 'country-edit', data: response.data })
          );
        }),
        catchError((err: Error) => {
          return from([
            loadCountryForEdit.failure(new EntityFetchError(err.message)),
            pushRoute('/country-manager')
          ]);
        })
      )));

export const setFormDataFromCountryEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$
    .pipe(
      filter(isActionOf(setFormDataFromCountry)),
      map((action) => {
        return initialize(action.payload.formId, transformCountryToFormData(action.payload.data));
      })
    );

export const fetchCountryForDeleteEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(loadCountryForDelete.request)),
      switchMap((action) =>
        forkJoin([
          from(curatorApi.getCountry(action.payload)),
          from(curatorApi.getCaseQueryEntityDeleteEligibility('country', action.payload))
        ]).pipe(
          switchMap(([fetchEntityResponse, deleteEligibilityResponse]) =>
            of(loadCountryForDelete.success({
              data: fetchEntityResponse.data,
              info: deleteEligibilityResponse.data
            }))),
          catchError((err) =>
            of(
              loadCountryForDelete.failure(err),
              pushRoute('/country-manager')
            ))
        ))
    );

export const deleteCountryEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(deleteCountry.request)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const guid = state.countries.delete.operationGuid;
        if (!guid) {
          return of(deleteCountry.failure(new DeleteError('operation guid missing')));
        }
        if (!state.countries.delete.info?.canDelete) {
          return of(deleteCountry.failure(new DeleteError('delete failed state check')));
        }
        const submission = {
          guid,
          id: action.payload
        };
        return from(curatorApi.deleteCountry(submission)).pipe(
          switchMap(() => of(deleteCountry.success(), pushRoute('/country-manager'))),
          catchError((err) => of(deleteCountry.failure(err)))
        );
      })
    );

export const countryEpics = [
  fetchCountriesEpic,
  fetchPaginatedCountriesEpic,
  refreshEpic,
  updateEpic,
  changePageEpic,
  changePageSizeEpic,
  createCountryEpic,
  updateCountryEpic,
  fetchCountryForEditEpic,
  setFormDataFromCountryEpic,
  fetchCountryForDeleteEpic,
  deleteCountryEpic
];
