import { createAsyncEntitySelector } from '../../../entity-selector';
import { namedEntityFormatter } from '../../../../redux/modules/case-manager/utils';
import { CuratorApi } from '../../../../services/CuratorApi';

function search(api: CuratorApi, filter: string) {
  return api.searchFirms(
    {
      filter: {
        name: filter
      },
      pagination: {
        page: 1,
        perPage: 20
      }
    }
  ).then((result) =>
    result.data.map((firm) => ({
      label: namedEntityFormatter(firm),
      value: firm.id
    })));
}

export const FirmField = createAsyncEntitySelector({
  search,
  pluralEntityName: 'firms',
  key: 'firm'
});
