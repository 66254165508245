import { Typography } from '@mui/material';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';

import { IconComponent } from '../../../components/ui/Icon';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCard';
import { RootAction } from '../../../redux/modules/actions';
import { toggleQuickAddFormVisibility } from '../../../redux/modules/case-manager/actions';
import { CaseManagerQuickAddForm } from '../../../redux/modules/case-manager/reducer';
import { createQuickAddSelector } from '../../../redux/modules/case-manager/selectors';
import {
  fetchDependentJudgeData,
  startNewJudgeOperation,
  submitJudgeCreateForm
} from '../../../redux/modules/judges/actions';
import { JudgeForm } from '../../../redux/modules/judges/types';
import { RootState } from '../../../redux/modules/root';
import { JudgeFormComponent } from '../components/JudgeFormComponent';
import { createJudgeFormDispatchers, judgeFormOptionsSelector, JudgeFormProps } from '../selectors';
import { HighlightOffIcon } from '../../../components/ui/mui-icons';
import { JudgeFormData } from '../../../types/cases/judge';

const selectJudgeQuickAddState = createQuickAddSelector(CaseManagerQuickAddForm.JUDGE);

const mapStateToProps = (state: RootState) => ({
  formProps: judgeFormOptionsSelector(state),
  quickAddOpen: selectJudgeQuickAddState(state)
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>, ownProps: OwnProps) => ({
  fetchDependentData: () => dispatch(fetchDependentJudgeData.request()),
  startNewOperation: () => dispatch(startNewJudgeOperation()),
  formDispatchers: createJudgeFormDispatchers(dispatch, submitJudgeCreateForm, JudgeForm.QUICK_ADD, true, ownProps.parentForm, ownProps.fieldName),
  toggleQuickAddOpen: () => dispatch(toggleQuickAddFormVisibility({ form: CaseManagerQuickAddForm.JUDGE }))
});

export type StateProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  fieldName: string,
  form: JudgeForm,
  parentForm: string
};

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type NewJudgeContainerProps = StateProps & DispatchProps & OwnProps;

const NewJudgeForm = reduxForm<JudgeFormData, JudgeFormProps>({
  onSubmit: (values: JudgeFormData, _dispatch: Dispatch, props: JudgeFormProps) => props.submitHandler(values)
})(JudgeFormComponent);

class NewJudgeContainerQuickAddComponent extends React.Component<NewJudgeContainerProps> {
  componentDidMount(): void {
    this.props.fetchDependentData();
    this.props.startNewOperation();
  }

  render() {
    if (this.props.quickAddOpen) {
      return (
        <Container>
          <MainContainerCardComponent>
            <IconContainer>
              <IconComponent
                icon={HighlightOffIcon}
                onClick={this.props.toggleQuickAddOpen}
              />
            </IconContainer>
            <Typography component="h4" variant="h5">Create Judge</Typography>
            <NewJudgeForm form={this.props.form} {...this.props.formProps} {...this.props.formDispatchers} />
          </MainContainerCardComponent>
        </Container>
      );
    }
    return <></>;
  }
}

const Container = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const IconContainer = styled.span`
  float: right;
`;

export const NewJudgeQuickAddContainer = connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(NewJudgeContainerQuickAddComponent);
