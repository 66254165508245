import { Box } from '@mui/material';
import * as React from 'react';
import { Field } from 'redux-form';

import { TextField } from './TextField';

type TranslatedTextFieldProps = {
  name: string;
  label: string;
  disabled?: boolean;
}

export const TranslatedTextField: React.FunctionComponent<TranslatedTextFieldProps> = ({ name, label, disabled = false }) => (
  <>
    <Box marginBottom={1} marginTop={2}>
      <Box marginBottom={1}>
        <label>{ label }</label>
      </Box>
      <Box display="flex">
        <Box flex="1" marginRight={2}>
          <Field
            marginTop={0}
            name={name}
            label="English"
            component={TextField}
            margin="none"
            disabled={disabled}
          />
        </Box>
        <Box flex="1">
          <Field
            name={`${name}_fr`}
            label="French"
            component={TextField}
            margin="none"
            disabled={disabled}
          />
        </Box>
      </Box>
    </Box>
  </>
)
