import { CaseTypeEntity } from '../types/cases/case-type';
import {
  CourtDeleteSubmission,
  CourtEntity,
  CourtSubmission,
  CourtUpdateSubmission
} from '../types/cases/court';
import {
  CaseSubjectDeleteSubmission,
  CaseSubjectEntity, CaseSubjectSubmission,
  CaseSubjectUpdateSubmission
} from '../types/cases/case-subject';
import { CaseTypeDeleteSubmission, CaseTypeSubmission, CaseTypeUpdateSubmission } from '../types/cases/case-type';
import {
  CountryDeleteSubmission,
  CountryEntity,
  CountrySubmission,
  CountryUpdateSubmission
} from '../types/cases/country';
import { StateDeleteSubmission, StateEntity, StateSubmission, StateUpdateSubmission } from '../types/cases/state';
import {
  CaseDeleteSubmission,
  CaseSubmission,
  CaseUpdateSubmission,
  ImportedCase,
  RawCaseEntity
} from '../types/cases/case';
import {
  RepresentativeDeleteSubmission,
  RepresentativeEntity, RepresentativeSubmission,
  RepresentativeUpdateSubmission
} from '../types/cases/representative';
import { JudgeDeleteSubmission, JudgeEntity, JudgeSubmission, JudgeUpdateSubmission } from '../types/cases/judge';
import { FirmDeleteSubmission, FirmEntity, FirmSubmission, FirmUpdateSubmission } from '../types/cases/firm';
import {
  DocumentCreateSubmission,
  DocumentDeleteSubmission,
  DocumentUpdateSubmission, RawDocumentEntity
} from '../types/documents/document';
import {
  DocumentAuthorCreateSubmission,
  DocumentAuthorDeleteSubmission, DocumentAuthorEntity,
  DocumentAuthorUpdateSubmission
} from '../types/documents/document-author';
import {
  DocumentTypeCreateSubmission,
  DocumentTypeDeleteSubmission, DocumentTypeEntity,
  DocumentTypeUpdateSubmission
} from '../types/documents/document-type';
import { CaseBodyEntity } from '../types/cases/case-body';
import {
  LegalDomainDeleteSubmission,
  LegalDomainEntity,
  LegalDomainSubmission,
  LegalDomainUpdateSubmission
} from '../types/cases/legal-domain';
import {
  IndustryGroupDeleteSubmission,
  IndustryGroupEntity,
  IndustryGroupSubmission, IndustryGroupUpdateSubmission
} from '../types/cases/industry-group';
import {
  SubjectAreaDeleteSubmission,
  SubjectAreaEntity,
  SubjectAreaSubmission,
  SubjectAreaUpdateSubmission
} from '../types/cases/subject-area';

import { CuratorApiAbstract } from './CuratorApiAbstract';

export type CaseSearchQuery = {
  citation?: string,
  title?: string,
  date_from?: string,
  date_to?: string,
  legalDomains?: string[],
  countryIds?: string[],
  courtIds?: string[],
  firmIds?: string[],
  representativeIds?: string[],
  judgeIds?: string[],
  caseSubjectIds?: string[],
  bodyAvailability?: string,
  flaggedAs?: string
};

export type DocumentSearchQuery = {
  typeIds?: string[],
  title?: string,
  authorIds?: string[],
  countryIds?: string[],
  citation?: string,
  indexNumber?: string,
  'dateReleased_from'?: string,
  'dateReleased_to'?: string,
  'datePublished_from'?: string,
  'datePublished_to'?: string,
  bodyAvailability?: string,
  flaggedAs?: string
}

export type RepresentativeSearchQuery = {
  country?: string,
  fullName?: string
};

export type FirmSearchQuery = {
  name?: string,
  country?: string
};

export type JudgeSearchQuery = {
  country?: string,
  fullName?: string
};

export type DocumentAuthorSearchQuery = {
  name?: string;
}

export class CuratorApi extends CuratorApiAbstract {
  public getCaseBodies = this.createFetchCaseQuery<CaseBodyEntity>('rest/case-body');

  public getCase = this.createFetchStandardEntity<RawCaseEntity>('case-query', 'rest/case');
  public getFullCase = this.createFetchStandardEntity<RawCaseEntity>('case-query', 'case');
  public searchCases = this.createPaginatedFetchCaseQuery<RawCaseEntity, CaseSearchQuery>('case-search');
  public createCase = this.createCaseManagerCommandRequest<RawCaseEntity, CaseSubmission>('create-case');
  public updateCase = this.createCaseManagerCommandRequest<RawCaseEntity, CaseUpdateSubmission>('update-case');
  public deleteCase = this.createCaseManagerCommandRequest<{}, CaseDeleteSubmission>('delete-case');

  public getDocument = this.createFetchStandardEntity<RawDocumentEntity>('document-query', 'rest/document');
  public getFullDocument = this.createFetchStandardEntity<RawDocumentEntity>('document-query', 'document');
  public searchDocuments = this.createPaginatedFetchDocumentQuery<RawDocumentEntity, DocumentSearchQuery>('document-search');
  public createDocument = this.createDocumentManagerCommandRequest<RawDocumentEntity, DocumentCreateSubmission>('create-document');
  public updateDocument = this.createDocumentManagerCommandRequest<RawDocumentEntity, DocumentUpdateSubmission>('update-document');
  public deleteDocument = this.createDocumentManagerCommandRequest<{}, DocumentDeleteSubmission>('delete-document');

  public getPaginatedDocumentAuthors = this.createPaginatedFetchDocumentQuery<DocumentAuthorEntity, {}>('rest/document-author');
  public searchDocumentAuthors = this.createPaginatedFetchDocumentQuery<DocumentAuthorEntity, DocumentAuthorSearchQuery>('document-author-search');
  public getDocumentAuthor = this.createFetchStandardEntity<DocumentAuthorEntity>('document-query', 'rest/document-author');
  public createDocumentAuthor = this.createDocumentManagerCommandRequest<DocumentAuthorEntity, DocumentAuthorCreateSubmission>('create-document-author');
  public updateDocumentAuthor = this.createDocumentManagerCommandRequest<DocumentAuthorEntity, DocumentAuthorUpdateSubmission>('update-document-author');
  public deleteDocumentAuthor = this.createDocumentManagerCommandRequest<{}, DocumentAuthorDeleteSubmission>('delete-document-author');

  public getAllDocumentTypes = this.createFetchStandardEntities<DocumentTypeEntity>('document-query', 'rest/document-type/all', 'GET').bind(this, {});
  public getPaginatedDocumentTypes = this.createPaginatedFetchDocumentQuery<DocumentTypeEntity, {}>('rest/document-type');
  public getDocumentType = this.createFetchStandardEntity<DocumentTypeEntity>('document-query', 'rest/document-type');
  public createDocumentType = this.createDocumentManagerCommandRequest<DocumentTypeEntity, DocumentTypeCreateSubmission>('create-document-type');
  public updateDocumentType = this.createDocumentManagerCommandRequest<DocumentTypeEntity, DocumentTypeUpdateSubmission>('update-document-type');
  public deleteDocumentType = this.createDocumentManagerCommandRequest<{}, DocumentTypeDeleteSubmission>('delete-document-type');

  public getFirm = this.createFetchStandardEntity<FirmEntity>('case-query', 'rest/firm');
  public searchFirms = this.createPaginatedFetchCaseQuery<FirmEntity, FirmSearchQuery>('firm-search');
  public createFirm = this.createCaseManagerCommandRequest<FirmEntity, FirmSubmission>('create-firm');
  public updateFirm = this.createCaseManagerCommandRequest<FirmEntity, FirmUpdateSubmission>('update-firm');
  public deleteFirm = this.createCaseManagerCommandRequest<{}, FirmDeleteSubmission>('delete-firm');

  public getRepresentative = this.createFetchStandardEntity<JudgeEntity>('case-query', 'rest/representative');
  public searchRepresentatives = this.createPaginatedFetchCaseQuery<RepresentativeEntity, RepresentativeSearchQuery>('representative-search');
  public createRepresentative = this.createCaseManagerCommandRequest<RepresentativeEntity, RepresentativeSubmission>('create-representative');
  public updateRepresentative = this.createCaseManagerCommandRequest<RepresentativeEntity, RepresentativeUpdateSubmission>('update-representative');
  public deleteRepresentative = this.createCaseManagerCommandRequest<{}, RepresentativeDeleteSubmission>('delete-representative');

  public getJudge = this.createFetchStandardEntity<JudgeEntity>('case-query', 'rest/judge');
  public searchJudges = this.createPaginatedFetchCaseQuery<JudgeEntity, JudgeSearchQuery>('judge-search');
  public createJudge = this.createCaseManagerCommandRequest<JudgeEntity, JudgeSubmission>('create-judge');
  public updateJudge = this.createCaseManagerCommandRequest<JudgeEntity, JudgeUpdateSubmission>('update-judge');
  public deleteJudge = this.createCaseManagerCommandRequest<{}, JudgeDeleteSubmission>('delete-judge');

  public getState = this.createFetchStandardEntity<StateEntity>('case-query', 'rest/state');
  public getAllStates = this.createFetchCaseQuery<StateEntity>('rest/state/all', 'GET');
  public searchStates = this.createPaginatedFetchCaseQuery<StateEntity>('rest/state');
  public createState = this.createCaseManagerCommandRequest<StateEntity, StateSubmission>('create-state');
  public updateState = this.createCaseManagerCommandRequest<StateEntity, StateUpdateSubmission>('update-state');
  public deleteState = this.createCaseManagerCommandRequest<{}, StateDeleteSubmission>('delete-state');

  public getCountry = this.createFetchStandardEntity<CountryEntity>('case-query', 'rest/country');
  public getAllCountries = this.createFetchCaseQuery<CountryEntity>('rest/country/all', 'GET');
  public searchCountries = this.createPaginatedFetchCaseQuery<CountryEntity>('rest/country');
  public createCountry = this.createCaseManagerCommandRequest<CountryEntity, CountrySubmission>('create-country');
  public updateCountry = this.createCaseManagerCommandRequest<CountryEntity, CountryUpdateSubmission>('update-country');
  public deleteCountry = this.createCaseManagerCommandRequest<{}, CountryDeleteSubmission>('delete-country');

  public getCaseSubject = this.createFetchStandardEntity<CaseSubjectEntity>('case-query', 'rest/case-subject');
  public getAllCaseSubjects = this.createFetchCaseQuery<CaseSubjectEntity>('rest/case-subject/all', 'GET');
  public searchCaseSubjects = this.createPaginatedFetchCaseQuery<CaseSubjectEntity>('rest/case-subject');
  public createCaseSubject = this.createCaseManagerCommandRequest<CaseSubjectEntity, CaseSubjectSubmission>('create-case-subject');
  public updateCaseSubject = this.createCaseManagerCommandRequest<CaseSubjectEntity, CaseSubjectUpdateSubmission>('update-case-subject');
  public deleteCaseSubject = this.createCaseManagerCommandRequest<{}, CaseSubjectDeleteSubmission>('delete-case-subject');

  public getCourt = this.createFetchStandardEntity<CourtEntity>('case-query', 'rest/court');
  public getAllCourts = this.createFetchCaseQuery<CourtEntity>('rest/court/all', 'GET');
  public searchCourts = this.createPaginatedFetchCaseQuery<CourtEntity>('rest/court');
  public createCourt = this.createCaseManagerCommandRequest<CourtEntity, CourtSubmission>('create-court');
  public updateCourt = this.createCaseManagerCommandRequest<CourtEntity, CourtUpdateSubmission>('update-court');
  public deleteCourt = this.createCaseManagerCommandRequest<{}, CourtDeleteSubmission>('delete-court');

  public getCaseType = this.createFetchStandardEntity<CaseTypeEntity>('case-query', 'rest/case-type');
  public getAllCaseTypes = this.createFetchCaseQuery<CourtEntity>('rest/case-type/all', 'GET');
  public searchCaseTypes = this.createPaginatedFetchCaseQuery<CaseTypeEntity>('rest/case-type');
  public createCaseType = this.createCaseManagerCommandRequest<CaseTypeEntity, CaseTypeSubmission>('create-case-type');
  public deleteCaseType = this.createCaseManagerCommandRequest<{}, CaseTypeDeleteSubmission>('delete-case-type')
  public updateCaseType = this.createCaseManagerCommandRequest<CaseTypeEntity, CaseTypeUpdateSubmission>('update-case-type');

  public getAllIndustryGroups = this.createFetchCaseQuery<IndustryGroupEntity>('rest/industry-group/all', 'GET');
  public getIndustryGroup = this.createFetchStandardEntity<IndustryGroupEntity>('case-query', 'rest/industry-group');
  public searchIndustryGroups = this.createPaginatedFetchCaseQuery<IndustryGroupEntity>('rest/industry-group');
  public createIndustryGroup = this.createCaseManagerCommandRequest<IndustryGroupEntity, IndustryGroupSubmission>('create-industry-group');
  public deleteIndustryGroup = this.createCaseManagerCommandRequest<{}, IndustryGroupDeleteSubmission>('delete-industry-group')
  public updateIndustryGroup = this.createCaseManagerCommandRequest<IndustryGroupEntity, IndustryGroupUpdateSubmission>('update-industry-group');

  public getAllSubjectAreas = this.createFetchCaseQuery<SubjectAreaEntity>('rest/subject-area/all', 'GET');
  public getSubjectArea = this.createFetchStandardEntity<SubjectAreaEntity>('case-query', 'rest/subject-area');
  public searchSubjectAreas = this.createPaginatedFetchCaseQuery<SubjectAreaEntity>('rest/subject-area');
  public createSubjectArea = this.createCaseManagerCommandRequest<SubjectAreaEntity, SubjectAreaSubmission>('create-subject-area');
  public deleteSubjectArea = this.createCaseManagerCommandRequest<{}, SubjectAreaDeleteSubmission>('delete-subject-area')
  public updateSubjectArea = this.createCaseManagerCommandRequest<SubjectAreaEntity, SubjectAreaUpdateSubmission>('update-subject-area');

  public getAllLegalDomains = this.createFetchCaseQuery<LegalDomainEntity>('rest/legal-domain/all', 'GET');
  public getLegalDomain = this.createFetchStandardEntity<LegalDomainEntity>('case-query', 'rest/legal-domain');
  public searchLegalDomains = this.createPaginatedFetchCaseQuery<LegalDomainEntity>('rest/legal-domain');
  public createLegalDomain = this.createCaseManagerCommandRequest<LegalDomainEntity, LegalDomainSubmission>('create-legal-domain');
  public deleteLegalDomain = this.createCaseManagerCommandRequest<{}, LegalDomainDeleteSubmission>('delete-legal-domain')
  public updateLegalDomain = this.createCaseManagerCommandRequest<LegalDomainEntity, LegalDomainUpdateSubmission>('update-legal-domain');

  public importFromUrl = this.createCaseImportCommandRequest<ImportedCase, { url: string }>('import-case');

  public getCaseQueryEntityDeleteEligibility = this.createGetEntityDeleteEligibility('case-query');
  public getDocumentQueryEntityDeleteEligibility = this.createGetEntityDeleteEligibility('document-query');
}
