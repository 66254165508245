import { Checkbox as MuiCheckbox, FormControl, FormControlLabel } from '@mui/material';
import * as React from 'react';

import { ICheckboxProps } from './types';

export const Checkbox: React.FC<ICheckboxProps> = (
  {
    input,
    label,
    disabled = false,
    margin = 'normal',
    fullWidth = true
  }
) => (
  <FormControl margin={margin} fullWidth={fullWidth}>
    <FormControlLabel
      control={
        <MuiCheckbox
          name={input.name}
          disabled={disabled}
          value={input.value}
          checked={input.value}
          onChange={input.onChange}
        />
      }
      label={label ?? ''}
    />
  </FormControl>
);
