import { Column, CustomPaging, PagingState } from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { Button, Box } from '@mui/material';
import * as React from 'react';
import styled from 'styled-components';

import { PAGE_SIZES } from '../../constants';
import { ErrorBoundary } from '../ErrorBoundary';

import { ColumnDataWithId, EntitySelectedCallback, EntityTableComponentProps } from './types';

const TableRow = styled.tr<Table.DataRowProps>`
  &:hover {
    background-color: #f9f9f9;
  }
`;

type ActionButtonProps = {
  id: string,
  label: string,
  onClick: EntitySelectedCallback,
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
};

const ActionButton = (props: ActionButtonProps) => (
  <Box mr={2} ml={1} mb={1} display="inline-block">
    <Button onClick={() => props.onClick(props.id)} variant="contained" color={props.color}>{props.label}</Button>
  </Box>
);

function addActionColumn<TColumnData extends ColumnDataWithId>(data: TColumnData, onEdit?: EntitySelectedCallback, onDelete?: EntitySelectedCallback) {
  return {
    ...data,
    action: (
      <>
        {onEdit && <ActionButton id={data.id} onClick={onEdit} label="Edit" color="primary" />}
        {onDelete && <ActionButton id={data.id} onClick={onDelete} label="Delete" />}
      </>
    )
  };
}

export class EntityTable<TColumnData extends ColumnDataWithId> extends React.Component<EntityTableComponentProps<TColumnData>> {
  render() {
    const data = Array.isArray(this.props.data) ? this.props.data.map((item) => addActionColumn<TColumnData>(item, this.props.onEntityEdit, this.props.onEntityDelete)) : [];
    const columns: Column[] = [
      ...this.props.columns,
      {
        name: 'action',
        title: 'Actions'
      }
    ];

    return (
      <ErrorBoundary>
        <Grid rows={data} columns={columns}>
          <PagingState
            currentPage={this.props.pagination.page - 1}
            pageSize={this.props.pagination.perPage}
            onCurrentPageChange={this.props.onChangePage}
            onPageSizeChange={this.props.onChangePageSize} />
          <CustomPaging totalCount={this.props.pagination.total} />
          <Table rowComponent={TableRow} />
          <TableHeaderRow />
          <PagingPanel pageSizes={PAGE_SIZES} />
        </Grid>
      </ErrorBoundary>
    );
  }
}
