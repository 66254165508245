import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import { PAGINATION_DEFAULT } from '../../../constants';
import { PaginationMeta } from '../../util/fetch';
import { RootAction } from '../actions';
import { fetchDependentCaseManagerData } from '../case-manager/actions';
import { CaseManagerDependentDataState } from '../case-manager/reducer';
import { ManagerDeleteState } from '../types';
import { CaseSubjectData, CaseSubjectEntity } from '../../../types/cases/case-subject';

import {
  caseSubjectsFetchActions,
  caseSubjectsPaginatedFetchActions,
  loadCaseSubjectForDelete,
  loadCaseSubjectForEdit,
  startNewCaseSubjectOperation
} from './actions';

export type CaseSubjectManagerEntitiesState = {
  entities: CaseSubjectEntity[];
  paginated: {
    entities: CaseSubjectEntity[];
    pagination: PaginationMeta;
  }
};

export type CaseSubjectManagerDependentDataState = {
  loading: boolean,
};

export const caseSubjectsManagerEntitiesInitialState: CaseSubjectManagerEntitiesState = {
  entities: [],
  paginated: {
    entities: [],
    pagination: PAGINATION_DEFAULT
  }
};

export const caseSubjectsManagerEntitiesReducer = (state: CaseSubjectManagerEntitiesState = caseSubjectsManagerEntitiesInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(caseSubjectsFetchActions.request): {
      return {
        ...state,
        entities: []
      };
    }
    case getType(caseSubjectsFetchActions.success): {
      return {
        ...state,
        entities: action.payload.data
      };
    }
    case getType(caseSubjectsFetchActions.failure): {
      return {
        ...state,
        entities: []
      };
    }
    case getType(caseSubjectsPaginatedFetchActions.success): {
      return {
        ...state,
        paginated: {
          entities: action.payload.data,
          pagination: action.payload.pagination
        }
      };
    }

    case getType(caseSubjectsPaginatedFetchActions.request): {
      return {
        ...state
      };
    }

    case getType(caseSubjectsPaginatedFetchActions.failure): {
      return {
        ...state,
        paginated: {
          entities: [],
          pagination: PAGINATION_DEFAULT
        }
      };
    }

    default: {
      return state;
    }
  }
};

export type CaseSubjectManagerNewState = {
  operationGuid: string | null,
};

export const caseSubjectManagerNewInitialState = {
  operationGuid: null
};

export const caseSubjectManagerNewReducer = (state: CaseSubjectManagerNewState = caseSubjectManagerNewInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(startNewCaseSubjectOperation): {
      return {
        ...state,
        operationGuid: uuidv4()
      };
    }

    default: {
      return state;
    }
  }
};

export type CaseSubjectManagerEditState = {
  id: string | null,
  operationGuid: string | null,
  data: CaseSubjectData | null,
};

const caseSubjectManagerEditInitialState = {
  id: null,
  operationGuid: null,
  data: null
};

export const caseSubjectManagerEditReducer = (state: CaseSubjectManagerEditState = caseSubjectManagerEditInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(loadCaseSubjectForEdit.request): {
      return {
        ...state,
        id: action.payload,
        operationGuid: null
      };
    }
    case getType(loadCaseSubjectForEdit.success): {
      return {
        ...state,
        data: action.payload,
        operationGuid: uuidv4()
      };
    }
    case getType(loadCaseSubjectForEdit.failure): {
      return {
        ...state,
        data: null
      };
    }
    default: {
      return state;
    }
  }
};

const caseSubjectManagerDeleteInitialState: ManagerDeleteState<CaseSubjectEntity> = {
  loading: false
};

export const caseSubjectManagerDeleteReducer = (state: ManagerDeleteState<CaseSubjectEntity> = caseSubjectManagerDeleteInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(loadCaseSubjectForDelete.request): {
      return {
        loading: true
      };
    }
    case getType(loadCaseSubjectForDelete.success): {
      return {
        ...state,
        loading: false,
        operationGuid: uuidv4(),
        ...action.payload
      };
    }
    case getType(loadCaseSubjectForDelete.failure): {
      return {
        ...state,
        loading: false,
        info: {
          canDelete: false,
          usage: 0
        }
      };
    }
    default: {
      return state;
    }
  }
};

export const caseSubjectManagerDependentDataInitialState = {
  loading: false
};

export const caseSubjectManagerDependentDataReducer = (state: CaseManagerDependentDataState = caseSubjectManagerDependentDataInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(fetchDependentCaseManagerData.request): {
      return {
        ...state,
        loading: true
      };
    }
    case getType(fetchDependentCaseManagerData.success): {
      return {
        ...state,
        loading: false
      };
    }
    case getType(fetchDependentCaseManagerData.failure): {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export const caseSubjectsManagerInitialState = {
  entities: caseSubjectsManagerEntitiesInitialState,
  dependentData: caseSubjectManagerDependentDataInitialState,
  edit: caseSubjectManagerEditInitialState,
  new: caseSubjectManagerNewInitialState,
  delete: caseSubjectManagerDeleteInitialState
};

export type CaseSubjectsManagerState = {
  entities: CaseSubjectManagerEntitiesState,
  dependentData: CaseSubjectManagerDependentDataState,
  edit: CaseSubjectManagerEditState,
  new: CaseSubjectManagerNewState,
  delete: ManagerDeleteState<CaseSubjectEntity>
};

export const caseSubjectsManagerReducer = combineReducers<CaseSubjectsManagerState>({
  entities: caseSubjectsManagerEntitiesReducer,
  dependentData: caseSubjectManagerDependentDataReducer,
  edit: caseSubjectManagerEditReducer,
  new: caseSubjectManagerNewReducer,
  delete: caseSubjectManagerDeleteReducer
});
