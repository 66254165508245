import { DocumentFilterFormData } from '../../../modules/document-manager/components/DocumentFilterComponent';
import { DocumentSearchQuery } from '../../../services/CuratorApi';
import {
  entitiesToSelectOptions,
  entityToSelectOption,
  namedEntityFormatter,
  nonEmptyStringOrNull
} from '../case-manager/utils';
import {
  transformFormFlaggedAsToEntityFlaggedAs,
  transformSelectOptionsToValuesArray,
  transformSelectOptionToValue
} from '../../util/forms';
import { trimOrEmptyString, trimOrNull } from '../../util/string-util';
import { StateEntity } from '../../../types/cases/state';
import {
  CreateDocumentData,
  DocumentEntity,
  DocumentFormData,
  RawDocumentEntity
} from '../../../types/documents/document';
import { DocumentAuthorEntity } from '../../../types/documents/document-author';
import { DocumentTypeEntity } from '../../../types/documents/document-type';
import { SubjectAreaEntity } from '../../../types/cases/subject-area';
import { IndustryGroupEntity } from '../../../types/cases/industry-group';
import { flaggedAsToFormData } from '../../../components/FlagComponent/utils';

export function transformDocumentFilterFormDataToQuery(formData: Partial<DocumentFilterFormData>): DocumentSearchQuery {
  return {
    title: formData.title || '',
    citation: formData.citation || '',
    countryIds: formData.country ? [formData.country.value] : undefined,
    typeIds: formData.type ? [formData.type.value] : undefined,
    authorIds: formData.author ? [formData.author.value] : undefined,
    indexNumber: formData.indexNumber || '',
    dateReleased_from: formData.dateReleased_from || undefined,
    dateReleased_to: formData.dateReleased_to || undefined,
    datePublished_from: formData.datePublished_from || undefined,
    datePublished_to: formData.datePublished_to || undefined,
    bodyAvailability: formData.bodyAvailability?.value,
    flaggedAs: formData.flaggedAs?.value
  }
}

export function transformRawDocumentEntityToDocumentEntity(rawDoc: RawDocumentEntity): DocumentEntity {
  return rawDoc; // they are the same for now
}

export function transformDocumentEntityToDocumentForm(doc: DocumentEntity): DocumentFormData {
  return {
    type: entityToSelectOption<DocumentTypeEntity>(namedEntityFormatter, doc.type),
    title: doc.title,
    title_fr: doc.title_fr,
    state: entityToSelectOption<StateEntity>(namedEntityFormatter, doc.state),
    citations: doc.citations,
    indexNumbers: doc.indexNumbers,
    dateReleased: doc.dateReleased,
    datePublished: doc.datePublished,
    urlPrimary: doc.urlPrimary,
    urlSecondary: doc.urlSecondary,
    authors: entitiesToSelectOptions<DocumentAuthorEntity>(namedEntityFormatter, doc.authors),
    descriptionBody: doc?.description?.body,
    descriptionBody_fr: doc?.description?.body_fr,
    documentBody: doc?.body?.body,
    documentBody_fr: doc?.body?.body_fr,
    meta_subjectAreas: entitiesToSelectOptions<SubjectAreaEntity>(namedEntityFormatter, doc.documentMetaData?.subjectAreas),
    meta_industryGroups: entitiesToSelectOptions<IndustryGroupEntity>(namedEntityFormatter, doc.documentMetaData?.industryGroups),
    meta_headlineBody: doc.documentMetaData?.headline?.body,
    meta_headlineBody_fr: doc.documentMetaData?.headline?.body_fr,
    meta_crossReferenceBody: doc.documentMetaData?.crossReference?.body,
    meta_crossReferenceBody_fr: doc.documentMetaData?.crossReference?.body_fr,
    meta_bodyUnavailable: doc.documentMetaData?.body_availability === 'restricted',
    meta_codeSection: doc.documentMetaData?.code_section,
    meta_sourceId: doc.documentMetaData?.source_id || '',
    meta_sourceType: doc.documentMetaData?.source_type || '',
    meta_flaggedAs: flaggedAsToFormData(doc.documentMetaData?.flaggedAs)
  };
}

export function transformDocumentFormDataToDocumentSubmission(formData: DocumentFormData): CreateDocumentData {
  return {
    documentData: {
      title: trimOrEmptyString(formData.title),
      title_fr: trimOrEmptyString(formData.title_fr),
      authors: transformSelectOptionsToValuesArray(formData.authors),
      citations: (formData.citations || []).map((x) => x.trim()),
      indexNumbers: (formData.indexNumbers || []).map((x) => x.trim()),
      datePublished: trimOrNull(formData.datePublished),
      dateReleased: trimOrNull(formData.dateReleased),
      state: transformSelectOptionToValue(formData.state),
      type: transformSelectOptionToValue(formData.type),
      urlPrimary: trimOrNull(formData.urlPrimary),
      urlSecondary: trimOrNull(formData.urlSecondary)
    },
    documentBody: {
      body: formData.documentBody || '',
      body_fr: formData.documentBody_fr || ''
    },
    documentDescription: {
      body: formData.descriptionBody || '',
      body_fr: formData.descriptionBody_fr || ''
    },
    documentMetaData: {
      body_availability: formData.meta_bodyUnavailable ? 'restricted' : 'available',
      source_type: nonEmptyStringOrNull(formData.meta_sourceType),
      source_id: nonEmptyStringOrNull(formData.meta_sourceId),
      code_section: (formData.meta_codeSection || []).map(x => x.trim()),
      subjectAreas: transformSelectOptionsToValuesArray(formData.meta_subjectAreas),
      industryGroups: transformSelectOptionsToValuesArray(formData.meta_industryGroups),
      headline: {
        body: formData.meta_headlineBody || '',
        body_fr: formData.meta_headlineBody_fr || ''
      },
      crossReference: {
        body: formData.meta_crossReferenceBody || '',
        body_fr: formData.meta_crossReferenceBody_fr || ''
      },
      flaggedAs: transformFormFlaggedAsToEntityFlaggedAs(formData.meta_flaggedAs)
    }
  }
}
