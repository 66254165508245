import { Box, Button } from '@mui/material';
import * as React from 'react';
import { Field, Form, InjectedFormProps } from 'redux-form';

import { TextField } from '../../../components/form/TextField';
import { ProgressDimmer } from '../../../components/ui/Dimmer';
import { ButtonRow, Column, FullRowColumn, Row } from '../../../components/ui/FlexTable';
import { ImportFromUrlFormProps } from '../selectors';
import { ImportFromUrlDataForm } from '../../../types/cases/case';

type FormProps = ImportFromUrlFormProps;

type ReduxFormInjectedProps = InjectedFormProps<ImportFromUrlDataForm, FormProps>;

export const CaseImportFromUrlComponent: React.FC<ReduxFormInjectedProps & FormProps> = (
  {
    handleSubmit,
    error,
    isPending,
    importFromUrlSubmitHandler
  }
) => {
  return (
    <Box position="relative">
      <Form onSubmit={handleSubmit(importFromUrlSubmitHandler)}>
        <Row>
          <Column>
            <Field
              disabled={isPending}
              autoFocus={true}
              name="importUrl"
              label="Import from URL"
              component={TextField}
            />
          </Column>
        </Row>
        <ButtonRow>
          <Column>
            <Button disabled={isPending} type="submit" variant={'contained'} color="primary">Submit</Button>
          </Column>
        </ButtonRow>
        {
          error &&
          (
            <Row>
              <FullRowColumn>
                <pre>{error}</pre>
              </FullRowColumn>
            </Row>
          )
        }
      </Form>
      {isPending && <ProgressDimmer />}
    </Box>
  );
};
