import { createAsyncAction, createAction } from 'typesafe-actions';

import { ICuratorError } from '../../../lib/errors/types';
import { LoadEntityForDeleteResponse } from '../../../types/command-types';
import { FormSubmissionMeta } from '../../../types/forms';
import {
  PageNumberType,
  PageSizeType,
  PaginatedQuery,
  PaginationConfig,
  WithFetchMeta,
  WithPaginatedFetchMeta
} from '../../util/fetch';
import { CountryData, CountryEntity, CountryFormData } from '../../../types/cases/country';

export const countriesPaginatedFetchActions = createAsyncAction(
  '@@CURATOR/COUNTRIES/PAGINATED/REQUEST',
  '@@CURATOR/COUNTRIES/PAGINATED/SUCCESS',
  '@@CURATOR/COUNTRIES/PAGINATED/FAILURE'
)<PaginatedQuery<CountryEntity>, WithPaginatedFetchMeta<CountryEntity>, ICuratorError>();

export const countriesFetchActions = createAsyncAction(
  '@@CURATOR/COUNTRIES/REQUEST',
  '@@CURATOR/COUNTRIES/SUCCESS',
  '@@CURATOR/COUNTRIES/FAILURE'
)<void, WithFetchMeta<CountryEntity[]>, ICuratorError>();

export const refreshCountriesList = createAction('@@CURATOR/COUNTRIES_MANAGER/REFRESH_LIST')<void>();

export const updateCountriesList = createAction('@@CURATOR/COUNTRIES_MANAGER/UPDATE_LIST')<PaginationConfig>();

export const changeCountriesListPage = createAction('@@CURATOR/COUNTRIES_MANAGER/CHANGE_PAGE')<PageNumberType>();

export const changeCountriesListPageSize = createAction('@@CURATOR/COUNTRIES_MANAGER/CHANGE_PAGE_SIZE')<PageSizeType>();

export const startNewCountryOperation = createAction('@@CURATOR/COUNTRIES_MANAGER/START_NEW_COUNTRY_OPERATION')<void>();

export const submitCountryCreateForm = createAction('@@CURATOR/COUNTRIES_MANAGER/SUBMIT_NEW_STATE')<CountryFormData, FormSubmissionMeta>();

export const submitCountryCreateFormCompleted = createAction('@@CURATOR/COUNTRIES_MANAGER/SUBMIT_NEW_STATE_COMPLETED')<void>();

export const submitCountryCreateFormFailed = createAction('@@CURATOR/COUNTRIES_MANAGER/SUBMIT_NEW_STATE_FAILED')<ICuratorError>();

export const submitCountryEditForm = createAction('@@CURATOR/COUNTRIES_MANAGER/SUBMIT_EDIT_STATE')<CountryFormData, FormSubmissionMeta>();

export const submitCountryEditFormCompleted = createAction('@@CURATOR/COUNTRIES_MANAGER/SUBMIT_EDIT_STATE_COMPLETED')<void>();

export const submitCountryEditFormFailed = createAction('@@CURATOR/COUNTRIES_MANAGER/SUBMIT_EDIT_STATE_FAILURE')<ICuratorError>();

export const loadCountryForEdit = createAsyncAction(
  '@@CURATOR/COUNTRIES_MANAGER/LOAD_STATE_FOR_EDIT',
  '@@CURATOR/COUNTRIES_MANAGER/LOAD_STATE_FOR_EDIT_SUCCESS',
  '@@CURATOR/COUNTRIES_MANAGER/LOAD_STATE_FOR_EDIT_FAILURE'
)<string, CountryData, ICuratorError>();

export const loadCountryForDelete = createAsyncAction(
  '@@CURATOR/COUNTRIES_MANAGER/LOAD_COUNTRY_FOR_DELETE',
  '@@CURATOR/COUNTRIES_MANAGER/LOAD_COUNTRY_FOR_DELETE_SUCCESS',
  '@@CURATOR/COUNTRIES_MANAGER/LOAD_COUNTRY_FOR_DELETE_FAILURE'
)<string, LoadEntityForDeleteResponse<CountryEntity>, ICuratorError>();

export const deleteCountry = createAsyncAction(
  '@@CURATOR/CASE_MANAGER/DELETE_COUNTRY',
  '@@CURATOR/CASE_MANAGER/DELETE_COUNTRY_SUCCESS',
  '@@CURATOR/CASE_MANAGER/DELETE_COUNTRY_FAILURE'
)<CountryEntity['id'], void, ICuratorError>();

export type SetCountryPayload = {
  formId: string,
  data: CountryData
};

export const setFormDataFromCountry = createAction('@@CURATOR/COUNTRIES_MANAGER/SET_FORM_DATA_FROM_STATE')<SetCountryPayload>();
