import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { removeEmptyValues } from '../forms/shared';

import { useCuratorApiContext } from './useCuratorApiContext';
import { useEntityApiContext } from './useEntityApiContext';
import { useManagerContext } from './useManagerContext';
import { useListCacheContext } from './useListCacheContext';

const DEFAULT_FIRST_PAGE = 1;
const DEFAULT_PER_PAGE = 25;
const DEFAULT_EMPTY_FILTERS = {};

export function useEntitiesFetch() {
  const [ready, setReady] = React.useState(false);
  const [page, setPageState] = React.useState(DEFAULT_FIRST_PAGE);
  const [perPage, setPerPageState] = React.useState(DEFAULT_PER_PAGE);
  const [filters, setFiltersState] = React.useState(DEFAULT_EMPTY_FILTERS);
  const [total, setTotal] = React.useState(0);

  const listCache = useListCacheContext();
  const { dataKey } = useManagerContext();
  const { curatorApi } = useCuratorApiContext();
  const api = useEntityApiContext();

  const setPage = React.useCallback((newPage: number) => {
    setPageState(newPage);
  }, []);

  const setPerPage = React.useCallback((newPerPage: number) => {
    setPage(1);
    setPerPageState(newPerPage);
  }, []);

  const setFilters = React.useCallback((newFilters: any) => {
    setFiltersState(newFilters);
  }, []);

  // TODO: Do we need filters in the key? Is there a react-query way to handle fetching payloads better?
  const cacheKey = ['list', dataKey, perPage, page, JSON.stringify(filters)];

  const useQueryResult = useQuery(cacheKey, async () => {
    const cleanedFilters = removeEmptyValues(filters);
    return api.all(curatorApi, cleanedFilters, page, perPage).then((result) => {
      // TODO: Refactor to use `onSuccess`, need to
      setPageState(result.pagination.page);
      setPerPageState(result.pagination.perPage);
      setTotal(result.pagination.total);
      return result.data;
    });
  }, {
    enabled: ready,
    keepPreviousData: true
  });

  // TODO: Maybe pass page/perPage/filters data as parameters to the hook and sync this from the component side
  //   instead of using list cache here
  React.useEffect(() => {
    if (listCache) {
      setPageState(listCache.state.page);
      setPerPageState(listCache.state.perPage);
      setFiltersState(listCache.state.filters);
    }
    // We want to avoid running the query until we've had a chance to grab any cached data
    setReady(true);
  }, []);

  React.useEffect(() => {
    if (listCache) {
      listCache.setPage(page);
      listCache.setPerPage(perPage);
      listCache.setFilters(filters);
    }
  }, [listCache, page, perPage, filters]);

  return {
    ...useQueryResult,
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    setFilters
  }
}
