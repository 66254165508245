import * as React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';

import { RichTextAreaComponent } from './RichTextAreaComponent';

type TranslatedRichTextAreaProps = {
  name: string;
  label: string;
  disabled?: boolean;
  height?: number | string;
  hidden?: boolean;
}

export const TranslatedRichTextArea: React.FunctionComponent<TranslatedRichTextAreaProps> = ({ height, name, label, disabled = false, hidden = false }) => (
  <HideDiv hidden={hidden}>
    <Field
      name={name}
      label={`${label} - English`}
      component={RichTextAreaComponent}
      disabled={disabled}
      height={height}
    />
    <Field
      name={`${name}_fr`}
      label={`${label} - French`}
      component={RichTextAreaComponent}
      disabled={disabled}
      height={height}
    />
  </HideDiv>
)

export const HideDiv = styled.div<{ hidden?: boolean }>`
  display: ${(props) => props.hidden ? 'none' : 'initial' };
`;
