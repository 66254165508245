import styled from 'styled-components';

export const List = styled.ul`
  list-style-type: none;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
`;

export const ListItem = styled.li``;
