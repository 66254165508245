import { Epic } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { Services } from '../../../services';
import { RootAction } from '../actions';
import { RootState } from '../root';

import { caseBodyFetchActions } from './actions';

export const fetchCaseBodyEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(caseBodyFetchActions.request)),
    mergeMap((action) =>
      from(curatorApi.getCaseBodies(action.payload)).pipe(
        map(caseBodyFetchActions.success),
        catchError((err) => of(caseBodyFetchActions.failure(err)))
      )
    )
  );
