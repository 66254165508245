import { Route, Routes } from 'react-router';
import React from 'react';

import { createListCacheContextValue, ListCacheContextProvider } from '../contexts/list-cache-context';

import { EntityManagerLandingContainer } from './landing';
import { EntityManagerCreateContainer } from './create';
import { EntityManagerEditRoutingComponent } from './edit';
import { EntityManagerDeleteRoutingComponent } from './delete';

export function EntityManagerRoutes() {
  // We put the list cache provider at this level so that it's value is retained while navigating within the module
  const listCacheContextValue = createListCacheContextValue();
  return (
    <ListCacheContextProvider value={listCacheContextValue}>
      <Routes>
        <Route path="/" element={<EntityManagerLandingContainer />} />
        <Route path="/add" element={<EntityManagerCreateContainer />} />
        <Route path="/:id" element={<EntityManagerEditRoutingComponent />} />
        <Route path="/:id/delete" element={<EntityManagerDeleteRoutingComponent />} />
      </Routes>
    </ListCacheContextProvider>
  )
}
