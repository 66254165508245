import React from 'react';
import { DefaultValues } from 'react-hook-form';

import { EntityFilterFieldConfigs, EntityFormFieldConfigs } from '../forms/shared';

interface FormContextValue {
  filterConfigs: EntityFilterFieldConfigs;
  defaultFilterValues: DefaultValues<any>;
  fieldConfigs: EntityFormFieldConfigs;
  defaultValues: DefaultValues<any>;
}

export const FormContext = React.createContext<FormContextValue | null>(null);

export const FormContextProvider = FormContext.Provider;

