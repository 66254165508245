import React from 'react';
import * as R from 'ramda';

export type FieldId = string;

export interface CommonFieldProps {
  name: string;
  label: string;
}

export enum FieldTypes {
  Text = 'text',
  Judge = 'judge',
  Country = 'country',
  State = 'state'
}

export type EntitySelectorFieldConfig = {
  type: FieldTypes.Judge | FieldTypes.Country | FieldTypes.State;
  isMulti: boolean;
}

export type TextFieldConfig = {
  type: FieldTypes.Text;
}

export type FieldConfig = (EntitySelectorFieldConfig | TextFieldConfig) & {
  label: string;
}

export interface FormLayoutComponentProps {
  fields: Record<FieldId, React.ReactElement>;
}

export type EntityFormFieldConfigs<FieldIds extends string = string> = Record<FieldIds, FieldConfig>;

export type EntityFilterFieldConfigs<FieldIds extends string = string> = Record<FieldIds, FieldConfig | undefined>;

export const removeEmptyValues = R.reject(R.anyPass([R.isEmpty, R.isNil]));
