import { FieldArray, WrappedFieldArrayProps } from 'redux-form';
import * as React from 'react';
import { Box } from '@mui/material';

import { IconButtonComponent } from '../ui/IconButton';
import { AddIcon } from '../ui/mui-icons';

import { SingleFlagEntry } from './SingleFlagEntry';

const FlaggedAsListComponent:React.FC<WrappedFieldArrayProps> = ({ fields }) => {
  return (
    <>
      { fields.length === 0 &&
        (<Box style={{ textAlign: 'center' }}>
          <h3>No flags</h3>
        </Box>
        )
      }
      {
        fields.map((member: string, index: number) => (
          <SingleFlagEntry key={member} index={index} member={member} fields={fields} />
        ))
      }
      <IconButtonComponent onClick={() => fields.push({})} icon={AddIcon}>Add New Flag</IconButtonComponent>
    </>
  );
};

export const FlaggedAsListField = (props: { name: string; }) => {
  return (
      <FieldArray name={props.name} component={FlaggedAsListComponent} />
  );
};
