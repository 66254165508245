import * as R from 'ramda';
import { createSelector } from 'reselect';

import { CourtTableData } from '../../../modules/court-manager/containers/CourtListContainer';
import { SelectOption } from '../../../types/util';
import { EntityLabelFormatter } from '../case-manager/types';
import { RootState } from '../root';
import { CourtEntity } from '../../../types/cases/court';

import { CourtManagerEntitiesState, CourtsManagerState } from './reducer';

export const selectCourtsManagerState = (state: RootState): CourtsManagerState => state.courts;

export const selectCourtsManagerEntitiesState = createSelector(
  [selectCourtsManagerState],
  (courtsManager: CourtsManagerState) => courtsManager.entities);

export const selectCourtsManagerEntities = createSelector(
  [selectCourtsManagerEntitiesState],
  (courtsManagerEntityState: CourtManagerEntitiesState) => courtsManagerEntityState.entities
);

export const selectCourtsManagerPaginatedEntitiesState = createSelector(
  [selectCourtsManagerState],
  (courtsManager) => courtsManager.entities.paginated
);

export const selectCourtsManagerPaginatedEntities = createSelector(
  [selectCourtsManagerPaginatedEntitiesState],
  (courtsPaginatedState) => courtsPaginatedState.entities
);

export function formatCourtLabel(court: CourtEntity): string {
  return `${court.name} (${court.state.name})`;
}

function formatCourtsToOptions(formatter: EntityLabelFormatter<CourtEntity>, courts: CourtEntity[]): SelectOption[] {
  return courts.map((court) => ({
    value: court.id.toString(),
    label: formatter(court)
  }));
}

export const createCourtOptionsSelector = (formatter: EntityLabelFormatter<CourtEntity>) => createSelector(
  [selectCourtsManagerEntities],
  formatCourtsToOptions.bind(null, formatter)
);

export const courtOptionsSelector = createCourtOptionsSelector(formatCourtLabel);

export const selectCourtsForTable = createSelector(
  [selectCourtsManagerPaginatedEntities],
  (courts: CourtEntity[]): CourtTableData[] => courts.map(
    R.applySpec<CourtTableData>({
      id: R.prop('id'),
      name: R.prop('name'),
      state: R.pathOr('', ['state', 'name'])
    })
  )
);

export const selectCourtsPagination = createSelector(
  [selectCourtsManagerPaginatedEntitiesState],
  (courtsPaginationState) => courtsPaginationState.pagination
);

export const selectCourtsManagerDeleteState = createSelector(
  [selectCourtsManagerState],
  (courtsManager) => courtsManager.delete
);

export const selectDeleteCourtData = createSelector(
  [selectCourtsManagerDeleteState],
  (deleteState) => deleteState.data
);

export const selectIsLoadingCourtForDelete = createSelector(
  [selectCourtsManagerDeleteState],
  (deleteState) => deleteState.loading
);

export const selectCourtDeleteEligibility = createSelector(
  [selectCourtsManagerDeleteState],
  (deleteState) => deleteState.info
);
