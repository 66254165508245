import styled from 'styled-components';

type ButtonRowProps = {
  noTopPadding?: boolean,
  noBottomPadding?: boolean,
};

export const ButtonRow = styled.div<ButtonRowProps>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-top: ${(props) => props.noTopPadding ? '' : '20px'};
  padding-bottom: ${(props) => props.noBottomPadding ? '' : '20px'};
  margin: 0 -20px 0 0;
`;

ButtonRow.displayName = 'ButtonRow';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 -20px 0 0;
`;

Row.displayName = 'Row';

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin: 0 20px;
`;

Column.displayName = 'Column';

export const DoubleColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  margin: 0 20px;
`;

DoubleColumn.displayName = 'DoubleColumn';

export const FullRowColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 3;
  margin: 0 20px;
`;

FullRowColumn.displayName = 'FullRowColumn';

type FullWidthColumnProps = {
  flexDirection?: string
};

export const FullWidthColumn = styled.div<FullWidthColumnProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection ? props.flexDirection : 'row'};
  flex-basis: 100%;
  flex: 4;
  margin: 0 10px 0 20px;
`;

FullWidthColumn.displayName = 'FullWidthColumn';

type HalfWidthColumnProps = {
  flexDirection?: string,
  noMargin?: boolean
};

export const HalfWidthColumn = styled.div<HalfWidthColumnProps>`
  display: flex;
  flex-direction: ${(props) => props.flexDirection ? props.flexDirection : 'row'};
  flex-basis: 100%;
  flex: 1;
  margin: ${(props) => props.noMargin ? '' : '0 10px 0 20px'};
`;

HalfWidthColumn.displayName = 'HalfWidthColumn';

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
`;

FlexContainer.displayName = 'FlexContainer';
