import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@mui/material';

import { CaseModuleListComponent, DocumentModuleListComponent } from './ModuleListComponent';

export const LandingComponent = () => (
  <>
    <HeadingWrapper>
      <Typography component="h2" variant="h5">Case Curation</Typography>
    </HeadingWrapper>
    <Wrapper>
      <CaseModuleListComponent />
    </Wrapper>
    <HeadingWrapper>
      <Typography component="h2" variant="h5">Document Curation</Typography>
    </HeadingWrapper>
    <Wrapper>
      <DocumentModuleListComponent />
    </Wrapper>
  </>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  justify-content: flex-start;
  flex: 2;
  margin: 0 20px;
  flex-wrap: wrap;
`;
const HeadingWrapper = styled.div`
  padding-top: 1rem;
  margin: 0 20px;
`;
