import { arrayPush, initialize, SubmissionError } from 'redux-form';
import { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import { forkJoin, from, merge, of } from 'rxjs';
import { catchError, filter, map,  mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { PAGINATION_DEFAULT } from '../../../constants';
import { DeleteError } from '../../../lib/errors/DeleteError';
import { EntityFetchError } from '../../../lib/errors/EntityFetchError';
import { EntityCreateError } from '../../../lib/errors/EntityCreateError';
import { EntityUpdateError } from '../../../lib/errors/EntityUpdateError';
import { representativeFormOptionsSelector } from '../../../modules/representative-manager/selectors';
import { Services } from '../../../services';
import { entityToSelectOption } from '../../util/forms';
import { RootAction } from '../actions';
import { toggleQuickAddFormVisibility } from '../case-manager/actions';
import { countriesFetchActions } from '../countries/actions';
import { RootState } from '../root';
import { pushRoute } from '../router/actions';

import {
  applyRepresentativeFilters,
  changeRepresentativesListPage,
  changeRepresentativesListPageSize,
  clearRepresentativeFilters,
  deleteRepresentative,
  fetchDependentRepresentativeData,
  loadRepresentativeForDelete,
  loadRepresentativeForEdit,
  refreshRepresentativesList,
  representativePaginatedFetchActions,
  setFormDataFromRepresentative,
  SetRepresentativePayload,
  submitRepresentativeCreateForm,
  submitRepresentativeCreateFormCompleted,
  submitRepresentativeCreateFormFailed,
  submitRepresentativeEditForm,
  submitRepresentativeEditFormCompleted,
  submitRepresentativeEditFormFailed,
  updateRepresentativesList
} from './actions';
import {
  formatRepresentativeLabel,
  selectRepresentativeFilterFormData,
  selectRepresentativeFilterQuery,
  selectRepresentativesPagination
} from './selectors';
import { transformRepresentativeFormDataToRepresentativeSubmission, transformRepresentativeToFormData } from './utils';

const requestActions = [
  countriesFetchActions.request()
];

const successActionCreators = [
  countriesFetchActions.success
];

export const fetchPaginatedRepresentativesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(representativePaginatedFetchActions.request)),
    mergeMap((action) =>
      from(curatorApi.searchRepresentatives(action.payload)).pipe(
        map(representativePaginatedFetchActions.success),
        catchError((err) => of(representativePaginatedFetchActions.failure(err)))
      )
    )
  );

export const fetchRepresentativeManagerDependentDataEpic: Epic<RootAction, RootAction> = (action$) =>
  action$
    .pipe(
      filter(isActionOf(fetchDependentRepresentativeData.request)),
      switchMap(() => requestActions)
    );

export const fetchRepresentativeManagerDependentDataEpicSuccessWatcher: Epic<RootAction, RootAction> = (action$) =>
  action$
    .pipe(
      filter(isActionOf(fetchDependentRepresentativeData.request)),
      switchMap(() =>
        // TODO: Ideally, we should handle any of the request actions failing and dispatch fetchDependentCaseManagerData.failure.
        //   Wasn't able to get `race` to work, maybe there's some `takeUntil` magic
        forkJoin(
          successActionCreators.map((action) =>
            action$.pipe(
              filter(isActionOf(action)),
              take(1)
            )
          )
        )
      ),
      map(() => fetchDependentRepresentativeData.success())
    );

const refreshEpic: Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(refreshRepresentativesList)),
    withLatestFrom(store$),
    switchMap(([_, state]) => {
      const currentPagination = selectRepresentativesPagination(state);
      const currentFilters = selectRepresentativeFilterQuery(state);
      const currentFilterFormData = selectRepresentativeFilterFormData(state);
      return [
        representativePaginatedFetchActions.request({
          filter: currentFilters,
          pagination: currentPagination
        }),
        initialize('representative-filters', currentFilterFormData)
      ];
    })
  );

const updateEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(updateRepresentativesList)),
    map((action) => {
      return representativePaginatedFetchActions.request(action.payload);
    })
  );

const changePageEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeRepresentativesListPage)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = selectRepresentativesPagination(state);
      const currentFilters = selectRepresentativeFilterQuery(state);
      return updateRepresentativesList({
        filter: currentFilters,
        pagination: {
          ...currentPagination,
          page: action.payload
        }
      });
    })
  );

const changePageSizeEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeRepresentativesListPageSize)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = selectRepresentativesPagination(state);
      const currentFilters = selectRepresentativeFilterQuery(state);
      return updateRepresentativesList({
        filter: currentFilters,
        pagination: {
          ...currentPagination,
          page: 1,
          perPage: action.payload
        }
      });
    })
  );

const applyFiltersEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(applyRepresentativeFilters)),
    withLatestFrom(store$),
    map(([_, state]) => {
      const currentFilters = selectRepresentativeFilterQuery(state);
      return updateRepresentativesList({
        filter: currentFilters,
        pagination: PAGINATION_DEFAULT
      });
    })
  );

const clearFiltersEpic: Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(clearRepresentativeFilters)),
    switchMap(() => {
      return [
        initialize('representative-filters', {}),
        fetchDependentRepresentativeData.request(),
        updateRepresentativesList({ filter: {}, pagination: PAGINATION_DEFAULT })
      ];
    })
  );

export const createRepresentativeEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(submitRepresentativeCreateForm)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const { reject, resolve, quickAddForm, formId, parentForm, fieldId } = action.meta;
        const guid = state.representatives.new.operationGuid[formId];
        if (!guid) {
          reject(new SubmissionError({ _error: 'Failed to create representative, operation GUID missing' }));
          return [submitRepresentativeCreateFormFailed(new EntityCreateError('server error'))];
        }
        const submission = {
          guid,
          data: transformRepresentativeFormDataToRepresentativeSubmission(action.payload)
        };

        return from(curatorApi.createRepresentative(submission)).pipe(
          switchMap((response) => {
            if (response.status >= 300) {
              // TODO: Parse errors into friendlier format and field-specific where possible
              reject(new SubmissionError({ _error: `Failed to create representative\n\n${JSON.stringify(response.data, null, 2)}` }));
              return [submitRepresentativeCreateFormFailed(new EntityCreateError('server error'))];
            }
            resolve();
            if (quickAddForm) {
              return merge(
                from([
                  submitRepresentativeCreateFormCompleted(),
                  toggleQuickAddFormVisibility({ form: quickAddForm })
                ]),
                (parentForm && fieldId) ? [
                  arrayPush(parentForm, fieldId, entityToSelectOption(formatRepresentativeLabel, response.data.result))
                ] : []
              );
            } else {
              return [
                submitRepresentativeCreateFormCompleted(),
                pushRoute('/representative-manager')
              ];
            }
          }),
          catchError((err: Error) => {
            reject(new SubmissionError({ _error: `Failed to create representative. (${err.message})` }));
            return of(submitRepresentativeCreateFormFailed(new EntityCreateError('unknown error')));
          })
        );
      })
    );

export const updateRepresentativeEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(submitRepresentativeEditForm)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const { reject, resolve } = action.meta;
        const id = state.representatives.edit.id;
        const guid = state.representatives.edit.operationGuid;
        if (!id) {
          reject(new SubmissionError({ _error: 'Failed to update representative, id missing' }));
          return [submitRepresentativeEditFormFailed(new EntityUpdateError('representative id missing'))];
        }
        if (!guid) {
          reject(new SubmissionError({ _error: 'Failed to update representative, operation GUID missing' }));
          return [submitRepresentativeEditFormFailed(new EntityUpdateError('operation guid missing'))];
        }
        const submission = {
          id,
          guid,
          data: transformRepresentativeFormDataToRepresentativeSubmission(action.payload)
        };
        return from(curatorApi.updateRepresentative(submission)).pipe(
          switchMap((response) => {
            if (response.status >= 300) {
              // TODO: Parse errors into friendlier format and field-specific where possible
              reject(new SubmissionError({ _error: `Failed to update representative\n\n${JSON.stringify(response.data, null, 2)}` }));
              return [submitRepresentativeEditFormFailed(new EntityUpdateError('server error'))];
            }
            resolve();
            return [
              submitRepresentativeEditFormCompleted(),
              pushRoute('/representative-manager')
            ];
          }),
          catchError((err: Error) => {
            reject(new SubmissionError({ _error: `Failed to update representative. (${err.message})` }));
            return of(submitRepresentativeEditFormFailed(new EntityUpdateError('unknown error')));
          })
        );
      })
    );

export const fetchRepresentativeForEditEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(loadRepresentativeForEdit.request)),
      switchMap((action) => from(curatorApi.getRepresentative(action.payload)).pipe(
        switchMap((response) =>
          of(
            loadRepresentativeForEdit.success(response.data),
            setFormDataFromRepresentative({ formId: 'representative-edit', data: response.data })
          )),
        catchError((err: Error) => {
          return from([
            loadRepresentativeForEdit.failure(new EntityFetchError(err.message)),
            pushRoute('/representative-manager')
          ]);
        })
      )));

export const fetchRepresentativeForDeleteEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(loadRepresentativeForDelete.request)),
      switchMap((action) =>
        forkJoin([
          from(curatorApi.getRepresentative(action.payload)),
          from(curatorApi.getCaseQueryEntityDeleteEligibility('representative', action.payload))
        ]).pipe(
          switchMap(([fetchEntityResponse, deleteEligibilityResponse]) =>
            of(loadRepresentativeForDelete.success({
              data: fetchEntityResponse.data,
              info: deleteEligibilityResponse.data
            }))),
          catchError((err) =>
            of(
              loadRepresentativeForDelete.failure(err),
              pushRoute('/representative-manager')
            ))
        ))
    );

export const deleteRepresentativeEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(deleteRepresentative.request)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const guid = state.representatives.delete.operationGuid;
        if (!guid) {
          return of(deleteRepresentative.failure(new DeleteError('operation guid missing')));
        }
        if (!state.representatives.delete.info?.canDelete) {
          return of(deleteRepresentative.failure(new DeleteError('delete failed state check')));
        }
        const submission = {
          guid,
          id: action.payload
        };
        return from(curatorApi.deleteRepresentative(submission)).pipe(
          switchMap(() => of(deleteRepresentative.success(), pushRoute('/representative-manager'))),
          catchError((err) => of(deleteRepresentative.failure(err)))
        );
      })
    );

export const waitForSuccessAndInitializeForm = (action$: ActionsObservable<RootAction>, store$: StateObservable<RootState>, payload: SetRepresentativePayload) => {
  return action$.pipe(
    filter(isActionOf(fetchDependentRepresentativeData.success)),
    take(1),
    withLatestFrom(store$),
    map(([_, state]) => {
      return initialize(payload.formId, transformRepresentativeToFormData(payload.data, representativeFormOptionsSelector(state)));
    })
  );
};

export const setFormDataFromRepresentativeEpic: Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$
    .pipe(
      filter(isActionOf(setFormDataFromRepresentative)),
      map((action) => action.payload),
      switchMap((payload) =>
        merge(
          // Dispatch action to refresh dependent data
          of(fetchDependentRepresentativeData.request()),
          // Listen for the dependent data success and then initialize the form values
          waitForSuccessAndInitializeForm(action$, store$, payload)
        )
      )
    );

export const representativeEpics = [
  fetchPaginatedRepresentativesEpic,
  refreshEpic,
  updateEpic,
  changePageEpic,
  changePageSizeEpic,
  fetchRepresentativeManagerDependentDataEpic,
  fetchRepresentativeManagerDependentDataEpicSuccessWatcher,
  createRepresentativeEpic,
  updateRepresentativeEpic,
  fetchRepresentativeForEditEpic,
  setFormDataFromRepresentativeEpic,
  applyFiltersEpic,
  clearFiltersEpic,
  fetchRepresentativeForDeleteEpic,
  deleteRepresentativeEpic
];
