import * as R from 'ramda';

import { FirmFormOptions } from '../../../modules/firm-manager/selectors';
import { transformSelectOptionToValue, transformValueToSelectOption } from '../../util/forms';
import { FirmData, FirmEntity, FirmFormData } from '../../../types/cases/firm';

export const transformFirmToFormData = (firmData: FirmEntity, formOptions: FirmFormOptions): Partial<FirmFormData> => {
  return {
    name: firmData.name,
    name_fr: firmData.name_fr,
    country: transformValueToSelectOption(formOptions.countryOptions, firmData.country.id)
  };
};

export const transformFirmFormDataToFirmSubmission = (formData: Partial<FirmFormData>): FirmData => {
  return {
    name: R.trim(formData.name || ''),
    name_fr: R.trim(formData.name_fr || ''),
    country: transformSelectOptionToValue(formData.country)
  };
};
