import { Button, Grid } from '@mui/material';
import * as React from 'react';
import { Field, Form, InjectedFormProps } from 'redux-form';

import { AsyncSelectField } from '../../../components/form/AsyncSelectField';
import { DatePickerField } from '../../../components/form/DatePickerField';
import { SelectField } from '../../../components/form/SelectField';
import { TextField } from '../../../components/form/TextField';
import { SelectOption } from '../../../types/util';
import { CaseFilterProps } from '../selectors';
import { getFlaggedSelectOptions } from '../../../components/FlagComponent/utils';

export type CaseFilterFormProps = {
  disabled: boolean,
  bodyAvailability: SelectOption[]
} & CaseFilterProps;

export type CaseFilterFormData = {
  title: string,
  citation: string,
  domain: SelectOption,
  court: SelectOption,
  country: SelectOption,
  date_from: string,
  date_to: string,
  firmIds: SelectOption[]
  representativeIds: SelectOption[],
  judgeIds: SelectOption[],
  caseSubjectIds: SelectOption[],
  bodyAvailability: SelectOption,
  flaggedAs: SelectOption
};

const FORM_CONTROL_MARGIN = 'normal';

type ReduxFormInjectedProps = InjectedFormProps<CaseFilterFormData, CaseFilterFormProps>;

export const CaseFilterComponent: React.FC<ReduxFormInjectedProps & CaseFilterFormProps> = (
  {
    handleSubmit,
    applyFilters,
    resetFilters,
    domainOptions,
    countryOptions,
    courtOptions,
    caseSubjectOptions,
    firmsSearchHandler,
    judgesSearchHandler,
    representativesSearchHandler,
    disabled,
    bodyAvailability
  }
) => (
  <Form onSubmit={handleSubmit((val) => {
    applyFilters(val);
  })}>
    <Grid container={true} spacing={3} alignContent="center">
      <Grid item={true} xs={3}>
        <Grid container={true} direction="column" spacing={1}>
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            component={TextField}
            name="title"
            label="Case Title / ID"
          />
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            component={TextField}
            name="citation"
            label="Citation"
          />
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            clearable={true}
            component={SelectField}
            name="court"
            label="Court"
            options={courtOptions}
          />
        </Grid>
      </Grid>
      <Grid item={true} xs={3}>
        <Grid container={true} direction="column" spacing={1}>
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            clearable={true}
            component={SelectField}
            name="domain"
            label="Domain"
            options={domainOptions}
          />
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            clearable={true}
            component={SelectField}
            isMulti={true}
            name="caseSubjectIds"
            label="Case Subjects"
            options={caseSubjectOptions}
          />
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            clearable={true}
            component={SelectField}
            name="country"
            label="Country"
            options={countryOptions}
          />
        </Grid>
      </Grid>
      <Grid item={true} xs={3}>
        <Grid container={true} direction="column" spacing={1}>
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            component={DatePickerField}
            name="date_from"
            label="Judgment Date From"
          />
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            component={DatePickerField}
            name="date_to"
            label="Judgment Date To"
          />
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            clearable={true}
            component={SelectField}
            name="bodyAvailability"
            label="Body Availability"
            options={bodyAvailability}
          />
          <Field
            name="flaggedAs"
            label="Case Flagged As"
            component={SelectField}
            clearable={true}
            isMulti={false}
            options={getFlaggedSelectOptions()}
          />
        </Grid>
      </Grid>
      <Grid item={true} xs={3}>
        <Grid container={true} direction="column" spacing={1}>
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            isMulti={true}
            entitySearch={judgesSearchHandler}
            clearable={true}
            component={AsyncSelectField}
            name="judgeIds"
            label="Judges"
          />
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            isMulti={true}
            entitySearch={firmsSearchHandler}
            component={AsyncSelectField}
            name="firmIds"
            label="Firms"
          />
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            isMulti={true}
            clearable={true}
            entitySearch={representativesSearchHandler}
            component={AsyncSelectField}
            name="representativeIds"
            label="Representatives"
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid container={true} spacing={3} justifyContent="flex-end">
      <Grid item={true}>
        <Button type="submit" variant="contained" color="primary" disabled={disabled}>Apply</Button>
      </Grid>
      <Grid item={true}>
        <Button type="button" onClick={resetFilters}>Clear</Button>
      </Grid>
    </Grid>
  </Form>
);
