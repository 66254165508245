import * as R from 'ramda';

import { CountryData, CountryFormData } from '../../../types/cases/country';

export const transformCountryToFormData = (countryData: CountryData): Partial<CountryFormData> => {
  return {
    name: countryData.name,
    name_fr: countryData.name_fr
  };
};

export const transformCountryFormDataToCountrySubmission = (formData: Partial<CountryFormData>): CountryData => {
  return {
    name: R.trim(formData.name || ''),
    name_fr: R.trim(formData.name_fr || '')
  };
};
