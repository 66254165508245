import * as R from 'ramda';
import { createReducer, StateType } from 'typesafe-actions';

import { arrayToIdMap } from '../../util/array-to-id-map';
import { RootAction } from '../actions';
import { LegalDomainEntity } from '../../../types/cases/legal-domain';

import { legalDomainsFetchActions } from './actions';

type LegalDomainMap = Record<string, LegalDomainEntity>;

export const legalDomainsInitialState: LegalDomainMap = {};

export const legalDomainsReducer = createReducer<LegalDomainMap, RootAction>(legalDomainsInitialState)
  .handleAction(legalDomainsFetchActions.success, (state, { payload }) => {
    const newMap = arrayToIdMap(payload.data);
    return R.mergeRight(state, newMap);
  });

export type LegalDomainsState = StateType<typeof legalDomainsReducer>;
