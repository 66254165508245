import { createAsyncAction, createAction } from 'typesafe-actions';

import { ICuratorError } from '../../../lib/errors/types';
import { JudgeFilterFormData } from '../../../modules/judge-manager/components/JudgeFilterComponent';
import { JudgeSearchQuery } from '../../../services/CuratorApi';
import { LoadEntityForDeleteResponse } from '../../../types/command-types';
import { JudgeManagerFormSubmissionMeta, FormSubmissionMeta } from '../../../types/forms';
import {
  PageNumberType,
  PageSizeType,
  PaginatedQuery,
  WithPaginatedFetchMeta
} from '../../util/fetch';
import { JudgeEntity, JudgeFormData } from '../../../types/cases/judge';

export const judgesPaginatedFetchActions = createAsyncAction(
  '@@CURATOR/JUDGES/PAGINATED/REQUEST',
  '@@CURATOR/JUDGES/PAGINATED/SUCCESS',
  '@@CURATOR/JUDGES/PAGINATED/FAILURE'
)<PaginatedQuery<JudgeSearchQuery>, WithPaginatedFetchMeta<JudgeEntity>, ICuratorError>();

export const refreshJudgesList = createAction('@@CURATOR/JUDGES_MANAGER/REFRESH_LIST')<void>();

export const updateJudgesList = createAction('@@CURATOR/JUDGES_MANAGER/UPDATE_LIST')<PaginatedQuery<JudgeSearchQuery>>();

export const changeJudgesListPage = createAction('@@CURATOR/JUDGES_MANAGER/CHANGE_PAGE')<PageNumberType>();

export const changeJudgesListPageSize = createAction('@@CURATOR/JUDGES_MANAGER/CHANGE_PAGE_SIZE')<PageSizeType>();

export const fetchDependentJudgeData = createAsyncAction(
  '@@CURATOR/JUDGES_MANAGER/FETCH_DEPENDENT_DATA',
  '@@CURATOR/JUDGES_MANAGER/FETCH_DEPENDENT_DATA_SUCCESS',
  '@@CURATOR/JUDGES_MANAGER/FETCH_DEPENDENT_DATA_FAILURE')<void, void, void>();

export const startNewJudgeOperation = createAction('@@CURATOR/JUDGES_MANAGER/START_NEW_JUDGE_OPERATION')<void>();

export const submitJudgeCreateForm = createAction('@@CURATOR/JUDGES_MANAGER/SUBMIT_NEW_JUDGE')<Partial<JudgeFormData>, JudgeManagerFormSubmissionMeta>();

export const submitJudgeCreateFormCompleted = createAction('@@CURATOR/JUDGES_MANAGER/SUBMIT_NEW_JUDGE_COMPLETED')<void>();

export const submitJudgeCreateFormFailed = createAction('@@CURATOR/JUDGES_MANAGER/SUBMIT_NEW_JUDGE_FAILED')<ICuratorError>();

export const submitJudgeEditForm = createAction('@@CURATOR/JUDGES_MANAGER/SUBMIT_EDIT_JUDGE')<Partial<JudgeFormData>, FormSubmissionMeta>();

export const submitJudgeEditFormCompleted = createAction('@@CURATOR/JUDGES_MANAGER/SUBMIT_EDIT_JUDGE_COMPLETED')<void>();

export const submitJudgeEditFormFailed = createAction('@@CURATOR/JUDGES_MANAGER/SUBMIT_EDIT_JUDGE_FAILURE')<ICuratorError>();

export const loadJudgeForEdit = createAsyncAction(
  '@@CURATOR/JUDGES_MANAGER/LOAD_JUDGE_FOR_EDIT',
  '@@CURATOR/JUDGES_MANAGER/LOAD_JUDGE_FOR_EDIT_SUCCESS',
  '@@CURATOR/JUDGES_MANAGER/LOAD_JUDGE_FOR_EDIT_FAILURE'
)<string, JudgeEntity, ICuratorError>();

export const applyJudgeFilters = createAction('@@CURATOR/JUDGES_MANAGER/APPLY_FILTERS')<JudgeFilterFormData>();

export const clearJudgeFilters = createAction('@@CURATOR/JUDGES_MANAGER/RESET_FILTERS')<void>();

export const loadJudgeForDelete = createAsyncAction(
  '@@CURATOR/JUDGES_MANAGER/LOAD_JUDGE_FOR_DELETE',
  '@@CURATOR/JUDGES_MANAGER/LOAD_JUDGE_FOR_DELETE_SUCCESS',
  '@@CURATOR/JUDGES_MANAGER/LOAD_JUDGE_FOR_DELETE_FAILURE'
)<string, LoadEntityForDeleteResponse<JudgeEntity>, ICuratorError>();

export const deleteJudge = createAsyncAction(
  '@@CURATOR/CASE_MANAGER/DELETE_JUDGE',
  '@@CURATOR/CASE_MANAGER/DELETE_JUDGE_SUCCESS',
  '@@CURATOR/CASE_MANAGER/DELETE_JUDGE_FAILURE')<JudgeEntity['id'], void, ICuratorError>();

export type SetJudgePayload = {
  formId: string,
  data: JudgeEntity
};

export const setFormDataFromJudge = createAction('@@CURATOR/JUDGES_MANAGER/SET_FORM_DATA_FROM_JUDGE')<SetJudgePayload>();
