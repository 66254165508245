import { Card, CardActionArea, CardContent, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

interface IModuleItemProps {
  path: string;
  name: string;
  description: string;
}

export const ModuleItemComponent: React.FC<IModuleItemProps> = (
  props
) => {
  return (
    <Card>
      <CardActionArea component={Link} to={props.path}>
        <CardContent>
          <Typography gutterBottom={true} variant="h5" component="h2">
            {props.name}
          </Typography>
          <Typography component="p">
            {props.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
