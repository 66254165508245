import { EntityTable } from '../../../components/EntityTable';

export type DocumentTableData = {
  id: string,
  title: string,
  citations: string;
  type: string,
  datePublished: string,
  state: string
};

export class DocumentEntityTableComponent extends EntityTable<DocumentTableData> {

}
