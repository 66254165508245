import * as R from 'ramda';

import { StateFormOptions } from '../../../modules/state-manager/selectors';
import { transformSelectOptionToValue, transformValueToSelectOption } from '../../util/forms';
import { StateData, StateEntity, StateFormData } from '../../../types/cases/state';

export const transformStateToFormData = (stateData: StateEntity, formOptions: StateFormOptions): Partial<StateFormData> => {
  return {
    name: stateData.name,
    name_fr: stateData.name_fr,
    country: transformValueToSelectOption(formOptions.countryOptions, stateData.country.id)
  };
};

export const transformStateFormDataToStateSubmission = (formData: Partial<StateFormData>): StateData => {
  return {
    name: R.trim(formData.name || ''),
    name_fr: R.trim(formData.name_fr || ''),
    country: transformSelectOptionToValue(formData.country)
  };
};
