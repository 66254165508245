import { Dispatch } from 'redux';
import { createSelector, createStructuredSelector } from 'reselect';

import { RootState } from '../../redux/modules/root';
import { RootAction } from '../../redux/modules/actions';
import {
  selectDocuments,
  selectIsLoadingDocumentManagerDependentData
} from '../../redux/modules/document-manager/selectors';
import { documentTypeOptionsSelector } from '../../redux/modules/document-types/selectors';
import { DocumentFormData } from '../../types/documents/document';

import { DocumentFilterFormData } from './components/DocumentFilterComponent';
import { DocumentTableData } from './components/DocumentEntityTableComponent';

export function filterDocumentFormDispatchers(
  dispatch: Dispatch<RootAction>,
  resetAction: (any: void) => RootAction,
  submitAction: (values: DocumentFilterFormData) => RootAction
) {
  return {
    resetFilters: () => dispatch(resetAction()),
    applyFilters: (values: DocumentFilterFormData) => dispatch(submitAction(values))
  };
}

export const isDocumentFormDisabledSelector = createSelector(
  [selectIsLoadingDocumentManagerDependentData],
  (loading) => loading
);

export const selectDocumentsForTable = createSelector(
  [selectDocuments],
  (documents) => documents.map((doc): DocumentTableData => ({
    id: doc.id,
    title: doc.title || doc.title_fr || '',
    citations: doc.citations.join(', '),
    type: doc.type?.name || doc.type?.name_fr || '',
    state: doc.state?.name || doc.state?.name_fr || '',
    datePublished: doc.datePublished || ''
  }))
)

type DocumentOptionsProps = {
  documentTypeOptions: ReturnType<typeof documentTypeOptionsSelector>
}

export const documentFormOptionsPropsSelector = createStructuredSelector<RootState, DocumentOptionsProps>({
  documentTypeOptions: documentTypeOptionsSelector
});

export type DocumentFilterProps = DocumentOptionsProps & ReturnType<typeof filterDocumentFormDispatchers>;

export type DocumentFormProps = DocumentOptionsProps & {
  isDisabled: ReturnType<typeof isDocumentFormDisabledSelector>,
  submitHandler: (values: DocumentFormData) => Promise<any>
};
