import * as R from 'ramda';

import { createManager } from '../lib/entity-manager';
import { FieldTypes } from '../lib/entity-manager/forms/shared';
import { CuratorApi } from '../services/CuratorApi';
import { FirmData, FirmEntity, FirmFormData } from '../types/cases/firm';
import { namedEntityFormatter } from '../redux/modules/case-manager/utils';
import { transformSelectOptionToValue } from '../redux/util/forms';

// TODO: Define a filtering type
const getEntities = (api: CuratorApi, _filter: Partial<FirmEntity>, page: number, perPage: number) => {
  return api.searchFirms({
    filter: {},
    pagination: {
      page,
      perPage
    }
  });
};

const transformFirmFormDataToFirmSubmission = (formData: Partial<FirmFormData>): FirmData => {
  return {
    name: R.trim(formData.name || ''),
    name_fr: R.trim(formData.name_fr || ''),
    country: transformSelectOptionToValue(formData.country)
  };
};

const transformFirmEntityToFormData = (entity: FirmEntity): FirmFormData => ({
  name: entity.name,
  name_fr: entity.name_fr,
  country: {
    label: entity.country.name,
    value: entity.country.id
  }
});

const createEntity = (api: CuratorApi, guid: string, formData: FirmFormData) => {
  const data = transformFirmFormDataToFirmSubmission(formData);
  return api.createFirm({ guid, data });
}

const checkDeleteEligibility = (api: CuratorApi, id: string) => {
  return api.getCaseQueryEntityDeleteEligibility('firm', id);
}

const updateEntity = (api: CuratorApi, guid:string, id: string, formData: FirmFormData) => {
  const data = transformFirmFormDataToFirmSubmission(formData);
  return api.updateFirm({ guid, id, data });
}

const getEntity = (api: CuratorApi, id: string) => {
  return api.getFirm(id, { relations: ['country']})
    .then((result) => result.data)
};

const deleteEntity = (api: CuratorApi, guid: string, id: string) => {
  return api.deleteFirm({ guid, id });
};

const generateDefaultFirmFilterData = () => ({
  name: '',
  name_fr: '',
  country: undefined
});

const generateDefaultFirmFormData = () => ({
  name: '',
  name_fr: '',
  country: undefined
});

export const firmManager = createManager<CuratorApi, FirmFormData, FirmEntity>({
  key: 'firm',
  basePath: 'firm-manager',
  displayName: {
    singular: 'Firm',
    plural: 'Firms'
  },
  generateDefaultFilterData: generateDefaultFirmFilterData,
  generateDefaultData: generateDefaultFirmFormData,
  data: {
    fetch: getEntity,
    create: createEntity,
    update: updateEntity,
    all: getEntities,
    delete: deleteEntity,
    deleteEligibility: checkDeleteEligibility,
    entityDisplayName: namedEntityFormatter,
    entityDataToFormDataTransform: transformFirmEntityToFormData
  },
  listColumns: [
    {
      id: 'name',
      label: 'Name',
      getDisplayValue: (entity) => namedEntityFormatter(entity)
    }
  ],
  fields: {
    name: {
      label: 'Name',
      type: FieldTypes.Text
    },
    name_fr: {
      label: 'Name (FR)',
      type: FieldTypes.Text
    },
    country: {
      label: 'Country',
      isMulti: false,
      type: FieldTypes.Country
    }
  },
  filters: {}
});
