import { createAsyncAction, createAction } from 'typesafe-actions';

import { ICuratorError } from '../../../lib/errors/types';
import { LoadEntityForDeleteResponse } from '../../../types/command-types';
import { FormSubmissionMeta } from '../../../types/forms';
import {
  PageNumberType,
  PageSizeType,
  PaginatedQuery,
  PaginationConfig,
  WithFetchMeta,
  WithPaginatedFetchMeta
} from '../../util/fetch';
import { StateEntity, StateFormData } from '../../../types/cases/state';

export const statesPaginatedFetchActions = createAsyncAction(
  '@@CURATOR/STATES/PAGINATED/REQUEST',
  '@@CURATOR/STATES/PAGINATED/SUCCESS',
  '@@CURATOR/STATES/PAGINATED/FAILURE'
)<PaginatedQuery<StateEntity>, WithPaginatedFetchMeta<StateEntity>, ICuratorError>();

export const statesFetchActions = createAsyncAction(
  '@@CURATOR/STATES/REQUEST',
  '@@CURATOR/STATES/SUCCESS',
  '@@CURATOR/STATES/FAILURE'
)<void, WithFetchMeta<StateEntity[]>, ICuratorError>();

export const refreshStatesList = createAction('@@CURATOR/STATES_MANAGER/REFRESH_LIST')<void>();

export const updateStatesList = createAction('@@CURATOR/STATES_MANAGER/UPDATE_LIST')<PaginationConfig>();

export const changeStatesListPage = createAction('@@CURATOR/STATES_MANAGER/CHANGE_PAGE')<PageNumberType>();

export const changeStatesListPageSize = createAction('@@CURATOR/STATES_MANAGER/CHANGE_PAGE_SIZE')<PageSizeType>();

export const fetchDependentStateData = createAsyncAction(
  '@@CURATOR/STATES_MANAGER/FETCH_DEPENDENT_DATA',
  '@@CURATOR/STATES_MANAGER/FETCH_DEPENDENT_DATA_SUCCESS',
  '@@CURATOR/STATES_MANAGER/FETCH_DEPENDENT_DATA_FAILURE')<void, void, void>();

export const startNewStateOperation = createAction('@@CURATOR/STATES_MANAGER/START_NEW_STATE_OPERATION')<void>();

export const submitStateCreateForm = createAction('@@CURATOR/STATES_MANAGER/SUBMIT_NEW_STATE')<Partial<StateFormData>, FormSubmissionMeta>();

export const submitStateCreateFormCompleted = createAction('@@CURATOR/STATES_MANAGER/SUBMIT_NEW_STATE_COMPLETED')<void>();

export const submitStateCreateFormFailed = createAction('@@CURATOR/STATES_MANAGER/SUBMIT_NEW_STATE_FAILED')<ICuratorError>();

export const submitStateEditForm = createAction('@@CURATOR/STATES_MANAGER/SUBMIT_EDIT_STATE')<Partial<StateFormData>, FormSubmissionMeta>();

export const submitStateEditFormCompleted = createAction('@@CURATOR/STATES_MANAGER/SUBMIT_EDIT_STATE_COMPLETED')<void>();

export const submitStateEditFormFailed = createAction('@@CURATOR/STATES_MANAGER/SUBMIT_EDIT_STATE_FAILURE')<ICuratorError>();

export const loadStateForEdit = createAsyncAction(
  '@@CURATOR/STATES_MANAGER/LOAD_STATE_FOR_EDIT',
  '@@CURATOR/STATES_MANAGER/LOAD_STATE_FOR_EDIT_SUCCESS',
  '@@CURATOR/STATES_MANAGER/LOAD_STATE_FOR_EDIT_FAILURE'
)<string, StateEntity, ICuratorError>();

export const loadStateForDelete = createAsyncAction(
  '@@CURATOR/STATES_MANAGER/LOAD_STATE_FOR_DELETE',
  '@@CURATOR/STATES_MANAGER/LOAD_STATE_FOR_DELETE_SUCCESS',
  '@@CURATOR/STATES_MANAGER/LOAD_STATE_FOR_DELETE_FAILURE'
)<string, LoadEntityForDeleteResponse<StateEntity>, ICuratorError>();

export const deleteState = createAsyncAction(
  '@@CURATOR/CASE_MANAGER/DELETE_STATE',
  '@@CURATOR/CASE_MANAGER/DELETE_STATE_SUCCESS',
  '@@CURATOR/CASE_MANAGER/DELETE_STATE_FAILURE')<StateEntity['id'], void, ICuratorError>();

export type SetStatePayload = {
  formId: string,
  data: StateEntity
};

export const setFormDataFromState = createAction('@@CURATOR/STATES_MANAGER/SET_FORM_DATA_FROM_STATE')<SetStatePayload>();
