import { createManager } from '../lib/entity-manager';
import { FieldTypes } from '../lib/entity-manager/forms/shared';
import { CuratorApi } from '../services/CuratorApi';
import { CaseTypeEntity, CaseTypeFormData } from '../types/cases/case-type';
import { namedEntityFormatter } from '../redux/modules/case-manager/utils';

const getCaseTypes = (api: CuratorApi, filter: Partial<CaseTypeEntity>, page: number, perPage: number) => {
  return api.searchCaseTypes({
    filter,
    pagination: {
      page,
      perPage
    }
  });
};

const deleteCaseType = (api: CuratorApi, guid: string, id: string) => {
  return api.deleteCaseType({ guid, id });
};


const createCaseType = (api: CuratorApi, guid: string, data: CaseTypeFormData) => {
  return api.createCaseType({
    guid,
    data
  })
}

const updateCaseType = (api: CuratorApi, guid: string, id: string, data: CaseTypeFormData) => {
  return api.updateCaseType({ guid, id, data });
}

const transformCaseTypeEntityToFormData = (entity: CaseTypeEntity): CaseTypeFormData => {
  return {
    name: entity.name || '',
    name_fr: entity.name_fr || ''
  };
};

const generateDefaultCaseTypeFilterData = () => ({
  name: '',
  name_fr: ''
});

const generateDefaultCaseTypeFormData = () => ({
  name: '',
  name_fr: ''
});

const checkDeleteEligibility = (api: CuratorApi, id: string) => {
  return api.getCaseQueryEntityDeleteEligibility('case-type', id);
}


export const caseTypeManager = createManager<CuratorApi, CaseTypeFormData, CaseTypeEntity>({
  key: 'case-type',
  basePath: 'case-type-manager',
  displayName: {
    singular: 'Case Type',
    plural: 'Case Types'
  },
  generateDefaultFilterData: generateDefaultCaseTypeFilterData,
  generateDefaultData: generateDefaultCaseTypeFormData,
  listColumns: [
    {
      id: 'name',
      label: 'Name',
      getDisplayValue: (entity) => entity.name
    },
    {
      id: 'name_fr',
      label: 'Name (FR)',
      getDisplayValue: (entity) => entity.name_fr || ''
    }
  ],
  data: {
    fetch: (api, id) => api.getCaseType(id).then((result) => result.data),
    create: createCaseType,
    update: updateCaseType,
    all: getCaseTypes,
    delete: deleteCaseType,
    entityDataToFormDataTransform: transformCaseTypeEntityToFormData,
    entityDisplayName: namedEntityFormatter,
    deleteEligibility: checkDeleteEligibility
  },
  fields: {
    name: {
      label: 'Name',
      type: FieldTypes.Text
    },
    name_fr: {
      label: 'Name (FR)',
      type: FieldTypes.Text
    }
  },
  filters: {}
});
