import React, { ChangeEvent } from 'react';
import { ColumnDef, useReactTable, getCoreRowModel, flexRender } from '@tanstack/react-table';
import {
  Stack,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Grid,
  TableFooter,
  TablePagination
} from '@mui/material';

import { useEntitiesFetch } from '../hooks/useEntitiesFetch';
import { useManagerContext } from '../hooks/useManagerContext';
import { EntityListColumnConfig } from '../shared';
import { EditIcon, DeleteIcon } from '../../../components/ui/mui-icons';
import { IconButtonComponent } from '../../../components/ui/IconButton';

import { EntityListFiltersContainer } from './filters';

function generateColumnDefinitions(columns: EntityListColumnConfig[]): ColumnDef<any>[] {
  const dataColumns = columns.map((columnConfiguration) => {
    return ({
      id: columnConfiguration.id,
      header: columnConfiguration.label,
      accessorFn: (entity: any) => columnConfiguration.getDisplayValue(entity)
    });
  });

  return [
    {
      id: 'entity_id',
      header: '',
      accessorFn: (entity: any) => entity.id
    },
    ...dataColumns,
    {
      id: 'actions',
      header: '',
      accessorFn: (entity: any) => entity.id,
      cell: (entity) => {
        const id = entity.getValue();
        return (
          <Stack direction="row" spacing={2}>
            <IconButtonComponent to={`./${id}`} icon={EditIcon}>Edit</IconButtonComponent>
            <IconButtonComponent to={`./${id}/delete`} icon={DeleteIcon}>Delete</IconButtonComponent>
          </Stack>
        )
      }
    }
  ]
}

type ListEntitiesContainerProps = {
  error?: React.ComponentType;
  loading?: React.ComponentType;
  list?: React.ComponentType;
}

type ListEntitiesListProps = {
  data: any[];
  page: number;
  perPage: number;
  total: number;
  onFiltersSubmit: (values: any) => void;
  onPageChange: (value: number) => void;
  onPerPageChange: (value: number) => void;
}

function DefaultListError() {
  return (
    <p>Error loading entities</p>
  )
}

function DefaultListLoading() {
  return (
    <p>Loading entities</p>
  )
}

function DefaultListEntitiesList(props: ListEntitiesListProps) {
  const {
    data,
    onFiltersSubmit,
    onPageChange,
    onPerPageChange,
    page,
    perPage,
    total
  } = props;

  const { listColumns } = useManagerContext();

  const columnDefinitions = generateColumnDefinitions(listColumns);

  const zeroIndexedPage = page - 1;

  const table = useReactTable({
    data: data || [],
    columns: columnDefinitions,
    getCoreRowModel: getCoreRowModel()
  });

  const handlePageChange = React.useCallback((_event: unknown, newPage: number) => {
    // The table pagination is zero indexed
    onPageChange(newPage + 1);
  }, [onPageChange]);

  const handleRowsPerPageChange = React.useCallback((event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const rowValue = parseInt(event.target.value, 10);
    onPerPageChange(rowValue);
  }, [onPerPageChange]);

  return (
    <Grid container={true} direction="column" spacing={3}>
      <EntityListFiltersContainer onSubmit={onFiltersSubmit} />
      <Grid item={true}>
        <Table>
          <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map(header => (
                  <TableCell key={header.id} variant={'head'}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {table.getRowModel().rows.map(row => (
              <TableRow key={row.id} hover={true}>
                {row.getVisibleCells().map(cell => (
                  <TableCell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={total}
                page={zeroIndexedPage}
                rowsPerPage={perPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Grid>
    </Grid>
  );
}

export function ListEntitiesContainer(props: ListEntitiesContainerProps) {
  const {
    list: List = DefaultListEntitiesList,
    loading: Loading = DefaultListLoading,
    error: Error = DefaultListError
  } = props;
  const {
    data,
    status,
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    setFilters
  } = useEntitiesFetch();

  const handleFiltersSubmit = React.useCallback((values) => setFilters(values), [setFilters]);
  const handlePageChange = React.useCallback((newPage: number) => setPage(newPage), [setPage]);
  const handleRowsPerPageChange = React.useCallback((newPerPage: number) => setPerPage(newPerPage), [setPerPage]);

  // Loading States
  if (status === 'loading') {
    return (
      <Loading />
    )
  }

  if (status === 'error') {
    return (
      <Error />
    );
  }

  // TODO: We can use `isFetching` to show when we're refreshing in the background
  if (status === 'success' && data) {
    return (
      <>
        <List
          page={page}
          perPage={perPage}
          total={total}
          data={data}
          onFiltersSubmit={handleFiltersSubmit}
          onPageChange={handlePageChange}
          onPerPageChange={handleRowsPerPageChange}
        />
      </>
    )
  }

  return null;
}
