import * as React from 'react';
import { reduxForm } from 'redux-form';

import { ImportFromUrlFormDispatchers, ImportFromUrlFormProps, ImportFromUrlFormState } from '../selectors';
import { ImportFromUrlDataForm } from '../../../types/cases/case';

import { CaseImportFromUrlComponent } from './CaseImportFromUrlComponent';

const ImportFromUrlForm = reduxForm<ImportFromUrlDataForm, ImportFromUrlFormProps>({
  form: 'case-import-from-url'
})(CaseImportFromUrlComponent);

type ImportFromUrlSectionProps = {
  props: ImportFromUrlFormState,
  dispatchers: ImportFromUrlFormDispatchers
};

export const ImportFromUrlFormSection: React.FC<ImportFromUrlSectionProps> = ({ props, dispatchers }) => (
  <ImportFromUrlForm {...props} {...dispatchers} />
);
