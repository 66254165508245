import { createManager } from '../lib/entity-manager';
import { FieldTypes } from '../lib/entity-manager/forms/shared';
import { CuratorApi } from '../services/CuratorApi';
import { CaseSubjectEntity, CaseSubjectFormData } from '../types/cases/case-subject';
import { namedEntityFormatter } from '../redux/modules/case-manager/utils';

const getEntities = (api: CuratorApi, filter: Partial<CaseSubjectEntity>, page: number, perPage: number) => {
  return api.searchCaseSubjects({
    filter,
    pagination: {
      page,
      perPage
    }
  });
};

const createEntity = (api: CuratorApi, guid: string, data: CaseSubjectFormData) => {
  return api.createCaseSubject({ guid, data });
}

const checkDeleteEligibility = (api: CuratorApi, id: string) => {
  return api.getCaseQueryEntityDeleteEligibility('case-subject', id);
}

const updateEntity = (api: CuratorApi, guid:string, id: string, data: CaseSubjectFormData) => {
  return api.updateCaseSubject({ guid, id, data });
}

const transformCaseSubjectEntityToFormData = (entity: CaseSubjectEntity): CaseSubjectFormData => {
  return {
    name: entity.name || '',
    name_fr: entity.name_fr || ''
  };
};

const getEntity = (api: CuratorApi, id: string) => {
  return api.getCaseSubject(id)
    .then((result) => result.data)
};

const deleteEntity = (api: CuratorApi, guid: string, id: string) => {
  return api.deleteCaseSubject({ guid, id });
};

const generateDefaultCaseSubjectFilterData = () => ({
  name: '',
  name_fr: ''
});

const generateDefaultCaseSubjectFormData = () => ({
  name: '',
  name_fr: ''
});

export const caseSubjectManager = createManager<CuratorApi, CaseSubjectFormData, CaseSubjectEntity>({
  key: 'case-subject',
  basePath: 'case-subject-manager',
  displayName: {
    singular: 'Case Subject',
    plural: 'Case Subjects'
  },
  generateDefaultFilterData: generateDefaultCaseSubjectFilterData,
  generateDefaultData: generateDefaultCaseSubjectFormData,
  data: {
    fetch: getEntity,
    create: createEntity,
    update: updateEntity,
    all: getEntities,
    delete: deleteEntity,
    deleteEligibility: checkDeleteEligibility,
    entityDisplayName: namedEntityFormatter,
    entityDataToFormDataTransform: transformCaseSubjectEntityToFormData
  },
  listColumns: [
    {
      id: 'name',
      label: 'Name',
      getDisplayValue: (entity) => entity.name ?? ''
    }
  ],
  fields: {
    name: {
      label: 'Name',
      type: FieldTypes.Text
    },
    name_fr: {
      label: 'Name (FR)',
      type: FieldTypes.Text
    }
  },
  filters: {}
});
