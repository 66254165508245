import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import { PAGINATION_DEFAULT } from '../../../constants';
import { PaginationMeta } from '../../util/fetch';
import { RootAction } from '../actions';
import { ManagerDeleteState } from '../types';
import { StateEntity } from '../../../types/cases/state';

import {
  fetchDependentStateData,
  loadStateForDelete,
  loadStateForEdit,
  startNewStateOperation,
  statesFetchActions,
  statesPaginatedFetchActions
} from './actions';

export type StateManagerEntitiesState = {
  entities: StateEntity[],
  paginated: {
    entities: StateEntity[],
    pagination: PaginationMeta,
  }
};

export type StateManagerDependentDataState = {
  loading: boolean,
};

export const statesManagerEntitiesInitialState: StateManagerEntitiesState = {
  entities: [],
  paginated: {
    entities: [],
    pagination: PAGINATION_DEFAULT
  }
};

export const statesManagerEntitiesReducer = (state: StateManagerEntitiesState = statesManagerEntitiesInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(statesFetchActions.success): {
      return {
        ...state,
        entities: action.payload.data
      };
    }

    case getType(statesFetchActions.failure): {
      return {
        ...state,
        entities: []
      };
    }

    case getType(statesPaginatedFetchActions.success): {
      return {
        ...state,
        paginated: {
          entities: action.payload.data,
          pagination: action.payload.pagination
        }
      };
    }

    case getType(statesPaginatedFetchActions.request): {
      return {
        ...state
      };
    }

    case getType(statesPaginatedFetchActions.failure): {
      return {
        ...state,
        paginated: {
          entities: [],
          pagination: PAGINATION_DEFAULT
        }
      };
    }

    default: {
      return state;
    }
  }
};

export type StateManagerNewState = {
  operationGuid: string | null,
};

export const stateManagerNewInitialState = {
  operationGuid: null
};

export const stateManagerNewReducer = (state: StateManagerNewState = stateManagerNewInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(startNewStateOperation): {
      return {
        ...state,
        operationGuid: uuidv4()
      };
    }

    default: {
      return state;
    }
  }
};

export type StateManagerEditState = {
  id: string | null,
  data: StateEntity | null,
  operationGuid: string | null,
};

const stateManagerEditInitialState = {
  id: null,
  data: null,
  operationGuid: null
};

export const stateManagerEditReducer = (state: StateManagerEditState = stateManagerEditInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(loadStateForEdit.request): {
      return {
        ...state,
        id: action.payload,
        operationGuid: null
      };
    }
    case getType(loadStateForEdit.success): {
      return {
        ...state,
        data: action.payload,
        operationGuid: uuidv4()
      };
    }
    case getType(loadStateForEdit.failure): {
      return {
        ...state,
        data: null
      };
    }
    default: {
      return state;
    }
  }
};

const stateManagerDeleteInitialState: ManagerDeleteState<StateEntity> = {
  loading: false
};

export const stateManagerDeleteReducer = (state: ManagerDeleteState<StateEntity> = stateManagerDeleteInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(loadStateForDelete.request): {
      return {
        loading: true
      };
    }
    case getType(loadStateForDelete.success): {
      return {
        ...state,
        loading: false,
        operationGuid: uuidv4(),
        ...action.payload
      };
    }
    case getType(loadStateForDelete.failure): {
      return {
        ...state,
        loading: false,
        info: {
          canDelete: false,
          usage: 0
        }
      };
    }
    default: {
      return state;
    }
  }
};

export const stateManagerDependentDataInitialState = {
  loading: false
};

export const stateManagerDependentDataReducer = (state: StateManagerDependentDataState = stateManagerDependentDataInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(fetchDependentStateData.request): {
      return {
        ...state,
        loading: true
      };
    }
    case getType(fetchDependentStateData.success): {
      return {
        ...state,
        loading: false
      };
    }
    case getType(fetchDependentStateData.failure): {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export const statesManagerInitialState = {
  entities: statesManagerEntitiesInitialState,
  dependentData: stateManagerDependentDataInitialState,
  edit: stateManagerEditInitialState,
  new: stateManagerNewInitialState,
  delete: stateManagerDeleteInitialState
};

export type StatesManagerState = {
  entities: StateManagerEntitiesState,
  dependentData: StateManagerDependentDataState,
  edit: StateManagerEditState,
  new: StateManagerNewState,
  delete: ManagerDeleteState<StateEntity>
};

export const statesManagerReducer = combineReducers<StatesManagerState>({
  entities: statesManagerEntitiesReducer,
  dependentData: stateManagerDependentDataReducer,
  edit: stateManagerEditReducer,
  new: stateManagerNewReducer,
  delete: stateManagerDeleteReducer
});
