import React from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Grid, Stack } from '@mui/material';
import { Link } from 'react-router-dom';

import { useFormContext } from '../hooks/useFormContext';
import { ButtonComponent } from '../../../components/ui/Button';

import { FormGenerator } from './form-generator';

type ActionTypes = 'create' | 'edit';

interface FormContainerProps {
  type: ActionTypes;
  onSubmit: (values: unknown) => void;
}

function getActionString(type: ActionTypes) {
  if (type === 'edit') {
    return 'Update';
  }
  return 'Create';
}


export function EntityFormContainer(props: FormContainerProps) {
  const { onSubmit, type } = props;

  const { defaultValues, fieldConfigs } = useFormContext();

  const { control, handleSubmit } = useForm({ defaultValues });

  const onValidCallback = (values: unknown) => onSubmit(values);

  const wrappedHandleSubmit = handleSubmit(onValidCallback);

  return (
    <Box component="form" onSubmit={wrappedHandleSubmit} sx={{ flexGrow: 1 }}>
      <Grid container={true} spacing={3} direction="column">
        <Grid item={true}>
          <FormGenerator
            fields={fieldConfigs}
            control={control}
          />
        </Grid>
        <Grid item={true}>
          <Stack direction="row" spacing={2}>
            <ButtonComponent variant="outlined" type="submit">{getActionString(type)}</ButtonComponent>
            <Button component={Link} to="..">Cancel</Button>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

