import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import { PAGINATION_DEFAULT } from '../../../constants';
import { DeleteEligibilityResponse } from '../../../types/command-types';
import { PaginationMeta } from '../../util/fetch';
import { RootAction } from '../actions';
import { ManagerDeleteState } from '../types';
import { CourtEntity } from '../../../types/cases/court';

import {
  courtsFetchActions,
  courtsPaginatedFetchActions,
  fetchDependentCourtData,
  loadCourtForDelete,
  loadCourtForEdit,
  startNewOperation
} from './actions';

export type CourtManagerEntitiesState = {
  entities: CourtEntity[],
  paginated: {
    entities: CourtEntity[],
    pagination: PaginationMeta,
  }
};

export type CourtManagerDependentDataState = {
  loading: boolean,
};

export const courtsManagerEntitiesInitialState: CourtManagerEntitiesState = {
  entities: [],
  paginated: {
    entities: [],
    pagination: PAGINATION_DEFAULT
  }
};

export const courtsManagerEntitiesReducer = (state: CourtManagerEntitiesState = courtsManagerEntitiesInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(courtsFetchActions.success): {
      return {
        ...state,
        entities: action.payload.data
      };
    }

    case getType(courtsFetchActions.failure): {
      return {
        ...state,
        entities: []
      };
    }

    case getType(courtsPaginatedFetchActions.success): {
      return {
        ...state,
        paginated: {
          entities: action.payload.data,
          pagination: action.payload.pagination
        }
      };
    }

    case getType(courtsPaginatedFetchActions.request): {
      return {
        ...state
      };
    }

    case getType(courtsPaginatedFetchActions.failure): {
      return {
        ...state,
        paginated: {
          entities: [],
          pagination: PAGINATION_DEFAULT
        }
      };
    }

    default: {
      return state;
    }
  }
};

export type CourtManagerNewState = {
  operationGuid: string | null,
};

export const courtManagerNewInitialState = {
  operationGuid: null
};

export const courtManagerNewReducer = (state: CourtManagerNewState = courtManagerNewInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(startNewOperation): {
      return {
        ...state,
        operationGuid: uuidv4()
      };
    }

    default: {
      return state;
    }
  }
};

export type CourtManagerEditState = {
  id: string | null,
  data: CourtEntity | null,
  operationGuid: string | null,
};

const courtManagerEditInitialState = {
  id: null,
  data: null,
  operationGuid: null
};

export const courtManagerEditReducer = (state: CourtManagerEditState = courtManagerEditInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(loadCourtForEdit.request): {
      return {
        ...state,
        id: action.payload,
        operationGuid: null
      };
    }
    case getType(loadCourtForEdit.success): {
      return {
        ...state,
        data: action.payload,
        operationGuid: uuidv4()
      };
    }
    case getType(loadCourtForEdit.failure): {
      return {
        ...state,
        data: null
      };
    }
    default: {
      return state;
    }
  }
};

export type CourtManagerDeleteState = {
  id: string | null,
  operationGuid: string | null,
  loading: boolean,
  data: CourtEntity | null,
  info: DeleteEligibilityResponse
};

const courtManagerDeleteInitialState: ManagerDeleteState<CourtEntity> = {
  loading: false
};

export const courtManagerDeleteReducer = (state: ManagerDeleteState<CourtEntity> = courtManagerDeleteInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(loadCourtForDelete.request): {
      return {
        loading: true
      };
    }
    case getType(loadCourtForDelete.success): {
      return {
        ...state,
        loading: false,
        operationGuid: uuidv4(),
        ...action.payload
      };
    }
    case getType(loadCourtForDelete.failure): {
      return {
        ...state,
        loading: false,
        info: {
          canDelete: false,
          usage: 0
        }
      };
    }
    default: {
      return state;
    }
  }
};

export const courtManagerDependentDataInitialState = {
  loading: false
};

export const courtManagerDependentDataReducer = (state: CourtManagerDependentDataState = courtManagerDependentDataInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(fetchDependentCourtData.request): {
      return {
        ...state,
        loading: true
      };
    }
    case getType(fetchDependentCourtData.success): {
      return {
        ...state,
        loading: false
      };
    }
    case getType(fetchDependentCourtData.failure): {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export const courtsManagerInitialState = {
  entities: courtsManagerEntitiesInitialState,
  dependentData: courtManagerDependentDataInitialState,
  edit: courtManagerEditInitialState,
  new: courtManagerNewInitialState,
  delete: courtManagerDeleteInitialState
};

export type CourtsManagerState = {
  entities: CourtManagerEntitiesState,
  dependentData: CourtManagerDependentDataState,
  edit: CourtManagerEditState,
  new: CourtManagerNewState,
  delete: ManagerDeleteState<CourtEntity>
};

export const courtsManagerReducer = combineReducers<CourtsManagerState>({
  entities: courtsManagerEntitiesReducer,
  dependentData: courtManagerDependentDataReducer,
  edit: courtManagerEditReducer,
  new: courtManagerNewReducer,
  delete: courtManagerDeleteReducer
});
