import * as R from 'ramda';
import { createSelector } from 'reselect';

import { StateTableData } from '../../../modules/state-manager/containers/StateListContainer';
import { SelectOption } from '../../../types/util';
import { RootState } from '../root';
import { StateEntity } from '../../../types/cases/state';

import { StateManagerEntitiesState, StatesManagerState } from './reducer';

export const selectStatesManagerState = (state: RootState): StatesManagerState => state.states;

export const selectStatesManagerEntitiesState = createSelector(
  [selectStatesManagerState],
  (statesManager: StatesManagerState) => statesManager.entities);

export const selectStatesManagerEntities = createSelector(
  [selectStatesManagerEntitiesState],
  (statesManagerEntityState: StateManagerEntitiesState) => statesManagerEntityState.entities
);

export const selectStatesManagerPaginatedEntitiesState = createSelector(
  [selectStatesManagerState],
  (statesManager) => statesManager.entities.paginated
);

export const selectStatesManagerPaginatedEntities = createSelector(
  [selectStatesManagerPaginatedEntitiesState],
  (statesPaginatedState) => statesPaginatedState.entities
);

export function formatStateLabel(state: StateEntity): string {
  return state.name;
}

function formatStatesToOptions(states: StateEntity[]): SelectOption[] {
  return states.map((state) => ({
    value: state.id.toString(),
    label: formatStateLabel(state)
  }));
}

export const stateOptionsSelector = createSelector(
  [selectStatesManagerEntities],
  formatStatesToOptions
);

export const selectStatesForTable = createSelector(
  [selectStatesManagerPaginatedEntities],
  (states: StateEntity[]): StateTableData[] => states.map(
    R.applySpec<StateTableData>({
      id: R.prop('id'),
      name: R.prop('name'),
      country: R.pathOr('', ['country', 'name'])
    })
  )
);

export const selectStatesPagination = createSelector(
  [selectStatesManagerPaginatedEntitiesState],
  (statesPaginationState) => statesPaginationState.pagination
);

export const selectStatesManagerDeleteState = createSelector(
  [selectStatesManagerState],
  (statesManager) => statesManager.delete
);

export const selectDeleteStateData = createSelector(
  [selectStatesManagerDeleteState],
  (deleteState) => deleteState.data
);

export const selectIsLoadingStateForDelete = createSelector(
  [selectStatesManagerDeleteState],
  (deleteState) => deleteState.loading
);

export const selectStateDeleteEligibility = createSelector(
  [selectStatesManagerDeleteState],
  (deleteState) => deleteState.info
);
