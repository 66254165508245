import * as R from 'ramda';

import { CourtFormOptions } from '../../../modules/court-manager/selectors';
import { transformSelectOptionToValue, transformValueToSelectOption } from '../../util/forms';
import { CourtData, CourtEntity, CourtFormData } from '../../../types/cases/court';

export const transformCourtToFormData = (stateData: CourtEntity, formOptions: CourtFormOptions): Partial<CourtFormData> => {
  return {
    name: stateData.name,
    name_fr: stateData.name_fr,
    state: transformValueToSelectOption(formOptions.stateOptions, stateData.state.id)
  };
};

export const transformCourtFormDataToCourtSubmission = (formData: Partial<CourtFormData>): CourtData => {
  return {
    name: R.trim(formData.name || ''),
    name_fr: R.trim(formData.name_fr || ''),
    state: transformSelectOptionToValue(formData.state)
  };
};
