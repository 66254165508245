import * as R from 'ramda';

export const isEmpty = R.anyPass([
  R.isNil,
  R.isEmpty
]);

export const lowerCaseAndRemoveChars = R.pipe(
  (val: string) => isEmpty(val) ? '' : val,
  R.toLower,
  R.replace(/[.]/, '')
);
