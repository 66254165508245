import { TextField as MuiTextField } from '@mui/material';
import * as React from 'react';

import { ITextFieldProps } from './types';

export const TextField: React.FC<ITextFieldProps> = ({ input, label, required, autoFocus = false, disabled = false, margin = 'normal', fullWidth = true }) => (
  <MuiTextField
    autoFocus={autoFocus}
    value={input.value || ''}
    label={label}
    margin={margin}
    disabled={disabled}
    required={required}
    onChange={(value: any) => input.onChange(value)}
    fullWidth={fullWidth}
  />
);
