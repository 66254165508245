import * as R from 'ramda';

import { lowerCaseAndRemoveChars } from '../../../lib/utils';
import { SelectOption } from '../../../types/util';
import {
  transformFormFlaggedAsToEntityFlaggedAs,
  transformLabelsToFormattedOptions,
  transformLabelToFormattedOption,
  transformSelectOptionsToValuesArray,
  transformSelectOptionToValue
} from '../../util/forms';
import { formatCaseSubjectLabel } from '../case-subjects/selectors';
import { formatCourtLabel } from '../courts/selectors';
import { RootState } from '../root';
import { formatStateLabel } from '../states/selectors';
import { CaseFilterFormData } from '../../../modules/case-manager/components/CaseFilterComponent';
import { CaseSearchQuery } from '../../../services/CuratorApi';
import { formatLegalDomainLabel } from '../legal-domains/selectors';
import { CourtEntity } from '../../../types/cases/court';
import { CaseSubjectEntity } from '../../../types/cases/case-subject';
import { StateEntity } from '../../../types/cases/state';
import { CaseEntity, CaseFormData, CreateCaseData, ImportedCase, RawCaseEntity } from '../../../types/cases/case';
import { RepresentativeEntity } from '../../../types/cases/representative';
import { JudgeEntity } from '../../../types/cases/judge';
import { FirmEntity } from '../../../types/cases/firm';
import { LegalDomainEntity } from '../../../types/cases/legal-domain';
import { SubjectAreaEntity } from '../../../types/cases/subject-area';
import { IndustryGroupEntity } from '../../../types/cases/industry-group';
import { US_FEDERAL_CIRCUIT_VALUES } from '../../../types/cases/case-metadata';
import { CaseTypeEntity } from '../../../types/cases/case-type';
import { flaggedAsToFormData } from '../../../components/FlagComponent/utils';

import { selectEntitiesForImportMatching } from './selectors';
import { EntityLabelFormatter, EntityWithLabel, NamedEntity, PersonEntity } from './types';

export const enumToSelectOption = (values: string[], value: unknown): SelectOption | undefined => {
  if (typeof value === 'string' && values.includes(value)) {
    return {
      value,
      label: value
    }
  }
}

export const entityToSelectOption = <TEntity extends EntityWithLabel>(labelFormatter: EntityLabelFormatter<TEntity>, entity: TEntity | null | undefined): SelectOption | undefined =>
  (entity === null || entity === undefined) ? undefined : ({
    label: labelFormatter(entity),
    value: entity.id.toString()
  });

export const entitiesToSelectOptions = <TEntity extends EntityWithLabel>(labelFormatter: EntityLabelFormatter<TEntity>, values: TEntity[] | undefined): SelectOption[] =>
  Array.isArray(values) ? values.map((val) => entityToSelectOption<TEntity>(labelFormatter, val)).filter(Boolean) as SelectOption[] : [];

export const personEntityFormatter = (entity: PersonEntity) => `${entity.givenNames} ${entity.lastName}`;

export const namedEntityFormatter = (entity: NamedEntity) => entity.name;

export const transformRawCaseEntityToCaseEntity = (rawEntity: RawCaseEntity): CaseEntity => ({
  ...rawEntity,
  authoringJudge: rawEntity.authoringJudge?.id !== null ? rawEntity.authoringJudge : undefined
});

export const transformCaseEntityToCaseForm = (entity: CaseEntity): Partial<CaseFormData> => {
  return ({
    title: entity.title,
    title_fr: entity.title_fr,
    sourceUrl: entity.sourceUrl,
    sourceUrl_fr: entity.sourceUrl_fr,
    citation: entity.citation,
    fileNumbers: entity.fileNumbers,
    judgementDate: entity.judgementDate,
    caseSubjects: entitiesToSelectOptions<CaseSubjectEntity>(namedEntityFormatter, entity.caseSubjects),
    state: entityToSelectOption<StateEntity>(namedEntityFormatter, entity.state),
    court: entityToSelectOption<CourtEntity>(formatCourtLabel, entity.court),
    judges: entitiesToSelectOptions<JudgeEntity>(personEntityFormatter, entity.judges),
    authoringJudge: entityToSelectOption<JudgeEntity>(personEntityFormatter, entity.authoringJudge),
    legalDomains: entitiesToSelectOptions<LegalDomainEntity>(namedEntityFormatter, entity.legalDomains),
    plaintiffFirms: entitiesToSelectOptions<FirmEntity>(namedEntityFormatter, entity.plaintiffFirms),
    defendantFirms: entitiesToSelectOptions<FirmEntity>(namedEntityFormatter, entity.defendantFirms),
    plaintiffRepresentatives: entitiesToSelectOptions<RepresentativeEntity>(personEntityFormatter, entity.plaintiffRepresentatives),
    defendantRepresentatives: entitiesToSelectOptions<RepresentativeEntity>(personEntityFormatter, entity.defendantRepresentatives),
    descriptionBody: entity?.description?.body,
    descriptionBody_fr: entity?.description?.body_fr,
    caseBody: entity.caseBody.body,
    caseBody_fr: entity.caseBody.body_fr,
    meta_subjectAreas: entitiesToSelectOptions<SubjectAreaEntity>(namedEntityFormatter, entity.caseMetaData?.subjectAreas),
    meta_industryGroups: entitiesToSelectOptions<IndustryGroupEntity>(namedEntityFormatter, entity.caseMetaData?.industryGroups),
    meta_headlineBody: entity.caseMetaData?.headline?.body,
    meta_headlineBody_fr: entity.caseMetaData?.headline?.body_fr,
    meta_crossReferenceBody: entity.caseMetaData?.crossReference?.body,
    meta_crossReferenceBody_fr: entity.caseMetaData?.crossReference?.body_fr,
    meta_bodyUnavailable: entity.caseMetaData?.body_availability === 'restricted',
    meta_codeSection: entity.caseMetaData?.code_section,
    meta_sourceId: entity.caseMetaData?.source_id || '',
    meta_sourceType: entity.caseMetaData?.source_type || '',
    meta_us_federal_circuit: enumToSelectOption(US_FEDERAL_CIRCUIT_VALUES, entity.caseMetaData?.us_federal_circuit),
    meta_case_type: entityToSelectOption<CaseTypeEntity>(namedEntityFormatter, entity.caseMetaData?.case_type),
    meta_flaggedAs: flaggedAsToFormData(entity.caseMetaData?.flaggedAs)
  });
};

export function nonEmptyStringOrNull(str: string | undefined): string | null {
  return (typeof str === 'string' && str.trim()) || null
}

export const transformCaseFormDataToCaseSubmission = (formData: Partial<CaseFormData>): CreateCaseData => {
  return {
    caseBody: {
      body: formData.caseBody || '',
      body_fr: formData.caseBody_fr || ''
    },
    caseData: {
      citation: (formData.citation || '').trim(),
      fileNumbers: (formData.fileNumbers || []).map(x => x.trim()),
      judgementDate: (formData.judgementDate || '').trim(),
      title: (formData.title || '').trim(),
      title_fr: (formData.title_fr || '').trim(),
      sourceUrl: nonEmptyStringOrNull(formData.sourceUrl),
      sourceUrl_fr: nonEmptyStringOrNull(formData.sourceUrl_fr),
      court: transformSelectOptionToValue(formData.court),
      state: transformSelectOptionToValue(formData.state),
      caseSubjects: transformSelectOptionsToValuesArray(formData.caseSubjects),
      legalDomains: transformSelectOptionsToValuesArray(formData.legalDomains),
      judges: transformSelectOptionsToValuesArray(formData.judges),
      authoringJudge: transformSelectOptionToValue(formData.authoringJudge),
      plaintiffFirms: transformSelectOptionsToValuesArray(formData.plaintiffFirms),
      defendantFirms: transformSelectOptionsToValuesArray(formData.defendantFirms),
      plaintiffRepresentatives: transformSelectOptionsToValuesArray(formData.plaintiffRepresentatives),
      defendantRepresentatives: transformSelectOptionsToValuesArray(formData.defendantRepresentatives)
    },
    caseDescription: {
      body: formData.descriptionBody || '',
      body_fr: formData.descriptionBody_fr || ''
    },
    caseMetaData: {
      us_federal_circuit: nonEmptyStringOrNull(formData.meta_us_federal_circuit?.value),
      case_type: nonEmptyStringOrNull(transformSelectOptionToValue(formData.meta_case_type)),
      body_availability: formData.meta_bodyUnavailable ? 'restricted' : 'available',
      source_type: nonEmptyStringOrNull(formData.meta_sourceType),
      source_id: nonEmptyStringOrNull(formData.meta_sourceId),
      code_section: (formData.meta_codeSection || []).map(x => x.trim()),
      subjectAreas: transformSelectOptionsToValuesArray(formData.meta_subjectAreas),
      industryGroups: transformSelectOptionsToValuesArray(formData.meta_industryGroups),
      headline: {
        body: formData.meta_headlineBody || '',
        body_fr: formData.meta_headlineBody_fr || ''
      },
      crossReference: {
        body: formData.meta_crossReferenceBody || '',
        body_fr: formData.meta_crossReferenceBody_fr || ''
      },
      flaggedAs: transformFormFlaggedAsToEntityFlaggedAs(formData.meta_flaggedAs)
    }
  };
};

export const transformImportedLabelsToKnownEntityIds = (state: RootState, caseData: ImportedCase): Partial<CaseFormData> => {
  const entities = selectEntitiesForImportMatching(state);
  return {
    title: caseData.title,
    fileNumbers: caseData.fileNumbers,
    judgementDate: caseData.judgementDate,
    caseBody: caseData.caseBody,
    sourceUrl: caseData.sourceUrl,
    citation: caseData.citation,
    legalDomains: transformLabelsToFormattedOptions<LegalDomainEntity>(namedEntityFormatter, formatLegalDomainLabel, entities.legalDomains, caseData.legalDomains),
    court: transformLabelToFormattedOption<CourtEntity>(namedEntityFormatter, formatCourtLabel, entities.courts, caseData.court),
    state: transformLabelToFormattedOption<StateEntity>(namedEntityFormatter, formatStateLabel, entities.states, caseData.state),
    caseSubjects: transformLabelsToFormattedOptions<CaseSubjectEntity>(namedEntityFormatter, formatCaseSubjectLabel, entities.caseSubjects, caseData.subjects)
  };
};

export const transformOptionLabelsForMatching = (options: SelectOption[]) => options.map(
  R.over(R.lensProp('label'), lowerCaseAndRemoveChars)
);

export function transformCaseFilterFormDataToQuery(formData: Partial<CaseFilterFormData>): CaseSearchQuery {
  return {
    title: formData.title || '',
    citation: formData.citation || '',
    courtIds: formData.court ? [formData.court.value] : undefined,
    legalDomains: formData.domain ? [formData.domain.value] : undefined,
    countryIds: formData.country ? [formData.country.value] : undefined,
    date_from: formData.date_from ? formData.date_from : undefined,
    date_to: formData.date_to ? formData.date_to : undefined,
    firmIds: formData.firmIds ? R.pluck('value', formData.firmIds) : undefined,
    representativeIds: formData.representativeIds ? R.pluck('value', formData.representativeIds) : undefined,
    judgeIds: formData.judgeIds ? R.pluck('value', formData.judgeIds) : undefined,
    caseSubjectIds: formData.caseSubjectIds ? R.pluck('value', formData.caseSubjectIds) : undefined,
    bodyAvailability: formData.bodyAvailability?.value,
    flaggedAs: formData.flaggedAs?.value
  };
}
