import { Autocomplete, FormControl, TextField } from '@mui/material';
import * as React from 'react';

import { ITextFieldProps } from './types';

export const MultiValueTextField: React.FC<ITextFieldProps> = ({ input, label, disabled, margin = 'normal' }) => (
  <FormControl margin={margin}>
    <Autocomplete
      multiple
      value={input.value || []}
      disabled={disabled}
      disableClearable
      freeSolo
      renderInput={(params) => <TextField {...params} label={label} />}
      options={[]}
      onChange={(_, chips) => {
        input.onChange(chips);
      }}
    />
  </FormControl>
);
