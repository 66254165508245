import { createAsyncAction, createAction } from 'typesafe-actions';

import { ICuratorError } from '../../../lib/errors/types';
import { LoadEntityForDeleteResponse } from '../../../types/command-types';
import { FormSubmissionMeta } from '../../../types/forms';
import {
  PageNumberType,
  PageSizeType,
  PaginatedQuery,
  PaginationConfig, WithFetchMeta,
  WithPaginatedFetchMeta
} from '../../util/fetch';
import { CaseSubjectData, CaseSubjectEntity, CaseSubjectFormData } from '../../../types/cases/case-subject';

export const caseSubjectsPaginatedFetchActions = createAsyncAction(
  '@@CURATOR/CASE_SUBJECTS/PAGINATED/REQUEST',
  '@@CURATOR/CASE_SUBJECTS/PAGINATED/SUCCESS',
  '@@CURATOR/CASE_SUBJECTS/PAGINATED/FAILURE'
)<PaginatedQuery<CaseSubjectEntity>, WithPaginatedFetchMeta<CaseSubjectEntity>, ICuratorError>();

export const caseSubjectsFetchActions = createAsyncAction(
  '@@CURATOR/CASE_SUBJECTS/REQUEST',
  '@@CURATOR/CASE_SUBJECTS/SUCCESS',
  '@@CURATOR/CASE_SUBJECTS/FAILURE'
)<void, WithFetchMeta<CaseSubjectEntity[]>, ICuratorError>();

export const refreshCaseSubjectsList = createAction('@@CURATOR/CASE_SUBJECTS_MANAGER/REFRESH_LIST')<void>();

export const updateCaseSubjectsList = createAction('@@CURATOR/CASE_SUBJECTS_MANAGER/UPDATE_LIST')<PaginationConfig>();

export const changeCaseSubjectsListPage = createAction('@@CURATOR/CASE_SUBJECTS_MANAGER/CHANGE_PAGE')<PageNumberType>();

export const changeCaseSubjectsListPageSize = createAction('@@CURATOR/CASE_SUBJECTS_MANAGER/CHANGE_PAGE_SIZE')<PageSizeType>();

export const startNewCaseSubjectOperation = createAction('@@CURATOR/CASE_SUBJECTS_MANAGER/START_NEW_CASE_SUBJECT_OPERATION')<void>();

export const submitCaseSubjectCreateForm = createAction('@@CURATOR/CASE_SUBJECTS_MANAGER/SUBMIT_NEW_CASE_SUBJECT')<Partial<CaseSubjectFormData>, FormSubmissionMeta>();

export const submitCaseSubjectCreateFormCompleted = createAction('@@CURATOR/CASE_SUBJECTS_MANAGER/SUBMIT_NEW_CASE_SUBJECT_COMPLETED')<void>();

export const submitCaseSubjectCreateFormFailed = createAction('@@CURATOR/CASE_SUBJECTS_MANAGER/SUBMIT_NEW_CASE_SUBJECT_FAILED')<ICuratorError>();

export const submitCaseSubjectEditForm = createAction('@@CURATOR/CASE_SUBJECTS_MANAGER/SUBMIT_EDIT_CASE_SUBJECT')<Partial<CaseSubjectFormData>, FormSubmissionMeta>();

export const submitCaseSubjectEditFormCompleted = createAction('@@CURATOR/CASE_SUBJECTS_MANAGER/SUBMIT_EDIT_CASE_SUBJECT_COMPLETED')<void>();

export const submitCaseSubjectEditFormFailed = createAction('@@CURATOR/CASE_SUBJECTS_MANAGER/SUBMIT_EDIT_CASE_SUBJECT_FAILURE')<ICuratorError>();

export const loadCaseSubjectForEdit = createAsyncAction(
  '@@CURATOR/CASE_SUBJECTS_MANAGER/LOAD_CASE_SUBJECT_FOR_EDIT',
  '@@CURATOR/CASE_SUBJECTS_MANAGER/LOAD_CASE_SUBJECT_FOR_EDIT_SUCCESS',
  '@@CURATOR/CASE_SUBJECTS_MANAGER/LOAD_CASE_SUBJECT_FOR_EDIT_FAILURE'
)<string, CaseSubjectData, ICuratorError>();

export const loadCaseSubjectForDelete = createAsyncAction(
  '@@CURATOR/CASE_SUBJECTS_MANAGER/LOAD_CASE_SUBJECT_FOR_DELETE',
  '@@CURATOR/CASE_SUBJECTS_MANAGER/LOAD_CASE_SUBJECT_FOR_DELETE_SUCCESS',
  '@@CURATOR/CASE_SUBJECTS_MANAGER/LOAD_CASE_SUBJECT_FOR_DELETE_FAILURE'
)<string, LoadEntityForDeleteResponse<CaseSubjectEntity>, ICuratorError>();

export const deleteCaseSubject = createAsyncAction(
  '@@CURATOR/CASE_SUBJECTS_MANAGER/DELETE_CASE_SUBJECT',
  '@@CURATOR/CASE_SUBJECTS_MANAGER/DELETE_CASE_SUBJECT_SUCCESS',
  '@@CURATOR/CASE_SUBJECTS_MANAGER/DELETE_CASE_SUBJECT_FAILURE')<CaseSubjectEntity['id'], void, ICuratorError>();

export type SetCaseSubjectPayload = {
  formId: string,
  data: CaseSubjectData
};

export const setFormDataFromCaseSubject = createAction('@@CURATOR/CASE_SUBJECTS_MANAGER/SET_FORM_DATA_FROM_CASE_SUBJECT')<SetCaseSubjectPayload>();
