import { DatePicker } from '@mui/lab';
import { FormControl, TextField } from '@mui/material';
import { parseISO, addMinutes, subMinutes, isValid } from 'date-fns';
import * as React from 'react';

import { IDatePickerFieldProps } from './types';

function utcStringToZonedNoTime(dateString: string): Date {
  const date = parseISO(dateString);
  if (!isValid(date)) {
    return date;
  }
  return addMinutes(date, date.getTimezoneOffset());
}

function zonedNoTimeToUTCString(zonedDate: Date): string {
  return subMinutes(zonedDate, zonedDate.getTimezoneOffset()).toISOString();
}

function formatDateValue(date: Date | null): string | Date | null {
  if (!(date instanceof Date)) {
    return null;
  } else {
    return isValid(date) ? zonedNoTimeToUTCString(date) : date;
  }
}

export const DatePickerField: React.FC<IDatePickerFieldProps> = ({ input, label, disabled, margin = 'normal', fullWidth = true }) => (
  <FormControl margin={margin} fullWidth={fullWidth}>
    <DatePicker
      renderInput={(props) => <TextField {...props} />}
      mask="____/__/__"
      inputFormat={'yyyy/MM/dd'}
      onChange={(date: Date | null) => input.onChange(formatDateValue(date))}
      value={input.value ? utcStringToZonedNoTime(input.value) : null}
      label={label}
      disabled={!!disabled}
    />
  </FormControl>
);
