import { Button } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';

interface ButtonProps {
  children?: any;
  onClick?: () => void;
  to?: string;
  disabled?: boolean;
  variant?: 'contained' | 'outlined' | 'text';
  type?: 'submit' | 'reset' | 'button';
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
}

export const ButtonComponent = (props: ButtonProps) => {
  const {
    disabled = false,
    color = 'primary',
    variant = 'text',
    type,
    onClick
  } = props;

  const commonProps = {
    disabled,
    color,
    variant,
    type,
    onClick
  }

  return (
    props.to ? (
        <Button component={Link} to={props.to} {...commonProps}>
          {props.children}
        </Button>
      ) :
      (
        <Button {...commonProps}>
          {props.children}
        </Button>
      )
  );
};
