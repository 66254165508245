import { Epic } from 'redux-observable';
import { filter, first, ignoreElements, map } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { getAppConfig } from '../../../config';
import { AUTH_LOCAL_STORAGE_KEY } from '../../../constants';
import { ErrorType } from '../../../lib/errors/types';
import { Services } from '../../../services';
import { failActions, RootAction } from '../actions';
import { RootState } from '../root';
import { getCuratorLoginUrl, getCuratorLogoutUrl } from '../../../config/util/api-url';

import { logout, setAuthData } from './actions';

type AuthEpic = Epic<RootAction, RootAction, RootState, Services>;

const unauthorizedErrorEpic: AuthEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(failActions)),
    first((action) => action.payload.type === ErrorType.Unauthorized),
    map(() => logout(false))
  );

const setAuthDataEpic: AuthEpic = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(setAuthData)),
    map((action) => {
      window.localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, action.payload.token);
      curatorApi.setToken(action.payload.token);
    }),
    ignoreElements()
  );

const logoutEpic: AuthEpic = (action$) =>
  action$.pipe(
    filter(isActionOf(logout)),
    map(({ payload: goToLogout }) => {
      const hasToken = !!window.localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
      if (hasToken) {
        window.localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
        window.location.replace(getAppConfig().authLocation);
        return;
      }
      // api session auth
      if (goToLogout) {
        window.location.replace(getCuratorLogoutUrl(window));
      } else {
        window.location.replace(getCuratorLoginUrl(window));
      }
    }),
    ignoreElements()
  );

export const authEpics = [
  unauthorizedErrorEpic,
  setAuthDataEpic,
  logoutEpic
]
