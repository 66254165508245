import React from 'react';

import { CuratorApiAbstract } from '../../../services/CuratorApiAbstract';
import { TestCuratorApi } from '../../../services/TestCuratorApi';

export type CuratorApiContextValue<TCuratorApi extends CuratorApiAbstract = any> = {
  curatorApi: TCuratorApi
}

export const CuratorApiContext = React.createContext<CuratorApiContextValue | null>(null);

export const CuratorApiContextProvider = CuratorApiContext.Provider;

CuratorApiContext.displayName = 'CuratorApiContext';


export function createExampleCuratorContextValue(): CuratorApiContextValue {
  return {
    curatorApi: new TestCuratorApi()
  }
}
