import { createEntitySelector } from '../../../entity-selector';
import { namedEntityFormatter } from '../../../../redux/modules/case-manager/utils';
import { CuratorApi } from '../../../../services/CuratorApi';

function fetchOptions(api: CuratorApi) {
  return api.getAllSubjectAreas({}).then((result) =>
    result.data.map((firm) => ({
      label: namedEntityFormatter(firm),
      value: firm.id
    })));
}

export const SubjectAreaField = createEntitySelector({
  fetchOptions,
  pluralEntityName: 'subject areas',
  key: 'subject-area'
});
