import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useCallback, useEffect, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';

import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { IconButtonComponent } from '../../../components/ui/IconButton';
import { PaperWrapper } from '../../../components/ui/PaperContainer';
import {
  loadCaseForEdit,
  submitCaseEditForm,
  submitImportFromUrlForm,
  toggleIsImportUrlOpen
} from '../../../redux/modules/case-manager/actions';
import { selectCaseManagerImportOpen, selectImportDetails } from '../../../redux/modules/case-manager/selectors';
import { CaseFormComponent } from '../components/CaseFormComponent';
import { CaseImportInfoPanelComponent } from '../components/CaseImportInfoPanel';
import { ImportFromUrlFormSection } from '../components/ImportFromUrlFormSection';
import {
  CaseFormProps,
  createCaseFormPropsSelector,
  createCaseFormDispatchers,
  importFormPropsSelectors,
  importFromUrlFormDispatchers
} from '../selectors';
import { createGenericFormComponent } from '../../../components/form/createGenericFormComponent';
import { ImportIcon } from '../../../components/ui/mui-icons';
import { CaseFormData } from '../../../types/cases/case';

const FORM_ID = 'case-edit';

const caseFormPropsSelector = createCaseFormPropsSelector(FORM_ID);

const EditCaseForm = createGenericFormComponent<CaseFormData, CaseFormProps>(FORM_ID, CaseFormComponent);

export const EditCase: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<'id'>();
  useEffect(() => {
    id && dispatch(loadCaseForEdit.request(id));
  }, [dispatch]);

  const toggleIsImportOpen = useCallback(() => dispatch(toggleIsImportUrlOpen()), []);
  const importFormDispatchers = useMemo(() => importFromUrlFormDispatchers(dispatch, submitImportFromUrlForm, FORM_ID), []);
  const formDispatchers = useMemo(() => createCaseFormDispatchers(dispatch, submitCaseEditForm, FORM_ID), []);

  const isImportOpen = useSelector(selectCaseManagerImportOpen);
  const importFormProps = useSelector(importFormPropsSelectors);
  const importData = useSelector(selectImportDetails);
  const formProps = useSelector(caseFormPropsSelector);

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12}>
        <Grid container={true}>
          <Grid item={true} xs={6} container={true}>
            <Grid item={true}>
              <Typography component="h2" variant="h5">
                <BreadcrumbLink to={'/case-manager'}>Cases</BreadcrumbLink> / Edit Case <small>({id})</small>
              </Typography>
            </Grid>
          </Grid>
          <Grid item={true} xs={6} container={true} justifyContent="flex-end">
            <IconButtonComponent
              icon={ImportIcon}
              onClick={toggleIsImportOpen}
            >
              Import from URL
            </IconButtonComponent>
          </Grid>
        </Grid>
      </Grid>
      <Grid container={true}>
        <Grid item={true} xs={12}>
          {
            isImportOpen &&
            (
              <PaperWrapper>
                <ImportFromUrlFormSection props={importFormProps}
                                          dispatchers={importFormDispatchers} />
              </PaperWrapper>
            )
          }
          {
            importData &&
            (
              <PaperWrapper>
                <CaseImportInfoPanelComponent importData={importData} />
              </PaperWrapper>
            )
          }
          <PaperWrapper>
            <EditCaseForm {...formProps} {...formDispatchers} />
          </PaperWrapper>
        </Grid>
      </Grid>
    </Grid>
  );
}
