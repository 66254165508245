import { createEntitySelector } from '../../../entity-selector';
import { namedEntityFormatter } from '../../../../redux/modules/case-manager/utils';
import { CuratorApi } from '../../../../services/CuratorApi';

function fetchOptions(api: CuratorApi) {
  return api.getAllCaseSubjects({}).then((result) =>
    result.data.map((entity) => ({
      label: namedEntityFormatter(entity),
      value: entity.id
    })));
}

export const CaseSubjectField = createEntitySelector({
  fetchOptions,
  key: 'casesubject',
  pluralEntityName: 'case subjects'
});
