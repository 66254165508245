import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';

import { ManagerContext } from '../contexts/manager-context';

import { useCuratorApiContext } from './useCuratorApiContext';
import { useEntityApiContext } from './useEntityApiContext';

export function useEntityDeleteEligibility(id: string) {
  const { dataKey } = useContext(ManagerContext);
  const { curatorApi } = useCuratorApiContext();
  const api = useEntityApiContext();

  const queryOptions = {
    cacheTime: 0,
    staleTime: 0
  };

  return useQuery(['delete-eligibility', dataKey, id], async () => {
    return api.deleteEligibility(curatorApi, id).then(result => result.data);
  }, queryOptions);
}
