import * as R from 'ramda';
import { createSelector } from 'reselect';

import { JudgeTableData } from '../../../modules/judge-manager/containers/JudgeListContainer';
import { RootState } from '../root';
import { JudgeEntity } from '../../../types/cases/judge';

import { JudgesManagerState } from './reducer';

export const selectJudgesManagerState = (state: RootState): JudgesManagerState => state.judges;

export const selectJudgesDependantData = createSelector(
  [selectJudgesManagerState],
  (judgesManager: JudgesManagerState) => judgesManager.dependentData
);

export const selectIsLoadingJudgesDependentData = createSelector(
  [selectJudgesDependantData],
  (dependantData) => dependantData.loading
);

export const selectJudgeEntitiesState = createSelector(
  [selectJudgesManagerState],
  (judgesManager: JudgesManagerState) => judgesManager.entities);

export const selectJudgesManagerPaginatedEntitiesState = createSelector(
  [selectJudgesManagerState],
  (judgesManager) => judgesManager.entities.paginated);

export const selectJudgesManagerPaginatedEntities = createSelector(
  [selectJudgesManagerPaginatedEntitiesState],
  (judgesManagerEntityState) => judgesManagerEntityState.entities
);

export function formatJudgeLabel(judge: JudgeEntity): string {
  return `${judge.givenNames} ${judge.lastName}`;
}

export const selectJudgesForTable = createSelector(
  [selectJudgesManagerPaginatedEntities],
  (judges: JudgeEntity[]): JudgeTableData[] => judges.map(
    R.applySpec<JudgeTableData>({
      id: R.prop('id'),
      givenNames: R.prop('givenNames'),
      lastName: R.prop('lastName'),
      country: R.pathOr('', ['country', 'name'])
    })
  )
);

export const selectJudgesPagination = createSelector(
  [selectJudgesManagerPaginatedEntitiesState],
  (paginatedEntitiesState) => paginatedEntitiesState.pagination
);

export const selectJudgeFilterFormData = createSelector(
  [selectJudgeEntitiesState],
  (judgesManager) => judgesManager.filter
);

export const selectJudgeFilterQuery = createSelector(
  [selectJudgeFilterFormData],
  (formData) => ({
    fullName: formData.fullName ? formData.fullName : undefined,
    country: formData.country ? formData.country.value : undefined
  })
);

export const selectJudgesManagerDeleteState = createSelector(
  [selectJudgesManagerState],
  (judgesManager) => judgesManager.delete
);

export const selectDeleteJudgeData = createSelector(
  [selectJudgesManagerDeleteState],
  (deleteState) => deleteState.data
);

export const selectIsLoadingJudgeForDelete = createSelector(
  [selectJudgesManagerDeleteState],
  (deleteState) => deleteState.loading
);

export const selectJudgeDeleteEligibility = createSelector(
  [selectJudgesManagerDeleteState],
  (deleteState) => deleteState.info
);
