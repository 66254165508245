import { createAsyncEntitySelector } from '../../../entity-selector';
import { personEntityFormatter } from '../../../../redux/modules/case-manager/utils';
import { CuratorApi } from '../../../../services/CuratorApi';

function search(api: CuratorApi, filter: string) {
  return api.searchRepresentatives(
    {
      filter: {
        fullName: filter
      },
      pagination: {
        page: 1,
        perPage: 20
      }
    }
  ).then((result) =>
    result.data.map((representative) => ({
      label: personEntityFormatter(representative),
      value: representative.id
    })));
}

export const RepresentativeField = createAsyncEntitySelector({
  search,
  pluralEntityName: 'representatives',
  key: 'representative'
});
