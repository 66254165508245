import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@mui/material';

import { DOCUMENT_MANAGER_ROUTE, NEW_DOCUMENT_FORM_ID } from '../types';
import { DocumentFormComponent } from '../components/DocumentFormComponent';
import { documentFormOptionsPropsSelector, isDocumentFormDisabledSelector } from '../selectors';
import {
  fetchDependentDocumentManagerData,
  startNewDocumentManagerOperation,
  submitDocumentCreateForm
} from '../../../redux/modules/document-manager/actions';
import { DocumentDependantDataRequestType } from '../../../redux/modules/document-manager/types';
import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { PaperWrapper } from '../../../components/ui/PaperContainer';
import { createGenericFormComponent } from '../../../components/form/createGenericFormComponent';
import { DocumentFormData } from '../../../types/documents/document';

const NewDocumentForm = createGenericFormComponent(NEW_DOCUMENT_FORM_ID, DocumentFormComponent);

export const NewDocumentContainer: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(startNewDocumentManagerOperation());
    dispatch(fetchDependentDocumentManagerData.request(DocumentDependantDataRequestType.EDIT))
  }, [dispatch]);

  const options = useSelector(documentFormOptionsPropsSelector);
  const isDisabled = useSelector(isDocumentFormDisabledSelector);
  const submitHandler = useCallback((values: DocumentFormData) => new Promise<void>((resolve, reject) => {
    dispatch(submitDocumentCreateForm(values, { resolve, reject }));
  }), []);

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12}>
        <Grid container={true}>
          <Grid item={true} xs={6} container={true}>
            <Grid item={true}>
              <Typography component="h2" variant="h5">
                <BreadcrumbLink to={DOCUMENT_MANAGER_ROUTE}>Documents</BreadcrumbLink> / Create Document
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <PaperWrapper>
            <NewDocumentForm
              {...options}
              isDisabled={isDisabled}
              submitHandler={submitHandler}
            />
          </PaperWrapper>
        </Grid>
      </Grid>
    </Grid>
  );
}
