import { AddCircleOutline } from '@mui/icons-material';
import { Button } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Field, InjectedFormProps } from 'redux-form';
import styled from 'styled-components';

import { Checkbox } from '../../../components/form/Checkbox';
import { DatePickerField } from '../../../components/form/DatePickerField';
import { MultiValueTextField } from '../../../components/form/MultiValueTextField';
import { SelectField } from '../../../components/form/SelectField';
import { TextField } from '../../../components/form/TextField';
import { TranslatedRichTextArea } from '../../../components/form/TranslatedRichTextArea';
import { TranslatedTextField } from '../../../components/form/TranslatedTextField';
import {
  ButtonRow,
  Column,
  DoubleColumn,
  FlexContainer,
  FullRowColumn,
  HalfWidthColumn,
  Row
} from '../../../components/ui/FlexTable';
import { IconComponent } from '../../../components/ui/Icon';
import { CaseManagerQuickAddForm } from '../../../redux/modules/case-manager/reducer';
import { FirmForm } from '../../../redux/modules/firms/types';
import { JudgeForm } from '../../../redux/modules/judges/types';
import { RepresentativeForm } from '../../../redux/modules/representatives/types';
import { NewFirmQuickAddContainer } from '../../firm-manager/containers/NewFirmQuickAddContainer';
import { NewJudgeQuickAddContainer } from '../../judge-manager/containers/NewJudgeQuickAddContainer';
import { NewRepresentativeQuickAdd } from '../../representative-manager/containers/NewRepresentativeQuickAdd';
import { CaseFormDispatchers, CaseFormState } from '../selectors';
import { createFormValueSelector } from '../../../redux/util/forms';
import { CaseFormData } from '../../../types/cases/case';
import { ReduxFormEntitySelectorField } from '../../../lib/entity-manager/forms/fields/redux-form/entity-selector';
import { FirmField } from '../../../lib/entity-manager/forms/fields/firm';
import { RepresentativeField } from '../../../lib/entity-manager/forms/fields/representative';
import { JudgeField } from '../../../lib/entity-manager/forms/fields/judge';
import { SubjectAreaField } from '../../../lib/entity-manager/forms/fields/subject-area';
import { IndustryGroupField } from '../../../lib/entity-manager/forms/fields/industry-group';
import { CourtField } from '../../../lib/entity-manager/forms/fields/court';
import { StateField } from '../../../lib/entity-manager/forms/fields/state';
import { LegalDomainField } from '../../../lib/entity-manager/forms/fields/legal-domain';
import { CaseSubjectField } from '../../../lib/entity-manager/forms/fields/case-subject';
import { CaseTypeField } from '../../../lib/entity-manager/forms/fields/case-type';
import { FlaggedAsListField } from '../../../components/FlagComponent/FlaggedAsListField';

type FormProps = CaseFormState & CaseFormDispatchers;

type ReduxFormInjectedProps = InjectedFormProps<CaseFormData, FormProps>;

type QuickAddFormComponentProps = {
  parentForm: string;
};

const renderQuickAddForm = (Component: React.ComponentType<QuickAddFormComponentProps>, isVisible: boolean | undefined, parentForm: string) =>
  isVisible && (
    <Row>
      <FullRowColumn>
        <Component parentForm={parentForm} />
      </FullRowColumn>
    </Row>
  );

const DefendantRepresentativeFormComponent = (props: QuickAddFormComponentProps) => (
  <NewRepresentativeQuickAdd
    contextTitle="Defendant"
    fieldName="defendantRepresentatives"
    parentForm={props.parentForm}
    form={RepresentativeForm.QUICK_ADD_DEFENDANT}
    quickAddFormId={CaseManagerQuickAddForm.DEFENDANT_REPRESENTATIVE} />
);

const PlaintiffRepresentativeFormComponent = (props: QuickAddFormComponentProps) => (
  <NewRepresentativeQuickAdd
    contextTitle="Plaintiff"
    fieldName="plaintiffRepresentatives"
    parentForm={props.parentForm}
    form={RepresentativeForm.QUICK_ADD_PLAINTIFF}
    quickAddFormId={CaseManagerQuickAddForm.PLAINTIFF_REPRESENTATIVE} />
);

const DefendantFirmFormComponent = (props: QuickAddFormComponentProps) => (
  <NewFirmQuickAddContainer
    contextTitle="Defendant"
    fieldName="defendantFirms"
    parentForm={props.parentForm}
    form={FirmForm.QUICK_ADD_DEFENDANT}
    quickAddFormId={CaseManagerQuickAddForm.DEFENDANT_FIRM} />
);

const PlaintiffFirmFormComponent = (props: QuickAddFormComponentProps) => (
  <NewFirmQuickAddContainer
    contextTitle="Plaintiff"
    fieldName="plaintiffFirms"
    parentForm={props.parentForm}
    form={FirmForm.QUICK_ADD_PLAINTIFF}
    quickAddFormId={CaseManagerQuickAddForm.PLAINTIFF_FIRM} />
);

const JudgeFormComponent = (props: QuickAddFormComponentProps) => (
  <NewJudgeQuickAddContainer fieldName="judges"
    parentForm={props.parentForm}
    form={JudgeForm.QUICK_ADD} />
);

export const CaseFormComponent: React.FC<ReduxFormInjectedProps & FormProps> = (
  {
    authoringJudgeOptions,
    usFederalCircuitOptions,
    error,
    submitForm,
    createToggleQuickAdd,
    quickAddVisibility,
    isDisabled,
    form
  }
) => {
  const bodyUnavailableSelector = React.useMemo(() => createFormValueSelector(form, 'meta_bodyUnavailable'), [form]);
  const bodyUnavailable = useSelector(bodyUnavailableSelector);

  return (
    <>
      <Row>
        <DoubleColumn>
          <TranslatedTextField name="title" label="Title" disabled={isDisabled} />
          <TranslatedTextField name="sourceUrl" label="Source URL" disabled={isDisabled} />
          <Field
            disabled={isDisabled}
            name="caseSubjects"
            label="Subject(s)"
            isMulti={true}
            formComponent={CaseSubjectField}
            component={ReduxFormEntitySelectorField}
          />
          <Field
            disabled={isDisabled}
            name="legalDomains"
            label="Legal Domain(s)"
            isMulti={true}
            formComponent={LegalDomainField}
            component={ReduxFormEntitySelectorField}
          />
          <Field
            disabled={isDisabled}
            name="meta_industryGroups"
            label="Industry Group(s)"
            isMulti={true}
            formComponent={IndustryGroupField}
            component={ReduxFormEntitySelectorField}
          />
          <Field
            disabled={isDisabled}
            name="meta_subjectAreas"
            label="Subject Area(s)"
            isMulti={true}
            formComponent={SubjectAreaField}
            component={ReduxFormEntitySelectorField}
          />
         <Field
            disabled={isDisabled}
            name="meta_case_type"
            label="Case Type"
            isMulti={false}
            formComponent={CaseTypeField}
            component={ReduxFormEntitySelectorField}
          />
          <FlaggedAsListField
            name="meta_flaggedAs"
          />
        </DoubleColumn>
        <Column>
          <Field
            disabled={isDisabled}
            name="judgementDate"
            label="Judgment Date"
            component={DatePickerField}
          />
          <Field
            disabled={isDisabled}
            name="citation"
            label="Citation"
            component={TextField}
          />
          <Field
            disabled={isDisabled}
            name="fileNumbers"
            label="File Numbers"
            component={MultiValueTextField}
          />
          <Field
            disabled={isDisabled}
            name="meta_codeSection"
            label="Code Section(s)"
            component={MultiValueTextField}
          />
          <Field
            disabled={isDisabled}
            label="Source Type"
            name="meta_sourceType"
            component={TextField}
            fullWidth={true}
          />
          <Field
            disabled={isDisabled}
            label="Source ID"
            name="meta_sourceId"
            component={TextField}
            fullWidth={true}
          />
          <Field
            disabled={isDisabled}
            name="meta_us_federal_circuit"
            label="US Federal Circuit"
            component={SelectField}
            isMulti={false}
            options={usFederalCircuitOptions}
          />
        </Column>
      </Row>
      <Row>
        <FullRowColumn>
          <Field
            disabled={isDisabled}
            name="state"
            label="State/Province"
            isMulti={false}
            formComponent={StateField}
            component={ReduxFormEntitySelectorField}
          />
          <Field
            disabled={isDisabled}
            name="court"
            label="Courts"
            isMulti={false}
            formComponent={CourtField}
            component={ReduxFormEntitySelectorField}
          />
        </FullRowColumn>
      </Row>
      <Row>
        <HalfWidthColumn flexDirection="column">
          <FlexContainer>
            <Field
              disabled={isDisabled}
              name="judges"
              label="Judges"
              isMulti={true}
              formComponent={JudgeField}
              component={ReduxFormEntitySelectorField}
            />
            <IconContainer>
              <IconComponent
                icon={AddCircleOutline}
                onClick={createToggleQuickAdd(CaseManagerQuickAddForm.JUDGE, 'judges')}
              />
            </IconContainer>
          </FlexContainer>
          <FlexContainer>
            {renderQuickAddForm(JudgeFormComponent, quickAddVisibility[CaseManagerQuickAddForm.JUDGE], form)}
          </FlexContainer>
        </HalfWidthColumn>
        <HalfWidthColumn>
          <Field
            disabled={isDisabled}
            name="authoringJudge"
            label="Authoring Judge"
            component={SelectField}
            options={authoringJudgeOptions}
            clearValueIfOptionRemoved={true}
            isMulti={false}
          />
        </HalfWidthColumn>
      </Row>
      <Row>
        <HalfWidthColumn flexDirection="column">
          <FlexContainer>
            <Field
              disabled={isDisabled}
              name="plaintiffFirms"
              label="Plaintiff/Moving Party Firm(s)"
              isMulti={true}
              formComponent={FirmField}
              component={ReduxFormEntitySelectorField}
            />
            <IconContainer>
              <IconComponent
                icon={AddCircleOutline}
                onClick={createToggleQuickAdd(CaseManagerQuickAddForm.PLAINTIFF_FIRM, 'plaintiffFirms')}
              />
            </IconContainer>
          </FlexContainer>
          <FlexContainer>
            {renderQuickAddForm(PlaintiffFirmFormComponent, quickAddVisibility[CaseManagerQuickAddForm.PLAINTIFF_FIRM], form)}
          </FlexContainer>
        </HalfWidthColumn>
        <HalfWidthColumn flexDirection="column">
          <FlexContainer>
            <Field
              disabled={isDisabled}
              name="plaintiffRepresentatives"
              label="Plaintiff/Moving Party Representative(s)"
              isMulti={true}
              formComponent={RepresentativeField}
              component={ReduxFormEntitySelectorField}
            />
            <IconContainer>
              <IconComponent
                icon={AddCircleOutline}
                onClick={createToggleQuickAdd(CaseManagerQuickAddForm.PLAINTIFF_REPRESENTATIVE, 'plaintiffRepresentatives')}
              />
            </IconContainer>
          </FlexContainer>
          <FlexContainer>
            {renderQuickAddForm(PlaintiffRepresentativeFormComponent, quickAddVisibility[CaseManagerQuickAddForm.PLAINTIFF_REPRESENTATIVE], form)}
          </FlexContainer>
        </HalfWidthColumn>
      </Row>
      <Row>
        <HalfWidthColumn flexDirection="column">
          <FlexContainer>
            <Field
              disabled={isDisabled}
              label="Defendant/Opposing Party Firm(s)"
              name="defendantFirms"
              isMulti={true}
              formComponent={FirmField}
              component={ReduxFormEntitySelectorField}
            />
            <IconContainer>
              <IconComponent
                icon={AddCircleOutline}
                onClick={createToggleQuickAdd(CaseManagerQuickAddForm.DEFENDANT_FIRM, 'defendantFirms')}
              />
            </IconContainer>
          </FlexContainer>
          <FlexContainer>
            {renderQuickAddForm(DefendantFirmFormComponent, quickAddVisibility[CaseManagerQuickAddForm.DEFENDANT_FIRM], form)}
          </FlexContainer>
        </HalfWidthColumn>
        <HalfWidthColumn flexDirection="column">
          <FlexContainer>
            <Field
              disabled={isDisabled}
              name="defendantRepresentatives"
              label="Defendant/Opposing Party Representative(s)"
              isMulti={true}
              formComponent={RepresentativeField}
              component={ReduxFormEntitySelectorField}
            />
            <IconContainer>
              <IconComponent
                icon={AddCircleOutline}
                onClick={createToggleQuickAdd(CaseManagerQuickAddForm.DEFENDANT_REPRESENTATIVE, 'defendantRepresentatives')}
              />
            </IconContainer>
          </FlexContainer>
          <FlexContainer>
            {renderQuickAddForm(DefendantRepresentativeFormComponent, quickAddVisibility[CaseManagerQuickAddForm.DEFENDANT_REPRESENTATIVE], form)}
          </FlexContainer>
        </HalfWidthColumn>
      </Row>
      <hr />
      <TranslatedRichTextArea height="20vh" name="meta_headlineBody" label="Headline" />
      <TranslatedRichTextArea height="20vh" name="descriptionBody" label="Description" />
      <TranslatedRichTextArea height="20vh" name="meta_crossReferenceBody" label="Cross Reference" />
      <Row>
        <HalfWidthColumn flexDirection="column">
          <FlexContainer>
            <Field
              disabled={isDisabled}
              name="meta_bodyUnavailable"
              label="Unavailable Case Body"
              fullWidth={false}
              component={Checkbox}
            />
          </FlexContainer>
        </HalfWidthColumn>
      </Row>
      <TranslatedRichTextArea disabled={bodyUnavailable} hidden={bodyUnavailable} name="caseBody" label="Case Body" />
      <ButtonRow>
        <Column>
          <Button disabled={isDisabled} type="button" onClick={submitForm} variant={'contained'} color="primary">Submit</Button>
        </Column>
      </ButtonRow>
      {
        error &&
        (
          <Row>
            <FullRowColumn>
              <pre>{error}</pre>
            </FullRowColumn>
          </Row>
        )
      }
    </>);
};

const IconContainer = styled.span`
  margin-top: 32px;
  margin-left: 8px;
`;
