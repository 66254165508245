export enum ErrorType {
  Unauthorized,
  Save,
  Update,
  Load,
  Delete,
  Noop,
  ImportFromUrl
}

export interface ICuratorError extends Error {
  status: number;
  type: ErrorType;
}
