import * as R from 'ramda';
import { formValueSelector } from 'redux-form';

import { isEmpty, lowerCaseAndRemoveChars } from '../../lib/utils';
import { SelectOption } from '../../types/util';
import { EntityLabelFormatter, EntityWithLabel } from '../modules/case-manager/types';
import { transformOptionLabelsForMatching } from '../modules/case-manager/utils';
import { RootState } from '../modules/root';
import { Flag, FlaggedAsFormState } from '../../components/FlagComponent/types';

import { arrayToMapByProperty } from './array-to-value-map';

export const transformValueToSelectOption = (options: SelectOption[], value: string): SelectOption | undefined => transformKeyToSelectOption('value', options, value);

export const transformKeyToSelectOption = (key: string, options: SelectOption[], value: any): SelectOption | undefined => R.find(R.propEq(key, value), options);

export const transformSelectOptionsToValuesArray = (options: SelectOption[] | undefined): string[] => {
  if (!Array.isArray(options)) {
    return [];
  }
  return options.map(transformSelectOptionToValue);
};

export const transformSelectOptionToValue = (option: SelectOption | undefined): string => option && option.value ? option.value : '';

export const transformStringsToSelectOptions = (key: keyof SelectOption, options: SelectOption[], values: string[] | undefined): SelectOption[] => {
  if (!values || !options || isEmpty(values) || isEmpty(options)) {
    return [];
  }
  const transformedOptions = transformOptionLabelsForMatching(options);
  const optionsByKey = arrayToMapByProperty('value', options);
  const transformedOptionsByKey = arrayToMapByProperty(key, transformedOptions);
  const selectedOptions: SelectOption[] = [];

  for (const value of values) {
    if (!!transformedOptionsByKey[value]) {
      selectedOptions.push(optionsByKey[transformedOptionsByKey[value].value]);
    }
  }

  return selectedOptions;
};

export const transformArrayValueToSelectOptions = (options: SelectOption[], values: string[]): SelectOption[] => transformStringsToSelectOptions('value', options, values);

export const transformLabelsToKnownEntityOptions = (options: SelectOption[], values: string[]) => {
  if (!Array.isArray(values) || !Array.isArray(options) || values.length === 0 || options.length === 0) {
    return [];
  }
  const transformedValues = values.map(lowerCaseAndRemoveChars);
  return transformStringsToSelectOptions('label', options, transformedValues);
};

export function entityToSelectOption<TEntity extends EntityWithLabel>(
  labelFormatter: EntityLabelFormatter<TEntity>,
  entity: TEntity
): SelectOption {
  return {
    value: entity.id,
    label: labelFormatter(entity)
  };
}

function createOptionsFormatter<TEntity extends EntityWithLabel>(formatter: EntityLabelFormatter<TEntity>) {
  const labelFormatFn = R.pipe(formatter, lowerCaseAndRemoveChars);
  return (option: TEntity) => ({
    label: labelFormatFn(option),
    entity: option
  });
}

export function transformLabelsToFormattedOptions<TEntity extends EntityWithLabel>(
  matchingFormatter: EntityLabelFormatter<TEntity>,
  displayFormatter: EntityLabelFormatter<TEntity>,
  entities: TEntity[],
  valuesToMatch: string[]
): SelectOption[] {
  if (isEmpty(entities)) {
    return [];
  }
  const optionFormatter = createOptionsFormatter<TEntity>(matchingFormatter);
  const optionsForMatching = entities.map(optionFormatter);
  const formattedValuesToMatch = valuesToMatch.map(lowerCaseAndRemoveChars);

  const allMatchedOptions: SelectOption[] = [];

  for (const formattedValue of formattedValuesToMatch) {
    const currentMatches = [];
    for (const option of optionsForMatching) {
      if (option.label === formattedValue) {
        currentMatches.push(option.entity);
      }
    }
    if (currentMatches.length === 1) {
      allMatchedOptions.push(entityToSelectOption<TEntity>(displayFormatter, currentMatches[0] as TEntity));
    }
  }
  return allMatchedOptions;
}
export function transformLabelToFormattedOption<TEntity extends EntityWithLabel>(
  matchingFormatter: EntityLabelFormatter<TEntity>,
  displayFormatter: EntityLabelFormatter<TEntity>,
  entities: TEntity[],
  valueToMatch: string
): SelectOption | undefined {
  const matches = transformLabelsToFormattedOptions<TEntity>(matchingFormatter, displayFormatter, entities, [valueToMatch]);
  if (matches.length === 1) {
    return matches[0];
  }
  return;
}

export const createFormValueSelector = (form: string, valueKey: string) =>
  (state: RootState) => formValueSelector(form)(state, valueKey);

export const transformFormFlaggedAsToEntityFlaggedAs = (formFlaggedAs: FlaggedAsFormState[] = []): Flag[] => {
  return formFlaggedAs.filter(f => !!f.type?.value ).map((f) => {
    return {
      type: f.type.value,
      reason: f.reason,
      by: f.by
    } as Flag;
  });
};
