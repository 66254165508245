
import { initialize, SubmissionError } from 'redux-form';
import { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import { forkJoin, from, merge, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { PAGINATION_DEFAULT } from '../../../constants';
import { DeleteError } from '../../../lib/errors/DeleteError';
import { EntityFetchError } from '../../../lib/errors/EntityFetchError';
import { EntityCreateError } from '../../../lib/errors/EntityCreateError';
import { EntityUpdateError } from '../../../lib/errors/EntityUpdateError';
import { stateFormOptionsSelector } from '../../../modules/state-manager/selectors';
import { Services } from '../../../services';
import { RootAction } from '../actions';
import { countriesFetchActions } from '../countries/actions';
import { RootState } from '../root';
import { pushRoute } from '../router/actions';

import {
  changeStatesListPage,
  changeStatesListPageSize,
  deleteState,
  fetchDependentStateData,
  loadStateForDelete,
  loadStateForEdit,
  refreshStatesList,
  setFormDataFromState,
  SetStatePayload,
  statesFetchActions,
  statesPaginatedFetchActions,
  submitStateCreateForm,
  submitStateCreateFormCompleted,
  submitStateCreateFormFailed,
  submitStateEditForm,
  submitStateEditFormCompleted,
  submitStateEditFormFailed,
  updateStatesList
} from './actions';
import { RELATIONS_DEFAULT } from './constants';
import { selectStatesPagination } from './selectors';
import { transformStateFormDataToStateSubmission, transformStateToFormData } from './utils';

const requestActions = [
  countriesFetchActions.request()
];

const successActionCreators = [
  countriesFetchActions.success
];

export const fetchPaginatedStatesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(statesPaginatedFetchActions.request)),
    switchMap((action) =>
      from(curatorApi.searchStates(action.payload)).pipe(
        map(statesPaginatedFetchActions.success),
        catchError((err) => of(statesPaginatedFetchActions.failure(err)))
      )
    )
  );

export const fetchStatesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(statesFetchActions.request)),
    switchMap(() =>
      from(curatorApi.getAllStates({})).pipe(
        map(statesFetchActions.success),
        catchError((err) => of(statesFetchActions.failure(err)))
      )
    )
  );

export const fetchStateManagerDependentDataEpic: Epic<RootAction, RootAction> = (action$) =>
  action$
    .pipe(
      filter(isActionOf(fetchDependentStateData.request)),
      switchMap(() => requestActions)
    );

export const fetchStateManagerDependentDataEpicSuccessWatcher: Epic<RootAction, RootAction> = (action$) =>
  action$
    .pipe(
      filter(isActionOf(fetchDependentStateData.request)),
      switchMap(() =>
        // TODO: Ideally, we should handle any of the request actions failing and dispatch fetchDependentCaseManagerData.failure.
        //   Wasn't able to get `race` to work, maybe there's some `takeUntil` magic
        forkJoin(
          successActionCreators.map((action) =>
            action$.pipe(
              filter(isActionOf(action)),
              take(1)
            )
          )
        )
      ),
      map(() => fetchDependentStateData.success())
    );

const refreshEpic: Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(refreshStatesList)),
    map(() => statesPaginatedFetchActions.request({
      filter: {},
      pagination: PAGINATION_DEFAULT,
      relations: RELATIONS_DEFAULT
    })));

const updateEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(updateStatesList)),
    map((action) => {
      const queryFilter = {};
      return statesPaginatedFetchActions.request({
        filter: queryFilter,
        pagination: action.payload,
        relations: RELATIONS_DEFAULT
      });
    })
  );

const changePageEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeStatesListPage)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = selectStatesPagination(state);
      return updateStatesList({
        ...currentPagination,
        page: action.payload
      });
    })
  );

const changePageSizeEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeStatesListPageSize)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = selectStatesPagination(state);
      return updateStatesList({
        ...currentPagination,
        page: 1,
        perPage: action.payload
      });
    })
  );

export const createStateEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(submitStateCreateForm)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const { reject, resolve } = action.meta;
        const guid = state.states.new.operationGuid;
        if (!guid) {
          reject(new SubmissionError({ _error: 'Failed to create state, operation GUID missing' }));
          return [submitStateCreateFormFailed(new EntityCreateError('operation guid missing'))];
        }
        const submission = {
          guid,
          data: transformStateFormDataToStateSubmission(action.payload)
        };
        return from(curatorApi.createState(submission)).pipe(
          switchMap((response) => {
            if (response.status >= 300) {
              // TODO: Parse errors into friendlier format and field-specific where possible
              reject(new SubmissionError({ _error: `Failed to create state\n\n${JSON.stringify(response.data, null, 2)}` }));
              return [submitStateCreateFormFailed(new EntityCreateError('server error'))];
            }
            resolve();
            return [
              submitStateCreateFormCompleted(),
              pushRoute('/state-manager')
            ];
          }),
          catchError((err: Error) => {
            reject(new SubmissionError({ _error: `Failed to create state. (${err.message})` }));
            return of(submitStateCreateFormFailed(new EntityCreateError('unknown error')));
          })
        );
      })
    );

export const updateStateEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(submitStateEditForm)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const { reject, resolve } = action.meta;
        const id = state.states.edit.id;
        const guid = state.states.edit.operationGuid;
        if (!id) {
          reject(new SubmissionError({ _error: 'Failed to update state, id missing' }));
          return [submitStateEditFormFailed(new EntityUpdateError('state id missing'))];
        }
        if (!guid) {
          reject(new SubmissionError({ _error: 'Failed to update state, operation GUID missing' }));
          return [submitStateEditFormFailed(new EntityUpdateError('state id missing'))];
        }
        const submission = {
          id,
          guid,
          data: transformStateFormDataToStateSubmission(action.payload)
        };
        return from(curatorApi.updateState(submission)).pipe(
          switchMap((response) => {
            if (response.status >= 300) {
              // TODO: Parse errors into friendlier format and field-specific where possible
              reject(new SubmissionError({ _error: `Failed to update state\n\n${JSON.stringify(response.data, null, 2)}` }));
              return [submitStateEditFormFailed(new EntityUpdateError('server error'))];
            }
            resolve();
            return [
              submitStateEditFormCompleted(),
              pushRoute('/state-manager')
            ];
          }),
          catchError((err: Error) => {
            reject(new SubmissionError({ _error: `Failed to update state. (${err.message})` }));
            return of(submitStateEditFormFailed(new EntityUpdateError('unknown error')));
          })
        );
      })
    );

export const fetchStateForEditEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(loadStateForEdit.request)),
      switchMap((action) => from(curatorApi.getState(action.payload)).pipe(
        mergeMap((response) =>
          of(
            loadStateForEdit.success(response.data),
            setFormDataFromState({ formId: 'state-edit', data: response.data })
          )),
        catchError((err: Error) => {
          return from([
            loadStateForEdit.failure(new EntityFetchError(err.message)),
            pushRoute('/state-manager')
          ]);
        })
      )));

export const fetchStateForDeleteEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(loadStateForDelete.request)),
      switchMap((action) =>
        forkJoin([
          from(curatorApi.getState(action.payload)),
          from(curatorApi.getCaseQueryEntityDeleteEligibility('state', action.payload))
        ]).pipe(
          switchMap(([fetchEntityResponse, deleteEligibilityResponse]) =>
            of(loadStateForDelete.success({
              data: fetchEntityResponse.data,
              info: deleteEligibilityResponse.data
            }))),
          catchError((err) =>
            of(
              loadStateForDelete.failure(err),
              pushRoute('/state-manager')
            ))
        ))
    );

export const deleteStateEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(deleteState.request)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const guid = state.states.delete.operationGuid;
        if (!guid) {
          return of(deleteState.failure(new DeleteError('operation guid missing')));
        }
        if (!state.states.delete.info?.canDelete) {
          return of(deleteState.failure(new DeleteError('delete failed state check')));
        }
        const submission = {
          guid,
          id: action.payload
        };
        return from(curatorApi.deleteState(submission)).pipe(
          switchMap(() => of(deleteState.success(), pushRoute('/state-manager'))),
          catchError((err) => of(deleteState.failure(err)))
        );
      })
    );

export const waitForSuccessAndInitializeForm = (action$: ActionsObservable<RootAction>, store$: StateObservable<RootState>, payload: SetStatePayload) => {
  return action$.pipe(
    filter(isActionOf(fetchDependentStateData.success)),
    take(1),
    withLatestFrom(store$),
    map(([_, state]) => {
      return initialize(payload.formId, transformStateToFormData(payload.data, stateFormOptionsSelector(state)));
    })
  );
};

export const setFormDataFromStateEpic: Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$
    .pipe(
      filter(isActionOf(setFormDataFromState)),
      map((action) => action.payload),
      switchMap((payload) =>
        merge(
          // Dispatch action to refresh dependent data
          of(fetchDependentStateData.request()),
          // Listen for the dependent data success and then initialize the form values
          waitForSuccessAndInitializeForm(action$, store$, payload)
        )
      )
    );

export const stateEpics = [
  fetchStatesEpic,
  fetchPaginatedStatesEpic,
  refreshEpic,
  updateEpic,
  changePageEpic,
  changePageSizeEpic,
  fetchStateManagerDependentDataEpic,
  fetchStateManagerDependentDataEpicSuccessWatcher,
  createStateEpic,
  updateStateEpic,
  fetchStateForEditEpic,
  setFormDataFromStateEpic,
  fetchStateForDeleteEpic,
  deleteStateEpic
];
