import { createEntitySelector } from '../../../entity-selector';
import { namedEntityFormatter } from '../../../../redux/modules/case-manager/utils';
import { CuratorApi } from '../../../../services/CuratorApi';

function fetchOptions(api: CuratorApi) {
  return api.getAllIndustryGroups({}).then((result) => {
    return result.data.map((industryGroup) => ({
      label: namedEntityFormatter(industryGroup),
      value: industryGroup.id
    }));
  });
}

export const IndustryGroupField = createEntitySelector({
  fetchOptions,
  pluralEntityName: 'industry groups',
  key: 'industry-group'
});
