import { useMutation } from '@tanstack/react-query';

import { EntityCreateError } from '../../errors/EntityCreateError';

import { useOperationGuid } from './useOperationGuid';
import { useEntityApiContext } from './useEntityApiContext';
import { useCuratorApiContext } from './useCuratorApiContext';

export function useEntityCreate<TFormData = any, TData = any>() {
  const guid = useOperationGuid();
  const api = useEntityApiContext();
  const { curatorApi } = useCuratorApiContext();

  const useMutationResult = useMutation<TData, EntityCreateError, TFormData>((data) => {
    // TODO: Form data -> payload data transform
    return api.create(curatorApi, guid, data).then((result) => {
      // TODO: Contraint errors can be parsed and applied to fields
      //   - Minimum: Get this info out and displayed like we have in the past
      if (result.status >= 300) {
        throw new EntityCreateError('Entity create failed');
      }

      if (result.data.status === 'noop') {
        throw new EntityCreateError('Entity create failed with no op');
      }

      return result.data.result;
    })
  });

  return {
    ...useMutationResult,
    operationGuid: guid
  }
}
