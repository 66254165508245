import { Dispatch } from 'redux';
import { submit } from 'redux-form';

import { RootAction } from '../../redux/modules/actions';
import { RootState } from '../../redux/modules/root';
import { stateOptionsSelector } from '../../redux/modules/states/selectors';
import { FormSubmissionMeta } from '../../types/forms';
import { CourtFormData } from '../../types/cases/court';

type CourtFormSubmitAction = (values: CourtFormData, meta: FormSubmissionMeta) => RootAction;

export const createCourtFormDispatchers = (dispatch: Dispatch<RootAction>, submitAction: CourtFormSubmitAction, formId: string) =>
  ({
    submitForm: () => dispatch(submit((formId))),
    submitHandler: (values: CourtFormData) =>
      new Promise<void>((resolve, reject) => {
        dispatch(submitAction(values, { resolve, reject }));
      })
  });

export const courtFormOptionsSelector = (state: RootState) => ({
  stateOptions: stateOptionsSelector(state)
});

export type CourtFormOptions = ReturnType<typeof courtFormOptionsSelector>;

export type CourtFormDispatchers = ReturnType<typeof createCourtFormDispatchers>;

export type CourtFormProps = CourtFormOptions & CourtFormDispatchers;
