import React from 'react';
import { Control } from 'react-hook-form';

import {
  EntityFilterFieldConfigs,
  EntityFormFieldConfigs,
  FieldConfig,
  FieldId,
  FieldTypes,
  FormLayoutComponentProps
} from './shared';
import { HookFormControlledTextField } from './fields/hook-form/text';
import { DefaultFormLayout } from './default-form-layout';
import { HookFormControlledEntitySelectorField } from './fields/hook-form/entity-selector';
import { JudgeField } from './fields/judge';
import { CountryField } from './fields/country';
import { StateField } from './fields/state';

interface FormContainerProps {
  fields: EntityFormFieldConfigs | EntityFilterFieldConfigs;
  control: Control<any>;
  layoutComponent?: React.ComponentType<FormLayoutComponentProps>;
}

function createFormElement(name: string, control: Control<any>, config: FieldConfig) {
  switch (config.type) {
    case FieldTypes.Text:
      return (
        <HookFormControlledTextField name={name} control={control} label={config.label} />
      );
    case FieldTypes.Judge:
      return (
        <HookFormControlledEntitySelectorField
          control={control}
          isMulti={config.isMulti}
          name={name}
          label={config.label}
          formComponent={JudgeField}
        />
      );
    case FieldTypes.Country:
      return (
        <HookFormControlledEntitySelectorField
          control={control}
          name={name}
          isMulti={config.isMulti}
          label={config.label}
          formComponent={CountryField}
        />
      );
    case FieldTypes.State:
      return (
        <HookFormControlledEntitySelectorField
          control={control}
          name={name}
          isMulti={config.isMulti}
          label={config.label}
          formComponent={StateField}
        />
      );
  }
}

export function FormGenerator(props: FormContainerProps) {
  const {
    layoutComponent: Layout = DefaultFormLayout,
    fields,
    control
  } = props;

  const formElements = React.useMemo(() => {
    const elements: Record<FieldId, React.ReactElement> = {};
    for (const [key, config] of Object.entries(fields)) {
      if (config) {
        elements[key] = createFormElement(key, control, config);
      }
    }
    return elements;
  }, [fields, control]);

  return (
    <Layout fields={formElements} />
  );
}
