import { arrayPush, initialize, SubmissionError } from 'redux-form';
import { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import { forkJoin, from, merge, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { PAGINATION_DEFAULT } from '../../../constants';
import { DeleteError } from '../../../lib/errors/DeleteError';
import { EntityFetchError } from '../../../lib/errors/EntityFetchError';
import { EntityCreateError } from '../../../lib/errors/EntityCreateError';
import { EntityUpdateError } from '../../../lib/errors/EntityUpdateError';
import { judgeFormOptionsSelector } from '../../../modules/judge-manager/selectors';
import { Services } from '../../../services';
import { entityToSelectOption } from '../../util/forms';
import { RootAction } from '../actions';
import { toggleQuickAddFormVisibility } from '../case-manager/actions';
import { CaseManagerQuickAddForm } from '../case-manager/reducer';
import { countriesFetchActions } from '../countries/actions';
import { RootState } from '../root';
import { pushRoute } from '../router/actions';

import {
  applyJudgeFilters,
  changeJudgesListPage,
  changeJudgesListPageSize,
  clearJudgeFilters,
  deleteJudge,
  fetchDependentJudgeData,
  judgesPaginatedFetchActions,
  loadJudgeForDelete,
  loadJudgeForEdit,
  refreshJudgesList,
  SetJudgePayload,
  setFormDataFromJudge,
  submitJudgeCreateForm,
  submitJudgeCreateFormCompleted,
  submitJudgeCreateFormFailed,
  submitJudgeEditForm,
  submitJudgeEditFormCompleted,
  submitJudgeEditFormFailed,
  updateJudgesList
} from './actions';
import {
  formatJudgeLabel,
  selectJudgeFilterFormData,
  selectJudgeFilterQuery,
  selectJudgesPagination
} from './selectors';
import { transformJudgeFormDataToJudgeSubmission, transformJudgeToFormData } from './utils';

const requestActions = [
  countriesFetchActions.request()
];

const successActionCreators = [
  countriesFetchActions.success
];

export const fetchPaginatedJudgesEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(judgesPaginatedFetchActions.request)),
    mergeMap((action) =>
      from(curatorApi.searchJudges(action.payload)).pipe(
        map(judgesPaginatedFetchActions.success),
        catchError((err) => of(judgesPaginatedFetchActions.failure(err)))
      )
    )
  );

export const fetchJudgeManagerDependentDataEpic: Epic<RootAction, RootAction> = (action$) =>
  action$
    .pipe(
      filter(isActionOf(fetchDependentJudgeData.request)),
      switchMap(() => requestActions)
    );

export const fetchJudgeManagerDependentDataEpicSuccessWatcher: Epic<RootAction, RootAction> = (action$) =>
  action$
    .pipe(
      filter(isActionOf(fetchDependentJudgeData.request)),
      switchMap(() =>
        // TODO: Ideally, we should handle any of the request actions failing and dispatch fetchDependentCaseManagerData.failure.
        //   Wasn't able to get `race` to work, maybe there's some `takeUntil` magic
        forkJoin(
          successActionCreators.map((action) =>
            action$.pipe(
              filter(isActionOf(action)),
              take(1)
            )
          )
        )
      ),
      map(() => fetchDependentJudgeData.success())
    );

const refreshEpic: Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(refreshJudgesList)),
    withLatestFrom(store$),
    switchMap(([_, state]) => {
      const currentPagination = selectJudgesPagination(state);
      const currentFilters = selectJudgeFilterQuery(state);
      const currentFilterFormData = selectJudgeFilterFormData(state);
      return [
        judgesPaginatedFetchActions.request({
          filter: currentFilters,
          pagination: currentPagination
        }),
        initialize('judge-filters', currentFilterFormData)
      ];
    })
  );

const updateEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(updateJudgesList)),
    map((action) => {
      return judgesPaginatedFetchActions.request(action.payload);
    })
  );

const changePageEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeJudgesListPage)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = selectJudgesPagination(state);
      const currentFilters = selectJudgeFilterQuery(state);
      return updateJudgesList({
        filter: currentFilters,
        pagination: {
          ...currentPagination,
          page: action.payload
        }
      });
    })
  );

const changePageSizeEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeJudgesListPageSize)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = selectJudgesPagination(state);
      const currentFilters = selectJudgeFilterQuery(state);
      return updateJudgesList({
        filter: currentFilters,
        pagination: {
          ...currentPagination,
          page: 1,
          perPage: action.payload
        }
      });
    })
  );

const applyFiltersEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(applyJudgeFilters)),
    withLatestFrom(store$),
    map(([_, state]) => {
      const currentFilters = selectJudgeFilterQuery(state);
      return updateJudgesList({
        filter: currentFilters,
        pagination: PAGINATION_DEFAULT
      });
    })
  );

const clearFiltersEpic: Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(clearJudgeFilters)),
    switchMap(() => {
      return [
        initialize('judge-filters', {}),
        fetchDependentJudgeData.request(),
        updateJudgesList({ filter: {}, pagination: PAGINATION_DEFAULT })
      ];
    })
  );

export const createJudgeEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(submitJudgeCreateForm)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const { reject, resolve, inline } = action.meta;
        const guid = state.judges.new.operationGuid;
        if (!guid) {
          reject(new SubmissionError({ _error: 'Failed to create judge, operation GUID missing' }));
          return [submitJudgeCreateFormFailed(new EntityCreateError('operation guid missing'))];
        }
        const submission = {
          guid,
          data: transformJudgeFormDataToJudgeSubmission(action.payload)
        };
        return from(curatorApi.createJudge(submission)).pipe(
          switchMap((response) => {
            if (response.status >= 300) {
              // TODO: Parse errors into friendlier format and field-specific where possible
              reject(new SubmissionError({ _error: `Failed to create judge\n\n${JSON.stringify(response.data, null, 2)}` }));
              return [submitJudgeCreateFormFailed(new EntityCreateError('server error'))];
            }
            resolve();
            if (inline) {
              const { parentForm, fieldId } = action.meta;
              return merge(
                from([
                  submitJudgeCreateFormCompleted(),
                  toggleQuickAddFormVisibility({ form: CaseManagerQuickAddForm.JUDGE })
                ]),
                (parentForm && fieldId) ? [
                  arrayPush(parentForm, fieldId, entityToSelectOption(formatJudgeLabel, response.data.result))
                ] : []
              );
            } else {
              return [
                submitJudgeCreateFormCompleted(),
                pushRoute('/judge-manager')
              ];
            }
          }),
          catchError((err: Error) => {
            reject(new SubmissionError({ _error: `Failed to create judge. (${err.message})` }));
            return of(submitJudgeCreateFormFailed(new EntityCreateError('unknown error')));
          })
        );
      })
    );

export const updateJudgeEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(submitJudgeEditForm)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const { reject, resolve } = action.meta;
        const id = state.judges.edit.id;
        const guid = state.judges.edit.operationGuid;
        if (!id) {
          reject(new SubmissionError({ _error: 'Failed to update judge, id missing' }));
          return [submitJudgeEditFormFailed(new EntityUpdateError('judge id missing'))];
        }
        if (!guid) {
          reject(new SubmissionError({ _error: 'Failed to update judge, operation GUID missing' }));
          return [submitJudgeEditFormFailed(new EntityUpdateError('operation guid missing'))];
        }
        const submission = {
          id,
          guid,
          data: transformJudgeFormDataToJudgeSubmission(action.payload)
        };
        return from(curatorApi.updateJudge(submission)).pipe(
          switchMap((response) => {
            if (response.status >= 300) {
              // TODO: Parse errors into friendlier format and field-specific where possible
              reject(new SubmissionError({ _error: `Failed to update judge\n\n${JSON.stringify(response.data, null, 2)}` }));
              return [submitJudgeEditFormFailed(new EntityUpdateError('server error'))];
            }
            resolve();
            return [
              submitJudgeEditFormCompleted(),
              pushRoute('/judge-manager')
            ];
          }),
          catchError((err: Error) => {
            reject(new SubmissionError({ _error: `Failed to update judge. (${err.message})` }));
            return of(submitJudgeEditFormFailed(new EntityUpdateError('unknown error')));
          })
        );
      })
    );

export const fetchJudgeForEditEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(loadJudgeForEdit.request)),
      switchMap((action) => from(curatorApi.getJudge(action.payload)).pipe(
        mergeMap((response) =>
          of(
            loadJudgeForEdit.success(response.data),
            setFormDataFromJudge({ formId: 'judge-edit', data: response.data })
          )),
        catchError((err: Error) => {
          return from([
            loadJudgeForEdit.failure(new EntityFetchError(err.message)),
            pushRoute('/judge-manager')
          ]);
        })
      )));

export const fetchJudgeForDeleteEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(loadJudgeForDelete.request)),
      switchMap((action) =>
        forkJoin([
          from(curatorApi.getJudge(action.payload)),
          from(curatorApi.getCaseQueryEntityDeleteEligibility('judge', action.payload))
        ]).pipe(
          switchMap(([fetchEntityResponse, deleteEligibilityResponse]) =>
            of(loadJudgeForDelete.success({
              data: fetchEntityResponse.data,
              info: deleteEligibilityResponse.data
            }))),
          catchError((err) =>
            of(
              loadJudgeForDelete.failure(err),
              pushRoute('/judge-manager')
            ))
        ))
    );

export const deleteJudgeEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(deleteJudge.request)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const guid = state.judges.delete.operationGuid;
        if (!guid) {
          return of(deleteJudge.failure(new DeleteError('operation guid missing')));
        }
        if (!state.judges.delete.info?.canDelete) {
          return of(deleteJudge.failure(new DeleteError('delete failed state check')));
        }
        const submission = {
          guid,
          id: action.payload
        };
        return from(curatorApi.deleteJudge(submission)).pipe(
          switchMap(() => of(deleteJudge.success(), pushRoute('/judge-manager'))),
          catchError((err) => of(deleteJudge.failure(err)))
        );
      })
    );

export const waitForSuccessAndInitializeForm = (action$: ActionsObservable<RootAction>, store$: StateObservable<RootState>, payload: SetJudgePayload) => {
  return action$.pipe(
    filter(isActionOf(fetchDependentJudgeData.success)),
    take(1),
    withLatestFrom(store$),
    map(([_, state]) => {
      return initialize(payload.formId, transformJudgeToFormData(payload.data, judgeFormOptionsSelector(state)));
    })
  );
};

export const setFormDataFromJudgeEpic: Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$
    .pipe(
      filter(isActionOf(setFormDataFromJudge)),
      map((action) => action.payload),
      switchMap((payload) =>
        merge(
          // Dispatch action to refresh dependent data
          of(fetchDependentJudgeData.request()),
          // Listen for the dependent data success and then initialize the form values
          waitForSuccessAndInitializeForm(action$, store$, payload)
        )
      )
    );

export const judgeEpics = [
  fetchPaginatedJudgesEpic,
  refreshEpic,
  updateEpic,
  changePageEpic,
  changePageSizeEpic,
  fetchJudgeManagerDependentDataEpic,
  fetchJudgeManagerDependentDataEpicSuccessWatcher,
  createJudgeEpic,
  updateJudgeEpic,
  fetchJudgeForEditEpic,
  setFormDataFromJudgeEpic,
  applyFiltersEpic,
  clearFiltersEpic,
  fetchJudgeForDeleteEpic,
  deleteJudgeEpic
];
