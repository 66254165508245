import { combineReducers } from 'redux';
import { getType } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import { PAGINATION_DEFAULT } from '../../../constants';
import { JudgeFilterFormData } from '../../../modules/judge-manager/components/JudgeFilterComponent';
import { PaginationMeta } from '../../util/fetch';
import { RootAction } from '../actions';
import { ManagerDeleteState } from '../types';
import { JudgeEntity } from '../../../types/cases/judge';

import {
  applyJudgeFilters,
  clearJudgeFilters,
  fetchDependentJudgeData,
  judgesPaginatedFetchActions,
  loadJudgeForDelete,
  loadJudgeForEdit,
  startNewJudgeOperation
} from './actions';

export type JudgeManagerEntitiesState = {
  filter: Partial<JudgeFilterFormData>,
  paginated: {
    entities: JudgeEntity[],
    pagination: PaginationMeta,
  }
};

export type JudgeManagerDependentDataState = {
  loading: boolean,
};

export const judgesManagerEntitiesInitialState: JudgeManagerEntitiesState = {
  filter: {},
  paginated: {
    entities: [],
    pagination: PAGINATION_DEFAULT
  }
};

export const judgesManagerEntitiesReducer = (state: JudgeManagerEntitiesState = judgesManagerEntitiesInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(applyJudgeFilters): {
      return {
        ...state,
        filter: action.payload
      };
    }

    case getType(clearJudgeFilters): {
      return {
        ...state,
        filter: {}
      };
    }

    case getType(judgesPaginatedFetchActions.success): {
      return {
        ...state,
        paginated: {
          entities: action.payload.data,
          pagination: action.payload.pagination
        }
      };
    }

    case getType(judgesPaginatedFetchActions.request): {
      return {
        ...state
      };
    }

    case getType(judgesPaginatedFetchActions.failure): {
      return {
        ...state,
        paginated: {
          entities: [],
          pagination: PAGINATION_DEFAULT
        }
      };
    }

    default: {
      return state;
    }
  }
};

export type JudgeManagerNewState = {
  operationGuid: string | null,
};

export const judgeManagerNewInitialState = {
  operationGuid: null
};

export const judgeManagerNewReducer = (state: JudgeManagerNewState = judgeManagerNewInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(startNewJudgeOperation): {
      return {
        ...state,
        operationGuid: uuidv4()
      };
    }

    default: {
      return state;
    }
  }
};

export type JudgeManagerEditState = {
  id: string | null,
  operationGuid: string | null,
};

const judgeManagerEditInitialState = {
  id: null,
  operationGuid: null
};

export const judgeManagerEditReducer = (state: JudgeManagerEditState = judgeManagerEditInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(loadJudgeForEdit.request): {
      return {
        ...state,
        id: action.payload,
        operationGuid: null
      };
    }
    case getType(loadJudgeForEdit.success): {
      return {
        ...state,
        data: action.payload,
        operationGuid: uuidv4()
      };
    }
    case getType(loadJudgeForEdit.failure): {
      return {
        ...state,
        data: null
      };
    }
    default: {
      return state;
    }
  }
};

const judgeManagerDeleteInitialState: ManagerDeleteState<JudgeEntity> = {
  loading: false
};

export const judgeManagerDeleteReducer = (state: ManagerDeleteState<JudgeEntity> = judgeManagerDeleteInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(loadJudgeForDelete.request): {
      return {
        loading: true
      };
    }
    case getType(loadJudgeForDelete.success): {
      return {
        ...state,
        loading: false,
        operationGuid: uuidv4(),
        ...action.payload
      };
    }
    case getType(loadJudgeForDelete.failure): {
      return {
        ...state,
        loading: false,
        info: {
          canDelete: false,
          usage: 0
        }
      };
    }
    default: {
      return state;
    }
  }
};

export const judgeManagerDependentDataInitialState = {
  loading: false
};

export const judgeManagerDependentDataReducer = (state: JudgeManagerDependentDataState = judgeManagerDependentDataInitialState, action: RootAction) => {
  switch (action.type) {
    case getType(fetchDependentJudgeData.request): {
      return {
        ...state,
        loading: true
      };
    }
    case getType(fetchDependentJudgeData.success): {
      return {
        ...state,
        loading: false
      };
    }
    case getType(fetchDependentJudgeData.failure): {
      return {
        ...state,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
};

export const judgesManagerInitialState = {
  entities: judgesManagerEntitiesInitialState,
  dependentData: judgeManagerDependentDataInitialState,
  edit: judgeManagerEditInitialState,
  new: judgeManagerNewInitialState,
  delete: judgeManagerDeleteInitialState
};

export type JudgesManagerState = {
  entities: JudgeManagerEntitiesState,
  dependentData: JudgeManagerDependentDataState,
  edit: JudgeManagerEditState,
  new: JudgeManagerNewState,
  delete: ManagerDeleteState<JudgeEntity>
};

export const judgesManagerReducer = combineReducers<JudgesManagerState>({
  entities: judgesManagerEntitiesReducer,
  dependentData: judgeManagerDependentDataReducer,
  edit: judgeManagerEditReducer,
  new: judgeManagerNewReducer,
  delete: judgeManagerDeleteReducer
});
