import React from 'react';

import { Option } from '../shared';
import { CuratorApiAbstract } from '../../../services/CuratorApiAbstract';

interface EntityAsyncSelectContextValue<TCuratorApi extends CuratorApiAbstract = any> {
  key: string;
  search: (api: TCuratorApi, filter: string) => Promise<Option[]>;
}

interface EntitySelectContextValue<TCuratorApi extends CuratorApiAbstract = any> {
  key: string;
  get: (api: TCuratorApi) => Promise<Option[]>;
}

export const EntityAsyncSelectContext = React.createContext<EntityAsyncSelectContextValue | null>(null);

export const EntityAsyncSelectContextProvider = EntityAsyncSelectContext.Provider;

export const EntitySelectContext = React.createContext<EntitySelectContextValue | null>(null);

export const EntitySelectContextProvider = EntitySelectContext.Provider;
