import { SelectOption } from '../../types/util';

import { CaseDocumentFlags, Flag, FlaggedAsFormState, InvalidateFlags, SimpleFlags } from './types';

// TODO: we will eventually get this from the API
const flags: CaseDocumentFlags[] = [
  'AMENDED',
  'DISTINGUISHED',
  'BADVERSION_TD',
  'IRRELEVANT_DOCUMENTTYPE_PROPOSEDREGULATIONS',
  'MODIFIED',
  'OBSOLETED',
  'RECONSIDERED',
  'REPEALED',
  'REVERSED',
  'REVOKED',
  'SUPPLEMENTED',
  'SUSPENDED',
  'UPDATED',
  'VACATED',
  'BADVERSION_PUB',
  'WITHDRAWN',
  'IRRELEVANT',
  'OUTDATED',
  'CONTAINS_MIXED_JCT',
  'CONTAINS_MIXED_LEGHIST',
  'CONTAINS_MIXED_OTHER',
  'OLD_LEGHIST',
  'TIME_SPECIFIC',
  'CAUSES_ERROR',
  'SUPERSEDED',
  'DUPLICATE',
  'AUTOMATICALLY_FLAGGED',
  'EXCISE_TAX',
  'SALT',
  'OECD',
  'DATE_EXCLUSION_COMMENTARY',
  'CURATE_LATER',
  'REMANDED',
  'OTHER'
];

export function doesFlagHaveBy(flagType: string): boolean {
  return flagType === 'SUPERSEDED' ||
    flagType === 'DUPLICATE' ||
    flagType === 'AMENDED' ||
    flagType === 'DISTINGUISHED' ||
    flagType === 'MODIFIED' ||
    flagType === 'OBSOLETED' ||
    flagType === 'RECONSIDERED' ||
    flagType === 'REPEALED' ||
    flagType === 'REVERSED' ||
    flagType === 'REVOKED' ||
    flagType === 'SUPPLEMENTED' ||
    flagType === 'SUSPENDED' ||
    flagType === 'UPDATED' ||
    flagType === 'VACATED' ||
    flagType === 'WITHDRAWN';
}

export function isInvalidatedFlag(flag: SimpleFlags | InvalidateFlags): flag is InvalidateFlags {
  const flagType = flag.type;
  return doesFlagHaveBy(flagType);
}

export const flaggedAsToFormData = (flags: Flag[] | undefined): FlaggedAsFormState[] => {
  if (!flags) return [];

  return flags.map((f) => {
    const t = f.type as string;
    return {
      type: { label: t, value: t },
      reason: f.reason,
      ...(isInvalidatedFlag(f) && { by: f.by || [] })
    } as FlaggedAsFormState;
  });
};

export const getFlaggedSelectOptions = (): SelectOption[] => {
  return flags.map((f) => ({ label: f, value: f }));
};
