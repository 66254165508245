import { Box, Paper } from '@mui/material';
import * as React from 'react';

interface IMainContainerCardProps {
  children?: any;
}

export const MainContainerCardComponent = (props: IMainContainerCardProps) => (
  <Paper elevation={1}>
    <Box p={2}>
      {props.children}
    </Box>
  </Paper>
);
