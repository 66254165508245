import React from 'react';
import { Box } from '@mui/material';

import { MainContainerCardComponent } from '../../../components/ui/MainContainerCard';

interface ManagerLayoutConfig {
  managerComponent: React.ComponentType;
}

export function createManagerLayout(config: ManagerLayoutConfig): React.ComponentType {
  const ManagerComponent = config.managerComponent;

  return () => (
    <Box p={2}>
      <MainContainerCardComponent>
        <ManagerComponent />
      </MainContainerCardComponent>
    </Box>
  )
}
