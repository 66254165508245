export function trimOrNull(str: any): string | null {
  if (typeof str !== 'string') {
    return null;
  }
  return str.trim();
}

export function trimOrEmptyString(str: any): string {
  if (typeof str !== 'string') {
    return '';
  }
  return str.trim();
}
