import { Editor } from '@tinymce/tinymce-react';
import * as React from 'react';
import styled from 'styled-components';

import { Row, Column } from '../ui/FlexTable';

import { IRichTextFieldProps } from './types';

// TinyMCE so the global var exists
import 'tinymce/tinymce';
// DOM model
import 'tinymce/models/dom/model'
// Theme
import 'tinymce/themes/silver';
// Toolbar icons
import 'tinymce/icons/default';
// Editor styles
import 'tinymce/skins/ui/oxide/skin';

// importing the plugin js.
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/code';

// importing plugin resources
import 'tinymce/plugins/emoticons/js/emojis';

// Content styles, including inline UI like fake cursors
import 'tinymce/skins/content/default/content';
import 'tinymce/skins/ui/oxide/content';

export class RichTextAreaComponent extends React.PureComponent<IRichTextFieldProps> {
  private unmounting: boolean;

  constructor(props: IRichTextFieldProps) {
    super(props);
    this.unmounting = false;
  }

  componentWillUnmount(): void {
    // this is set for a race condition where tinymce dispatches a save on unmount which can clobber the redux form destroy
    this.unmounting = true;
  }

  render() {
    const { input, disabled, label, height = 759 } = this.props;

    const formattedHeight = typeof height === 'string' ? height : `${height}px`;

    const OutlinedDiv = styled(BaseOutlinedDiv) `
      height: ${formattedHeight}
    `;

    return (
      <CaseEditorContainer>
        <Row>
          <Column>
            <label>{ label }</label>
          </Column>
        </Row>
        <Row>
          <Column>
            <Editor value={input.value}
                    disabled={disabled}
                    onEditorChange={this.editorChangeHandler}
                    tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
                    licenseKey={'v5lb7p1yzkdfdp08036qjfbseklrwtmm77v9mpuvfqi2pbk9'}
                    init={{
                      promotion: false,
                      invalid_elements: 'o:p,style,q',
                      height: formattedHeight,
                      branding: false,
                      plugins: ['code'],
                      toolbar: 'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | code',
                      content_style: 'h1 {margin-top:0;margin-right:0;margin-bottom:16pt;margin-left:0;text-align:justify;font-size:14pt;font-family:"CG Times","serif";font-weight:normal;text-decoration:underline;text-underline:single} .mce-content-body .mce-item-anchor {background:none; width:0px !important; padding:0px;}'
                    }}
            />
          </Column>
          <Column>
            <OutlinedDiv dangerouslySetInnerHTML={{ __html: input.value || 'Nothing to preview' }} />
          </Column>
        </Row>
      </CaseEditorContainer>
    );
  }

  private editorChangeHandler = (value: string): void => {
    if (!this.unmounting) {
        this.props.input.onChange(value);
      }
  }
}

const BaseOutlinedDiv = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  width: 100%;
  padding: 15px;
  overflow-y: scroll;
  margin: auto;
`;

const CaseEditorContainer = styled.div`
  margin-top: 25px;
`;
