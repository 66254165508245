import { createSelector } from 'reselect';

import { RootState } from '../root';

import { transformDocumentFilterFormDataToQuery } from './utils';

export const selectDocumentManagerState = (state: RootState) => state.documentManager;

export const selectDocumentsPagination = createSelector(
  [selectDocumentManagerState],
  ({ pagination }) => pagination
);

export const selectDocumentFilterFormData = createSelector(
  [selectDocumentManagerState],
  ({ filter }) => filter
);

export const selectDocumentFilterQuery = createSelector(
  [selectDocumentFilterFormData],
  transformDocumentFilterFormDataToQuery
);

export const selectIsLoadingDocumentManagerDependentData = createSelector(
  [selectDocumentManagerState],
  ({ dependentDataLoading }) => dependentDataLoading
);

export const selectDocuments = createSelector(
  [selectDocumentManagerState],
  ({ entities }) => entities
);

export const selectDocumentCreateNew = createSelector(
  [selectDocumentManagerState],
  (documentManagerState) => documentManagerState.new
);

export const selectDocumentEdit = createSelector(
  [selectDocumentManagerState],
  (documentManagerState) => documentManagerState.edit
);

export const selectDocumentDelete = createSelector(
  [selectDocumentManagerState],
  (documentManagerState) => documentManagerState.delete
);
