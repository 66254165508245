import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { reduxForm } from 'redux-form';

import {
  applyCaseFilters,
  clearCaseFilters,
  fetchDependentCaseManagerData
} from '../../../redux/modules/case-manager/actions';
import { selectIsLoadingCaseManagerDependentData } from '../../../redux/modules/case-manager/selectors';
import { CASE_FILTERS_FORM_ID, CaseDependantDataRequestType } from '../../../redux/modules/case-manager/types';
import { RootState } from '../../../redux/modules/root';
import { CaseFilterComponent, CaseFilterFormData, CaseFilterFormProps } from '../components/CaseFilterComponent';
import { caseFilterPropsSelector, filterCaseFormDispatchers } from '../selectors';
import { BODY_AVAILABILITY_OPTIONS } from '../../../constants';

const mapStateToProps = (state: RootState) => ({
  isFilterDataLoading: selectIsLoadingCaseManagerDependentData(state),
  formProps: caseFilterPropsSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchDependentData: () => dispatch(fetchDependentCaseManagerData.request(CaseDependantDataRequestType.FILTER)),
  formDispatchers: filterCaseFormDispatchers(dispatch, clearCaseFilters, applyCaseFilters, CASE_FILTERS_FORM_ID)
});

type StateProps = ReturnType<typeof mapStateToProps>;

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type ContainerProps = StateProps & DispatchProps;

const FormComponent = reduxForm<CaseFilterFormData, CaseFilterFormProps>({ form: CASE_FILTERS_FORM_ID })(CaseFilterComponent);

class ContainerComponent extends React.Component<ContainerProps> {
  componentDidMount(): void {
    this.props.fetchDependentData();
  }

  render() {
    const { isFilterDataLoading, formProps, formDispatchers } = this.props;
    return (
      <FormComponent disabled={isFilterDataLoading} bodyAvailability={BODY_AVAILABILITY_OPTIONS} {...formProps} {...formDispatchers} />
    );
  }
}

export const CaseFilterContainerComponent = connect<StateProps, DispatchProps, {}, RootState>(mapStateToProps, mapDispatchToProps)(
  ContainerComponent
);
