import { ErrorType, ICuratorError } from './types';

export class EntityUpdateError extends Error implements ICuratorError {
  public readonly status: number = 500;
  public readonly type: ErrorType = ErrorType.Update;
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, EntityUpdateError.prototype);
  }
}
