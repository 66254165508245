import { Typography } from '@mui/material';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';

import { IconComponent } from '../../../components/ui/Icon';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCard';
import { RootAction } from '../../../redux/modules/actions';
import { toggleQuickAddFormVisibility } from '../../../redux/modules/case-manager/actions';
import { CaseManagerQuickAddForm } from '../../../redux/modules/case-manager/reducer';
import { fetchDependentFirmData, startNewFirmOperation, submitFirmCreateForm } from '../../../redux/modules/firms/actions';
import { FirmForm } from '../../../redux/modules/firms/types';
import { RootState } from '../../../redux/modules/root';
import { FirmFormComponent } from '../components/FirmFormComponent';
import { createFirmFormDispatchers, firmFormOptionsSelector, FirmFormProps } from '../selectors';
import { HighlightOffIcon } from '../../../components/ui/mui-icons';
import { FirmFormData } from '../../../types/cases/firm';

const mapStateToProps = (state: RootState) => ({
  formProps: firmFormOptionsSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch<RootAction>, ownProps: OwnProps) => ({
  fetchDependentData: () => dispatch(fetchDependentFirmData.request()),
  startNewOperation: () => dispatch(startNewFirmOperation(ownProps.form)),
  formDispatchers: createFirmFormDispatchers(dispatch, submitFirmCreateForm, ownProps.form, ownProps.parentForm, ownProps.fieldName, ownProps.quickAddFormId),
  toggleQuickAddOpen: () => dispatch(toggleQuickAddFormVisibility({ form: ownProps.quickAddFormId }))
});

export type StateProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  fieldName: string,
  contextTitle: string,
  form: FirmForm,
  parentForm: string,
  quickAddFormId: CaseManagerQuickAddForm
};

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type NewFirmContainerProps = StateProps & DispatchProps & OwnProps;

const NewFirmForm = reduxForm<FirmFormData, FirmFormProps>({
  onSubmit: (values: FirmFormData, _dispatch: Dispatch, props: FirmFormProps) => props.submitHandler(values)
})(FirmFormComponent);

class NewFirmQuickAddContainerComponent extends React.Component<NewFirmContainerProps> {
  componentDidMount(): void {
    this.props.fetchDependentData();
    this.props.startNewOperation();
  }

  render() {
    return (
      <Container>
        <MainContainerCardComponent>
          <IconContainer>
            <IconComponent
              icon={HighlightOffIcon}
              onClick={this.props.toggleQuickAddOpen}
            />
          </IconContainer>
          <Typography component="h4" variant="h5">Create Firm ({this.props.contextTitle})</Typography>
          <NewFirmForm form={this.props.form} {...this.props.formProps} {...this.props.formDispatchers} />
        </MainContainerCardComponent>
      </Container>
    );
  }
}

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const IconContainer = styled.span`
  float: right;
`;

export const NewFirmQuickAddContainer = connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(NewFirmQuickAddContainerComponent);
