import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Grid, Typography } from '@mui/material';

import { loadDocumentForEdit, submitDocumentEditForm } from '../../../redux/modules/document-manager/actions';
import { DocumentFormComponent } from '../components/DocumentFormComponent';
import { documentFormOptionsPropsSelector, isDocumentFormDisabledSelector } from '../selectors';
import { PaperWrapper } from '../../../components/ui/PaperContainer';
import { BreadcrumbLink } from '../../../components/ui/BreadcrumbLink';
import { DOCUMENT_MANAGER_ROUTE, EDIT_DOCUMENT_FORM_ID } from '../types';
import { createGenericFormComponent } from '../../../components/form/createGenericFormComponent';
import { DocumentFormData } from '../../../types/documents/document';

const EditDocumentForm = createGenericFormComponent(EDIT_DOCUMENT_FORM_ID, DocumentFormComponent);

export const EditDocument: React.FC = () => {
  const dispatch = useDispatch();
  const { id } = useParams<'id'>();

  useEffect(() => {
    id && dispatch(loadDocumentForEdit.request(id));
  }, [dispatch, id]);

  const options = useSelector(documentFormOptionsPropsSelector);
  const isDisabled = useSelector(isDocumentFormDisabledSelector);
  const submitHandler = useCallback((values: DocumentFormData) => new Promise<void>((resolve, reject) => {
    dispatch(submitDocumentEditForm(values, { resolve, reject }));
  }), [dispatch]);

  return (
    <Grid container={true} spacing={3}>
      <Grid item={true} xs={12}>
        <Grid container={true}>
          <Grid item={true} xs={6} container={true}>
            <Grid item={true}>
              <Typography component="h2" variant="h5">
                <BreadcrumbLink to={DOCUMENT_MANAGER_ROUTE}>Documents</BreadcrumbLink> / Edit Document <small>({id})</small>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container={true}>
        <Grid item={true} xs={12}>
          <PaperWrapper>
            <EditDocumentForm
              {...options}
              isDisabled={isDisabled}
              submitHandler={submitHandler}
            />
          </PaperWrapper>
        </Grid>
      </Grid>
    </Grid>
  );
};
