import { Typography } from '@mui/material';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useCallback, useEffect, useMemo } from 'react';

import { IconComponent } from '../../../components/ui/Icon';
import { MainContainerCardComponent } from '../../../components/ui/MainContainerCard';
import { toggleQuickAddFormVisibility } from '../../../redux/modules/case-manager/actions';
import { CaseManagerQuickAddForm } from '../../../redux/modules/case-manager/reducer';
import {
  fetchDependentRepresentativeData,
  startNewRepresentativeOperation,
  submitRepresentativeCreateForm
} from '../../../redux/modules/representatives/actions';
import { RepresentativeForm } from '../../../redux/modules/representatives/types';
import { RepresentativeFormComponent } from '../components/RepresentativeFormComponent';
import {
  createRepresentativeFormDispatchers,
  representativeFormOptionsSelector
} from '../selectors';
import { createGenericFormComponent } from '../../../components/form/createGenericFormComponent';
import { HighlightOffIcon } from '../../../components/ui/mui-icons';

const NewRepresentativeQuickAddForm = createGenericFormComponent('new-representative', RepresentativeFormComponent)

type Props = {
  fieldName: string,
  contextTitle: string,
  form: RepresentativeForm,
  parentForm: string,
  quickAddFormId: CaseManagerQuickAddForm
};

export const NewRepresentativeQuickAdd: React.FC<Props> = (
  {
    form,
    parentForm,
    fieldName,
    quickAddFormId,
    contextTitle
  }
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDependentRepresentativeData.request());
    dispatch(startNewRepresentativeOperation(form));
  }, []);

  const formProps = useSelector(representativeFormOptionsSelector);

  const formDispatchers = useMemo(() =>
      createRepresentativeFormDispatchers(dispatch, submitRepresentativeCreateForm, form, parentForm, fieldName, quickAddFormId)
    , [form, parentForm, fieldName, quickAddFormId]);

  const toggleQuickAddOpen = useCallback(() => dispatch(toggleQuickAddFormVisibility({ form: quickAddFormId })), [quickAddFormId]);

  return (
    <Container>
      <MainContainerCardComponent>
        <IconContainer>
          <IconComponent
            icon={HighlightOffIcon}
            onClick={toggleQuickAddOpen}
          />
        </IconContainer>
        <Typography component="h4" variant="h5">Create Representative ({contextTitle})</Typography>
        <NewRepresentativeQuickAddForm
          form={form} {...formProps} {...formDispatchers} />
      </MainContainerCardComponent>
    </Container>
  );
}

const Container = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const IconContainer = styled.span`
  float: right;
`;
