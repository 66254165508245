import { DOCUMENT_TYPE_MANAGER_ROUTE } from '../modules/document-type-manager/types';
import { DOCUMENT_AUTHOR_MANAGER_ROUTE } from '../modules/document-author-manager/types';
import { DOCUMENT_MANAGER_ROUTE } from '../modules/document-manager/types';
import { CASE_MANAGER_ROUTE } from '../modules/case-manager/types';
import { SelectOption } from '../types/util';

type NavItem = {
  name: string,
  path: string,
  description: string
};

export const DOCUMENT_MODULE_NAVIGATION_ITEMS: NavItem[] = [
  {
    name: 'Document Manager',
    path: DOCUMENT_MANAGER_ROUTE,
    description: 'Manage documents in the system'
  },
  {
    name: 'Document Type Manager',
    path: DOCUMENT_TYPE_MANAGER_ROUTE,
    description: 'Manage document types in the system'
  },
  {
    name: 'Document Author Manager',
    path: DOCUMENT_AUTHOR_MANAGER_ROUTE,
    description: 'Manage document authors in the system'
  }
];

export const CASE_MODULE_NAVIGATION_ITEMS: NavItem[] = [
  {
    name: 'Case Manager',
    path: CASE_MANAGER_ROUTE,
    description: 'Manage cases in the system'
  },
  {
    name: 'Judge Manager',
    path: '/judge-manager',
    description: 'Manage judges in the system'
  },
  {
    name: 'Firm Manager',
    path: '/firm-manager',
    description: 'Manage firms in the system'
  },
  {
    name: 'Representative Manager',
    path: '/representative-manager',
    description: 'Manage representatives in the system'
  },
  {
    name: 'State Manager',
    path: '/state-manager',
    description: 'Manage state/provinces in the system'
  },
  {
    name: 'Country Manager',
    path: '/country-manager',
    description: 'Manage countries in the system'
  },
  {
    name: 'Case Subject Manager',
    path: '/case-subject-manager',
    description: 'Manager case subjects in the system'
  },
  {
    name: 'Court Manager',
    path: '/court-manager',
    description: 'Manager courts in the system'
  },
  {
    name: 'Case Type Manager',
    path: '/case-type-manager',
    description: 'Manage case types'
  },
  {
    name: 'Industry Group Manager',
    path: '/industry-group-manager',
    description: 'Manage industry groups'
  },
  {
    name: 'Subject Area Manager',
    path: '/subject-area-manager',
    description: 'Manage subject areas'
  },
  {
    name: 'Legal Domain Manager',
    path: '/legal-domain-manager',
    description: 'Manage legal domains in the system'
  }
];

export const AUTH_LOCAL_STORAGE_KEY = 'CURATOR_API_KEY';

export const PAGE_SIZES = [25, 50, 100];

export const PAGINATION_DEFAULT = { page: 1, perPage: PAGE_SIZES[0], total: 0 };

export const BODY_AVAILABILITY_OPTIONS: SelectOption[] = [
  { label: 'Body Available', value: 'available' },
  { label: 'Body Not Available', value: 'restricted' }
];
