import { createAsyncAction } from 'typesafe-actions';

import { ICuratorError } from '../../../lib/errors/types';
import { WithFetchMeta } from '../../util/fetch';
import { LegalDomainEntity } from '../../../types/cases/legal-domain';

export const legalDomainsFetchActions = createAsyncAction(
  '@@CURATOR/LEGAL_DOMAINS/REQUEST',
  '@@CURATOR/LEGAL_DOMAINS/SUCCESS',
  '@@CURATOR/LEGAL_DOMAINS/FAILURE'
)<Partial<LegalDomainEntity>, WithFetchMeta<LegalDomainEntity[]>, ICuratorError>();
