import { createAsyncEntitySelector } from '../../../entity-selector';
import { namedEntityFormatter } from '../../../../redux/modules/case-manager/utils';
import { CuratorApi } from '../../../../services/CuratorApi';

function search(api: CuratorApi, filter: string) {
  return api.searchDocumentAuthors(
    {
      filter: {
        name: filter
      },
      pagination: {
        page: 1,
        perPage: 20
      }
    }
  ).then((result) =>
    result.data.map((documentAuthor) => ({
      label: namedEntityFormatter(documentAuthor),
      value: documentAuthor.id
    })));
}

export const DocumentAuthorField = createAsyncEntitySelector({
  search,
  pluralEntityName: 'Document Authors',
  key: 'document-authors'
});
