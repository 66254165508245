import { Box, Divider, Grid, Typography } from '@mui/material';
import * as React from 'react';

import { List, ListItem } from '../../../components/ui/List';
import { isEmpty } from '../../../lib/utils';
import { Omit } from '../../../types/util';
import { ImportedCase } from '../../../types/cases/case';

type ImportData = {
  [K: string]: string | string[] | undefined | null,
} & Partial<Omit<ImportedCase, 'caseBody'>>;

type ImportDetailsPanelProps = {
  importData: Partial<ImportData> | null
};

type DisplayValue = {
  [K in keyof ImportData]?: string
};

const DISPLAY_VALUES: DisplayValue = {
  title: 'Title',
  citation: 'Citation',
  judges: 'Judges',
  authoringJudge: 'Authoring Judge',
  subjects: 'Subjects',
  court: 'Court',
  state: 'State'
};

const renderValue = (value: any) => {
  if (Array.isArray(value) && value.length > 0) {
    return (
      <List>
        <Typography variant="body2">
          {value.map((item) => <ListItem key={item}>{item}</ListItem>)}
        </Typography>
      </List>
    );
  }
  return <Typography variant="body2">{isEmpty(value) ? 'No value(s)' : value}</Typography>;
};

const renderValues = (values: ImportData | null) => {
  if (!values) {
    return;
  }
  const keys = Object.keys(DISPLAY_VALUES);
  return keys.map((k: string) => {
    if (values.hasOwnProperty(k) && !!values[k]) {
      return (
        <Grid item={true} key={k}>
          <Divider />
          <Grid container={true} direction="row">
            <Grid item={true}>
              <Box p={2} width={260} fontWeight="bold">
                {DISPLAY_VALUES[k]}
              </Box>
            </Grid>
            <Grid item={true}>
              <Box p={2}>
                {renderValue(values[k])}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return null;
  });
};

export const CaseImportInfoPanelComponent: React.FC<ImportDetailsPanelProps> = (props) => (
  <Grid container={true} direction="column">
    <Grid item={true}>
      <Grid container={true} direction="row">
        <Grid item={true}>
          <Box p={2}>
            <Typography component="h3" variant="h6">Imported Data</Typography>
          </Box>
        </Grid>
      </Grid>
    </Grid>
    {renderValues(props.importData)}
  </Grid>
);
