import { Button } from '@mui/material';
import * as React from 'react';
import { Field, InjectedFormProps } from 'redux-form';

import { SelectField } from '../../../components/form/SelectField';
import { TextField } from '../../../components/form/TextField';
import { ButtonRow, Column, FullRowColumn, Row } from '../../../components/ui/FlexTable';
import { FirmFormDispatchers, FirmFormOptions } from '../selectors';
import { FirmFormData } from '../../../types/cases/firm';

type FormProps = FirmFormOptions & FirmFormDispatchers;

type ReduxFormInjectedProps = InjectedFormProps<FirmFormData, FormProps>;

export const FirmFormComponent: React.FC<ReduxFormInjectedProps & FormProps> = (
  {
    countryOptions,
    error,
    submitForm
  }
) => {
  return (
    <>
      <Row>
        <Column>
          <Field
            name="name"
            label="Name"
            component={TextField}
          />
          <Field
            name="country"
            label="Country"
            component={SelectField}
            isMulti={false}
            options={countryOptions}
          />
        </Column>
      </Row>
      <ButtonRow>
        <Column>
          <Button type="button" onClick={submitForm} variant={'contained'} color="primary">Submit</Button>
        </Column>
      </ButtonRow>
      {
        error &&
        (
          <Row>
            <FullRowColumn>
              <pre>{error}</pre>
            </FullRowColumn>
          </Row>
        )
      }
    </>);
};
