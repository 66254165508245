import React from 'react';
import { Control, Controller } from 'react-hook-form';

import { TextField } from '../text';
import { CommonFieldProps } from '../../shared';

interface FormFieldProps extends CommonFieldProps {
  control: Control<any>;
}

export const HookFormControlledTextField: React.FC<FormFieldProps> = (
  {
    name,
    control,
    label
  }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField label={label} {...field} />
      )}
    />
  )
}
