import { Button, Grid } from '@mui/material';
import * as React from 'react';
import { Field, Form, InjectedFormProps } from 'redux-form';

import { DatePickerField } from '../../../components/form/DatePickerField';
import { SelectField } from '../../../components/form/SelectField';
import { TextField } from '../../../components/form/TextField';
import { SelectOption } from '../../../types/util';
import { DocumentFilterProps } from '../selectors';
import { DocumentAuthorField } from '../../../lib/entity-manager/forms/fields/authors';
import { ReduxFormEntitySelectorField } from '../../../lib/entity-manager/forms/fields/redux-form/entity-selector';
import { getFlaggedSelectOptions } from '../../../components/FlagComponent/utils';

export type DocumentFilterFormProps = {
  disabled: boolean,
  bodyAvailability: SelectOption[]
} & DocumentFilterProps;

export type DocumentFilterFormData = {
  type: SelectOption,
  title: string,
  author: SelectOption,
  country: SelectOption,
  citation: string,
  indexNumber: string,
  'dateReleased_from': string,
  'dateReleased_to': string,
  'datePublished_from': string,
  'datePublished_to': string,
  bodyAvailability: SelectOption,
  flaggedAs: SelectOption
};

const FORM_CONTROL_MARGIN = 'normal';

type ReduxFormInjectedProps = InjectedFormProps<DocumentFilterFormData, DocumentFilterFormProps>;

export const DocumentFilterComponent: React.FC<ReduxFormInjectedProps & DocumentFilterFormProps> = (
  {
    handleSubmit,
    applyFilters,
    resetFilters,
    documentTypeOptions,
    disabled,
    bodyAvailability
  }
) => (
  <Form onSubmit={handleSubmit((val) => {
    applyFilters(val);
  })}>
    <Grid container={true} spacing={3} alignContent="center">
      <Grid item={true} xs={3}>
        <Grid container={true} direction="column" spacing={1}>
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            component={TextField}
            name="title"
            label="Document Title / ID"
          />
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            component={TextField}
            name="citation"
            label="Citation"
          />
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            component={TextField}
            name="indexNumber"
            label="Index Number"
          />
          <Field
            name="flaggedAs"
            label="Document Flagged As"
            component={SelectField}
            isMulti={false}
            clearable={true}
            options={getFlaggedSelectOptions()}
          />
        </Grid>
      </Grid>
      <Grid item={true} xs={3}>
        <Grid container={true} direction="column" spacing={1}>
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            clearable={true}
            component={SelectField}
            name="type"
            label="Document Type"
            options={documentTypeOptions}
          />
          <Field
            disabled={disabled}
            name="author"
            label="Document Author"
            isMulti={false}
            formComponent={DocumentAuthorField}
            component={ReduxFormEntitySelectorField}
          />
        </Grid>
      </Grid>
      <Grid item={true} xs={3}>
        <Grid container={true} direction="column" spacing={1}>
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            component={DatePickerField}
            name="dateReleased_from"
            label="Date Released From"
          />
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            component={DatePickerField}
            name="dateReleased_to"
            label="Date Released To"
          />
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            clearable={true}
            component={SelectField}
            name="bodyAvailability"
            label="Body Availability"
            options={bodyAvailability}
          />
        </Grid>
      </Grid>
      <Grid item={true} xs={3}>
        <Grid container={true} direction="column" spacing={1}>
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            component={DatePickerField}
            name="datePublished_from"
            label="Date Published From"
          />
          <Field
            margin={FORM_CONTROL_MARGIN}
            disabled={disabled}
            component={DatePickerField}
            name="datePublished_to"
            label="Date Published To"
          />
        </Grid>
      </Grid>
    </Grid>
    <Grid container={true} spacing={3} justifyContent="flex-end">
      <Grid item={true}>
        <Button type="submit" variant="contained" color="primary" disabled={disabled}>Apply</Button>
      </Grid>
      <Grid item={true}>
        <Button type="button" onClick={resetFilters}>Clear</Button>
      </Grid>
    </Grid>
  </Form>
);
