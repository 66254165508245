import * as React from 'react';
import styled from 'styled-components';

type ErrorBoundaryProps = {
  children: any,
};

type ErrorBoundaryState = {
  error: Error | null
};

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: null
    };
  }

  componentDidCatch(error: Error | null) {
    this.setState({ error: error || new Error('Unknown Error') });
  }

  render() {
    if (this.state.error) {
      return (
        <MessageContainer>
          <span>Error occurred rendering table</span>
        </MessageContainer>
      );
    }
    return this.props.children;
  }
}

const MessageContainer = styled.div`
  text-align: center;
  padding: 20px;
`;
