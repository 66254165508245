import { createAsyncAction, createAction } from 'typesafe-actions';

import { ICuratorError } from '../../../lib/errors/types';
import { LoadEntityForDeleteResponse } from '../../../types/command-types';
import { FormSubmissionMeta } from '../../../types/forms';
import {
  PageNumberType,
  PageSizeType,
  PaginatedQuery,
  PaginationConfig,
  WithFetchMeta,
  WithPaginatedFetchMeta
} from '../../util/fetch';
import { CourtEntity, CourtFormData } from '../../../types/cases/court';

export const courtsPaginatedFetchActions = createAsyncAction(
  '@@CURATOR/COURTS/PAGINATED/REQUEST',
  '@@CURATOR/COURTS/PAGINATED/SUCCESS',
  '@@CURATOR/COURTS/PAGINATED/FAILURE'
)<PaginatedQuery<CourtEntity>, WithPaginatedFetchMeta<CourtEntity>, ICuratorError>();

export const courtsFetchActions = createAsyncAction(
  '@@CURATOR/COURTS/REQUEST',
  '@@CURATOR/COURTS/SUCCESS',
  '@@CURATOR/COURTS/FAILURE'
)<void, WithFetchMeta<CourtEntity[]>, ICuratorError>();

export const refreshCourtsList = createAction('@@CURATOR/COURTS_MANAGER/REFRESH_LIST')<void>();

export const updateCourtsList = createAction('@@CURATOR/COURTS_MANAGER/UPDATE_LIST')<PaginationConfig>();

export const changeCourtsListPage = createAction('@@CURATOR/COURTS_MANAGER/CHANGE_PAGE')<PageNumberType>();

export const changeCourtsListPageSize = createAction('@@CURATOR/COURTS_MANAGER/CHANGE_PAGE_SIZE')<PageSizeType>();

export const fetchDependentCourtData = createAsyncAction(
  '@@CURATOR/COURTS_MANAGER/FETCH_DEPENDENT_DATA',
  '@@CURATOR/COURTS_MANAGER/FETCH_DEPENDENT_DATA_SUCCESS',
  '@@CURATOR/COURTS_MANAGER/FETCH_DEPENDENT_DATA_FAILURE')<void, void, void>();

export const startNewOperation = createAction('@@CURATOR/COURTS_MANAGER/START_NEW_COURT_OPERATION')<void>();

export const submitCourtCreateForm = createAction('@@CURATOR/COURTS_MANAGER/SUBMIT_NEW_COURT')<Partial<CourtFormData>, FormSubmissionMeta>();

export const submitCourtCreateFormCompleted = createAction('@@CURATOR/COURTS_MANAGER/SUBMIT_NEW_COURT_COMPLETED')<void>();

export const submitCourtCreateFormFailed = createAction('@@CURATOR/COURTS_MANAGER/SUBMIT_NEW_COURT_FAILED')<ICuratorError>();

export const submitCourtEditForm = createAction('@@CURATOR/COURTS_MANAGER/SUBMIT_EDIT_COURT')<Partial<CourtFormData>, FormSubmissionMeta>();

export const submitCourtEditFormCompleted = createAction('@@CURATOR/COURTS_MANAGER/SUBMIT_EDIT_COURT_COMPLETED')<void>();

export const submitCourtEditFormFailed = createAction('@@CURATOR/COURTS_MANAGER/SUBMIT_EDIT_COURT_FAILURE')<ICuratorError>();

export const loadCourtForEdit = createAsyncAction(
  '@@CURATOR/COURTS_MANAGER/LOAD_COURT_FOR_EDIT',
  '@@CURATOR/COURTS_MANAGER/LOAD_COURT_FOR_EDIT_SUCCESS',
  '@@CURATOR/COURTS_MANAGER/LOAD_COURT_FOR_EDIT_FAILURE'
)<string, CourtEntity, ICuratorError>();

export const loadCourtForDelete = createAsyncAction(
  '@@CURATOR/COURTS_MANAGER/LOAD_COURT_FOR_DELETE',
  '@@CURATOR/COURTS_MANAGER/LOAD_COURT_FOR_DELETE_SUCCESS',
  '@@CURATOR/COURTS_MANAGER/LOAD_COURT_FOR_DELETE_FAILURE'
)<string, LoadEntityForDeleteResponse<CourtEntity>, ICuratorError>();

export const deleteCourt = createAsyncAction(
  '@@CURATOR/CASE_MANAGER/DELETE_COURT',
  '@@CURATOR/CASE_MANAGER/DELETE_COURT_SUCCESS',
  '@@CURATOR/CASE_MANAGER/DELETE_COURT_FAILURE')<CourtEntity['id'], void, ICuratorError>();

export type SetCourtPayload = {
  formId: string,
  data: CourtEntity
};

export const setFormDataFromCourt = createAction('@@CURATOR/COURTS_MANAGER/SET_FORM_DATA_FROM_COURT')<SetCourtPayload>();
