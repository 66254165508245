import React from 'react';
import { BrowserHistory } from 'history';
import { Router } from 'react-router';

export const BrowserRouter: React.FC<{ browserHistory: BrowserHistory }> = (
  {
    browserHistory,
    children
  }
) => {
  const historyRef = React.useRef<BrowserHistory>(browserHistory);

  let history = historyRef.current;
  let [state, setState] = React.useState({
    action: history.action,
    location: history.location
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
}
