import * as React from 'react';
import { Field, FieldArrayFieldsProps } from 'redux-form';
import { Box } from '@mui/material';

import { SelectField } from '../form/SelectField';
import { TextField } from '../form/TextField';
import { MultiValueTextField } from '../form/MultiValueTextField';
import { IconButtonComponent } from '../ui/IconButton';
import { DeleteIcon } from '../ui/mui-icons';

import { doesFlagHaveBy, getFlaggedSelectOptions } from './utils';
import { FlaggedAsFormState } from './types';

type SingleFlagEntryProps = { index: number, member: string; fields: FieldArrayFieldsProps<FlaggedAsFormState> };
export const SingleFlagEntry: React.FC<SingleFlagEntryProps> = (props) => {
  const { index, member, fields} = props;
  const [showBy, setShowBy] = React.useState<boolean>(false);

  React.useEffect(() => {
    const type = fields.get(index).type?.value;
    if (doesFlagHaveBy(type)) {
      setShowBy(true);
    }
  }, [fields]);

  const onTypeChange = React.useCallback((v: any) => {
    if (doesFlagHaveBy(v.value)) {
      setShowBy(true);
      return;
    }
    setShowBy(false);
  }, []);

  return (
    <>
      <h4>Flag #{index + 1}</h4>
      <Field
        name={`${member}.type`}
        label="Type"
        component={SelectField}
        isMulti={false}
        options={getFlaggedSelectOptions()}
        onChange={onTypeChange}
      />
      <Field
        name={`${member}.reason`}
        label="Reason"
        component={TextField}
      />
      {showBy && (
        <Field
          name={`${member}.by`}
          label="By"
          component={MultiValueTextField}
        />
      )}
    <br />
      <Box textAlign="right">
        <IconButtonComponent onClick={() => fields.remove(index)} icon={DeleteIcon}> Delete Flag </IconButtonComponent>
      </Box>
    </>
  );
};
