import { useQueryClient } from '@tanstack/react-query';
import React from 'react';

import { ManagerContext } from '../contexts/manager-context';

export function useEntityRefresh() {
  const queryClient = useQueryClient();
  const { dataKey } = React.useContext(ManagerContext);
  return React.useCallback(() => queryClient.invalidateQueries(['list', dataKey]), [queryClient, dataKey]);
}
