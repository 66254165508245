import { createAsyncAction } from 'typesafe-actions';

import { ICuratorError } from '../../../lib/errors/types';
import { WithFetchMeta } from '../../util/fetch';
import { CaseBodyEntity } from '../../../types/cases/case-body';

export const caseBodyFetchActions = createAsyncAction(
  '@@CURATOR/CASE_BODY/REQUEST',
  '@@CURATOR/CASE_BODY/SUCCESS',
  '@@CURATOR/CASE_BODY/FAILURE'
)<Partial<CaseBodyEntity>, WithFetchMeta<CaseBodyEntity[]>, ICuratorError>();
