import { arrayPush, initialize, SubmissionError } from 'redux-form';
import { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import { forkJoin, from, merge, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { PAGINATION_DEFAULT } from '../../../constants';
import { DeleteError } from '../../../lib/errors/DeleteError';
import { EntityFetchError } from '../../../lib/errors/EntityFetchError';
import { EntityCreateError } from '../../../lib/errors/EntityCreateError';
import { EntityUpdateError } from '../../../lib/errors/EntityUpdateError';
import { firmFormOptionsSelector } from '../../../modules/firm-manager/selectors';
import { Services } from '../../../services';
import { entityToSelectOption } from '../../util/forms';
import { RootAction } from '../actions';
import { toggleQuickAddFormVisibility } from '../case-manager/actions';
import { countriesFetchActions } from '../countries/actions';
import { RootState } from '../root';
import { pushRoute } from '../router/actions';

import {
  applyFirmFilters,
  changeFirmsListPage,
  changeFirmsListPageSize,
  clearFirmFilters,
  deleteFirm,
  fetchDependentFirmData,
  firmsPaginatedFetchActions,
  loadFirmForDelete,
  loadFirmForEdit,
  refreshFirmsList,
  SetFirmPayload,
  setFormDataFromFirm,
  submitFirmCreateForm,
  submitFirmCreateFormCompleted,
  submitFirmCreateFormFailed,
  submitFirmEditForm,
  submitFirmEditFormCompleted,
  submitFirmEditFormFailed,
  updateFirmsList
} from './actions';
import {
  formatFirmLabel,
  selectFirmFilterFormData,
  selectFirmFilterQuery,
  selectFirmsPagination
} from './selectors';
import { transformFirmFormDataToFirmSubmission, transformFirmToFormData } from './utils';

const requestActions = [
  countriesFetchActions.request()
];

const successActionCreators = [
  countriesFetchActions.success
];

export const fetchPaginatedFirmsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(firmsPaginatedFetchActions.request)),
    mergeMap((action) =>
      from(curatorApi.searchFirms(action.payload)).pipe(
        map(firmsPaginatedFetchActions.success),
        catchError((err) => of(firmsPaginatedFetchActions.failure(err)))
      )
    )
  );

export const fetchFirmManagerDependentDataEpic: Epic<RootAction, RootAction> = (action$) =>
  action$
    .pipe(
      filter(isActionOf(fetchDependentFirmData.request)),
      switchMap(() => requestActions)
    );

export const fetchFirmManagerDependentDataEpicSuccessWatcher: Epic<RootAction, RootAction> = (action$) =>
  action$
    .pipe(
      filter(isActionOf(fetchDependentFirmData.request)),
      switchMap(() =>
        // TODO: Ideally, we should handle any of the request actions failing and dispatch fetchDependentCaseManagerData.failure.
        //   Wasn't able to get `race` to work, maybe there's some `takeUntil` magic
        forkJoin(
          successActionCreators.map((action) =>
            action$.pipe(
              filter(isActionOf(action)),
              take(1)
            )
          )
        )
      ),
      map(() => fetchDependentFirmData.success())
    );

const refreshEpic: Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(refreshFirmsList)),
    withLatestFrom(store$),
    switchMap(([_, state]) => {
      const currentPagination = selectFirmsPagination(state);
      const currentFilters = selectFirmFilterQuery(state);
      const currentFilterFormData = selectFirmFilterFormData(state);
      return [
        firmsPaginatedFetchActions.request({
          filter: currentFilters,
          pagination: currentPagination
        }),
        initialize('firm-filters', currentFilterFormData)
      ];
    })
  );

const updateEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(updateFirmsList)),
    map((action) => {
      return firmsPaginatedFetchActions.request(action.payload);
    })
  );

const changePageEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeFirmsListPage)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = selectFirmsPagination(state);
      const currentFilters = selectFirmFilterQuery(state);
      return updateFirmsList({
        filter: currentFilters,
        pagination: {
          ...currentPagination,
          page: action.payload
        }
      });
    })
  );

const changePageSizeEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeFirmsListPageSize)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = selectFirmsPagination(state);
      const currentFilters = selectFirmFilterQuery(state);
      return updateFirmsList({
        filter: currentFilters,
        pagination: {
          ...currentPagination,
          page: 1,
          perPage: action.payload
        }
      });
    })
  );

const applyFiltersEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(applyFirmFilters)),
    withLatestFrom(store$),
    map(([_, state]) => {
      const currentFilters = selectFirmFilterQuery(state);
      return updateFirmsList({
        filter: currentFilters,
        pagination: PAGINATION_DEFAULT
      });
    })
  );

const clearFiltersEpic: Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(clearFirmFilters)),
    switchMap(() => {
      return [
        initialize('firm-filters', {}),
        fetchDependentFirmData.request(),
        updateFirmsList({ filter: {}, pagination: PAGINATION_DEFAULT })
      ];
    })
  );

export const createFirmEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(submitFirmCreateForm)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const { reject, resolve, quickAddForm, parentForm, fieldId, formId } = action.meta;
        const guid = state.firms.new.operationGuid[formId];
        if (!guid) {
          reject(new SubmissionError({ _error: 'Failed to create firm, operation GUID missing' }));
          return [submitFirmCreateFormFailed(new EntityCreateError('operation guid missing'))];
        }
        const submission = {
          guid,
          data: transformFirmFormDataToFirmSubmission(action.payload)
        };
        return from(curatorApi.createFirm(submission)).pipe(
          switchMap((response) => {
            if (response.status >= 300) {
              // TODO: Parse errors into friendlier format and field-specific where possible
              reject(new SubmissionError({ _error: `Failed to create firm\n\n${JSON.stringify(response.data, null, 2)}` }));
              return [submitFirmCreateFormFailed(new EntityCreateError('server error'))];
            }
            resolve();
            if (quickAddForm) {
              return merge(
                from([
                  submitFirmCreateFormCompleted(),
                  toggleQuickAddFormVisibility({ form: quickAddForm })
                ]),
                (parentForm && fieldId) ? [
                  arrayPush(parentForm, fieldId, entityToSelectOption(formatFirmLabel, response.data.result))
                ] : []
              );
            } else {
              return [
                submitFirmCreateFormCompleted(),
                pushRoute('/firm-manager')
              ];
            }
          }),
          catchError((err: Error) => {
            reject(new SubmissionError({ _error: `Failed to create firm. (${err.message})` }));
            return of(submitFirmCreateFormFailed(new EntityCreateError('unknown error')));
          })
        );
      })
    );

export const updateFirmEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(submitFirmEditForm)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const { reject, resolve } = action.meta;
        const id = state.firms.edit.id;
        const guid = state.firms.edit.operationGuid;
        if (!id) {
          reject(new SubmissionError({ _error: 'Failed to update firm, id missing' }));
          return [submitFirmEditFormFailed(new EntityUpdateError('firm id missing'))];
        }
        if (!guid) {
          reject(new SubmissionError({ _error: 'Failed to update firm, operation GUID missing' }));
          return [submitFirmEditFormFailed(new EntityUpdateError('operation guid missing'))];
        }
        const submission = {
          id,
          guid,
          data: transformFirmFormDataToFirmSubmission(action.payload)
        };
        return from(curatorApi.updateFirm(submission)).pipe(
          switchMap((response) => {
            if (response.status >= 300) {
              // TODO: Parse errors into friendlier format and field-specific where possible
              reject(new SubmissionError({ _error: `Failed to update firm\n\n${JSON.stringify(response.data, null, 2)}` }));
              return [submitFirmEditFormFailed(new EntityUpdateError('server error'))];
            }
            resolve();
            return [submitFirmEditFormCompleted(), pushRoute('/firm-manager')];
          }),
          catchError((err: Error) => {
            reject(new SubmissionError({ _error: `Failed to update firm. (${err.message})` }));
            return of(submitFirmEditFormFailed(new EntityUpdateError('unknown error')));
          })
        );
      })
    );

export const fetchFirmForEditEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(loadFirmForEdit.request)),
      switchMap((action) => from(curatorApi.getFirm(action.payload)).pipe(
        mergeMap((response) =>
          of(
            loadFirmForEdit.success(response.data),
            setFormDataFromFirm({ formId: 'firm-edit', data: response.data })
          )
        ),
        catchError((err: Error) => {
          return from([
            loadFirmForEdit.failure(new EntityFetchError(err.message)),
            pushRoute('/firm-manager')
          ]);
        })
      )));

export const fetchFirmForDeleteEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(loadFirmForDelete.request)),
      switchMap((action) =>
        forkJoin([
          from(curatorApi.getFirm(action.payload)),
          from(curatorApi.getCaseQueryEntityDeleteEligibility('firm', action.payload))
        ]).pipe(
          switchMap(([fetchEntityResponse, deleteEligibilityResponse]) =>
            of(loadFirmForDelete.success({
              data: fetchEntityResponse.data,
              info: deleteEligibilityResponse.data
            }))),
          catchError((err: Error) => {
            return from([
              loadFirmForEdit.failure(new EntityFetchError(err.message)),
              pushRoute('/firm-manager')
            ]);
          })
        ))
    );

export const deleteFirmEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(deleteFirm.request)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const guid = state.firms.delete.operationGuid;
        if (!guid) {
          return of(deleteFirm.failure(new DeleteError('operation guid missing')));
        }
        if (!state.firms.delete.info?.canDelete) {
          return of(deleteFirm.failure(new DeleteError('delete failed state check')));
        }
        const submission = {
          guid,
          id: action.payload
        };
        return from(curatorApi.deleteFirm(submission)).pipe(
          switchMap(() => of(deleteFirm.success(), pushRoute('/firm-manager'))),
          catchError((err) => of(deleteFirm.failure(err)))
        );
      })
    );

export const waitForSuccessAndInitializeForm = (action$: ActionsObservable<RootAction>, store$: StateObservable<RootState>, payload: SetFirmPayload) => {
  return action$.pipe(
    filter(isActionOf(fetchDependentFirmData.success)),
    take(1),
    withLatestFrom(store$),
    map(([_, state]) => {
      return initialize(payload.formId, transformFirmToFormData(payload.data, firmFormOptionsSelector(state)));
    })
  );
};

export const setFormDataFromFirmEpic: Epic<RootAction, RootAction, RootState> = (action$, store$) =>
  action$
    .pipe(
      filter(isActionOf(setFormDataFromFirm)),
      map((action) => action.payload),
      switchMap((payload) =>
        merge(
          // Dispatch action to refresh dependent data
          of(fetchDependentFirmData.request()),
          // Listen for the dependent data success and then initialize the form values
          waitForSuccessAndInitializeForm(action$, store$, payload)
        )
      )
    );

export const firmEpics = [
  fetchPaginatedFirmsEpic,
  refreshEpic,
  updateEpic,
  changePageEpic,
  changePageSizeEpic,
  fetchFirmManagerDependentDataEpic,
  fetchFirmManagerDependentDataEpicSuccessWatcher,
  createFirmEpic,
  updateFirmEpic,
  fetchFirmForEditEpic,
  setFormDataFromFirmEpic,
  applyFiltersEpic,
  clearFiltersEpic,
  fetchFirmForDeleteEpic,
  deleteFirmEpic
];
