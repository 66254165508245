import { createAsyncAction, createAction } from 'typesafe-actions';

import { ICuratorError } from '../../../lib/errors/types';
import { PageNumberType, PageSizeType, PaginatedQuery, WithFetchMeta, WithPaginatedFetchMeta } from '../../util/fetch';
import { LoadEntityForDeleteResponse } from '../../../types/command-types';
import { FormSubmissionMeta } from '../../../types/forms';
import { DocumentTypeEntity, DocumentTypeFormData } from '../../../types/documents/document-type';

export const documentTypeFetchActions = createAsyncAction(
  '@@CURATOR/DOCUMENT_TYPE/REQUEST',
  '@@CURATOR/DOCUMENT_TYPE/SUCCESS',
  '@@CURATOR/DOCUMENT_TYPE/FAILURE'
)<void, WithFetchMeta<DocumentTypeEntity[]>, ICuratorError>();

export const documentTypePaginatedFetchActions = createAsyncAction(
  '@@CURATOR/DOCUMENT_TYPE/PAGINATED/REQUEST',
  '@@CURATOR/DOCUMENT_TYPE/PAGINATED/SUCCESS',
  '@@CURATOR/DOCUMENT_TYPE/PAGINATED/FAILURE'
)<PaginatedQuery<DocumentTypeEntity>, WithPaginatedFetchMeta<DocumentTypeEntity>, ICuratorError>();

export const refreshDocumentTypesList = createAction('@@CURATOR/DOCUMENT_TYPE/REFRESH_LIST')<void>();

export const changeDocumentTypesListPage = createAction('@@CURATOR/DOCUMENT_TYPE/CHANGE_PAGE')<PageNumberType>();
export const changeDocumentTypesListPageSize = createAction('@@CURATOR/DOCUMENT_TYPE/CHANGE_PAGE_SIZE')<PageSizeType>();

export const loadDocumentTypeForEdit = createAsyncAction(
  '@@CURATOR/DOCUMENT_TYPE/LOAD_FOR_EDIT/REQUEST',
  '@@CURATOR/DOCUMENT_TYPE/LOAD_FOR_EDIT/SUCCESS',
  '@@CURATOR/DOCUMENT_TYPE/LOAD_FOR_EDIT/FAILURE'
)<DocumentTypeEntity['id'], DocumentTypeEntity, ICuratorError>();

export const initializeCreateNewDocumentType = createAction('@@CURATOR/DOCUMENT_TYPE/INIT_CREATE_NEW')<void>();
export const submitDocumentTypeCreateForm = createAction('@@CURATOR/DOCUMENT_TYPE/SUBMIT_CREATE_FORM')<DocumentTypeFormData, FormSubmissionMeta>();
export const submitDocumentTypeCreateFormCompleted = createAction('@@CURATOR/DOCUMENT_TYPE/SUBMIT_CREATE_FORM/COMPLETED')<void>();
export const submitDocumentTypeCreateFormFailed = createAction('@@CURATOR/DOCUMENT_TYPE/SUBMIT_CREATE_FORM/FAILED')<ICuratorError>();

export const submitDocumentTypeEditForm = createAction('@@CURATOR/DOCUMENT_TYPE/SUBMIT_EDIT_FORM')<DocumentTypeFormData, FormSubmissionMeta>();
export const submitDocumentTypeEditFormCompleted = createAction('@@CURATOR/DOCUMENT_TYPE/SUBMIT_EDIT_FORM/COMPLETED')<void>();
export const submitDocumentTypeEditFormFailed = createAction('@@CURATOR/DOCUMENT_TYPE/SUBMIT_EDIT_FORM/FAILED')<ICuratorError>();

export const loadDocumentTypeForDelete = createAsyncAction(
  '@@CURATOR/DOCUMENT_TYPE/LOAD_FOR_DELETE/REQUEST',
  '@@CURATOR/DOCUMENT_TYPE/LOAD_FOR_DELETE/SUCCESS',
  '@@CURATOR/DOCUMENT_TYPE/LOAD_FOR_DELETE/FAILURE'
)<DocumentTypeEntity['id'], LoadEntityForDeleteResponse<DocumentTypeEntity>, ICuratorError>();

export const deleteDocumentType = createAsyncAction(
  '@@CURATOR/DOCUMENT_TYPE/DELETE/REQUEST',
  '@@CURATOR/DOCUMENT_TYPE/DELETE/SUCCESS',
  '@@CURATOR/DOCUMENT_TYPE/DELETE/FAILURE'
)<DocumentTypeEntity['id'], void, ICuratorError>();
