import { ActionType, createReducer } from 'typesafe-actions';
import { v4 as uuidv4 } from 'uuid';

import { PaginationMeta } from '../../util/fetch';
import { ManagerDeleteState, ManagerEditState, ManagerNewState } from '../types';
import { PAGINATION_DEFAULT } from '../../../constants';
import { DocumentAuthorData, DocumentAuthorEntity } from '../../../types/documents/document-author';

import * as documentAuthorActions from './actions';

export type DocumentAuthorManagerState = {
  entities: DocumentAuthorEntity[],
  paginated: {
    entities: DocumentAuthorEntity[],
    pagination: PaginationMeta
  },
  edit: ManagerEditState<DocumentAuthorData>,
  new: ManagerNewState,
  delete: ManagerDeleteState<DocumentAuthorEntity>
};

const initialDocumentTypeManagerState: DocumentAuthorManagerState = {
  entities: [],
  paginated: {
    entities: [],
    pagination: PAGINATION_DEFAULT
  },
  edit: {},
  new: {},
  delete: {}
};

export const documentAuthorReducer = createReducer<DocumentAuthorManagerState, ActionType<typeof documentAuthorActions>>(initialDocumentTypeManagerState)
  .handleAction(documentAuthorActions.documentAuthorFetchActions.request, (state) => ({
    ...state,
    entities: []
  }))
  .handleAction(documentAuthorActions.documentAuthorFetchActions.success, (state, { payload }) => ({
    ...state,
    entities: payload.data
  }))
  .handleAction(documentAuthorActions.documentAuthorPaginatedFetchActions.success, (state, { payload }) => ({
    ...state,
    paginated: {
      entities: payload.data,
      pagination: payload.pagination
    }
  }))
  .handleAction(documentAuthorActions.initializeCreateNewDocumentAuthor, (state) => ({
    ...state,
    new: {
      operationGuid: uuidv4()
    }
  }))
  .handleAction(documentAuthorActions.loadDocumentAuthorForEdit.request, (state, { payload }) => ({
    ...state,
    edit: {
      id: payload
    }
  }))
  .handleAction(documentAuthorActions.loadDocumentAuthorForEdit.success, (state, { payload }) => ({
    ...state,
    edit: {
      ...state.edit,
      data: payload,
      operationGuid: uuidv4()
    }
  }))
  .handleAction(documentAuthorActions.loadDocumentAuthorForEdit.failure, (state) => ({
    ...state,
    edit: {}
  }))
  .handleAction(documentAuthorActions.loadDocumentAuthorForDelete.request, (state) => ({
    ...state,
    delete: {
      loading: true
    }
  }))
  .handleAction(documentAuthorActions.loadDocumentAuthorForDelete.success, (state, { payload }) => ({
    ...state,
    delete: {
      ...state.delete,
      loading: false,
      operationGuid: uuidv4(),
      ...payload
    }
  }))
  .handleAction(documentAuthorActions.loadDocumentAuthorForDelete.failure, (state) => ({
    ...state,
    delete: {
      ...state.delete,
      loading: false,
      info: {
        canDelete: false,
        usage: 0
      }
    }
  }));
