import { BaseMetaDataEntity } from '../common';
import { Flag } from '../../components/FlagComponent/types';

import { CaseTypeEntity } from './case-type';

export enum USFederalCircuit {
  CIRCUIT_ONE = 'Federal 1st Circuit',
  CIRCUIT_TWO = 'Federal 2nd Circuit',
  CIRCUIT_THREE = 'Federal 3rd Circuit',
  CIRCUIT_FOUR = 'Federal 4th Circuit',
  CIRCUIT_FIVE = 'Federal 5th Circuit',
  CIRCUIT_SIX = 'Federal 6th Circuit',
  CIRCUIT_SEVEN = 'Federal 7th Circuit',
  CIRCUIT_EIGHT = 'Federal 8th Circuit',
  CIRCUIT_NINE = 'Federal 9th Circuit',
  CIRCUIT_TEN = 'Federal 10th Circuit',
  CIRCUIT_ELEVEN = 'Federal 11th Circuit',
  CIRCUIT_TWELVE = 'Federal Circuit',
  CIRCUIT_THIRTEEN = 'Federal D.C. Circuit'
}

export const US_FEDERAL_CIRCUIT_VALUES = Object.values(USFederalCircuit);

export type CaseMetaDataEntity = BaseMetaDataEntity & {
  us_federal_circuit: string | null; // Using USFederalCircuit here means we need to validate it
  case_type?: CaseTypeEntity;
  flaggedAs?: Flag[]
}
