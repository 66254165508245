import * as R from 'ramda';

import { RepresentativeFormOptions } from '../../../modules/representative-manager/selectors';
import { transformSelectOptionToValue, transformValueToSelectOption } from '../../util/forms';
import { RepresentativeData, RepresentativeEntity, RepresentativeFormData } from '../../../types/cases/representative';

export const transformRepresentativeToFormData = (representativeData: RepresentativeEntity, formOptions: RepresentativeFormOptions): Partial<RepresentativeFormData> => {
  return {
    givenNames: representativeData.givenNames,
    lastName: representativeData.lastName,
    country: transformValueToSelectOption(formOptions.countryOptions, representativeData.country.id)
  };
};

export const transformRepresentativeFormDataToRepresentativeSubmission = (formData: Partial<RepresentativeFormData>): RepresentativeData => {
  return {
    givenNames: R.trim(formData.givenNames || ''),
    lastName: R.trim(formData.lastName || ''),
    country: transformSelectOptionToValue(formData.country)
  };
};
