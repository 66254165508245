import React from 'react';
import { useQuery } from '@tanstack/react-query';

import { Option } from '../shared';
import { useCuratorApiContext } from '../../entity-manager/hooks/useCuratorApiContext';

import { useEntityAsyncSelectContext } from './useEntityAsyncSelectContext';

const DEFAULT_NO_OPTIONS: Option[] = [];

export function useEntityAsyncSelectData() {
  const [filter, setFilterInternal] = React.useState('');
  const { search, key } = useEntityAsyncSelectContext();
  const { curatorApi } = useCuratorApiContext();
  const setFilter = React.useCallback((newFilter: string) => {
    setFilterInternal(newFilter);
  }, [key]);

  const { data, status, fetchStatus } = useQuery(['entity-select', key, filter], () => {
    return search(curatorApi, filter);
  }, {
    initialData: DEFAULT_NO_OPTIONS,
    enabled: !!filter
  });

  return {
    setFilter,
    status,
    fetchStatus,
    data
  }
}
