import { initialize, SubmissionError } from 'redux-form';
import { Epic } from 'redux-observable';
import { forkJoin, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { isActionOf } from 'typesafe-actions';

import { PAGINATION_DEFAULT } from '../../../constants';
import { DeleteError } from '../../../lib/errors/DeleteError';
import { EntityFetchError } from '../../../lib/errors/EntityFetchError';
import { EntityCreateError } from '../../../lib/errors/EntityCreateError';
import { EntityUpdateError } from '../../../lib/errors/EntityUpdateError';
import { Services } from '../../../services';
import { RootAction } from '../actions';
import { RootState } from '../root';
import { pushRoute } from '../router/actions';

import {
  caseSubjectsFetchActions,
  caseSubjectsPaginatedFetchActions,
  changeCaseSubjectsListPage,
  changeCaseSubjectsListPageSize,
  deleteCaseSubject,
  loadCaseSubjectForDelete,
  loadCaseSubjectForEdit,
  refreshCaseSubjectsList,
  setFormDataFromCaseSubject,
  submitCaseSubjectCreateForm,
  submitCaseSubjectCreateFormCompleted,
  submitCaseSubjectCreateFormFailed,
  submitCaseSubjectEditForm,
  submitCaseSubjectEditFormCompleted,
  submitCaseSubjectEditFormFailed,
  updateCaseSubjectsList
} from './actions';
import { RELATIONS_DEFAULT } from './constants';
import { selectCaseSubjectsPagination } from './selectors';
import { transformCaseSubjectFormDataToCaseSubjectSubmission, transformCaseSubjectToFormData } from './utils';

export const fetchPaginatedCaseSubjectsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(caseSubjectsPaginatedFetchActions.request)),
    mergeMap((action) =>
      from(curatorApi.searchCaseSubjects(action.payload)).pipe(
        map(caseSubjectsPaginatedFetchActions.success),
        catchError((err) => of(caseSubjectsPaginatedFetchActions.failure(err)))
      )
    )
  );

export const fetchCaseSubjectsEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$.pipe(
    filter(isActionOf(caseSubjectsFetchActions.request)),
    mergeMap(() =>
      from(curatorApi.getAllCaseSubjects({})).pipe(
        map(caseSubjectsFetchActions.success),
        catchError((err) => {
          return of(caseSubjectsFetchActions.failure(err));
        })
      )
    )
  );

const refreshEpic: Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(refreshCaseSubjectsList)),
    map(() => caseSubjectsPaginatedFetchActions.request({
      filter: {},
      pagination: PAGINATION_DEFAULT,
      relations: RELATIONS_DEFAULT
    })));

const updateEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf(updateCaseSubjectsList)),
    map((action) => {
      const queryFilter = {};
      return caseSubjectsPaginatedFetchActions.request({
        filter: queryFilter,
        pagination: action.payload,
        relations: RELATIONS_DEFAULT
      });
    })
  );

const changePageEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeCaseSubjectsListPage)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = selectCaseSubjectsPagination(state);
      return updateCaseSubjectsList({
        ...currentPagination,
        page: action.payload
      });
    })
  );

const changePageSizeEpic: Epic<RootAction, RootAction> = (action$, store$) =>
  action$.pipe(
    filter(isActionOf(changeCaseSubjectsListPageSize)),
    withLatestFrom(store$),
    map(([action, state]) => {
      const currentPagination = selectCaseSubjectsPagination(state);
      return updateCaseSubjectsList({
        ...currentPagination,
        page: 1,
        perPage: action.payload
      });
    })
  );

export const createCaseSubjectEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(submitCaseSubjectCreateForm)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const { reject, resolve } = action.meta;
        const guid = state.caseSubjects.new.operationGuid;
        if (!guid) {
          reject(new SubmissionError({ _error: 'Failed to create caseSubject, operation GUID missing' }));
          return [submitCaseSubjectCreateFormFailed(new EntityCreateError('operation guid missing'))];
        }
        const submission = {
          guid,
          data: transformCaseSubjectFormDataToCaseSubjectSubmission(action.payload)
        };
        return from(curatorApi.createCaseSubject(submission)).pipe(
          switchMap((response) => {
            if (response.status >= 300) {
              // TODO: Parse errors into friendlier format and field-specific where possible
              reject(new SubmissionError({ _error: `Failed to create caseSubject\n\n${JSON.stringify(response.data, null, 2)}` }));
              return [submitCaseSubjectCreateFormFailed(new EntityCreateError('server error'))];
            }
            resolve();
            return [
              submitCaseSubjectCreateFormCompleted(),
              pushRoute('/case-subject-manager')
            ];
          }),
          catchError((err: Error) => {
            reject(new SubmissionError({ _error: `Failed to create caseSubject. (${err.message})` }));
            return of(submitCaseSubjectCreateFormFailed(new EntityCreateError('unknown error')));
          })
        );
      })
    );

export const updateCaseSubjectEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(submitCaseSubjectEditForm)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const { reject, resolve } = action.meta;
        const id = state.caseSubjects.edit.id;
        const guid = state.caseSubjects.edit.operationGuid;
        if (!id) {
          reject(new SubmissionError({ _error: 'Failed to update caseSubject, id missing' }));
          return [submitCaseSubjectEditFormFailed(new EntityUpdateError('caseSubject id missing'))];
        }
        if (!guid) {
          reject(new SubmissionError({ _error: 'Failed to update caseSubject, operation GUID missing' }));
          return [submitCaseSubjectEditFormFailed(new EntityUpdateError('operation guid missing'))];
        }
        const submission = {
          id,
          guid,
          data: transformCaseSubjectFormDataToCaseSubjectSubmission(action.payload)
        };
        return from(curatorApi.updateCaseSubject(submission)).pipe(
          switchMap((response) => {
            if (response.status >= 300) {
              // TODO: Parse errors into friendlier format and field-specific where possible
              reject(new SubmissionError({ _error: `Failed to update caseSubject\n\n${JSON.stringify(response.data, null, 2)}` }));
              return [submitCaseSubjectEditFormFailed(new EntityUpdateError('server error'))];
            }
            resolve();
            return [
              submitCaseSubjectEditFormCompleted(),
              pushRoute('/case-subject-manager')
            ];
          }),
          catchError((err: Error) => {
            reject(new SubmissionError({ _error: `Failed to update caseSubject. (${err.message})` }));
            return of(submitCaseSubjectEditFormFailed(new EntityUpdateError('unknown error')));
          })
        );
      })
    );

export const fetchCaseSubjectForEditEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(loadCaseSubjectForEdit.request)),
      switchMap((action) => from(curatorApi.getCaseSubject(action.payload)).pipe(
        switchMap((response) =>
          of(
            loadCaseSubjectForEdit.success(response.data),
            setFormDataFromCaseSubject({ formId: 'case-subject-edit', data: response.data })
          )
        ),
        catchError((err: Error) => {
          return from([
            loadCaseSubjectForEdit.failure(new EntityFetchError(err.message)),
            pushRoute('/case-subject-manager')
          ]);
        })
      )));

export const fetchCaseSubjectForDeleteEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, _store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(loadCaseSubjectForDelete.request)),
      switchMap((action) =>
        forkJoin([
          from(curatorApi.getCaseSubject(action.payload)),
          from(curatorApi.getCaseQueryEntityDeleteEligibility('case-subject', action.payload))
        ]).pipe(
          switchMap(([fetchEntityResponse, deleteEligibilityResponse]) =>
            of(loadCaseSubjectForDelete.success({
              data: fetchEntityResponse.data,
              info: deleteEligibilityResponse.data
            }))),
          catchError((err) =>
            of(
              loadCaseSubjectForDelete.failure(err),
              pushRoute('/case-subject-manager')
            ))
        ))
    );

export const deleteCaseSubjectEpic: Epic<RootAction, RootAction, RootState, Services> = (action$, store$, { curatorApi }) =>
  action$
    .pipe(
      filter(isActionOf(deleteCaseSubject.request)),
      withLatestFrom(store$),
      switchMap(([action, state]) => {
        const guid = state.caseSubjects.delete.operationGuid;
        if (!guid) {
          return of(deleteCaseSubject.failure(new DeleteError('operation guid missing')));
        }
        if (!state.caseSubjects.delete.info?.canDelete) {
          return of(deleteCaseSubject.failure(new DeleteError('delete failed state check')));
        }
        const submission = {
          guid,
          id: action.payload
        };
        return from(curatorApi.deleteCaseSubject(submission)).pipe(
          switchMap(() => of(deleteCaseSubject.success(), pushRoute('/case-subject-manager'))),
          catchError((err) => of(deleteCaseSubject.failure(err)))
        );
      })
    );

export const setFormDataFromCaseSubjectEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$
    .pipe(
      filter(isActionOf(setFormDataFromCaseSubject)),
      map((action) => {
        return initialize(action.payload.formId, transformCaseSubjectToFormData(action.payload.data));
      })
    );

export const caseSubjectEpics = [
  fetchCaseSubjectsEpic,
  fetchPaginatedCaseSubjectsEpic,
  refreshEpic,
  updateEpic,
  changePageEpic,
  changePageSizeEpic,
  createCaseSubjectEpic,
  updateCaseSubjectEpic,
  fetchCaseSubjectForEditEpic,
  setFormDataFromCaseSubjectEpic,
  fetchCaseSubjectForDeleteEpic,
  deleteCaseSubjectEpic
];
